import { IUserData } from './user';
import { IVideoInterviewData } from './videoInterviewData';

export enum UserVideoInterviewStatus {
  TODO = 'ToDo',
  STARTED = 'Started',
  SKIPPED = 'Skipped',
  FINISHED = 'Finished',
}

export interface IUserVideoInterviewData {
  id: string;
  userId: string;
  videoInterview: IVideoInterviewData;
  records: {
    questionNumber: number;
    fileName: string;
  }[];
  status: UserVideoInterviewStatus;
  sentDate: string;
  resultDate: string | null;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  isIndependentVI: boolean;
}

export interface IViewUserVideoInterviewData {
  id: string;
  videoInterview: IVideoInterviewData;
  records: {
    questionNumber: number;
    fileName: string;
  }[];
  resultDate: string | null;
  user: Pick<IUserData, 'firstName' | 'lastName'>;
}
