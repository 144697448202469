export const formatErrorMessage = (error: any, label?: string): string => {
  let errorMessage = label ? `${label} \n` : '';

  if (error?.response?.data?.message) {
    if (Array.isArray(error.response.data.message)) {
      error.response.data.message.forEach((message: string) => {
        errorMessage += `${message} \n`;
      });
    } else {
      errorMessage += error.response.data.message;
    }
  } else if (error?.message) {
    if (Array.isArray(error.message)) {
      error.message.forEach((message: string) => {
        errorMessage += `${message} \n`;
      });
    } else {
      errorMessage += error.message;
    }
  }

  return errorMessage || 'Something went wrong';
};
