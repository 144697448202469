import { KeyboardEvent, MouseEvent, useCallback } from 'react';
import { setIsSidebarOpen, sidebarSelectors } from '../redux/sidebar';
import { useAppDispatch, useAppSelector } from '../redux';

export const useSidebar = () => {
  const dispatch = useAppDispatch();
  const isSidebarOpen = useAppSelector(sidebarSelectors.getIsSidebarOpen);

  const handleToggleSidebar = useCallback(
    (event?: KeyboardEvent | MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as KeyboardEvent).key === 'Tab' ||
          (event as KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      dispatch(setIsSidebarOpen(!isSidebarOpen));
    },
    [isSidebarOpen],
  );

  return {
    isSidebarOpen,
    handleToggleSidebar,
  };
};
