import React, { useEffect } from 'react';
import { Typography } from '@mui/material';
import confirmEmailImage from '../../assets/confirm-email.webp';
import confirmEmailImagePNG from '../../assets/confirm-email.png';
import { ResponsiveBreak } from '../ResponsiveBreak/ResponsiveBreak';
import { StepPanel, StepPanelHeader } from '../stepPanel';
import { logEvent } from 'src/services';

interface IProps {
  email: string;
}

export const AuthUserConfirmation: React.FC<IProps> = ({ email }) => {
  useEffect(() => {
    logEvent('launchpod-email-confirmation-page-loaded');
  }, []);

  return (
    <StepPanel
      data-testid="auth-user-confirmation"
      maxWidth="34.25rem"
      gap="1.5rem"
    >
      <StepPanelHeader
        srcSetPng={confirmEmailImagePNG}
        srcSetWebp={confirmEmailImage}
        alt="Confirm email"
        imageWidth={{ xs: 57, sm: 90, xxl: 100 }}
      >
        Verify your email address
      </StepPanelHeader>
      <Typography variant="body1">
        We’ve sent you an email confirmation.
      </Typography>
      <Typography variant="body1">
        Click on the link in that email <ResponsiveBreak breakpoint="sm" />
        to continue your journey.
      </Typography>
      <Typography
        variant="body1"
        color="text.secondary"
        sx={{ wordBreak: 'break-all' }}
      >
        {email}
      </Typography>
    </StepPanel>
  );
};

export default AuthUserConfirmation;
