import { css, SerializedStyles } from '@emotion/react';
import { useTheme } from '@mui/material';
import { Color } from '../../../types';

export const useSidebarFooterStyles = (color: Color): SerializedStyles => {
  const theme = useTheme();

  const textColor =
    color === Color.INVERTED
      ? theme.palette.text.invertedSecondary
      : theme.palette.text.secondary;

  return css`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding-bottom: 2rem;
    padding-left: 1.25rem;
    margin-top: auto;

    .links {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 0.5rem;

      & > span {
        width: 0.1rem;
        height: 0.1rem;
        background: ${textColor};
        border-radius: 50%;
      }

      & > :not(:last-child) {
        margin-right: 0.4rem;
      }
    }

    .MuiTypography-root {
      color: ${textColor};
      text-decoration-color: ${textColor};
      text-decoration-thickness: 0.5px;
      text-underline-offset: 20%;
      font-size: ${theme.typography.body2.fontSize};
      font-weight: ${theme.typography.body2.fontWeight};
    }

    .MuiLink-root {
      cursor: pointer;
    }
  `;
};
