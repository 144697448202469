/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';

import { Box, Link, Typography } from '@mui/material';
import Referral from '../../assets/referral.png';
import { Link as RouterLink } from 'react-router-dom';
import { useLoadGsiScript } from '../../hooks';
import { useAppDispatch } from '../../redux';
import { login } from '../../redux/referFriend';
import { OAuthSettings } from '../../constants';

const ReferFriend: React.FC = () => {
  const dispatch = useAppDispatch();

  useLoadGsiScript();

  const handleGAuthCallback = (response: any) => {
    const payload = { credential: response.credential };
    dispatch(login(payload));
  };

  // data-callback value must be globally scoped function
  useEffect(() => {
    (window as any).handleGAuthCallback = handleGAuthCallback;

    return () => {
      delete (window as any).handleGAuthCallback;
    };
  }, []);

  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      sx={{ width: '100%' }}
    >
      <Box
        sx={(theme) => ({
          background: theme.palette.common.white,
          padding: '62px 100px',
          borderRadius: '48px',
          maxWidth: '615px',
          width: '100%',
          [theme.breakpoints.down('md')]: {
            padding: '48px 32px',
          },
        })}
      >
        <Box display="flex" justifyContent="center">
          <img src={Referral} alt="referral" width="195" />
        </Box>
        <Typography
          variant="h2"
          color="primary"
          sx={{
            fontSize: '30px',
            fontWeight: 600,
            marginTop: '40px',
            textAlign: 'center',
          }}
        >
          Refer a friend
        </Typography>
        <Typography
          variant="body1"
          color="primary"
          sx={{
            fontSize: '16px',
            marginTop: '40px',
            textAlign: 'center',
          }}
        >
          Came across the perfect opportunity for your
          <br />
          friend? Put in a good word for your buddy and grab
          <br />a nice cash bonus once they join AgileEngine.
        </Typography>
        <Typography
          variant="body1"
          color="text.secondary"
          gutterBottom
          sx={{
            marginTop: '40px',
            textAlign: 'center',
          }}
        >
          Continue with your AgileEngine email
        </Typography>
        <Box display="flex" justifyContent="center">
          <div
            id="g_id_onload"
            data-client_id={
              process.env.REACT_APP_ENV
                ? OAuthSettings[process.env.REACT_APP_ENV]
                : '1054972668338-k1ii46jig69admhmid4hj0q60aquq4k6.apps.googleusercontent.com'
            }
            data-context="signin"
            data-ux_mode="popup"
            data-callback="handleGAuthCallback"
            data-auto_prompt="false"
          ></div>

          <div
            className="g_id_signin"
            data-type="standard"
            data-shape="pill"
            data-theme="filled_blue"
            data-text="signin_with"
            data-size="large"
            data-locale="en-US"
            data-logo_alignment="left"
          ></div>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" mt={6}>
        <Typography
          variant="body2"
          color="secondary"
          textAlign="center"
          fontSize="0.675rem"
          mx={2}
        >
          <Link component={RouterLink} to="/terms-of-use">
            Terms of use
          </Link>
        </Typography>
        <Typography
          variant="body2"
          color="secondary"
          textAlign="center"
          fontSize="0.675rem"
          mx={2}
        >
          <Link href="https://agileengine.com/privacy-policy" target="_blank">
            Privacy policy
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default ReferFriend;
