import React, { ReactElement } from 'react';

import {
  Box,
  FormControlLabel,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { SelectableWrapper, RadioButton } from '../../../components';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import CheckIcon from '@mui/icons-material/Check';

import { AvailableTestTaskWithPositionsData } from '../../../types';
import { TEST_TASK_STATUS, TestTaskTypeToText } from '../../../constants';

interface IProps {
  selectedTestTask: string | null;
  testTasks?: AvailableTestTaskWithPositionsData[];
  testId?: string;
  handleChange: (
    testTask: AvailableTestTaskWithPositionsData,
    e?: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>,
  ) => void;
}

const STEP_MAX_WIDTH = '25rem';

const selectableIcon = (
  tt: AvailableTestTaskWithPositionsData,
  singleTT: boolean,
) => {
  if (tt.status === TEST_TASK_STATUS.FAILED) {
    return (
      <DoNotDisturbAltIcon color="secondary" sx={{ width: '24px', mx: 1 }} />
    );
  } else if (tt.status === TEST_TASK_STATUS.PASSED || singleTT) {
    return <Box width={10} />;
  }
  return <RadioButton />;
};

const TestTasksSelectableList = ({
  selectedTestTask,
  testTasks,
  testId,
  handleChange,
}: IProps): ReactElement => {
  return (
    <RadioGroup value={selectedTestTask} sx={{ mb: 1 }}>
      <Box
        display="flex"
        flexDirection="column"
        flexWrap="wrap"
        width="100%"
        sx={(theme) => ({
          [theme.breakpoints.up('xl')]: {
            width: STEP_MAX_WIDTH,
          },
        })}
        data-testid={testId}
      >
        {testTasks?.map((testTask) => (
          <Box
            key={testTask.id}
            sx={(theme) => ({
              margin: '0 0 0.25rem 0',
              maxWidth: STEP_MAX_WIDTH,
              [theme.breakpoints.down('xl')]: {
                margin: '0 0 0.5rem 0',
              },
            })}
          >
            <SelectableWrapper
              value={testTask.id}
              selectedValue={selectedTestTask}
              onClick={(_, e) => handleChange(testTask, e)}
              display="block"
              isDisabled={testTask.status === TEST_TASK_STATUS.FAILED}
              isSelected={
                testTask.status === TEST_TASK_STATUS.PASSED || undefined
              }
              testid="test-task-option"
            >
              <Stack direction="column">
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <FormControlLabel
                    value={testTask.id}
                    control={selectableIcon(testTask, testTasks.length === 1)}
                    sx={{
                      cursor:
                        testTask.status === TEST_TASK_STATUS.FAILED
                          ? 'auto'
                          : 'pointer',

                      width: '100%',
                    }}
                    label={
                      <Box display="flex" justifyContent="space-between">
                        <Typography
                          variant="body1"
                          color={
                            testTask.status === TEST_TASK_STATUS.FAILED
                              ? 'secondary'
                              : 'primary'
                          }
                        >
                          {testTask.name}
                        </Typography>
                        {testTask.status === TEST_TASK_STATUS.FAILED && (
                          <Typography variant="body1" color="secondary">
                            {testTask.additionalInfo?.score || 0}% 😥
                          </Typography>
                        )}
                      </Box>
                    }
                  />
                  {testTask.status === TEST_TASK_STATUS.PASSED ? (
                    <CheckIcon color="success" />
                  ) : (
                    <></>
                  )}
                  {testTask.isRequiredForUserPosition ? (
                    <Typography
                      variant="caption"
                      color="text.brand"
                      whiteSpace="nowrap"
                    >
                      Required for your position
                    </Typography>
                  ) : (
                    <></>
                  )}
                </Stack>
                <Typography
                  variant="body2"
                  color="secondary"
                  marginLeft={
                    testTask.status === TEST_TASK_STATUS.PASSED ||
                    testTasks.length === 1
                      ? '0rem'
                      : '2rem'
                  }
                  marginTop="0.25rem"
                >
                  {TestTaskTypeToText[testTask.type]}
                </Typography>
              </Stack>
            </SelectableWrapper>
          </Box>
        ))}
      </Box>
    </RadioGroup>
  );
};

export default TestTasksSelectableList;
