import { useEffect } from 'react';
import { useAppDispatch } from '../redux';
import { fetchUserGeoLocation } from '../redux/userGeoLocation';
import { useIsStandalonePage } from './useIsStandalonePage';

export const useFetchUserGeoLocation = (shouldFetch?: boolean) => {
  const dispatch = useAppDispatch();
  const isStandalonePage = useIsStandalonePage();

  useEffect(() => {
    if (isStandalonePage || !shouldFetch) return;

    dispatch(fetchUserGeoLocation());
  }, [isStandalonePage, shouldFetch]);
};
