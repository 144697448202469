import { IUnsubscribeFromRemindersSliceState } from './unsubscribeFromReminders.slice';
import { unsubscribeFromRemindersStoreKey } from './unsubscribeFromReminders.const';
import { IUnsubscribeFromRemindersRequest } from '@types';

interface IState {
  [unsubscribeFromRemindersStoreKey]: IUnsubscribeFromRemindersSliceState;
}

const selectUnsubscribeFromRemindersState = (state: IState) => {
  return state[unsubscribeFromRemindersStoreKey];
};

const getUnsubscribeFromRemindersApiStatus = (state: IState) => {
  return selectUnsubscribeFromRemindersState(state).apiStatus;
};

const getUnsubscribeFromRemindersData = (
  state: IState,
): IUnsubscribeFromRemindersRequest | null => {
  return selectUnsubscribeFromRemindersState(state).data;
};

export const unsubscribeFromRemindersSelectors = {
  getUnsubscribeFromRemindersApiStatus,
  getUnsubscribeFromRemindersData,
};
