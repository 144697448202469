import { Box, Typography } from '@mui/material';
import TestTasksSelectableList from '../components/TestTasksSelectableList';

import { AvailableTestTaskWithPositionsData } from '../../../types';

interface IOneTTPassedLayoutProps {
  testTaskSelected: string | null;
  testTasks: AvailableTestTaskWithPositionsData[];
  isPerformingAction: boolean;
  testTaskReminderDate: string | null;
  handleTTChange: (testTask: AvailableTestTaskWithPositionsData) => void;
  handlePassTTClick: () => void;
  handleScheduleForLater: () => void;
}

const OneTTPassedLayout: React.FC<IOneTTPassedLayoutProps> = ({
  testTaskSelected,
  testTasks,
  handleTTChange,
}) => {
  const ttScore = testTasks?.[0]?.additionalInfo?.score;

  return (
    <>
      <Typography
        variant="body1"
        mb={1}
        data-testid="tt-already-passed-subtext"
      >
        Tests for your skills:
      </Typography>
      <TestTasksSelectableList
        selectedTestTask={testTaskSelected}
        testTasks={testTasks}
        handleChange={handleTTChange}
      />
      <Typography variant="body1" data-testid="already-passed-one-tt-notifier">
        <strong>We’ve found your {ttScore}% coding test score!</strong>
        <br />
        Our Talent Executive will be happy{' '}
        <Box
          component="br"
          sx={(theme) => ({
            [theme.breakpoints.down('md')]: {
              display: 'none',
            },
          })}
        />
        to discuss the next steps with you.
      </Typography>
    </>
  );
};

export default OneTTPassedLayout;
