import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { SlideTransition } from '..';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { isKeyClick } from '../../utils';

interface IProps {
  children?: React.ReactNode;
  isOpen: boolean;
  handleClose: () => void;
  canClickOutside?: boolean;
  maxWidth?: string;
  isCloseDisabled?: boolean;
  overflowVisible?: boolean;
}

export const BasicDialog: React.FC<IProps> = ({
  children,
  isOpen = true,
  handleClose,
  canClickOutside = true,
  maxWidth,
  isCloseDisabled = false,
  overflowVisible = false,
}) => {
  const themeBreakpoints = useTheme();
  const fullScreen = useMediaQuery(themeBreakpoints.breakpoints.down('sm'));
  return (
    <Dialog
      open={isOpen}
      onClose={canClickOutside ? handleClose : undefined}
      TransitionComponent={SlideTransition}
      scroll="body"
      fullWidth
      fullScreen={fullScreen}
      sx={{
        maxWidth: '100vw',
        '& .MuiPaper-root': {
          overflow: overflowVisible ? 'visible' : undefined,
        },
        '& .MuiPaper-root, & .MuiDialog-paperScrollBody': {
          borderRadius: { md: '1rem' },
          maxWidth: { md: maxWidth ?? '31rem' },
          height: 'initial',
        },
      }}
    >
      <DialogTitle
        component={Stack}
        direction="row"
        sx={(theme) => ({
          minHeight: '4.75rem',
          justifyContent: 'end',
          px: 2,
          [theme.breakpoints.up('sm')]: {
            pt: 3,
          },
        })}
      >
        {!isCloseDisabled && (
          <IconButton
            onClick={handleClose}
            aria-label={'close'}
            onKeyUp={(e) => {
              if (isKeyClick(e)) {
                handleClose();
              }
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent
        sx={(theme) => ({
          pb: 6,
          [theme.breakpoints.up('md')]: {
            px: 12,
          },
          [theme.breakpoints.up('sm')]: {
            px: 9,
            pb: 9,
          },
        })}
      >
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default BasicDialog;
