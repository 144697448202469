import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { escapeRegExp } from 'lodash';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  TextField,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Stack,
  styled,
  ListProps,
  Link,
  Typography,
} from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';

import { EXTERNAL_LINKS } from 'src/constants';
import { CustomButton } from 'src/components';
import { isStatusIdle } from 'src/utils';
import {
  fetchReferralJobOpenings,
  referralJobOpeningsSelectors,
} from 'src/redux/referralJobOpenings';

const StyledList = styled(List)<ListProps>({
  width: '100%',
  '& .MuiListItem-root': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1rem',
    marginBottom: 0,
  },
  '& .MuiListItemText-root': {
    margin: 0,
  },
  '& .ae-custom-button_front': {
    paddingTop: '0.50rem !important',
    paddingBottom: '0.50rem !important',
  },
  '& .ae-custom-button_text': {
    fontSize: '0.813rem !important',
  },
});

export const ReferralJobOpeningModal: React.FC<{
  isOpen: boolean;
  handleClose: () => void;
  handleSelectJobOpening: (payload: string) => void;
}> = ({ isOpen, handleClose, handleSelectJobOpening }) => {
  const dispatch = useAppDispatch();
  const apiStatus = useAppSelector(
    referralJobOpeningsSelectors.getReferralJobOpeningsApiStatus,
  );

  const jobOpenings = useAppSelector(
    referralJobOpeningsSelectors.getReferralJobOpenings,
  );

  const [searchString, setSearchString] = useState<string>('');

  useEffect(() => {
    if (isStatusIdle(apiStatus)) {
      dispatch(fetchReferralJobOpenings());
    }
  }, [apiStatus]);

  useEffect(() => {
    if (!isOpen && searchString) {
      setSearchString('');
    }
  }, [isOpen, searchString]);

  const filteredJobOpenings = useMemo(() => {
    const searchTerm = escapeRegExp(searchString.toLowerCase());
    return (jobOpenings ?? []).filter(
      (jobOpening) =>
        jobOpening.LP_Specializations?.toLowerCase().match(
          new RegExp(searchTerm, 'g'),
        ) ||
        jobOpening.TechnicalFlow?.toLowerCase().match(
          new RegExp(searchTerm, 'g'),
        ) ||
        jobOpening.Stack?.toLowerCase().match(new RegExp(searchTerm, 'g')) ||
        jobOpening.Client_Name?.toLowerCase().match(
          new RegExp(searchTerm, 'g'),
        ) ||
        jobOpening.Marketing_Title?.toLowerCase().match(
          new RegExp(searchTerm, 'g'),
        ),
    );
  }, [jobOpenings, searchString]);

  const isSearching = useMemo(() => Boolean(searchString), [searchString]);

  return (
    <Dialog
      fullWidth
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        sx: { maxWidth: '46.5rem', height: '34rem' },
      }}
    >
      <DialogTitle variant="h3" color="primary" marginBottom="0">
        Select position
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 12,
          top: 12,
        }}
      >
        <CloseRoundedIcon color="secondary" fontSize="small" />
      </IconButton>
      <DialogContent>
        <Stack direction="column" gap="1rem" height="100%">
          <TextField
            fullWidth
            hiddenLabel
            placeholder="Search by specialization, stack, client, etc"
            type="search"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchRoundedIcon />
                </InputAdornment>
              ),
            }}
            value={searchString}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setSearchString(event.target.value);
            }}
          />
          {isSearching ? (
            <StyledList sx={{ width: '100%', overflowY: 'auto' }}>
              {filteredJobOpenings?.map((jobOpening) => (
                <ListItem key={jobOpening.id}>
                  <ListItemText
                    id={jobOpening.id}
                    primary={jobOpening.Marketing_Title}
                    secondary={jobOpening.Client_Name}
                  />
                  <Stack direction="row" gap="1rem">
                    {jobOpening.Careers_Site_Link && (
                      <Stack direction="row" gap="0.25rem">
                        <Link
                          target="_blank"
                          rel="noopener"
                          variant="body2"
                          whiteSpace="nowrap"
                          href={jobOpening.Careers_Site_Link}
                        >
                          More info
                        </Link>
                        <OpenInNewRoundedIcon sx={{ fontSize: '1.125rem' }} />
                      </Stack>
                    )}
                    <CustomButton
                      fullWidth={true}
                      maxWidth={'6.625rem'}
                      onClick={() => handleSelectJobOpening(jobOpening.id)}
                      label={'Select'}
                    />
                  </Stack>
                </ListItem>
              ))}
            </StyledList>
          ) : (
            <Stack
              direction="column"
              gap="1rem"
              alignItems="center"
              justifyContent="center"
              flex={1}
            >
              <Typography variant="body1" color="secondary">
                Find position that as you think matches your friends skillset.
              </Typography>
              <Stack direction="row" gap="0.25rem">
                <Link
                  target="_blank"
                  rel="noopener"
                  variant="body2"
                  href={EXTERNAL_LINKS.JOIN_AGILEENGINE}
                >
                  join.agileengine.com
                </Link>
                <OpenInNewRoundedIcon sx={{ fontSize: '1.125rem' }} />
              </Stack>
            </Stack>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
