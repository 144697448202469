import { createSlice } from '@reduxjs/toolkit';
import { exchangeRatesStoreKey } from './exchangeRates.const';
import { fetchExchangeRates } from './exchangeRates.thunks';
import { API_STATUS } from '../../constants';
import { RatesType } from '@types';

export interface IExchangeRatesSliceState {
  apiStatus: API_STATUS;
  data: RatesType | null;
  apiError: string;
}

const initialState: IExchangeRatesSliceState = {
  apiStatus: API_STATUS.IDLE,
  data: null,
  apiError: '',
};

export const exchangeRatesSlice = createSlice({
  name: exchangeRatesStoreKey,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchExchangeRates.pending, (state) => {
        state.apiStatus = API_STATUS.LOADING;
      })
      .addCase(fetchExchangeRates.fulfilled, (state, action) => {
        state.apiStatus = API_STATUS.COMPLETE;
        state.data = action.payload.reduce((acc, exr) => {
          return { ...acc, [exr.currency]: exr.exchangeRate };
        }, {} as RatesType);
      })
      .addCase(fetchExchangeRates.rejected, (state, action) => {
        state.apiStatus = API_STATUS.FAILED;
        state.apiError = action.payload as string;
      });
  },
});
