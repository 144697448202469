import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiEndpoints } from '../../constants';
import { formatErrorMessage } from '../../utils';
import { notificationAlert } from '../notifications';
import { massCampaignCandidateStoreKey } from './massCampaignCandidate.const';
import { IMassCampaign } from '@types';

export const postUserWithMassCampaignSignUp = createAsyncThunk(
  `${massCampaignCandidateStoreKey}/postUserWithMassCampaignSignUp`,
  async (data: IMassCampaign, thunkAPI) => {
    try {
      const response = await axios.post(
        apiEndpoints.userSignedWithMassCampaignPath(),
        data,
      );

      return response.data;
    } catch (err: any) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(err);
    }
  },
);
