/** @jsxImportSource @emotion/react */
import React from 'react';
import { Box, SxProps } from '@mui/material';
import { useChatMessageStyles } from './ChatMessage.styles';

interface IProps {
  children: string | React.ReactNode;
  isInCenter?: boolean;
  isInApplicationSuccess?: boolean;
  isInUnsubscribeLayout?: boolean;
  sx?: SxProps;
}

export const ChatMessage: React.FC<IProps> = ({
  isInCenter,
  children,
  isInApplicationSuccess,
  isInUnsubscribeLayout,
  sx = {},
}) => {
  const styles = useChatMessageStyles();
  return (
    <Box
      css={styles}
      className={`chat-message ${isInCenter ? 'isInCenter' : ''} ${
        isInApplicationSuccess ? 'isInApplicationSuccess' : ''
      } ${isInUnsubscribeLayout ? 'isInUnsubscribeLayout' : ''}`}
      sx={sx}
      data-testid="gamified-message"
    >
      <Box className="children-wrapper">{children}</Box>
    </Box>
  );
};

export default ChatMessage;
