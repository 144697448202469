import React, { FC, ReactNode, useCallback, useRef, useState } from 'react';
import { Box, Collapse } from '@mui/material';
import { CollapseButton } from './CollapseButton';
import { CollapseProps } from '@mui/material/Collapse/Collapse';

interface IProps extends CollapseProps {
  children: ReactNode;
  textLength: number;
  limitToCollapse?: number;
}

export const CollapseSection: FC<IProps> = ({
  children,
  collapsedSize = 320,
  limitToCollapse = 1000,
  textLength,
  ...props
}) => {
  const containerRef = useRef<HTMLDivElement>();
  const [checked, setChecked] = useState(false);

  const handleChange = useCallback(() => {
    setChecked((prev) => !prev);

    containerRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  if (textLength < limitToCollapse) {
    return <Box>{children}</Box>;
  }

  return (
    <Box>
      <Box
        display="flex"
        flexDirection="column"
        position="relative"
        mb={checked ? 2 : 0}
        ref={containerRef}
      >
        <Collapse in={checked} collapsedSize={collapsedSize} {...props}>
          {children}
        </Collapse>
        {!checked && (
          <Box
            sx={(theme) => ({
              position: 'absolute',
              inset: 0,
              zIndex: 1,
              background: theme.palette.background.whiteLinearGradient,
            })}
          />
        )}
      </Box>
      <CollapseButton isChecked={checked} onClick={handleChange}>
        {checked ? 'Collapse' : 'Expand'}
      </CollapseButton>
    </Box>
  );
};
