const usersApi = '/users';

export const deleteUser = () => `${usersApi}`;
export const countryPath = () => `${usersApi}/country`;
export const cityPath = () => `${usersApi}/city`;
export const officePath = () => `${usersApi}/office`;
export const englishLevelPath = () => `${usersApi}/english-level`;
export const techSkillsPath = () => `${usersApi}/tech-skills`;
export const experiencePath = () => `${usersApi}/experience`;
export const seniorityPath = () => `${usersApi}/seniority`;
export const compensationPath = () => `${usersApi}/compensation`;
export const emailPath = () => `${usersApi}/email`;
export const namesPath = () => `${usersApi}/full-name`;
export const skillsPath = () => `${usersApi}/skills`;
export const engagementPath = () => `${usersApi}/employment-type`;
export const submitProfilePath = () => `${usersApi}/apply`;
export const noticePeriodPath = () => `${usersApi}/notice-period`;
export const professionalIdentity = () => `${usersApi}/linkedin-link`;
export const userSignedWithZohoIdPath = () => `${usersApi}/signup-with-zoho-id`;
export const userSignedWithMassCampaignPath = () =>
  `${usersApi}/signup-with-mass-campaign`;
export const userSignedWithSourcePath = () => `${usersApi}/signup-with-source`;
export const resolveUserDuplicationPath = () =>
  `${usersApi}/resolve-user-duplication`;
export const confirmIntermediateScreenPath = () =>
  `${usersApi}/confirm-intermediate-screen`;
export const selectAnotherTTPath = () => `${usersApi}/select-another-tt`;
export const downloadCvPath = () => `${usersApi}/download-cv`;
export const cancelApplicationAPI = () => `${usersApi}/cancel-application`;
