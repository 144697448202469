import React from 'react';
import { Box, Typography } from '@mui/material';
import { NonTechRowOfImages } from './RowOfImages';

const RecordListOfDescription = () => {
  return (
    <Box>
      {NonTechRowOfImages.map((block, index) => {
        return (
          <Box key={index} display="flex" alignItems="center" pb={2}>
            <Box
              display="flex"
              minWidth="3rem"
              justifyContent="center"
              fontSize="2rem"
            >
              <img src={block.image} alt={block.alt} width="50px" />
            </Box>
            <Typography
              color="text.primary"
              variant="body2"
              px={2}
              sx={{ whiteSpace: 'pre-line' }}
            >
              {block.text}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

export default RecordListOfDescription;
