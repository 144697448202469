export enum EnglishLevels {
  PreIntermediate = 'Pre-Intermediate',
  Intermediate = 'Intermediate',
  UpperIntermediate = 'Upper-Intermediate',
  Advanced = 'Advanced',
}

export interface IEnglishLevelOption {
  value: string;
  label: string;
  description: string;
}

export const ENGLISH_LEVEL_LIST: IEnglishLevelOption[] = [
  {
    value: 'Pre-Intermediate',
    label: 'Pre-Intermediate or lower',
    description: `You can understand and communicate\n very basic information.`,
  },
  {
    value: 'Intermediate',
    label: 'Intermediate',
    description:
      'You can hold a conversation\n and express ideas and opinions.',
  },
  {
    value: 'Upper-Intermediate',
    label: 'Upper-Intermediate',
    description:
      'You communicate fluently and\n understand most of the topics.',
  },
  {
    value: 'Advanced',
    label: 'Advanced',
    description:
      'You can comprehend and express\n complex professional ideas with ease.',
  },
];
