import React, { FC } from 'react';
import {
  alpha,
  Box,
  BoxProps,
  SvgIcon,
  Theme,
  Typography,
} from '@mui/material';
import { Flex, SocialLinkButton } from '../../components';
import { SOCIAL_LINKS } from '../../constants';
import { useTheme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import {
  AllSystemCSSProperties,
  ResponsiveStyleValue,
} from '@mui/system/styleFunctionSx';

const variants = ({ palette, breakpoints }: Theme) => ({
  primary: {
    titleSx: {
      color: palette.text.secondary,
      mb: 2.5,
    },
    listContainerSx: {
      display: 'grid',
      gridTemplateColumns: 'repeat(6, 1fr)',
      gap: 1,
      [breakpoints.down('sm')]: {
        gridTemplateColumns: 'repeat(3, 1fr)',
      },
    },
    iconContainerSx: {
      bgcolor: palette.brand.accent,
      p: '1.25rem',
      borderRadius: '50%',
      transition: 'all 0.25s ease-in-out',
      '&:hover': {
        bgcolor: alpha(palette.brand.accent, 0.8),
      },
    },
    iconSx: {
      color: 'white',
    },
  },
  secondary: {
    titleSx: {
      color: palette.text.invertedSecondary,
      mb: 2,
      [breakpoints.up('sm')]: {
        textAlign: 'left',
      },
    },
    listContainerSx: {},
    iconContainerSx: {},
    iconSx: {
      color: 'white',
    },
  },
});

interface IProps extends BoxProps {
  variant?: 'primary' | 'secondary';
  justifyContent?: ResponsiveStyleValue<
    AllSystemCSSProperties['justifyContent']
  >;
  withHeader?: boolean | true;
  sx?: SxProps<Theme>;
}
const LetsConnect: FC<IProps> = ({
  variant = 'primary',
  withHeader = true,
  justifyContent,
  sx,
  ...rest
}) => {
  const theme = useTheme();
  const styles = variants(theme)[variant];

  return (
    <Box sx={sx} {...rest}>
      {withHeader && (
        <Typography variant="body2" sx={styles.titleSx}>
          Let’s keep in touch
        </Typography>
      )}
      <Flex justifyContent={justifyContent}>
        <Box sx={styles.listContainerSx}>
          {SOCIAL_LINKS.map((socialLink, index) => (
            <SocialLinkButton
              key={index}
              href={socialLink.href}
              icon={
                <Flex sx={styles.iconContainerSx}>
                  <SvgIcon
                    inheritViewBox
                    component={socialLink.icon}
                    sx={styles.iconSx}
                  />
                </Flex>
              }
              label={socialLink.label}
            />
          ))}
        </Box>
      </Flex>
    </Box>
  );
};

export default LetsConnect;
