import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { referralJobOpeningsStoreKey } from './referralJobOpenings.const';
import { referralJobOpeningsPath } from 'src/constants/apiEndpoints';
import { formatErrorMessage } from '../../utils';
import { notificationAlert } from '../notifications';
import { IJobOpeningRaw } from '@types';

export const fetchReferralJobOpenings = createAsyncThunk(
  `${referralJobOpeningsStoreKey}/fetchReferralJobOpenings`,
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(referralJobOpeningsPath());

      return response.data as IJobOpeningRaw[];
    } catch (err: any) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(err);
    }
  },
);
