import {
  Box,
  Divider,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { CustomButton, TextButton } from '../../../components';
import { useAppDispatch, useAppSelector } from '../../../redux';
import { authSelectors, logout } from '../../../redux/auth';
import { userSelectors } from '../../../redux/user';
import { isKeyClick, isStatusLoading } from '../../../utils';
import DeleteAccountDialog from '../../deleteAccountDialog/DeleteAccountDialog';

interface IProps {
  handleClose?: () => void;
}

const AccountDataForm: React.FC<IProps> = ({ handleClose }) => {
  const dispatch = useAppDispatch();
  const userData = useAppSelector(userSelectors.getUserData)!;
  const apiStatus = useAppSelector(authSelectors.getAuthApiStatus);
  const isLoading = isStatusLoading(apiStatus);
  const [isShownCookieBot, setIsShownCookieBot] = useState(
    !window.Cookiebot?.hasResponse,
  );
  const [openDeleteAccount, setOpenDeleteAccount] = useState(false);

  const handleLogout = async () => {
    const response = await dispatch(logout());

    if (logout.fulfilled.match(response)) {
      if (handleClose) {
        handleClose();
      }
    }
  };
  const handleCookiebot = async () => {
    window.Cookiebot?.renew();
  };

  useEffect(() => {
    setIsShownCookieBot(window.Cookiebot?.hasResponse);
  }, []);

  return (
    <>
      <Typography variant="h2" sx={{ mb: 2, px: 1.5 }}>
        Your account
      </Typography>

      <Stack spacing={2} sx={{ mb: 5 }}>
        <TextField
          name="email"
          placeholder="Email"
          label="Email"
          type="text"
          variant="outlined"
          value={userData.email}
          disabled
        />
        <Box sx={{ px: 1.5 }}>
          <Typography mb={2}>
            You may receive email updates on your application progress, meeting
            invitations, and reminders. No spam.
          </Typography>
          <CustomButton
            color="secondary"
            isDisabled={isLoading}
            isLoading={isLoading}
            onClick={handleLogout}
            label="Log out"
            type="submit"
          />
        </Box>
      </Stack>

      <Divider sx={{ mb: 4 }} />

      {isShownCookieBot && (
        <Box px={1.5} mb={6}>
          <Typography variant="h1" sx={{ mb: 2 }}>
            Cookies consent
          </Typography>

          <TextButton onClick={handleCookiebot} underlined>
            Customize...
          </TextButton>
        </Box>
      )}

      <Divider sx={{ mb: 4 }} />

      <Box px={1.5} mb={4}>
        <Typography variant="h1" sx={{ mb: 2 }}>
          Delete account and data
        </Typography>
        <Typography sx={{ mb: 2 }}>
          You can permanently erase your job application data from our database.
        </Typography>

        <Box display="flex" justifyContent="space-between">
          <Link
            onClick={() => setOpenDeleteAccount(true)}
            onKeyUp={(e: React.KeyboardEvent<any>) => {
              if (isKeyClick(e)) {
                setOpenDeleteAccount(true);
              }
            }}
            role="button"
            tabIndex={0}
            sx={(theme) => ({
              color: theme.palette.error.main,
              cursor: 'pointer',
              textDecoration: 'underline',
              '&:hover': {
                textDecoration: 'none',
              },
            })}
          >
            Delete profile
          </Link>
          <Link
            component={RouterLink}
            to="/terms-of-use"
            color="text.secondary"
          >
            Terms of use
          </Link>
          <Link
            href="https://agileengine.com/privacy-policy"
            target="_blank"
            variant="body1"
            color="text.secondary"
          >
            Privacy policy
          </Link>
        </Box>
      </Box>

      <Box sx={{ px: 1.5 }}>
        <Typography color="text.secondary">
          Please contact us if you need more help
        </Typography>
        <Link href="mailto:careers@agileengine.com" color="text.secondary">
          careers@agileengine.com
        </Link>
      </Box>

      <DeleteAccountDialog
        isOpen={openDeleteAccount}
        handleClose={() => setOpenDeleteAccount(false)}
      />
    </>
  );
};

export default AccountDataForm;
