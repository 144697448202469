import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Link,
  Stack,
  StackProps,
  TextField,
  Typography,
} from '@mui/material';
import { CustomButton } from '../../../../components';
import { useAppDispatch, useAppSelector } from '../../../../redux';
import { updateEmail, userSelectors } from '../../../../redux/user';
import {
  authSelectors,
  authUser,
  clearAuthError,
  setSubmitProfileEmailSent,
} from '../../../../redux/auth';
import { magicLinkCandidateSelectors } from 'src/redux/magicLinkCandidate';
import { positionsSelectors } from 'src/redux/positions';
import { massCampaignCandidateSelectors } from 'src/redux/massCampaignCandidate';
import { useIsMobileView, usePersistUserAnswers } from '../../../../hooks';
import { isStatusLoading } from '../../../../utils';
import { validationSchema } from './validationSchema';
import SignInDialog from '../../../email/components/SignInDialog';
import ConsentTermsDialog from '../../../email/components/ConsentTermsDialog';
import { logEvent } from 'src/services';
import { candidateSourceSelectors } from 'src/redux/candidateSource';

interface IEmailFormProps extends StackProps {
  buttonTitle?: string;
}

const EmailForm: React.FC<IEmailFormProps> = ({ buttonTitle, ...rest }) => {
  const dispatch = useAppDispatch();
  const isMobileView = useIsMobileView();

  const userData = useAppSelector(userSelectors.getUserData);
  const zohoId = useAppSelector(
    magicLinkCandidateSelectors.getMagicLinkCandidateZohoId,
  );
  const position = useAppSelector(positionsSelectors.getPositionData);
  const massCampaignData = useAppSelector(
    massCampaignCandidateSelectors.getMassCampaignCandidateData,
  );
  const candidateSource = useAppSelector(
    candidateSourceSelectors.getCandidateSourceData,
  );
  const isEmailLoading = useAppSelector(
    userSelectors.getIsUserPerformingAction,
  );
  const authApiStatus = useAppSelector(authSelectors.getAuthApiStatus);

  const [savedEmail, setSavedEmail] =
    usePersistUserAnswers<Record<string, string>>('emailStep');

  const [isOpenSignInDialog, setIsOpenSignInDialog] = useState(false);
  const [isOpenConsentTermsDialog, setIsOpenConsentTermsDialog] =
    useState(false);

  const formik = useFormik({
    initialValues: {
      email: userData?.email
        ? userData.email
        : savedEmail?.email
        ? savedEmail.email
        : '',
      isConsentChecked: savedEmail?.email ? true : false,
    },
    validationSchema,
    onSubmit: async (values) => {
      logEvent('launchpod-email-continue-click');

      if (userData) {
        dispatch(updateEmail({ ...values }));
        setSavedEmail({ email: formik.values.email });
      } else {
        dispatch(
          authUser({
            ...values,
            zohoId,
            position,
            massCampaignData,
            candidateSource,
          }),
        );
      }

      dispatch(setSubmitProfileEmailSent(false));
      dispatch(clearAuthError());
    },
  });

  useEffect(() => {
    setSavedEmail({ email: formik.values.email });
  }, [formik.values.email]);

  const handleLoginClick = () => {
    setIsOpenSignInDialog(true);
  };

  const handleModalClose = () => {
    setIsOpenSignInDialog(false);
  };

  return (
    <>
      <Box data-testid="email-layout" />
      <Stack direction={'column'} gap="2rem" {...rest}>
        <Stack gap={'1rem'}>
          <form onSubmit={(e) => e.preventDefault()}>
            <TextField
              name="email"
              placeholder="Your email"
              label="Email"
              type="email"
              variant="outlined"
              autoFocus
              autoComplete="email"
              onChange={formik.handleChange}
              value={formik.values.email}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              sx={(theme) => ({
                width: '100%',
                [theme.breakpoints.up('xl')]: {
                  width: '100%',
                },
                [theme.breakpoints.up('sm')]: {
                  width: '400px',
                },
              })}
            />
          </form>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  onClick={formik.handleChange}
                  checked={formik.values.isConsentChecked}
                  name="isConsentChecked"
                />
              }
              label={
                <Typography
                  variant="body1"
                  onClick={(e) => e.preventDefault()}
                  sx={(theme) => ({
                    [theme.breakpoints.up('lg')]: {
                      whiteSpace: 'nowrap',
                    },
                  })}
                >
                  I agree to{' '}
                  <Link onClick={() => setIsOpenConsentTermsDialog(true)}>
                    the personal data processing terms
                  </Link>
                </Typography>
              }
              sx={{ mb: 0.5 }}
            />
            {formik.touched.isConsentChecked &&
              formik.errors.isConsentChecked && (
                <FormHelperText
                  sx={(theme) => ({
                    color: theme.palette.error.main,
                    marginLeft: '2rem',
                  })}
                >
                  {formik.errors.isConsentChecked}
                </FormHelperText>
              )}
          </Box>
        </Stack>
        <Stack gap={{ xs: '1.5rem', sm: '1rem' }} {...rest}>
          <CustomButton
            fullWidth={true}
            maxWidth={isMobileView ? '100%' : '197px'}
            isLoading={isStatusLoading(authApiStatus) || isEmailLoading}
            onClick={() => formik.handleSubmit()}
            label={buttonTitle ?? 'Continue'}
            testid="continue-button"
          />
          {!userData?.email && (
            <Box
              sx={{
                mt: 0.5,
                display: 'flex',
                gap: 1,
                alignItems: 'center',
              }}
            >
              <Typography variant="body1" component="p">
                Applied before?
              </Typography>
              <Link
                onClick={handleLoginClick}
                role="button"
                tabIndex={0}
                sx={{ cursor: 'pointer' }}
              >
                Log in
              </Link>
            </Box>
          )}
        </Stack>
      </Stack>
      {isOpenConsentTermsDialog && (
        <ConsentTermsDialog
          isOpen={isOpenConsentTermsDialog}
          handleClose={() => setIsOpenConsentTermsDialog(false)}
          handleConfirm={() => setIsOpenConsentTermsDialog(false)}
        />
      )}
      <SignInDialog
        isOpen={isOpenSignInDialog}
        handleClose={handleModalClose}
        initialValue={savedEmail?.email}
      />
    </>
  );
};

export default EmailForm;
