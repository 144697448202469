import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { BasicDialog, ChatMessage, CustomButton } from '../../../../components';
import keyboardIcon from '../../../../assets/keyboard.png';
import { useAppDispatch } from '../../../../redux';
import { sendTestTaskReminder } from '../../../../redux/testTasks';

interface IProps {
  showTTDialog: boolean;
  setShowTTDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CodilityTTMobileDialog: React.FC<IProps> = ({
  showTTDialog,
  setShowTTDialog,
}) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(sendTestTaskReminder());
  }, []);

  return (
    <BasicDialog
      isOpen={showTTDialog}
      handleClose={() => setShowTTDialog(false)}
    >
      <Box display="flex" justifyContent="center">
        <img src={keyboardIcon} alt="keyboard" />
      </Box>
      <Typography variant="h2" textAlign="center" paddingTop={4}>
        You're going to need
        <br />a keyboard
      </Typography>
      <ChatMessage isInCenter>
        Our test tasks use Codility, a web IDE which requires a desktop browser
        and a trusty keyboard.
      </ChatMessage>
      <Typography variant="body1" textAlign="center" my={4}>
        We will email you a link to the task.
        <br />
        Please use it once you're all set up and
        <br />
        ready to ace the test.
      </Typography>
      <CustomButton
        label="Close"
        fullWidth
        onClick={() => setShowTTDialog(false)}
        color="secondary"
      />
    </BasicDialog>
  );
};
