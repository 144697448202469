import { IUserData } from '../types';
import { DEFAULT_DEVELOPER_TYPE } from '../constants';

export const getUserSkillsInfo = (userData: IUserData, showOnly?: number) => {
  const { notOurCoreStack, primarySkills, secondarySkills, specialization } =
    userData;

  if (notOurCoreStack) {
    return { skills: DEFAULT_DEVELOPER_TYPE, extraItems: null };
  }

  if (!primarySkills?.length) {
    return {
      skills: specialization ?? DEFAULT_DEVELOPER_TYPE,
      extraItems: null,
    };
  }

  const primary: string[] = primarySkills || [];
  const secondary: string[] = secondarySkills || [];

  let items = [...primary, ...secondary];
  const total = items.length;
  let extraItems = null;

  if (showOnly) {
    items = items.slice(0, showOnly);
    extraItems = total > items.length ? `+${total - items.length}` : '';
  }

  return {
    skills: items.join(', '),
    extraItems,
  };
};
