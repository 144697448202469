import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { PageTransitionWrapper, GoToNextStep } from '../../components';
import {
  useFeatureFlags,
  useFocusInputAfterAnimation,
  usePersistUserAnswers,
} from '../../hooks';
import { useAppDispatch, useAppSelector } from '../../redux';
import { updateName, userSelectors } from '../../redux/user';
import { gamifiedResponseSlice } from '../../redux/gamifiedResponse/gamifiedResponse.slice';
import { INamesBody } from '../../types';
import { getUserPrefferedName } from '../../utils';
import { logEvent } from 'src/services';

const Name: React.FC = () => {
  const dispatch = useAppDispatch();
  const userData = useAppSelector(userSelectors.getUserData)!;
  const [savedName, setSavedName] =
    usePersistUserAnswers<INamesBody>('nameStep');
  const isLoading = useAppSelector(userSelectors.getIsUserPerformingAction);
  const { showNicknameField } = useFeatureFlags();

  const [namesSelected, setNamesSelected] = useState<INamesBody>({
    firstName: userData?.firstName || savedName?.firstName,
    lastName: userData?.lastName || savedName?.lastName,
    nickname: userData?.nickname || savedName?.nickname,
  });
  const inputRef = useRef<HTMLInputElement | null>(null);
  const excludedKeys = /[,|*+0-9]/;
  useFocusInputAfterAnimation(inputRef);

  useEffect(() => {
    if (userData.firstName && userData.lastName) {
      dispatch(gamifiedResponseSlice.actions.updateMessage(null));
    } else {
      if (userData.firstName) {
        dispatch(
          gamifiedResponseSlice.actions.updateMessage(
            `Great to see you here, ${getUserPrefferedName(userData)}! 👋`,
          ),
        );
      } else {
        dispatch(
          gamifiedResponseSlice.actions.updateMessage(
            "Hello, hello, what's your name?",
          ),
        );
      }
    }

    if (showNicknameField) {
      logEvent('launchpod - name-step-with-nickname-field');
    } else {
      logEvent('launchpod - name-step-without-nickname-field');
    }

    logEvent('launchpod-name-page-loaded');
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    if (!excludedKeys.test(value)) {
      setNamesSelected({
        ...namesSelected,
        [name]: value,
      });
      setSavedName({ ...namesSelected, [name]: value });
    }
  };

  const handleSubmit = (e: React.SyntheticEvent | null): void => {
    if (e) {
      e.preventDefault();
    }

    logEvent('launchpod-your-name-continue-click');

    setTimeout(() => {
      dispatch(updateName(namesSelected!));
    }, 500);
  };

  const updateNameToChatMessage = (
    event: React.FocusEvent<HTMLInputElement>,
  ) => {
    const { name, value } = event.target;
    if (
      (name === 'nickname' && value) ||
      (name === 'firstName' && value && !namesSelected.nickname)
    ) {
      dispatch(
        gamifiedResponseSlice.actions.updateMessage(
          `Great to see you here, ${value}! 👋`,
        ),
      );
    }
  };

  return (
    <PageTransitionWrapper>
      <Box data-testid="name-layout" />
      <Typography variant="h2">Your name</Typography>
      <form onSubmit={handleSubmit}>
        <Stack
          spacing={2}
          my={2}
          sx={(theme) => ({
            maxWidth: '24rem',
            [theme.breakpoints.down('sm')]: {
              maxWidth: 'initial',
            },
          })}
        >
          <TextField
            inputRef={inputRef}
            name="firstName"
            type="text"
            placeholder="First name"
            label="First name"
            id="First name"
            variant="outlined"
            value={namesSelected.firstName ?? ''}
            onChange={handleChange}
            inputProps={{ maxLength: 35 }}
            onBlur={updateNameToChatMessage}
            InputProps={{
              endAdornment: namesSelected.firstName ? null : (
                <InputAdornment position="end">
                  <Typography variant="body2" color="secondary">
                    official
                  </Typography>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            name="lastName"
            type="text"
            label="Last name"
            placeholder="Last name"
            variant="outlined"
            value={namesSelected.lastName ?? ''}
            onChange={handleChange}
            inputProps={{ maxLength: 35 }}
            InputProps={{
              endAdornment: namesSelected.lastName ? null : (
                <InputAdornment position="end">
                  <Typography variant="body2" color="secondary">
                    official
                  </Typography>
                </InputAdornment>
              ),
            }}
          />
          {showNicknameField && (
            <TextField
              name="nickname"
              type="text"
              label="How should we address you?"
              variant="outlined"
              value={namesSelected.nickname ?? ''}
              onChange={handleChange}
              inputProps={{ maxLength: 35 }}
              InputProps={{
                endAdornment: namesSelected.nickname ? null : (
                  <InputAdornment position="end">
                    <Typography variant="body2" color="secondary">
                      optional
                    </Typography>
                  </InputAdornment>
                ),
              }}
              onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                updateNameToChatMessage(event);
              }}
              onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
                if (event.code === 'Enter') {
                  const e = {
                    target: { value: event.target.value },
                  } as React.FocusEvent<HTMLInputElement>;
                  updateNameToChatMessage(e);
                }
              }}
            />
          )}
        </Stack>
      </form>
      <GoToNextStep
        isLoading={isLoading}
        isDisabled={!namesSelected.firstName || !namesSelected.lastName}
        textIdentifier={0}
        handleSubmitStep={() => handleSubmit(null)}
      />
    </PageTransitionWrapper>
  );
};

export default Name;
