import { IPlainObject } from '../types';
import { decodeQueryParam } from './decodeQueryParam';

export const parseQueryParams = <T = IPlainObject>(source?: string): T => {
  const { search } = location;

  return (source || search || '')
    .replace(/\??/gi, '')
    .split('&')
    .reduce<T>((acum, item) => {
      if (!item) {
        return acum;
      }

      const [key, value] = item.split('=');

      return {
        ...acum,
        [key]: decodeQueryParam(value),
      };
    }, {} as T);
};
