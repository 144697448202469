import { MutableRefObject, useLayoutEffect } from 'react';
import { emojisplosion } from 'emojisplosion';
import { ANIMATION_DURATION } from '../constants';

const EMOJIS_PACK = {
  ALL_DONE: ['🤩', '👍', '🦆', '🌟', '✅', '🍀', '🎉'],
  TT_PASSED: ['🤩', '🧡', '🏀', '🌟', '✅', '⏱', '✏️'],
  CALL_BOOKED: ['💙', '🧡', '☕️', '🗓', '🔆', '🫖', '🐶'],
  DEFAULT: ['🤩', '👍', '🦆', '🌟', '✅', '🍀', '🎉'],
  VIDEO_INTERVIEW_PASSED: ['💖'],
  BAD_DONE: ['😭', '🥺', '😒', '😱', '💔', '💔'],
};

export const useEmojisplosion = (
  refEl: MutableRefObject<HTMLDivElement | null>,
  emojisPack: keyof typeof EMOJIS_PACK = 'DEFAULT',
) => {
  const delay = ANIMATION_DURATION * 1000;

  useLayoutEffect(() => {
    if (refEl.current === null) return;

    const el = refEl.current;
    const timeoutId = setTimeout(() => {
      explodeEmojis(el, emojisPack);
      refEl.current = null;
    }, delay);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [refEl.current]);
};

const explodeEmojis = function (
  el: HTMLDivElement,
  pack: keyof typeof EMOJIS_PACK = 'DEFAULT',
) {
  emojisplosion({
    emojis: EMOJIS_PACK[pack],
    physics: {
      fontSize: { max: 72, min: 16 },
      initialVelocities: {
        x: { max: 15, min: -15 },
        y: { max: 15, min: -15 },
        rotation: { max: 1, min: -1 },
      },
      rotationDeceleration: 0.01,
    },
    position() {
      const offset = cumulativeOffset(el);
      return {
        x: offset.left + el.clientWidth / 2,
        y: offset.top + el.clientHeight / 2,
      };
    },
  });
};

const cumulativeOffset = function (element: HTMLElement) {
  let top = 0,
    left = 0;
  do {
    top += element.offsetTop || 0;
    left += element.offsetLeft || 0;
    element = element.offsetParent as HTMLElement;
  } while (element);

  return {
    top: top,
    left: left,
  };
};
