import React, { useState } from 'react';
import { useAppDispatch } from '../../../redux';

import { useFormik } from 'formik';
import * as yup from 'yup';
import { Stack, TextField } from '@mui/material';
import { CustomButton } from '../../../components';

import { updateSimulatedTT } from '../../../redux/testTasks';
import { useUserTestTasks } from 'src/hooks';
import { IUserTestTask } from 'src/types';

const SimulatedTTScoreInput: React.FC = ({}) => {
  const dispatch = useAppDispatch();
  const { currentTestTask } = useUserTestTasks() as {
    currentTestTask: IUserTestTask;
  };

  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      score: 0,
    },
    validationSchema: yup.object({
      score: yup.number().min(0).max(100).required('Score is required'),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      const response = await dispatch(
        updateSimulatedTT({
          id: currentTestTask.id!,
          score: values.score,
        }),
      );
      if (updateSimulatedTT.fulfilled.match(response)) {
        setIsLoading(false);
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={4} mb={4} mt={4}>
        <TextField
          name="score"
          placeholder="Score"
          label="Score"
          type="number"
          variant="outlined"
          autoFocus
          onChange={formik.handleChange}
          value={formik.values.score}
          error={formik.touched.score && Boolean(formik.errors.score)}
          helperText={formik.touched.score && formik.errors.score}
        />
        <CustomButton
          color="secondary"
          isDisabled={!Boolean(formik.values.score)}
          isLoading={isLoading}
          label="Pass"
          fullWidth
          testid="simulated-tt-pass-btn"
        />
      </Stack>
    </form>
  );
};

export default SimulatedTTScoreInput;
