import { knowledgeQuizStoreKey } from './knowledgeQuiz.const';
import { IKnowledgeQuizSliceState } from './knowledgeQuiz.types';

interface IState {
  [knowledgeQuizStoreKey]: IKnowledgeQuizSliceState;
}

const selectKnowledgeQuizState = (state: IState) => {
  return state[knowledgeQuizStoreKey];
};

const getKnowledgeQuizAPIStatus = (state: IState) => {
  return selectKnowledgeQuizState(state).apiStatus;
};

const getKnowledgeQuizData = (state: IState) => {
  return selectKnowledgeQuizState(state).data;
};

const getKnowledgeQuizIsPerformingAction = (state: IState) => {
  return selectKnowledgeQuizState(state).isPerformingAction;
};

export const knowledgeQuizSelectors = {
  getKnowledgeQuizAPIStatus,
  getKnowledgeQuizData,
  getKnowledgeQuizIsPerformingAction,
};
