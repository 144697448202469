import { usePositionData } from './usePositionData';
import { parse } from 'date-fns';

export const useIsExpiredMagicLink = () => {
  const positionData = usePositionData();
  if (!positionData?.expireddate) return false;

  const date = parse(positionData?.expireddate, 'dd-MMM-yyyy', new Date());
  return date.getTime() < new Date().getTime();
};
