import { API_STATUS } from '../../constants';
import { IPositionsSliceState } from './positions.slice';
import { positionsStoreKey } from './positions.const';
import { IPositionData } from '@types';

interface IState {
  [positionsStoreKey]: IPositionsSliceState;
}

const selectPositionsState = (state: IState) => {
  return state[positionsStoreKey];
};

const getPositionsApiStatus = (state: IState): API_STATUS => {
  return selectPositionsState(state).apiStatus;
};

const getPositionData = (state: IState): IPositionData | null => {
  return selectPositionsState(state).data;
};

export const positionsSelectors = {
  getPositionsApiStatus,
  getPositionData,
};
