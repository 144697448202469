import * as amplitude from '@amplitude/analytics-browser';

const AMPLITUDE_API_KEY = '73bf4c9efeef3fecea19b87dee59087f';

export const initAnalytics = () => {
  amplitude.init(AMPLITUDE_API_KEY, { defaultTracking: true });
  const identifyEvent = new amplitude.Identify();
  identifyEvent.set('environment', process.env.REACT_APP_ENV || 'localhost');
  amplitude.identify(identifyEvent);
};

export const registerUser = (userId: string) => {
  amplitude.setUserId(userId);
};

export const logEvent = (eventName: string, data?: Record<string, any>) => {
  amplitude.track(eventName, data);
};
