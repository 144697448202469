/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { BrandLogo, CustomButton } from '../../components';
import { useLocation, useNavigate } from 'react-router-dom';
import { APP_ROUTES } from 'src/constants';
import { parseJSON, parseQueryParams } from 'src/utils';

const UnsubscribeReferral: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { data } = parseQueryParams<{
    data: string;
  }>(location.search);

  const [parsedData, setParsedData] = useState(
    null as { referralName: string } | null,
  );

  useEffect(() => {
    const decodedData = parseJSON(window.atob(data || ''));
    setParsedData(decodedData);
  }, [data]);

  const onReferMoreFriendsClick = useCallback(
    () => navigate(`/${APP_ROUTES.REFER_FRIEND}`),
    [],
  );

  return (
    <Box
      sx={(theme) => ({
        background: theme.palette.background.backgroundPrimary,
        minWidth: '100%',
        minHeight: '100%',
        padding: '41px 16px',
        [theme.breakpoints.down('lg')]: {
          paddingTop: '130px',
        },
      })}
    >
      <Box sx={{ position: 'absolute', top: '49px', left: '66px' }}>
        <BrandLogo width={133} />
      </Box>
      <Box display="flex" alignItems="center" flexDirection="column">
        <Box
          sx={(theme) => ({
            background: theme.palette.common.white,
            padding: '64px 48px',
            borderRadius: '48px',
            maxWidth: '490px',
            position: 'relative',
          })}
        >
          <Box display="flex" flexDirection="column">
            <Typography variant="h6" fontWeight="600" mb={1}>
              Ok, cool, you’ll receive less updates on your friend’s{' '}
              {parsedData?.referralName} application
            </Typography>

            <Typography variant="body1" mb={3}>
              You will only receive email if you get bonus for your referral.
            </Typography>

            <Typography variant="body1" mb={3}>
              Thanks
            </Typography>

            <Box sx={{ maxWidth: '254px' }}>
              <CustomButton
                onClick={onReferMoreFriendsClick}
                label="Refer more friends"
                color="primary"
                fullWidth
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default UnsubscribeReferral;
