import { Typography } from '@mui/material';
import { ResponsiveBreak } from 'src/components';

const MeetingDetailsDefaultMessage = () => {
  return (
    <>
      <Typography>
        <b>The meeting has been booked, and we're putting the kettle on.</b>
        <br />
        You will receive a calendar event where you can{' '}
        <ResponsiveBreak breakpoint="sm" />
        reschedule or cancel.
      </Typography>
      <Typography variant="body1">See you!</Typography>
    </>
  );
};

export default MeetingDetailsDefaultMessage;
