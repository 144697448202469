import React, { useEffect } from 'react';
import {
  APP_ROUTES,
  TEST_TASK_STATUS_REFRESH_TIME,
  simulatedTTString,
} from 'src/constants';
import { useUserTestTasks } from 'src/hooks';
import { useNavigate } from 'react-router';
import { PageTransitionWrapper, ResponsiveBreak } from '../../components';
import { Typography } from '@mui/material';
import { useAppDispatch } from '../../redux';

import PendingResults from './components/PendingResults';
import SimulatedTTScoreInput from './components/SimulatedTTScoreInput';

import { getCodilityResults } from 'src/redux/testTasks';

const CodilityTestTaskPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { currentTestTask } = useUserTestTasks();

  const hasCurrentTestTask = !!currentTestTask;
  const isSimulatedTT = currentTestTask?.link?.startsWith(simulatedTTString);

  // Get relevant test task status from Codility
  useEffect(() => {
    // Do not try to refresh tt result in case it's a simulated test task or tt from DWH
    if (isSimulatedTT) return;

    let intervalId: ReturnType<typeof setInterval> | undefined;
    if (hasCurrentTestTask) {
      dispatch(getCodilityResults(currentTestTask.id));
      intervalId = setInterval(() => {
        dispatch(getCodilityResults(currentTestTask.id));
      }, 1000 * TEST_TASK_STATUS_REFRESH_TIME);
    } else if (intervalId) {
      clearInterval(intervalId);
      navigate(`/${APP_ROUTES.APPLICATION_SUCCESS}`);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [hasCurrentTestTask]);

  return (
    <PageTransitionWrapper>
      <Typography
        variant="h2"
        sx={(theme) => ({
          fontSize: '2rem',
          [theme.breakpoints.down('sm')]: {
            fontSize: '1.25rem',
            maxWidth: '70%',
          },
          [theme.breakpoints.down('xl')]: {
            fontSize: '1.5rem',
          },
        })}
      >
        First step is done, <ResponsiveBreak breakpoint="md" />
        now it’s time to show your skills
      </Typography>
      <PendingResults testLink={currentTestTask?.link || null} />
      {isSimulatedTT && <SimulatedTTScoreInput />}
    </PageTransitionWrapper>
  );
};

export default CodilityTestTaskPage;
