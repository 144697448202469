/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Typography, Box } from '@mui/material';
import { useSidebarFooterStyles } from './SidebarFooter.styles';
import ShareFeedbackDialog from '../../shareFeedbackDialog/ShareFeedbackDialog';
import AccountDataDialog from '../../accountDataDialog/AccountDataDialog';
import { isKeyClick } from '../../../utils';
import { useAppSelector } from '../../../redux';
import { userSelectors } from '../../../redux/user';
import { Color } from '../../../types';

interface IProps {
  color?: Color;
}

const SidebarFooter: React.FC<IProps> = ({ color = Color.INVERTED }) => {
  const styles = useSidebarFooterStyles(color);
  const userData = useAppSelector(userSelectors.getUserData);
  const [openAccountData, setOpenAccountData] = useState(false);
  const [openReportIssue, setOpenReportIssue] = useState(false);

  return (
    <>
      <Box css={styles}>
        <Box className="links">
          <Link component={RouterLink} to="/terms-of-use">
            Terms of use
          </Link>
          {userData && (
            <>
              <Typography component={'span'} />
              <Link
                onClick={() => setOpenAccountData(true)}
                onKeyUp={(e: React.KeyboardEvent<any>) => {
                  if (isKeyClick(e)) {
                    setOpenAccountData(true);
                  }
                }}
                role="button"
                tabIndex={0}
              >
                Your data
              </Link>
              <Typography component={'span'} />
              <Link
                onClick={() => setOpenReportIssue(true)}
                onKeyUp={(e: React.KeyboardEvent<any>) => {
                  if (isKeyClick(e) && !openReportIssue) {
                    setOpenReportIssue(true);
                  }
                }}
                role="button"
                tabIndex={0}
              >
                Report issue
              </Link>
            </>
          )}
        </Box>
        <Box>
          <Typography>
            &copy; {new Date().getFullYear()} AgileEngine, LLC. All rights
            reserved
          </Typography>
        </Box>
      </Box>
      <AccountDataDialog
        isOpen={openAccountData}
        handleClose={() => setOpenAccountData(false)}
      />
      <ShareFeedbackDialog
        isOpen={openReportIssue}
        handleClose={() => setOpenReportIssue(false)}
      />
    </>
  );
};

export default SidebarFooter;
