import React, {
  forwardRef,
  ReactNode,
  useImperativeHandle,
  useRef,
} from 'react';
import { Box, Typography } from '@mui/material';
import { Flex } from '../flex/Flex';
import {
  AllSystemCSSProperties,
  ResponsiveStyleValue,
} from '@mui/system/styleFunctionSx';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { useIsMobileView } from '../../hooks';

interface IProps {
  srcSetWebp?: string;
  srcSetPng: string;
  alt?: string;
  imageWidth?: ResponsiveStyleValue<AllSystemCSSProperties['width']>;
  imageSx?: SxProps<Theme>;
  ['data-testid']?: string;
  children: ReactNode;
  sx?: SxProps<Theme> | undefined;
}

export const StepPanelHeader = forwardRef<HTMLDivElement | null, IProps>(
  (
    {
      children,
      srcSetWebp,
      srcSetPng,
      imageWidth = { xs: 60, sm: 75, lg: 80, xxl: 100 },
      imageSx,
      alt,
      ...rest
    },
    ref,
  ) => {
    const isMobileView = useIsMobileView();
    const starRef = useRef<HTMLDivElement>(null);
    const starMobileRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(
      ref,
      () => (isMobileView ? starMobileRef.current! : starRef.current!),
      [isMobileView],
    );

    return (
      <>
        {!isMobileView && (
          <Box
            ref={starRef}
            component="picture"
            display={{ xs: 'none', sm: 'block' }}
          >
            {srcSetWebp && <source srcSet={srcSetWebp} type="image/webp" />}
            <source srcSet={srcSetPng} type="image/png" />
            <Box
              component="img"
              src={srcSetPng}
              alt={alt}
              width={imageWidth}
              height="auto"
              sx={imageSx}
            />
          </Box>
        )}

        <Flex width={{ xs: '100%', sm: 'auto' }} justifyContent="space-between">
          <Typography variant="h2" mb={0} component="p" {...rest}>
            {children}
          </Typography>

          {isMobileView && (
            <Box
              ref={starMobileRef}
              component="picture"
              display={{ xs: 'block', sm: 'none' }}
            >
              {srcSetWebp && <source srcSet={srcSetWebp} type="image/webp" />}
              <source srcSet={srcSetPng} type="image/png" />
              <Box
                component="img"
                src={srcSetPng}
                alt={alt}
                width={imageWidth}
                height="auto"
                sx={imageSx}
              />
            </Box>
          )}
        </Flex>
      </>
    );
  },
);
