import { IGamifiedResponseSliceState } from './gamifiedResponse.slice';
import { gamifiedResponseStoreKey } from './gamifiedResponse.const';

interface IState {
  [gamifiedResponseStoreKey]: IGamifiedResponseSliceState;
}

const selectgamifiedResponseState = (state: IState) => {
  return state[gamifiedResponseStoreKey];
};

const getGamifiedResponse = (state: IState): string | null => {
  return selectgamifiedResponseState(state).lastMessage;
};

export const gamifiedResponseSelectors = {
  getGamifiedResponse,
};
