import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';

import {
  Box,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  CustomButton,
  PageTransitionWrapper,
  RadioButton,
  SelectableWrapper,
} from '../../components';
import QuizTimer from './components/QuizTimer';

import { IQuizAnswer, IQuizQuestion } from '@types';
import { QUIZ_QUESTION_TYPE } from '../../constants';

const MAX_WIDTH = '34rem';

export const Quiz: React.FC<{
  question: IQuizQuestion;
  questionIdx: string;
  questionsCount: number;
  questionAnswer: string[];
  unansweredQuestionsCount: number;
  duration: number;
  startDate: string;
  isPerformingAction: boolean;
  onSelectAnswer: (
    answer: IQuizAnswer,
    questionType: QUIZ_QUESTION_TYPE,
  ) => void;
  onNextQuestion: () => void;
  onPreviousQuestion: () => void;
  onFinish: () => void;
}> = ({
  question,
  questionIdx,
  questionsCount,
  questionAnswer,
  unansweredQuestionsCount,
  duration,
  startDate,
  isPerformingAction,
  onSelectAnswer,
  onNextQuestion,
  onPreviousQuestion,
  onFinish,
}) => {
  const [showFinishQuizConfirmationModal, setShowFinishQuizConfirmationModal] =
    useState(false);

  const handleChange = async (value: string) => {
    let newTestAnswers: string[] = [];

    if (question.type === QUIZ_QUESTION_TYPE.SINGLE) {
      newTestAnswers = [value];
    }

    if (question.type === QUIZ_QUESTION_TYPE.MULTIPLE) {
      const testOptionIndex = questionAnswer.indexOf(value);
      newTestAnswers = [...questionAnswer];
      if (testOptionIndex === -1) {
        newTestAnswers.push(value);
      } else {
        newTestAnswers.splice(testOptionIndex, 1);
      }
    }

    onSelectAnswer({ [questionIdx]: newTestAnswers }, question.type);
  };

  const renderFormControl = (option: string) => {
    if (question.type === QUIZ_QUESTION_TYPE.MULTIPLE) {
      return (
        <Checkbox
          onChange={() => handleChange(option)}
          name={option}
          checked={questionAnswer.includes(option)}
        />
      );
    }

    if (question.type === QUIZ_QUESTION_TYPE.SINGLE) {
      return (
        <RadioButton
          onChange={() => handleChange(option)}
          name={option}
          checked={questionAnswer.includes(option)}
        />
      );
    }

    return <></>;
  };

  const isShowPreviousButton = () => {
    return questionIdx !== '1';
  };

  const isShowNextButton = () => {
    return questionIdx !== `${questionsCount}`;
  };

  const isShowFinishButton = () => {
    return questionIdx === `${questionsCount}`;
  };

  return (
    <>
      <QuizTimer
        duration={duration}
        quizStartDateTime={startDate}
        handleFinishQuiz={onFinish}
        showFinishQuizConfirmationModal={showFinishQuizConfirmationModal}
        setShowFinishQuizConfirmationModal={setShowFinishQuizConfirmationModal}
        isLoading={isPerformingAction}
        unansweredQuestionsCount={unansweredQuestionsCount}
      />
      <PageTransitionWrapper>
        <Box>
          <Box mb={3}>
            <Typography
              variant="h4"
              color="primary"
              sx={(theme) => ({
                whiteSpace: 'pre-line',
                maxWidth: MAX_WIDTH,
                [theme.breakpoints.down('sm')]: {
                  fontSize: '1rem',
                  lineHeight: '1.43',
                  letterSpacing: '-0.015em',
                },
              })}
            >
              {question.label}
            </Typography>
            {question.type === QUIZ_QUESTION_TYPE.MULTIPLE && (
              <Typography variant="body2" color="secondary">
                Select all options that apply
              </Typography>
            )}
          </Box>
          <Box maxWidth={MAX_WIDTH} pb={16}>
            <Stack direction="column" spacing={0.5} mb={3}>
              {Object.keys(question.options).map((option, index) => (
                <Box display="flex" gap={2} key={index}>
                  <Typography pt="0.875rem">
                    {String.fromCharCode(97 + index).toUpperCase()}
                  </Typography>
                  <SelectableWrapper
                    key={index}
                    value={option}
                    selectedValue={questionAnswer}
                    onClick={() => handleChange(option)}
                  >
                    <FormControlLabel
                      control={renderFormControl(option)}
                      label={
                        <Typography
                          variant="body1"
                          onClick={(e) => e.preventDefault()}
                          sx={{ wordBreak: 'break-word' }}
                        >
                          {question.options[option]}
                        </Typography>
                      }
                      sx={{ mr: 0, alignItems: 'start' }}
                      value={option}
                      checked={questionAnswer.includes(option)}
                    />
                  </SelectableWrapper>
                </Box>
              ))}
            </Stack>
            <Stack direction="row" spacing={1}>
              {isShowPreviousButton() && (
                <CustomButton
                  label="Previous"
                  startIcon={<ArrowBackIcon />}
                  onClick={onPreviousQuestion}
                />
              )}
              {isShowNextButton() && (
                <CustomButton
                  label="Next"
                  endIcon={<ArrowForwardIcon />}
                  onClick={onNextQuestion}
                  isLoading={isPerformingAction}
                  fullWidth
                />
              )}
              {isShowFinishButton() && (
                <CustomButton
                  label="Finish"
                  onClick={() => setShowFinishQuizConfirmationModal(true)}
                  isLoading={isPerformingAction}
                  fullWidth
                />
              )}
            </Stack>
          </Box>
        </Box>
        <Outlet />
      </PageTransitionWrapper>
    </>
  );
};
