import { useCallback } from 'react';
import {
  gamifiedResponseSelectors,
  updateMessage,
} from '../redux/gamifiedResponse';
import { useAppDispatch, useAppSelector } from '../redux';

export const useGamifiedMessage = () => {
  const dispatch = useAppDispatch();
  const lastGamifiedMessage = useAppSelector(
    gamifiedResponseSelectors.getGamifiedResponse,
  );

  const updateGamifiedMessage = useCallback((message: string | null) => {
    dispatch(updateMessage(message));
  }, []);

  return {
    lastGamifiedMessage,
    updateGamifiedMessage,
  };
};
