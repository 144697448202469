import axios from 'axios';
import { videoInterviewDataStoreKey } from './videoInterview.const';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { notificationAlert } from '../notifications';
import { formatErrorMessage } from '../../utils';
import {
  createUserVideoInterviewPath,
  videoInterviewReminderDatePath,
  restartUserVideoInterviewPath,
  viewUserVideoInterviewPath,
  getAvailableVideoInterviewPath,
  skipUserVideoInterviewPath,
  finishUserVideoInterviewQuestionPath,
  finishUserVideoInterviewPath,
} from '../../constants/apiEndpoints';
import { fetchUser, updateUserVideoInterview } from '../user';
import { ISetVideoInterviewReminderPayload, IUserData } from '../../types';
import {
  IVideoInterviewData,
  IVideoInterviewFinishQuestionBody,
} from 'src/types/videoInterviewData';
import {
  IUserVideoInterviewData,
  IViewUserVideoInterviewData,
} from 'src/types/userVideoInterviewData';

export const getAvailableVideoInterview = createAsyncThunk(
  `${videoInterviewDataStoreKey}/getAvailableVideoInterview`,
  async () => {
    const response = await axios.get<IVideoInterviewData[]>(
      getAvailableVideoInterviewPath(),
    );
    return response.data;
  },
);

export const startVideoInterview = createAsyncThunk(
  `${videoInterviewDataStoreKey}/startVideoInterview`,
  async ({ videoInterviewId }: { videoInterviewId: string }, thunkAPI) => {
    try {
      const response = await axios.post<IUserVideoInterviewData>(
        createUserVideoInterviewPath(),
        {
          videoInterviewId,
        },
      );

      await thunkAPI.dispatch(fetchUser());
      await thunkAPI.dispatch(getAvailableVideoInterview());

      return response.data;
    } catch (err: any) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(err);
    }
  },
);

export const setVideoInterviewReminderDate = createAsyncThunk(
  `${videoInterviewDataStoreKey}/setVideoInterviewReminderDate`,
  async (
    { videoInterviewReminderDate }: ISetVideoInterviewReminderPayload,
    thunkAPI,
  ) => {
    try {
      const response = await axios.post<IUserData>(
        videoInterviewReminderDatePath(),
        {
          videoInterviewReminderDate,
        },
      );
      thunkAPI.dispatch(fetchUser());
      return response.data;
    } catch (err: any) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(err);
    }
  },
);

export const skipVideoInterview = createAsyncThunk(
  `${videoInterviewDataStoreKey}/skipVideoInterview`,
  async (videoInterviewId: string, thunkAPI) => {
    try {
      await axios.post(skipUserVideoInterviewPath(), { videoInterviewId });

      await thunkAPI.dispatch(fetchUser());
      await thunkAPI.dispatch(getAvailableVideoInterview());
    } catch (err: any) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(err);
    }
  },
);

export const restartVideoInterview = createAsyncThunk(
  `${videoInterviewDataStoreKey}/restartVideoInterview`,
  async (userVideoInterviewId: string, thunkAPI) => {
    try {
      const response = await axios.post<IUserVideoInterviewData>(
        restartUserVideoInterviewPath(userVideoInterviewId),
      );

      await thunkAPI.dispatch(fetchUser());

      return response.data;
    } catch (err: any) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(err);
    }
  },
);

export const finishVideoInterviewQuestion = createAsyncThunk(
  `${videoInterviewDataStoreKey}/finishVideoInterviewQuestion`,
  async (
    {
      userVideoInterviewId,
      questionNumber,
      record,
      onUploadProgress,
    }: IVideoInterviewFinishQuestionBody,
    thunkAPI,
  ) => {
    try {
      const formData = new FormData();
      formData.append('record', record);

      let percents = 0;
      const intervalId = setInterval(() => {
        if (percents > 90) clearInterval(intervalId);

        percents++;
        onUploadProgress(percents);
      }, 10);

      const response = await axios.post<IUserVideoInterviewData>(
        finishUserVideoInterviewQuestionPath(
          userVideoInterviewId,
          questionNumber,
        ),
        formData,
        {
          onDownloadProgress: () => {
            clearInterval(intervalId);
            onUploadProgress(100);
          },
        },
      );

      await thunkAPI.dispatch(updateUserVideoInterview(response.data));

      return response.data;
    } catch (err: any) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(err);
    }
  },
);

export const finishVideoInterview = createAsyncThunk(
  `${videoInterviewDataStoreKey}/finishVideoInterview`,
  async (
    { userVideoInterviewId }: { userVideoInterviewId: string },
    thunkAPI,
  ) => {
    try {
      const response = await axios.post<IUserVideoInterviewData>(
        finishUserVideoInterviewPath(userVideoInterviewId),
      );

      await thunkAPI.dispatch(fetchUser());

      return response.data;
    } catch (err: any) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(err);
    }
  },
);

export const viewUserVideoInterview = createAsyncThunk(
  `${videoInterviewDataStoreKey}/viewUserVideoInterview`,
  async ({ token }: { token: string }) => {
    try {
      const response = await axios.get(viewUserVideoInterviewPath(), {
        params: { token },
      });

      return response.data as IViewUserVideoInterviewData;
    } catch (err: any) {
      throw new Error(err);
    }
  },
);
