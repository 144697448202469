import React, { FC } from 'react';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import {
  ISelectableWrapperProps,
  SelectableWrapper,
} from '../../../components';

type TProps = Omit<ISelectableWrapperProps, 'children'>;
export const NoneOfTheAboveCheckbox: FC<TProps> = ({
  onClick,
  isSelected,
  ...rest
}) => {
  return (
    <SelectableWrapper isSelected={isSelected} onClick={onClick} {...rest}>
      <FormControlLabel
        control={
          <Checkbox
            onChange={(e) => onClick(e)}
            name="notOurCoreStack"
            checked={isSelected}
          />
        }
        label={
          <Typography
            variant="body1"
            onClick={(e) => e.preventDefault()}
            sx={{ whiteSpace: 'nowrap' }}
          >
            None of the above
          </Typography>
        }
        sx={{ mr: 0, width: '100%' }}
      />
    </SelectableWrapper>
  );
};
