import { CustomButton } from 'src/components';
import { Box, Typography } from '@mui/material';

import quizImageSrc from '../../../assets/quiz-image.png';

export const KnowledgeQuizIntro: React.FC<{
  questionsCount: number;
  duration: number;
  isPerformingAction: boolean;
  start: () => void;
}> = ({ questionsCount, duration, isPerformingAction, start }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{ position: 'relative', width: '65%' }}
    >
      <Typography variant="h1" mb={3}>
        Time for a short quiz!
      </Typography>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Typography variant="body1" mt={3} mb={4}>
            You're about to take a quick knowledge assessment focusing on
            marketing best practices. Make sure to check the following points
            for a better performance:
          </Typography>
          <Box component="ul" mt={4} mb={6} maxWidth="75%">
            <Box component="li" my={2}>
              <b>{questionsCount} short questions.</b>
            </Box>
            <Box component="li" my={2}>
              <b>{duration} minutes to finish them all.</b>
            </Box>
            <Box component="li" my={2}>
              Must be finished in one sitting. You cannot save and finish later.
            </Box>
            <Box component="li" my={2}>
              1 question per page, and you can answer in any order.
            </Box>
            <Box component="li" my={2}>
              Leave questions unanswered if you're not sure how to solve them
              and return to it later.
            </Box>
          </Box>
          <CustomButton
            isLoading={isPerformingAction}
            onClick={start}
            label="Start the test"
          />
        </Box>
        <Box
          component="img"
          srcSet={`${quizImageSrc} 2x`}
          sx={() => ({
            position: 'absolute',
            top: '0',
            right: '0',
            transform: 'translateX(100%) translateY(10%)',
          })}
        />
      </Box>
    </Box>
  );
};
