import Clapperboard from '../../../assets/clapperboard-icon.png';
import PointTimeIcon from '../../../assets/tt-point-time-small-icon.png';
import BigUpIcon from '../../../assets/big-up-icon.png';

export const NonTechRowOfImages = [
  {
    image: Clapperboard,
    alt: 'Clapperboard',
    text: `Some questions include preparation time`,
  },
  {
    image: PointTimeIcon,
    alt: 'PointTimeIcon',
    text: `One attempt per question`,
  },
  {
    image: BigUpIcon,
    alt: 'BigUpIcon',
    text: `This recording is for your hiring manager only`,
  },
];

export const VideoInterviewRowOfImages = [
  {
    image: '🌟️',
    text: `You get priority consideration\n for our open positions`,
  },
  {
    image: '🤝',
    text: '80% or candidates who recorded\n a video introduction received\n an offer',
  },
  {
    image: '😅️',
    text: 'Speeds up the process and saves\n you from answering the same\n stuff many times',
  },
];
