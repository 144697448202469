import React, { useEffect, useState } from 'react';
import { Box, FormControlLabel, Grid, Typography } from '@mui/material';
import {
  ContinueMessage,
  PageTransitionWrapper,
  RadioButton,
  SelectableWrapper,
} from '../../components';
import {
  EXPERIENCE_LIST,
  EXPERIENCE_LIST_MOBILE,
} from '../../constants/experienceList';
import { useAppDispatch, useAppSelector } from '../../redux';
import { updateExperience, userSelectors } from '../../redux/user';
import {
  gamifiedResponseSelectors,
  gamifiedResponseSlice,
} from '../../redux/gamifiedResponse';
import { getUserPrefferedName } from '../../utils';
import { useIsLargeView } from '../../hooks';
import { experienceMessage } from './components/experienceMessage';
import GoToPreviousStep from '../../components/goToPreviousStep/GoToPreviousStep';
import { logEvent } from 'src/services';
import isNumber from 'lodash/isNumber';

export interface IExperienceBody {
  yearsOfExperience: number;
  yearsOfExperienceNote: string | null;
}

const Experience: React.FC = () => {
  const dispatch = useAppDispatch();
  const userData = useAppSelector(userSelectors.getUserData)!;
  const lastGamifiedMessage = useAppSelector(
    gamifiedResponseSelectors.getGamifiedResponse,
  );
  const isLargeView = !useIsLargeView();
  const experienceList = isLargeView ? EXPERIENCE_LIST : EXPERIENCE_LIST_MOBILE;

  const getInitialState = (): null | IExperienceBody => {
    if (typeof userData.yearsOfExperience === 'number') {
      return {
        yearsOfExperience: userData.yearsOfExperience,
        yearsOfExperienceNote: userData.yearsOfExperienceNote,
      };
    } else {
      return null;
    }
  };

  const [experienceChecked, setExperienceChecked] =
    useState<null | IExperienceBody>(getInitialState());

  useEffect(() => {
    if (isNumber(userData.yearsOfExperience)) {
      dispatch(gamifiedResponseSlice.actions.updateMessage(null));
    } else {
      if (!lastGamifiedMessage) {
        dispatch(
          gamifiedResponseSlice.actions.updateMessage(
            `Welcome back, ${getUserPrefferedName(userData)}!`,
          ),
        );
      }
    }

    logEvent('launchpod-experience-page-loaded');
  }, []);

  const handleChange = (value: string) => {
    logEvent('launchpod-experience-selected', { 'Experience Selected': value });

    const numericValue: number | undefined = parseInt(
      value.match(/\d/g)?.join('') ?? '0',
    );
    const experience = {
      yearsOfExperience: numericValue,
      yearsOfExperienceNote: value,
    };

    setExperienceChecked(experience);
    dispatch(
      gamifiedResponseSlice.actions.updateMessage(
        experienceMessage({
          experienceSelected: numericValue,
        }),
      ),
    );
    setTimeout(() => {
      dispatch(updateExperience(experience));
    }, 500);
  };

  return (
    <PageTransitionWrapper>
      <Box display="flex" data-testid="experience-layout">
        <GoToPreviousStep />
        <Typography variant="h2">
          How many years of experience{' '}
          <Box
            component="br"
            sx={(theme) => ({
              [theme.breakpoints.down('lg')]: {
                display: 'none',
              },
            })}
          />
          do you have?
        </Typography>
      </Box>
      <Typography mb={3}>
        We will suggest you open positions{' '}
        <Box
          component="br"
          sx={(theme) => ({
            [theme.breakpoints.down('lg')]: {
              display: 'none',
            },
          })}
        />{' '}
        based on the years of your experience.
      </Typography>
      <Grid container spacing={1} maxWidth={{ lg: '33rem' }} mb={1}>
        {experienceList.map((option, idx) => {
          return (
            <Grid item key={option} xs={12} lg={!idx ? 12 : 6}>
              <SelectableWrapper
                value={option}
                selectedValue={experienceChecked?.yearsOfExperienceNote}
                onClick={() => handleChange(option)}
                testid="experience-option"
              >
                <FormControlLabel
                  checked={
                    option === experienceChecked?.yearsOfExperienceNote ?? ''
                  }
                  value={option}
                  control={<RadioButton tabIndex={-1} />}
                  label={option}
                />
              </SelectableWrapper>
            </Grid>
          );
        })}
      </Grid>
      <ContinueMessage textIdentifier={1} />
    </PageTransitionWrapper>
  );
};

export default Experience;
