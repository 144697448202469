import React from 'react';
import { Box, Stack, SvgIcon, Typography, Paper } from '@mui/material';
import { RESPONSIVE_PADDING } from '../../constants';
import { ReactComponent as BrandIcon } from '../../assets/brand-icon.svg';
import { ReactComponent as MenuEditIcon } from '../../assets/menu-edit-icon.svg';
import {
  useScrollDirection,
  usePositionData,
  useIsIndependentVIPage,
} from '../../hooks';

interface IProps {
  handleToggleSidebar: (event: React.KeyboardEvent | React.MouseEvent) => void;
}

const MobileHeader: React.FC<IProps> = ({ handleToggleSidebar }) => {
  const positionData = usePositionData();
  const scrollDirection = useScrollDirection();
  const isIndependentVIPage = useIsIndependentVIPage();
  const top = !scrollDirection || scrollDirection === 'up' ? '0' : '-100px';
  return (
    <Paper
      elevation={0}
      sx={(theme) => ({
        position: 'sticky',
        top,
        px: RESPONSIVE_PADDING,
        py: 2,
        mb: 1,
        zIndex: 1,
        transition: 'top 0.3s',
        borderRadius: 0,
        boxShadow:
          scrollDirection === 'up'
            ? `0 0.5rem 1rem ${theme.palette.highlight.boxShadowAccent}`
            : 'initial',
      })}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" alignItems="center" spacing={2}>
          <SvgIcon
            inheritViewBox
            component={BrandIcon}
            sx={{ width: 'inherit', height: 'inherit' }}
          />
          <Box>
            <Typography
              variant="body2"
              color="text.secondary"
              fontWeight="600"
              fontSize="0.5rem"
              textTransform="uppercase"
            >
              Apply for a job
            </Typography>
            <Typography
              variant="body1"
              color="text.primary"
              fontWeight="600"
              textOverflow="ellipsis"
              overflow="hidden"
              maxWidth={{ xs: '55vw', sm: '60vw' }}
              whiteSpace="nowrap"
            >
              {positionData?.isDisplayable
                ? positionData?.title
                : 'Agile Engine'}
            </Typography>
          </Box>
        </Stack>
        {!isIndependentVIPage ? (
          <SvgIcon
            inheritViewBox
            component={MenuEditIcon}
            onClick={handleToggleSidebar}
            sx={{
              cursor: 'pointer',
              width: 'inherit',
              height: 'inherit',
              pt: '7px',
            }}
          />
        ) : (
          <></>
        )}
      </Stack>
    </Paper>
  );
};

export default MobileHeader;
