/** @jsxImportSource @emotion/react */
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../redux';
import { downloadCv, userSelectors } from '../../redux/user';
import { userRouteSelector } from '../../redux/userRoute';
import {
  GoToNextStep,
  PageTransitionWrapper,
  QuickReview,
  StickyPanel,
} from '../../components';

const AboutYourself: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userData = useAppSelector(userSelectors.getUserData)!;
  const stepperRoute = useAppSelector(userRouteSelector.getUserRoute)!;

  const onDownloadCV = async () => {
    await dispatch(
      downloadCv({
        filename: userData.originalCvFileName! as string,
      }),
    );
  };

  const handleButtonClick = async () => {
    if (stepperRoute) {
      navigate(`/${stepperRoute}`);
    }
  };

  return (
    <>
      <PageTransitionWrapper data-testid="about-yourself-layout">
        <Typography variant="h2" mb={2}>
          About yourself
        </Typography>

        <QuickReview
          user={userData}
          maxWidth={{ md: '33rem' }}
          onDownloadCV={onDownloadCV}
        />

        <StickyPanel>
          <GoToNextStep
            isLoading={false}
            handleSubmitStep={handleButtonClick}
            label="Go back"
            mt={2}
            maxWidth={{
              xs: '10rem',
              sm: 'initial',
            }}
          />
        </StickyPanel>
      </PageTransitionWrapper>
    </>
  );
};

export default AboutYourself;
