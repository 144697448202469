import { useAppDispatch, useAppSelector } from 'src/redux';
import { useCallback, useEffect, useMemo } from 'react';
import { useIsStandalonePage } from './useIsStandalonePage';
import { userSelectors } from 'src/redux/user';
import {
  answerUserKnowledgeQuiz,
  finishUserKnowledgeQuiz,
  getUserKnowledgeQuiz,
  knowledgeQuizSelectors,
  startUserKnowledgeQuiz,
} from 'src/redux/knowledgeQuiz';
import { isStatusIdle, isStatusLoading } from 'src/utils';
import { KnowledgeQuizStatus } from 'src/constants';
import { IQuizAnswer } from 'src/types';

export const useKnowledgeQuiz = (shouldFetch?: boolean) => {
  const dispatch = useAppDispatch();
  const isStandalonePage = useIsStandalonePage();

  const userData = useAppSelector(userSelectors.getUserData);

  const apiStatus = useAppSelector(
    knowledgeQuizSelectors.getKnowledgeQuizAPIStatus,
  );
  const data = useAppSelector(knowledgeQuizSelectors.getKnowledgeQuizData);
  const isPerformingAction = useAppSelector(
    knowledgeQuizSelectors.getKnowledgeQuizIsPerformingAction,
  );

  useEffect(() => {
    if (isStandalonePage || !shouldFetch) return;

    if (userData && userData.profileSubmittedAt && isStatusIdle(apiStatus)) {
      dispatch(getUserKnowledgeQuiz());
    }
  }, [userData, apiStatus, isStandalonePage, shouldFetch]);

  const hasKnowledgeQuizToDo = useMemo(
    () => !!data && data.status === KnowledgeQuizStatus.ToDo,
    [data],
  );

  const hasStartedKnowledgeQuiz = useMemo(
    () => !!data && data.status === KnowledgeQuizStatus.Started,
    [data],
  );

  const start = useCallback(
    (knowledgeQuizId: string) =>
      dispatch(startUserKnowledgeQuiz(knowledgeQuizId)),
    [],
  );
  const answer = useCallback(
    (userKnowledgeQuizId: string, answer: IQuizAnswer) =>
      dispatch(answerUserKnowledgeQuiz({ userKnowledgeQuizId, answer })),
    [],
  );
  const finish = useCallback(
    (userKnowledgeQuizId: string) =>
      dispatch(finishUserKnowledgeQuiz(userKnowledgeQuizId)),
    [],
  );

  return {
    isLoadingData: isStatusLoading(apiStatus),
    knowledgeQuiz: data,
    isPerformingAction,
    hasKnowledgeQuizToDo,
    hasStartedKnowledgeQuiz,
    start,
    answer,
    finish,
  };
};
