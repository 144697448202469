import { ICandidateSource } from '@types';
import { API_STATUS } from '../../constants';
import { candidateSourceStoreKey } from './candidateSource.const';
import { ICandidateSourceSliceState } from './candidateSource.slice';

interface IState {
  [candidateSourceStoreKey]: ICandidateSourceSliceState;
}

const selectCandidateSourceState = (state: IState) => {
  return state[candidateSourceStoreKey];
};

const getCandidateSourceApiStatus = (state: IState): API_STATUS => {
  return selectCandidateSourceState(state).apiStatus;
};

const getCandidateSourceData = (state: IState): ICandidateSource | null => {
  return selectCandidateSourceState(state).data;
};

export const candidateSourceSelectors = {
  getCandidateSourceApiStatus,
  getCandidateSourceData,
};
