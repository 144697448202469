import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux';

import { useFormik } from 'formik';

import { BasicDialog, CustomButton } from '../../../components';
import { Stack, TextField, Typography } from '@mui/material';

import { authSelectors, authUser, clearAuthError } from '../../../redux/auth';
import { magicLinkCandidateSelectors } from 'src/redux/magicLinkCandidate';
import { positionsSelectors } from 'src/redux/positions';
import { massCampaignCandidateSelectors } from 'src/redux/massCampaignCandidate';

import { AUTH_ERROR_MESSAGE, AUTH_ERROR_TYPE } from '../../../constants';
import { validationSchema } from './validationSchema';
import { isStatusLoading } from '../../../utils';
import { candidateSourceSelectors } from 'src/redux/candidateSource';

interface IProps {
  handleClose: () => void;
  isOpen: boolean;
  initialValue?: string;
}

const SignInDialog: React.FC<IProps> = ({
  handleClose,
  isOpen = true,
  initialValue,
}) => {
  const dispatch = useAppDispatch();

  const zohoId = useAppSelector(
    magicLinkCandidateSelectors.getMagicLinkCandidateZohoId,
  );
  const position = useAppSelector(positionsSelectors.getPositionData);
  const massCampaignData = useAppSelector(
    massCampaignCandidateSelectors.getMassCampaignCandidateData,
  );
  const candidateSource = useAppSelector(
    candidateSourceSelectors.getCandidateSourceData,
  );
  const authError = useAppSelector(authSelectors.getAuthError);
  const authApiStatus = useAppSelector(authSelectors.getAuthApiStatus);

  const isLoading = isStatusLoading(authApiStatus);
  const authErrorMessage =
    authError === AUTH_ERROR_TYPE.TOKEN_EXPIRED
      ? AUTH_ERROR_MESSAGE.TOKEN_EXPIRED
      : null;

  useEffect(() => {
    return () => {
      dispatch(clearAuthError());
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      email: initialValue ? initialValue : '',
    },
    validationSchema,
    onSubmit: async (values) => {
      const response = await dispatch(
        authUser({
          ...values,
          zohoId,
          position,
          massCampaignData,
          candidateSource,
        }),
      );
      if (authUser.fulfilled.match(response)) {
        handleClose();
      }
      dispatch(clearAuthError());
    },
  });

  return (
    <BasicDialog isOpen={isOpen} handleClose={handleClose}>
      <Typography variant="h1" sx={{ mb: 4 }}>
        Proceed with email
      </Typography>
      {authErrorMessage ? (
        <Typography variant="body1" sx={{ mb: 4 }} color="error">
          {authErrorMessage}
        </Typography>
      ) : (
        <Typography variant="body1" sx={{ mb: 4 }}>
          Enter the email you've applied with and check your inbox. We'll send
          you a magic link for quick log in.
        </Typography>
      )}
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={4} mb={4}>
          <TextField
            name="email"
            placeholder="Email"
            label="Email"
            type="email"
            variant="outlined"
            autoFocus
            onChange={formik.handleChange}
            value={formik.values.email}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <CustomButton
            color="primary"
            isDisabled={!Boolean(formik.values.email)}
            isLoading={isLoading}
            label="Continue"
            fullWidth
          />
        </Stack>
      </form>
    </BasicDialog>
  );
};

export default SignInDialog;
