import { Box, Stack } from '@mui/material';
import React from 'react';

const TableRow = (props: {
  firstBlock: React.ReactElement;
  secondBlock: React.ReactElement;
  thirdBlock: React.ReactElement;
  hasBorder?: boolean;
}) => {
  const { firstBlock, secondBlock, thirdBlock, hasBorder } = props;
  return (
    <Stack
      direction="row"
      spacing={1}
      sx={(theme) => ({
        boxShadow: hasBorder ? 'inset 0px -1px 0px rgba(0, 0, 0, 0.1)' : 'none',
        position: hasBorder ? 'relative' : 'sticky',
        top: hasBorder ? 'auto' : 0,
        backgroundColor: theme.palette.highlight.white,
        zIndex: hasBorder ? 0 : 1,
      })}
    >
      <Box width="5.2rem" padding="1rem 0">
        {firstBlock}
      </Box>
      <Box
        width="22.5rem"
        padding={hasBorder ? '1rem 1.5rem 1rem 3.3rem' : 'auto'}
      >
        {secondBlock}
      </Box>
      <Box
        width="22.5rem"
        padding={hasBorder ? '1rem 1.5rem 1rem 3.3rem' : 'auto'}
      >
        {thirdBlock}
      </Box>
    </Stack>
  );
};

export default TableRow;
