import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../redux';
import { getSkills, skillsSelectors } from '../redux/skills';
import { isStatusIdle } from '../utils';
import { useIsStandalonePage } from './useIsStandalonePage';

export const useFetchSkillsData = (shouldFetch?: boolean) => {
  const dispatch = useAppDispatch();
  const isStandalonePage = useIsStandalonePage();

  const skillsData = useAppSelector(skillsSelectors.getSkillsData);

  useEffect(() => {
    if (isStandalonePage || !shouldFetch) return;

    if (isStatusIdle(skillsData.apiStatus)) {
      dispatch(getSkills());
    }
  }, [skillsData.apiStatus, isStandalonePage, shouldFetch]);
};
