import { IResolveUserDuplicationSliceState } from './resolveUserDuplication.slice';
import { resolveUserDuplicationStoreKey } from './resolveUserDuplication.const';

interface IState {
  [resolveUserDuplicationStoreKey]: IResolveUserDuplicationSliceState;
}

const selectResolveUserDuplicationState = (state: IState) => {
  return state[resolveUserDuplicationStoreKey];
};

const getIsLoading = (state: IState): boolean => {
  return selectResolveUserDuplicationState(state).isLoading;
};

export const resolveUserDuplicationSelectors = {
  getIsLoading,
};
