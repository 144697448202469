/* eslint-disable prettier/prettier */
import { css, SerializedStyles } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useCookieConsentSnackbarStyles = (): SerializedStyles => {
  const theme = useTheme();
  return css`
  position: fixed;
  bottom: 0;
  z-index: 1201;
  width: 100%;

  @media (min-width: ${theme.breakpoints.values.lg}px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .container {
    background: ${theme.palette.background.overlayPanel};
    color: ${theme.palette.text.invertedPrimary};
    padding: 16px 16px 18px;

    @media (min-width: ${theme.breakpoints.values.lg}px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 70%;
      max-width: 858px;
      padding: 16px 48px 18px;
      border-radius: 8px 8px 0px 0px;
    }
  }
`};
