import React, { useCallback, useEffect } from 'react';
import { Box } from '@mui/material';
import { Outlet, useNavigate, useOutletContext } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux';
import { usePersistUserAnswers, usePositionData } from '../../hooks';
import { PageTransitionWrapper } from '../../components';
import { primarySkillsMessage } from './components/primarySkillsMessage';
import { secondarySkillsMessage } from './components/secondarySkillsMessage';
import { skillsSelectors, updateUserTechStack } from '../../redux/skills';
import { updateSkills, userSelectors } from '../../redux/user';
import {
  gamifiedResponseSelectors,
  gamifiedResponseSlice,
} from '../../redux/gamifiedResponse';
import { getUserPrefferedName, isStatusComplete } from '../../utils';
import {
  getPositionSkills,
  getPrimarySkillsBySpecialization,
  getWorkAreaBySpecialization,
} from '../../utils/techSkills';
import { APP_ROUTES, WORK_AREA_TYPE } from '../../constants';
import { DetectedPositionType } from '../../types/positionData';
import { ITechStack } from '@types';
import { logEvent } from 'src/services';

interface ITechSkillsContext {
  handleWorkAreaChange: (workAreaSelected: WORK_AREA_TYPE) => void;
  handleSpecializationChange: (specialization: string) => void;
  handleHighlevelSpecializationChange: (
    highlevelSpecialization: string | null,
  ) => void;
  handlePrimarySkillsChange: (skillSelected: string) => void;
  handleNotOurCoreStackToggle: () => void;
  handleSubmitPrimarySkills: () => void;
  handleSecondarySkillsChange: (skills: string[]) => void;
  handleSubmitSecondarySkills: () => void;
  goBackToWorkArea: () => void;
  goBackToPrimarySkills: () => void;
  goBackToSpecialization: () => void;
}

export function useTechSkillsContext() {
  return useOutletContext<ITechSkillsContext>();
}

const TechSkills: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userData = useAppSelector(userSelectors.getUserData)!;
  const skillsData = useAppSelector(skillsSelectors.getSkillsData);
  const userTechStack = useAppSelector(skillsSelectors.getTechStack);
  const lastGamifiedMessage = useAppSelector(
    gamifiedResponseSelectors.getGamifiedResponse,
  );
  const positionData = usePositionData();

  const [savedTechStack, setSavedTechStack] =
    usePersistUserAnswers<
      Pick<
        ITechStack,
        | 'workArea'
        | 'highlevelSpecialization'
        | 'specialization'
        | 'primarySkills'
        | 'secondarySkills'
        | 'skillset'
      >
    >('techStackStep');

  // Set initial state
  useEffect(() => {
    if (isStatusComplete(skillsData.apiStatus)) {
      const realSpecialization =
        userData?.specialization || savedTechStack?.specialization;
      const primarySkills =
        userData.primarySkills ?? savedTechStack?.primarySkills;
      const secondarySkills =
        userData.secondarySkills ?? savedTechStack?.secondarySkills;
      const workArea =
        getWorkAreaBySpecialization(
          realSpecialization!,
          skillsData.data.specializations,
        ) ?? null;
      if (workArea && !userTechStack.workArea) {
        dispatch(
          updateUserTechStack({
            ...userTechStack,
            workArea,
            workAreaSet: Boolean(realSpecialization),
            specialization: realSpecialization,
            specializationSet:
              getPrimarySkillsBySpecialization(
                realSpecialization!,
                skillsData.data.skills,
              ).length !== 0,
            primarySkills,
            positionSkills: getPositionSkills(userData.positions),
            isPartiallySelected:
              positionData?.detectedPosition === DetectedPositionType.PARTIAL,
            secondarySkills,
          }),
        );
      }
    }
  }, [skillsData.apiStatus, skillsData.data, positionData?.detectedPosition]);

  // Update gamified message on page enter
  useEffect(() => {
    if (userData.specialization) {
      dispatch(gamifiedResponseSlice.actions.updateMessage(null));
    } else {
      if (!lastGamifiedMessage) {
        dispatch(
          gamifiedResponseSlice.actions.updateMessage(
            `Welcome back, ${getUserPrefferedName(userData)}!`,
          ),
        );
      }
    }
  }, []);

  // Save user data input on state change
  useEffect(() => {
    setSavedTechStack({
      workArea: userTechStack.workArea,
      highlevelSpecialization: userTechStack.highlevelSpecialization,
      specialization: userTechStack.specialization,
      primarySkills: userTechStack.primarySkills,
      secondarySkills: userTechStack.secondarySkills,
      skillset: userTechStack.skillset,
    });
  }, [userTechStack]);

  const handleWorkAreaChange = useCallback(
    (workAreaSelected: WORK_AREA_TYPE) => {
      logEvent('launchpod-work-area-selected');

      dispatch(
        gamifiedResponseSlice.actions.updateMessage(
          'Choose a specialization to help us determine the right position for you',
        ),
      );

      const isNewWorkAreaSelected = workAreaSelected !== userTechStack.workArea;

      dispatch(
        updateUserTechStack(
          isNewWorkAreaSelected
            ? {
                workArea: workAreaSelected,
                workAreaSet: true,
                highlevelSpecialization: null,
                specialization: null,
                specializationSet: false,
                primarySkills: null,
                notOurCoreStack: false,
                primarySkillsSet: false,
                secondarySkills: null,
                positionSkills: [],
                skillset: null,
              }
            : {
                ...userTechStack,
                workArea: workAreaSelected,
                workAreaSet: true,
              },
        ),
      );
      navigate(APP_ROUTES.SPECIALIZATION);
    },
    [userTechStack],
  );

  const handleHighlevelSpecializationChange = useCallback(
    (highlevelSpecialization: string | null) => {
      dispatch(
        updateUserTechStack({
          ...userTechStack,
          highlevelSpecialization,
        }),
      );
    },
    [userTechStack],
  );

  const handleSpecializationChange = useCallback(
    (specialization: string) => {
      logEvent('launchpod-specialization-selected');

      const isNewSpecializationSelected =
        specialization !== userTechStack.specialization;
      const specializationHasPrimarySkills =
        getPrimarySkillsBySpecialization(
          specialization!,
          skillsData.data.skills,
        ).length !== 0;

      const navigationData = {
        workAreaSet: true,
        specializationSet: true,
        primarySkillsSet: !specializationHasPrimarySkills,
      };
      const inputData = isNewSpecializationSelected
        ? {
            workArea: userTechStack.workArea,
            highlevelSpecialization: userTechStack.highlevelSpecialization,
            specialization: specialization,
            primarySkills: null,
            notOurCoreStack: false,
            secondarySkills: null,
            skillset: null,
          }
        : {};
      const gamifiedMessage = specializationHasPrimarySkills
        ? 'Awesome!'
        : 'Welcome to our back office!';

      dispatch(
        updateUserTechStack({
          ...userTechStack,
          ...inputData,
          ...navigationData,
        }),
      );
      dispatch(gamifiedResponseSlice.actions.updateMessage(gamifiedMessage));

      if (!specializationHasPrimarySkills) {
        dispatch(
          updateSkills({
            highlevelSpecialization: userTechStack.highlevelSpecialization,
            specialization: specialization,
            primarySkills: null,
            notOurCoreStack: false,
            secondarySkills: [],
            skillset: [],
          }),
        );
      }

      specializationHasPrimarySkills
        ? navigate(APP_ROUTES.PRIMARY_SKILLS)
        : null;
    },
    [userTechStack],
  );

  const handlePrimarySkillsChange = useCallback(
    (skillSelected: string) => {
      let newArray: string[] | null;

      if (userTechStack.primarySkills?.includes(skillSelected)) {
        newArray = userTechStack.primarySkills.filter(
          (skill: string) => skill !== skillSelected,
        );
        dispatch(
          updateUserTechStack({
            ...userTechStack,
            primarySkills: newArray ?? null,
            notOurCoreStack: false,
            highlevelSpecialization: null,
          }),
        );
      } else {
        if (
          userTechStack.primarySkills &&
          userTechStack.primarySkills.length !== 0
        ) {
          newArray = [...userTechStack.primarySkills, skillSelected];
        } else {
          newArray = [skillSelected];
        }

        dispatch(
          updateUserTechStack({
            ...userTechStack,
            primarySkills: newArray,
            notOurCoreStack: false,
            highlevelSpecialization: null,
          }),
        );
      }
      dispatch(
        gamifiedResponseSlice.actions.updateMessage(
          primarySkillsMessage({
            skills: newArray,
          }),
        ),
      );
    },
    [userTechStack],
  );

  const handleNotOurCoreStackToggle = useCallback(() => {
    dispatch(
      updateUserTechStack({
        ...userTechStack,
        primarySkills: userTechStack.notOurCoreStack
          ? userTechStack.primarySkills
          : null,
        notOurCoreStack: !userTechStack.notOurCoreStack,
      }),
    );
    dispatch(
      gamifiedResponseSlice.actions.updateMessage(
        'We will check if we have positions for other technologies',
      ),
    );
  }, [userTechStack]);

  const handleSecondarySkillsChange = useCallback(
    (skills: string[]) => {
      const arrayOfSecondarySkills: string[] = [];
      const arrayOfArbitrarySkills: string[] = [];

      skills.map((skillMixed) => {
        const isInSuggestions = skillsData.data.skills.find(
          (skill) => skill.name === skillMixed && skill.type === 'secondary',
        );
        if (isInSuggestions) {
          arrayOfSecondarySkills.push(skillMixed);
        } else {
          arrayOfArbitrarySkills.push(skillMixed);
        }
      });

      dispatch(
        updateUserTechStack({
          ...userTechStack,
          secondarySkills: arrayOfSecondarySkills,
          skillset: arrayOfArbitrarySkills,
        }),
      );
    },
    [userTechStack],
  );

  const handleSubmitPrimarySkills = useCallback((): void => {
    logEvent('launchpod-primary-skills-continue-click');

    dispatch(
      updateUserTechStack({
        ...userTechStack,
        primarySkillsSet: true,
      }),
    );
    userTechStack.workArea === WORK_AREA_TYPE.ENGINEERING
      ? navigate(APP_ROUTES.SECONDARY_SKILLS)
      : navigate(`/${APP_ROUTES.EXPERIENCE}`);
  }, [userTechStack]);

  const handleSubmitSecondarySkills = useCallback(() => {
    logEvent('launchpod-secondary-skills-continue-click');

    dispatch(
      gamifiedResponseSlice.actions.updateMessage(
        secondarySkillsMessage({
          secondarySkillsSelected: userTechStack.secondarySkills ?? [],
        }),
      ),
    );

    dispatch(
      updateSkills({
        highlevelSpecialization: userTechStack.highlevelSpecialization,
        specialization: userTechStack.specialization!,
        primarySkills: userTechStack.primarySkills,
        notOurCoreStack: userTechStack.notOurCoreStack,
        secondarySkills: userTechStack.secondarySkills ?? [],
        skillset: userTechStack.skillset ?? [],
      }),
    );
  }, [userTechStack]);

  const goBackToWorkArea = useCallback(() => {
    dispatch(
      updateUserTechStack({
        ...userTechStack,
        workAreaSet: false,
        specializationSet: false,
        primarySkillsSet: false,
      }),
    );
    navigate(`/${APP_ROUTES.TECH_SKILLS}`);
  }, [userTechStack]);

  const goBackToSpecialization = useCallback((): void => {
    dispatch(
      updateUserTechStack({
        ...userTechStack,
        workAreaSet: true,
        specializationSet: false,
        primarySkillsSet: false,
        highlevelSpecialization: null,
      }),
    );
    navigate(APP_ROUTES.SPECIALIZATION);
  }, [userTechStack]);

  const goBackToPrimarySkills = useCallback(() => {
    const specializationHasPrimarySkills =
      getPrimarySkillsBySpecialization(
        userTechStack.specialization!,
        skillsData.data.skills,
      ).length !== 0;

    dispatch(
      updateUserTechStack({
        ...userTechStack,
        workAreaSet: true,
        specializationSet: specializationHasPrimarySkills,
        primarySkillsSet: !specializationHasPrimarySkills,
      }),
    );
    navigate(APP_ROUTES.PRIMARY_SKILLS);
  }, [userTechStack]);

  return (
    <PageTransitionWrapper>
      <Box data-testid="skills-layout" />
      <Outlet
        context={{
          goBackToWorkArea,
          handleWorkAreaChange,
          handleSpecializationChange,
          handleHighlevelSpecializationChange,
          handlePrimarySkillsChange,
          handleNotOurCoreStackToggle,
          handleSubmitPrimarySkills,
          goBackToPrimarySkills,
          handleSecondarySkillsChange,
          handleSubmitSecondarySkills,
          goBackToSpecialization,
        }}
      />
    </PageTransitionWrapper>
  );
};

export default TechSkills;
