import { API_STATUS } from '../../constants';
import { IMagicLinkCandidateSliceState } from './magicLinkCandidate.slice';
import { magicLinkCandidateStoreKey } from './magicLinkCandidate.const';

interface IState {
  [magicLinkCandidateStoreKey]: IMagicLinkCandidateSliceState;
}

const selectMagicLinkCandidateState = (state: IState) => {
  return state[magicLinkCandidateStoreKey];
};

const getMagicLinkCandidateApiStatus = (state: IState): API_STATUS => {
  return selectMagicLinkCandidateState(state).apiStatus;
};

const getMagicLinkCandidateZohoId = (state: IState): string | null => {
  return selectMagicLinkCandidateState(state).zohoId;
};

export const magicLinkCandidateSelectors = {
  getMagicLinkCandidateApiStatus,
  getMagicLinkCandidateZohoId,
};
