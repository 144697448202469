import React, { useEffect } from 'react';
import {
  useNavigate,
  useParams,
  UNSAFE_NavigationContext as NavigationContext,
  useLocation,
} from 'react-router-dom';
import { useUserTestTasks } from 'src/hooks';

import { Quiz } from '../../components';

import { useAppDispatch } from '../../redux';
import { finishQuizTestTask, submitQuizAnswer } from '../../redux/testTasks';

import { APP_ROUTES } from '../../constants';
import { IQuizAnswer } from 'src/types';

export const QuizTestTask: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { navigator } = React.useContext(NavigationContext);
  const queryParams = useParams<{ quizQuestionId: string }>();
  const quizQuestionId = queryParams.quizQuestionId ?? '1';

  const { currentTestTask, isPerformingAction } = useUserTestTasks();

  const userTestTaskId = currentTestTask?.id;
  const quizStartDateTime = currentTestTask?.sentDate;
  const quizTestTaskDuration = currentTestTask?.quizTestTask?.duration ?? 20;
  const quizTestTask = currentTestTask?.quizTestTask?.quiz ?? null;
  const questionsCount = Object.keys(quizTestTask ?? {}).length;
  const quizAnswers = currentTestTask?.quizAnswers || {};
  const answersCount = Object.keys(quizAnswers ?? {}).length;

  const unansweredQuestionsCount = questionsCount - answersCount;

  useEffect(() => {
    if (!currentTestTask) {
      navigate(`/${APP_ROUTES.APPLICATION_SUCCESS}`);
    }
    if (!queryParams.quizQuestionId) {
      const firstUnansweredQuestionId = Object.keys(quizTestTask ?? {}).find(
        (questionId) => !quizAnswers?.[questionId]?.length,
      );
      navigate(
        `/${APP_ROUTES.QUIZ_TEST_TASK}/${
          firstUnansweredQuestionId ?? questionsCount
        }`,
      );
    }
  }, []);

  useEffect(() => {
    const unblock = (navigator as any).block((tx: any) => {
      const isNavigationEnabled = [
        APP_ROUTES.QUIZ_TEST_TASK,
        APP_ROUTES.APPLICATION_SUCCESS,
      ].some((e) => tx.location.pathname.includes(e));
      if (isNavigationEnabled && location.pathname !== tx.location.pathname) {
        unblock();
        tx.retry();
      }
    });

    return unblock;
  }, [location.pathname]);

  const currentTest =
    quizTestTask && quizQuestionId ? quizTestTask[quizQuestionId] : null;
  const currentAnswer = quizAnswers[quizQuestionId] || [];

  const handleChange = async (answer: IQuizAnswer) => {
    await dispatch(
      submitQuizAnswer({
        userTestTaskId: userTestTaskId!,
        answer,
      }),
    );
  };

  const handlePreviousQuestion = () => {
    navigate(`/${APP_ROUTES.QUIZ_TEST_TASK}/${Number(quizQuestionId) - 1}`);
  };

  const handleNextQuestion = async () => {
    navigate(`/${APP_ROUTES.QUIZ_TEST_TASK}/${Number(quizQuestionId) + 1}`);
  };

  const handleFinishQuiz = async () => {
    await dispatch(finishQuizTestTask(userTestTaskId!));
  };

  if (!currentTest) return null;

  return (
    <Quiz
      question={currentTest!}
      questionIdx={quizQuestionId}
      questionsCount={questionsCount}
      questionAnswer={currentAnswer}
      unansweredQuestionsCount={unansweredQuestionsCount}
      duration={quizTestTaskDuration}
      startDate={quizStartDateTime!}
      isPerformingAction={isPerformingAction}
      onSelectAnswer={handleChange}
      onNextQuestion={handleNextQuestion}
      onPreviousQuestion={handlePreviousQuestion}
      onFinish={handleFinishQuiz}
    />
  );
};

export default QuizTestTask;
