import React from 'react';
import { Box, FormControlLabel, Typography } from '@mui/material';
import {
  ContinueMessage,
  RadioButton,
  SelectableWrapper,
} from '../../../components';
import { useScrollDirection } from '../../../hooks';
import {
  CONTRACTOR_ENGAGEMENT_DATA,
  EMPLOYEE_ENGAGEMENT_DATA,
  ENGAGEMENT_TABLE_HEADERS,
  ENGAGEMENT_TYPE,
} from '../../../constants';
import MobileTableRow from './MobileTableRow';

interface IProps {
  engagementSelected: string | null;
  handleChange: (engagement: string) => void;
}

const MobileEngagementTable: React.FC<IProps> = ({
  engagementSelected,
  handleChange,
}) => {
  const scrollDirection = useScrollDirection();
  return (
    <>
      <Box my={3}>
        <MobileTableRow
          secondBlock={
            <SelectableWrapper
              value={ENGAGEMENT_TYPE.CONTRACTOR}
              onClick={handleChange}
              selectedValue={engagementSelected}
              display="block"
              testid="engagement-option"
            >
              <FormControlLabel
                value={ENGAGEMENT_TYPE.CONTRACTOR}
                control={<RadioButton tabIndex={-1} />}
                label={
                  <Typography variant="body1" fontWeight="600">
                    {CONTRACTOR_ENGAGEMENT_DATA.name}
                  </Typography>
                }
              />
              <Typography variant="body2" marginLeft="1.8rem">
                {CONTRACTOR_ENGAGEMENT_DATA.info}
              </Typography>
            </SelectableWrapper>
          }
          top={scrollDirection === 'up' ? '4.75rem' : 0}
        />
        <MobileTableRow
          hasBorder
          firstBlock={
            <Typography variant="body2" color="brand.accent">
              {ENGAGEMENT_TABLE_HEADERS.cooperationType}
            </Typography>
          }
          secondBlock={
            <Typography variant="body1" textAlign="end">
              {CONTRACTOR_ENGAGEMENT_DATA.cooperationType}
            </Typography>
          }
        />
        <MobileTableRow
          hasBorder
          firstBlock={
            <Typography variant="body2" color="brand.accent">
              {ENGAGEMENT_TABLE_HEADERS.payment}
            </Typography>
          }
          secondBlock={
            <Typography variant="body1" textAlign="end">
              {CONTRACTOR_ENGAGEMENT_DATA.payment}
            </Typography>
          }
        />
        <MobileTableRow
          hasBorder
          firstBlock={
            <Typography variant="body2" color="brand.accent">
              {ENGAGEMENT_TABLE_HEADERS.taxes}
            </Typography>
          }
          secondBlock={
            <Typography variant="body1" textAlign="end">
              {CONTRACTOR_ENGAGEMENT_DATA.taxes}
            </Typography>
          }
        />
        <MobileTableRow
          hasBorder
          firstBlock={
            <Typography variant="body2" color="brand.accent">
              {ENGAGEMENT_TABLE_HEADERS.socialSecurity}
            </Typography>
          }
          secondBlock={
            <Typography
              variant="body1"
              textAlign="end"
              sx={{ whiteSpace: 'pre-wrap' }}
            >
              {CONTRACTOR_ENGAGEMENT_DATA.socialSecurity}
            </Typography>
          }
        />
        <MobileTableRow
          hasBorder
          firstBlock={
            <Typography variant="body2" color="brand.accent">
              {ENGAGEMENT_TABLE_HEADERS.daysOff}
            </Typography>
          }
          secondBlock={
            <Typography
              variant="body1"
              textAlign="end"
              sx={{ whiteSpace: 'pre-wrap' }}
            >
              {CONTRACTOR_ENGAGEMENT_DATA.daysOff}
            </Typography>
          }
        />
        <MobileTableRow
          hasBorder
          firstBlock={
            <Typography variant="body2" color="brand.accent">
              {ENGAGEMENT_TABLE_HEADERS.vacationBonus}
            </Typography>
          }
          secondBlock={
            <Typography variant="body1" textAlign="end">
              {CONTRACTOR_ENGAGEMENT_DATA.vacationBonus}
            </Typography>
          }
        />
        <MobileTableRow
          hasBorder
          firstBlock={
            <Typography variant="body2" color="brand.accent">
              {ENGAGEMENT_TABLE_HEADERS.christmasBonus}
            </Typography>
          }
          secondBlock={
            <Typography variant="body1" textAlign="end">
              {CONTRACTOR_ENGAGEMENT_DATA.christmasBonus}
            </Typography>
          }
        />
        <MobileTableRow
          hasBorder
          firstBlock={
            <Typography variant="body2" color="brand.accent">
              {ENGAGEMENT_TABLE_HEADERS.healthInsurance}
            </Typography>
          }
          secondBlock={
            <Typography variant="body1" textAlign="end">
              {CONTRACTOR_ENGAGEMENT_DATA.healthInsurance}
            </Typography>
          }
        />
        <MobileTableRow
          hasBorder
          firstBlock={
            <Typography variant="body2" color="brand.accent">
              {ENGAGEMENT_TABLE_HEADERS.anualBudget}
            </Typography>
          }
          secondBlock={
            <Typography
              variant="body1"
              textAlign="end"
              sx={{ whiteSpace: 'pre-wrap' }}
            >
              {CONTRACTOR_ENGAGEMENT_DATA.anualBudget}
            </Typography>
          }
        />
        <MobileTableRow
          hasBorder
          firstBlock={
            <Typography variant="body2" color="brand.accent">
              {ENGAGEMENT_TABLE_HEADERS.severance}
            </Typography>
          }
          secondBlock={
            <Typography variant="body1" overflow="visible" textAlign="end">
              {CONTRACTOR_ENGAGEMENT_DATA.severance}
            </Typography>
          }
        />
        <MobileTableRow
          secondBlock={
            <SelectableWrapper
              value={ENGAGEMENT_TYPE.EMPLOYEE}
              selectedValue={engagementSelected}
              onClick={handleChange}
              display="block"
            >
              <FormControlLabel
                value={ENGAGEMENT_TYPE.EMPLOYEE}
                control={<RadioButton tabIndex={-1} />}
                label={
                  <Typography variant="body1" fontWeight="600">
                    {EMPLOYEE_ENGAGEMENT_DATA.name}
                  </Typography>
                }
              />
              <Typography variant="body2" marginLeft="1.8rem">
                {EMPLOYEE_ENGAGEMENT_DATA.info}
              </Typography>
            </SelectableWrapper>
          }
          top={scrollDirection === 'up' ? '4.75rem' : 0}
        />
        <MobileTableRow
          hasBorder
          firstBlock={
            <Typography variant="body2" color="brand.accent">
              {ENGAGEMENT_TABLE_HEADERS.cooperationType}
            </Typography>
          }
          secondBlock={
            <Typography variant="body1" textAlign="end">
              {EMPLOYEE_ENGAGEMENT_DATA.cooperationType}
            </Typography>
          }
        />
        <MobileTableRow
          hasBorder
          firstBlock={
            <Typography variant="body2" color="brand.accent">
              {ENGAGEMENT_TABLE_HEADERS.payment}
            </Typography>
          }
          secondBlock={
            <Typography variant="body1" textAlign="end">
              {EMPLOYEE_ENGAGEMENT_DATA.payment}
            </Typography>
          }
        />
        <MobileTableRow
          hasBorder
          firstBlock={
            <Typography variant="body2" color="brand.accent">
              {ENGAGEMENT_TABLE_HEADERS.taxes}
            </Typography>
          }
          secondBlock={
            <Typography variant="body1" textAlign="end">
              {EMPLOYEE_ENGAGEMENT_DATA.taxes}
            </Typography>
          }
        />
        <MobileTableRow
          hasBorder
          firstBlock={
            <Typography variant="body2" color="brand.accent">
              {ENGAGEMENT_TABLE_HEADERS.socialSecurity}
            </Typography>
          }
          secondBlock={
            <Typography
              variant="body1"
              textAlign="end"
              sx={{ whiteSpace: 'pre-wrap' }}
            >
              {EMPLOYEE_ENGAGEMENT_DATA.socialSecurity}
            </Typography>
          }
        />
        <MobileTableRow
          hasBorder
          firstBlock={
            <Typography variant="body2" color="brand.accent">
              {ENGAGEMENT_TABLE_HEADERS.daysOff}
            </Typography>
          }
          secondBlock={
            <Typography
              variant="body1"
              textAlign="end"
              sx={{ whiteSpace: 'pre-wrap' }}
            >
              {EMPLOYEE_ENGAGEMENT_DATA.daysOff}
            </Typography>
          }
        />
        <MobileTableRow
          hasBorder
          firstBlock={
            <Typography variant="body2" color="brand.accent">
              {ENGAGEMENT_TABLE_HEADERS.vacationBonus}
            </Typography>
          }
          secondBlock={
            <Typography variant="body1" textAlign="end">
              {EMPLOYEE_ENGAGEMENT_DATA.vacationBonus}
            </Typography>
          }
        />
        <MobileTableRow
          hasBorder
          firstBlock={
            <Typography variant="body2" color="brand.accent">
              {ENGAGEMENT_TABLE_HEADERS.christmasBonus}
            </Typography>
          }
          secondBlock={
            <Typography variant="body1" textAlign="end">
              {EMPLOYEE_ENGAGEMENT_DATA.christmasBonus}
            </Typography>
          }
        />
        <MobileTableRow
          hasBorder
          firstBlock={
            <Typography variant="body2" color="brand.accent">
              {ENGAGEMENT_TABLE_HEADERS.healthInsurance}
            </Typography>
          }
          secondBlock={
            <Typography variant="body1" textAlign="end">
              {EMPLOYEE_ENGAGEMENT_DATA.healthInsurance}
            </Typography>
          }
        />
        <MobileTableRow
          hasBorder
          firstBlock={
            <Typography variant="body2" color="brand.accent">
              {ENGAGEMENT_TABLE_HEADERS.anualBudget}
            </Typography>
          }
          secondBlock={
            <Typography
              variant="body1"
              textAlign="end"
              sx={{ whiteSpace: 'pre-wrap' }}
            >
              {EMPLOYEE_ENGAGEMENT_DATA.anualBudget}
            </Typography>
          }
        />
        <MobileTableRow
          hasBorder
          firstBlock={
            <Typography variant="body2" color="brand.accent">
              {ENGAGEMENT_TABLE_HEADERS.severance}
            </Typography>
          }
          secondBlock={
            <Typography variant="body1" overflow="visible" textAlign="end">
              {EMPLOYEE_ENGAGEMENT_DATA.severance}
            </Typography>
          }
        />
      </Box>
      <ContinueMessage textIdentifier={1} />
    </>
  );
};

export default MobileEngagementTable;
