/** @jsxImportSource @emotion/react */
import React from 'react';
import classnames from 'classnames';
import { ButtonUnstyled } from '@mui/base';
import { Box, ButtonBaseProps } from '@mui/material';
import { useProgressButtonStyles } from './ProgressButton.styles';

interface IProps extends ButtonBaseProps {
  label?: string;
  testid?: string;
  color?: 'primary' | 'secondary';
  fullWidth?: boolean;
  maxWidth?: string;
  progress?: number;
  isLoading?: boolean;
}

export const ProgressButton = React.forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  IProps
>(
  (
    {
      label,
      testid,
      onClick,
      color = 'primary',
      fullWidth = false,
      maxWidth,
      progress = 0,
      isLoading,
    },
    ref,
  ) => {
    const styles = useProgressButtonStyles(color, fullWidth, maxWidth);

    return (
      <ButtonUnstyled
        css={styles}
        className={classnames({
          'is-loading': isLoading,
        })}
        onClick={onClick}
        ref={ref}
        data-testid={testid ?? ''}
        aria-label={label}
        data-label={label}
      >
        <Box className="ae-progress-button_text">{label}</Box>
        {isLoading ? (
          <div
            className="linear-progress"
            style={{ width: `${progress ?? 0}%` }}
          ></div>
        ) : null}
      </ButtonUnstyled>
    );
  },
);
