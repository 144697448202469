/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux';
import { useLocation } from 'react-router-dom';

import { Box, TextField, Typography } from '@mui/material';
import { CustomButton, Loader } from '../../components';

import { declineReferral, referFriendSelectors } from '../../redux/referFriend';

import { parseQueryParams } from '../../utils';

const DECLINE_OPTIONS = [
  'I don’t know referrer',
  'I am not looking for a job',
  'Accepted another job offer',
];

const OptionWrapper = ({
  onClick,
  children,
  isSelected = false,
}: {
  onClick?: () => void;
  children: React.ReactNode;
  isSelected?: boolean;
}) => (
  <Box
    sx={(theme) => ({
      background: isSelected
        ? theme.palette.highlight.accent
        : theme.palette.highlight.actionable,
      borderRadius: '4px',
      padding: '14px 24px 16px',
      cursor: 'pointer',
      '&:hover': {
        background: theme.palette.highlight.accent,
      },
    })}
    onClick={onClick}
  >
    {children}
  </Box>
);

const DeclineReasons = ({
  onDeclineSubmit,
}: {
  onDeclineSubmit: () => void;
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const isDeclining = useAppSelector(
    referFriendSelectors.getReferralDeclineIsLoading,
  );

  const { token } = parseQueryParams<{
    token: string;
  }>(location.search);

  const [isCustomReasonSelected, setIsCustomReasonSelected] = useState(false);
  const [customReason, setCustomReason] = useState('');

  const onDeclineReferral = async (reason: string) => {
    const response = await dispatch(declineReferral({ token, reason }));
    if (declineReferral.fulfilled.match(response)) {
      onDeclineSubmit();
    }
  };

  return (
    <>
      <Typography variant="body2" color="secondary" mb={1.5}>
        Please share why you are not interested
      </Typography>
      <Box display="flex" flexDirection="column" gap={1}>
        {DECLINE_OPTIONS.map((option) => (
          <OptionWrapper onClick={() => onDeclineReferral(option)}>
            <Typography variant="body1">{option}</Typography>
          </OptionWrapper>
        ))}
        <OptionWrapper
          isSelected={isCustomReasonSelected}
          onClick={() => setIsCustomReasonSelected(true)}
        >
          {isCustomReasonSelected ? (
            <>
              <TextField
                name="customReason"
                type="text"
                label="Something else"
                id="customReason"
                variant="outlined"
                autoFocus
                fullWidth
                onChange={(e) => setCustomReason(e.target.value)}
                onBlur={() => setIsCustomReasonSelected(!!customReason)}
                value={customReason}
                InputProps={{
                  sx: { input: { background: '#FFF' } },
                }}
              />
            </>
          ) : (
            <>
              <Typography variant="body1">Something else...</Typography>
              <Typography variant="body2" color="secondary">
                Click to add your own reason, this will greatly help us to
                improve our service
              </Typography>
            </>
          )}
        </OptionWrapper>
        {!!customReason && (
          <Box>
            <CustomButton
              onClick={() => onDeclineReferral(customReason)}
              label="Submit"
              color="primary"
            />
          </Box>
        )}
      </Box>
      {isDeclining && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'rgba(0,0,0,0.2)',
            borderRadius: '48px',
            zIndex: 1,
          }}
        >
          <Loader />
        </Box>
      )}
    </>
  );
};

export default DeclineReasons;
