/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux';

import { Typography } from '@mui/material';
import {
  CustomButton,
  Flex,
  PageTransitionWrapper,
  StepPanel,
} from '../../components';

import { confirmIntermediateScreen, userSelectors } from '../../redux/user';
import { differenceInMonths } from 'date-fns';

const LongTimeNoSeeStep: React.FC = () => {
  const dispatch = useAppDispatch();
  const userData = useAppSelector(userSelectors.getUserData);

  const confirmIntermediateScreenLoading = useAppSelector(
    userSelectors.getIsUserPerformingAction,
  );

  const handleClick = () => {
    dispatch(confirmIntermediateScreen());
  };

  const lastApplyInMonths = useMemo(() => {
    if (!userData?.profileSubmittedAt) return 0;

    return differenceInMonths(
      new Date(),
      new Date(userData.profileSubmittedAt),
    );
  }, [userData?.profileSubmittedAt]);

  return (
    <PageTransitionWrapper>
      <StepPanel data-testid="long-time-no-see-step">
        <Typography sx={{ fontSize: '60px' }}>👋</Typography>
        <Typography variant="h2" mb={0}>
          Long time no see, {userData?.firstName}!
        </Typography>

        {Boolean(lastApplyInMonths) && (
          <Typography variant="body1" mb={2}>
            You were applying for a job {lastApplyInMonths}{' '}
            {lastApplyInMonths > 1 ? 'months' : 'month'} ago.
          </Typography>
        )}
        <Typography variant="body1">
          Happy to have you back! Let’s proceed from where you’ve finished.
        </Typography>
        <Flex width={{ xs: '100%', sm: 'auto' }}>
          <CustomButton
            onClick={handleClick}
            label="Continue"
            color="primary"
            isLoading={confirmIntermediateScreenLoading}
            fullWidth
          />
        </Flex>
      </StepPanel>
    </PageTransitionWrapper>
  );
};

export default LongTimeNoSeeStep;
