import React from 'react';
import { CustomButton, GoToNextStep, TextButton } from '../../../components';
import { Box, Stack, Typography } from '@mui/material';
import { formatReminderDate } from '../../../utils';
import { useAppSelector } from '../../../redux';
import { userSelectors } from '../../../redux/user';
import { useUserVideoInterview } from 'src/hooks';

interface IProps {
  handleStartVideoInterview: () => void;
  handleSkipSelection?: (e: React.SyntheticEvent | null) => void;
  handleCalendar: () => void;
  handleSubmitStep: () => void;
  showRescheduleMessage: boolean;
  showSkipButton?: boolean;
}

const SelectionButtons: React.FC<IProps> = ({
  handleStartVideoInterview,
  handleSkipSelection,
  handleCalendar,
  handleSubmitStep,
  showRescheduleMessage,
  showSkipButton,
}) => {
  const { isLoading: createVILoading } = useUserVideoInterview();
  const userData = useAppSelector(userSelectors.getUserData)!;
  const userReminderDate = userData.videoInterviewReminderDate;

  return (
    <>
      {userReminderDate && showRescheduleMessage ? (
        <Box sx={{ maxWidth: '38rem' }}>
          <Box
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              background: theme.palette.highlight.accent,
              p: 3,
              mb: 3,
              mt: 3,
              alignItems: 'center',
            })}
          >
            <Typography variant="body1" fontWeight="bold">
              The video interview has been scheduled for{' '}
              {formatReminderDate(userReminderDate, userData.country!)}
            </Typography>
            <Typography variant="body1">
              You will receive a calendar event that you can{' '}
              <TextButton onClick={handleCalendar} underlined>
                reschedule or cancel
              </TextButton>
            </Typography>
          </Box>
          <Stack direction="column" alignItems="center">
            <Typography variant="body2" color="secondary">
              In case you're still up to it now
            </Typography>
            <TextButton onClick={handleStartVideoInterview}>
              Start now
            </TextButton>
          </Stack>
        </Box>
      ) : (
        <Box
          sx={(theme) => ({
            display: 'flex',
            marginTop: '60px',
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
            },
          })}
        >
          <Box
            sx={(theme) => ({
              display: 'flex',
              [theme.breakpoints.down('sm')]: {
                marginBottom: '20px',
              },
            })}
          >
            <GoToNextStep
              my={0}
              isLoading={createVILoading}
              handleSubmitStep={handleSubmitStep}
            />
            {showSkipButton && (
              <Box ml="24px">
                <CustomButton
                  color="secondary"
                  label="Skip"
                  fullWidth
                  maxWidth="10rem"
                  onClick={handleSkipSelection}
                />
              </Box>
            )}
          </Box>
          <Box
            sx={{
              ml: '24px',
            }}
          >
            <Typography variant="body2" color="text.secondary">
              Prefer to do it later?
            </Typography>
            <TextButton onClick={handleCalendar}>
              {userReminderDate ? 'Reschedule event' : 'Add to your calendar'}
            </TextButton>
          </Box>
        </Box>
      )}
    </>
  );
};

export default SelectionButtons;
