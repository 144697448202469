import React, { useEffect, useState } from 'react';
import {
  Box,
  FormControl,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  ContinueMessage,
  PageTransitionWrapper,
  RadioButton,
  SelectableWrapper,
} from '../../components';
import { APP_ROUTES, ENGLISH_LEVEL_LIST } from '../../constants';
import { getUserPrefferedName } from '../../utils';
import { useAppDispatch, useAppSelector } from '../../redux';
import { updateEnglishLevel, userSelectors } from '../../redux/user';
import {
  gamifiedResponseSelectors,
  gamifiedResponseSlice,
} from '../../redux/gamifiedResponse';
import { englishLevelMessage } from './components/englishLevelMessage';
import GoToPreviousStep from '../../components/goToPreviousStep/GoToPreviousStep';
import { logEvent } from 'src/services';

const STEP_MAX_WIDTH = '40rem';

const EnglishLevel: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userData = useAppSelector(userSelectors.getUserData)!;
  const lastGamifiedMessage = useAppSelector(
    gamifiedResponseSelectors.getGamifiedResponse,
  );
  const [englishLevelChecked, setEnglishLevelChecked] = useState<string | null>(
    userData.englishLevel ?? null,
  );

  useEffect(() => {
    if (userData.englishLevel) {
      dispatch(gamifiedResponseSlice.actions.updateMessage(null));
    } else {
      if (!lastGamifiedMessage) {
        dispatch(
          gamifiedResponseSlice.actions.updateMessage(
            `Welcome back, ${getUserPrefferedName(userData)}!`,
          ),
        );
      }
    }

    logEvent('launchpod-english-level-page-loaded');
  }, []);

  const handleChange = (levelSelected: string) => {
    logEvent('launchpod-english-level-selected', {
      'English Level': levelSelected,
    });

    setEnglishLevelChecked(levelSelected);
    dispatch(
      gamifiedResponseSlice.actions.updateMessage(
        englishLevelMessage({ englishLevelSelected: levelSelected }),
      ),
    );
    setTimeout(() => {
      dispatch(updateEnglishLevel(levelSelected));
    }, 500);
  };

  const handleGoBack = () => {
    navigate(`/${APP_ROUTES.CITY}`);
  };

  const formatOption = (option: string) => {
    const lowed = option.toLowerCase();
    const formatted = lowed.charAt(0).toUpperCase() + lowed.slice(1);
    return formatted;
  };

  return (
    <PageTransitionWrapper>
      <Box display="flex" data-testid="english-level-layout">
        <GoToPreviousStep handleGoToPreviousStep={handleGoBack} />
        <Typography variant="h2">
          How would you rate
          <br />
          your English?
        </Typography>
      </Box>
      <Typography variant="body1">
        <Typography variant="h5" component="span" mr={0.5}>
          ☝️
        </Typography>
        Upcoming screening call is going to be in English.
      </Typography>
      <Box maxWidth={STEP_MAX_WIDTH} mt={4} mb={2}>
        <FormControl>
          <Stack spacing={0.5} width={{ lg: '27rem' }}>
            {ENGLISH_LEVEL_LIST.map((levelObject) => {
              return (
                <SelectableWrapper
                  key={levelObject.value}
                  value={levelObject.value}
                  selectedValue={englishLevelChecked}
                  onClick={() => handleChange(levelObject.value)}
                  display="block"
                  testid={`selects-${levelObject.value}`}
                >
                  <FormControlLabel
                    checked={levelObject.value === englishLevelChecked}
                    value={levelObject.value}
                    control={<RadioButton tabIndex={-1} />}
                    label={
                      <Typography variant="body1" fontWeight="600">
                        {formatOption(levelObject.label)}
                      </Typography>
                    }
                  />
                  <Typography
                    variant="body1"
                    ml={4}
                    whiteSpace={{ lg: 'pre-line' }}
                    color="text.secondary"
                  >
                    {levelObject.description}
                  </Typography>
                </SelectableWrapper>
              );
            })}
          </Stack>
        </FormControl>
      </Box>
      <ContinueMessage textIdentifier={1} />
    </PageTransitionWrapper>
  );
};

export default EnglishLevel;
