import React, { useState } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import TestTasksSelectableList from '../components/TestTasksSelectableList';
import PassTestTaskButton from '../components/PassTestTaskButton';
import RescheduleTestTaskButton from '../components/RescheduleTestTaskButton';
import { Flex } from '../../../components';

import { AvailableTestTaskWithPositionsData } from '../../../types';
import { TEST_TASK_STATUS } from '../../../constants';
import { CodilityDescription } from '../components/CodilityDescription';
import { useFeatureFlags } from 'src/hooks/useFeatureFlags';

interface IMultipleTTWithPassedLayoutProps {
  testTaskSelected: string | null;
  testTasks: AvailableTestTaskWithPositionsData[];
  isPerformingAction: boolean;
  testTaskReminderDate: string | null;
  handleTTChange: (testTask: AvailableTestTaskWithPositionsData) => void;
  handlePassTTClick: () => void;
  handleScheduleForLater: () => void;
}

const MultipleTTWithPassedLayout: React.FC<
  IMultipleTTWithPassedLayoutProps
> = ({
  testTaskSelected,
  testTasks,
  isPerformingAction,
  testTaskReminderDate,
  handleTTChange,
  handlePassTTClick,
  handleScheduleForLater,
}) => {
  const { isTTSelectionPageSimplified } = useFeatureFlags();

  const passedTT = testTasks.filter(
    (tt) => tt.status === TEST_TASK_STATUS.PASSED,
  );
  const otherTT = testTasks?.filter(
    (option) => option.status !== TEST_TASK_STATUS.PASSED,
  );

  const [displayOtherTechOptions, setDisplayOtherTechOptions] = useState(false);

  return (
    <>
      <Typography
        variant="body1"
        mb={1}
        data-testid="tt-already-passed-subtext"
      >
        Tests for your skills:
      </Typography>
      <TestTasksSelectableList
        selectedTestTask={''}
        testTasks={passedTT}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        handleChange={() => {}}
      />
      <Typography
        variant="body1"
        data-testid="selectables-and-one-passed-notifier"
      >
        We’ve found your good coding test score!
        <Box component="br" />
        Our Talent Executive will be happy{' '}
        <Box
          component="br"
          sx={(theme) => ({
            [theme.breakpoints.down('md')]: {
              display: 'none',
            },
          })}
        />
        to discuss the next steps with you.
      </Typography>
      <Stack spacing={1} direction="column" mt={4}>
        <Box>
          <Typography
            variant="body1"
            fontWeight="bold"
            color={displayOtherTechOptions ? 'text.primary' : 'brand.accent'}
            sx={{
              cursor: displayOtherTechOptions ? 'auto' : 'pointer',
              my: 3.5,
              width: 'max-content',
            }}
            onClick={() => setDisplayOtherTechOptions(true)}
            data-testid="select-another-test-option"
          >
            Select another technology ↓
          </Typography>
          {displayOtherTechOptions && (
            <div>
              <TestTasksSelectableList
                selectedTestTask={testTaskSelected}
                testTasks={otherTT}
                handleChange={handleTTChange}
                testId="other-selectable-options"
              />
              <Stack spacing={4} direction="column" mt={4}>
                <Flex gap={2}>
                  <PassTestTaskButton
                    isDisabled={!testTaskSelected}
                    isLoading={isPerformingAction}
                    handlePassTTClick={handlePassTTClick}
                  />
                  <RescheduleTestTaskButton
                    isDisabled={isPerformingAction}
                    testTaskReminderDate={testTaskReminderDate}
                    handleScheduleForLater={handleScheduleForLater}
                  />
                </Flex>
                {!isTTSelectionPageSimplified && <CodilityDescription />}
              </Stack>
            </div>
          )}
        </Box>
      </Stack>
    </>
  );
};

export default MultipleTTWithPassedLayout;
