import { css, SerializedStyles } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useSidebarStyles = (): SerializedStyles => {
  const theme = useTheme();
  return css`
    flex-shrink: 0;

    .MuiDrawer-paper {
      box-sizing: border-box;
      border-right: none;
      padding-left: ${theme.spacing(6)};
      padding-right: ${theme.spacing(1)};
      z-index: 1;
    }
  `;
};
