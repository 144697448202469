import React from 'react';
import { PageTransitionWrapper } from '../../../../components';
import { Box, Stack, Typography } from '@mui/material';
import SelectionButtons from '../SelectionButtons';
import { apiEndpoints } from '../../../../constants';
import { ScheduleDialog } from '../../../../components';
import { VideoInterviewDialog } from '../VideoInterviewDialog';
import { useIsLaptop } from 'src/hooks';
import VideoInterviewMobileDialog from '../VideoInterviewMobileDialog';
import { useAppSelector } from 'src/redux';
import { userSelectors } from 'src/redux/user';
import SelectionVideoPlayer from '../SelectionVideoPlayer';
import { GuaranteeDescription } from '../GuaranteeDescription';

interface IProps {
  handleCloseScheduleForLater: () => void;
  handleScheduleSuccess: () => void;
  handleStartRecord: (e: React.SyntheticEvent | null) => void;
  handleAddToCalendar: () => void;
  handleStartVideoInterviewNow: () => void;
  handleSkipVideoInterview: (e: React.SyntheticEvent | null) => void;
  showScheduleDialog: boolean;
  showRescheduleMessage: boolean;
  showVIDialog: boolean;
  setShowVIDialog: (showVIDialog: boolean) => void;
  isIndependentVIPage: boolean;
}

const NonTechVideoInterviewSelection: React.FC<IProps> = ({
  handleCloseScheduleForLater,
  handleScheduleSuccess,
  handleStartRecord,
  handleAddToCalendar,
  handleStartVideoInterviewNow,
  handleSkipVideoInterview,
  showScheduleDialog,
  showRescheduleMessage,
  showVIDialog,
  setShowVIDialog,
  isIndependentVIPage,
}) => {
  const isLaptop = useIsLaptop();
  const userData = useAppSelector(userSelectors.getUserData)!;

  const hasOpeninPositions = !userData.closedAt;

  const VideoComponent = () => {
    return (
      <SelectionVideoPlayer
        videoUrl={apiEndpoints.videoFileUrl('intro-video-bo.mp4')}
        subtitleUrl={`${process.env.PUBLIC_URL}/intro-video-bo.vtt`}
        posterUrl={`${process.env.PUBLIC_URL}/intro-video-poster.jpg`}
        signature="Meet Alex Kalinovsky, CEO of AgileEngine"
      />
    );
  };

  return (
    <>
      {showScheduleDialog && (
        <ScheduleDialog
          isOpen={showScheduleDialog}
          handleClose={handleCloseScheduleForLater}
          handleScheduleSuccess={handleScheduleSuccess}
          isVideoInterview={true}
        />
      )}
      {showVIDialog && (
        <>
          {isLaptop ? (
            <VideoInterviewDialog
              isOpen={showVIDialog}
              handleClose={() => setShowVIDialog(false)}
              handleStartRecord={handleStartRecord}
              handleCalendar={handleAddToCalendar}
              showSkipButton={!hasOpeninPositions && !isIndependentVIPage}
              handleSkipSelection={handleSkipVideoInterview}
            />
          ) : (
            <VideoInterviewMobileDialog
              isOpen={showVIDialog}
              handleClose={() => setShowVIDialog(false)}
            />
          )}
        </>
      )}
      <PageTransitionWrapper>
        <Box data-testid="non-tech-video-interview-selection-layout">
          <Stack
            sx={(theme) => ({
              [theme.breakpoints.down('lg')]: {
                flexDirection: 'column',
              },
              [theme.breakpoints.up('lg')]: {
                flexDirection: 'row',
              },
            })}
          >
            <Stack
              sx={(theme) => ({
                [theme.breakpoints.up('lg')]: {
                  minWidth: '28.25rem',
                  maxWidth: '37rem',
                },
              })}
            >
              <Typography variant="h2" mb={2}>
                Record a short video introduction to move faster with your
                application
              </Typography>

              <Box
                sx={(theme) => ({
                  [theme.breakpoints.up('lg')]: {
                    display: 'none',
                  },
                })}
              >
                {VideoComponent()}
              </Box>

              <GuaranteeDescription />
              <Typography variant="body1" marginTop="1rem">
                We’re excited to get to know you better and sooner!
              </Typography>

              <SelectionButtons
                handleStartVideoInterview={handleStartVideoInterviewNow}
                handleSubmitStep={() => setShowVIDialog(true)}
                handleCalendar={handleAddToCalendar}
                showRescheduleMessage={showRescheduleMessage}
                showSkipButton={!hasOpeninPositions && !isIndependentVIPage}
                handleSkipSelection={() => setShowVIDialog(true)}
              />
            </Stack>
            <Box
              sx={(theme) => ({
                [theme.breakpoints.down('lg')]: {
                  display: 'none',
                },
                [theme.breakpoints.up('xl')]: {
                  marginLeft: '-1rem',
                },
                marginLeft: '-3rem',
                marginTop: '4.3rem',
              })}
            >
              {VideoComponent()}
            </Box>
          </Stack>
        </Box>
      </PageTransitionWrapper>
    </>
  );
};

export default NonTechVideoInterviewSelection;
