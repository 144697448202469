import { createSlice } from '@reduxjs/toolkit';
import { API_STATUS } from '../../constants';
import { locationsStoreKey } from './locations.const';
import { getCountries, getCities } from './locations.thunks';
import { ICity, ICountry } from '../../types/location';

export interface ILocationsSliceState {
  countriesAPIStatus: API_STATUS;
  countries: ICountry[];

  citiesAPIStatus: API_STATUS;
  cities: ICity[];
}

const initialState: ILocationsSliceState = {
  countriesAPIStatus: API_STATUS.IDLE,
  countries: [],

  citiesAPIStatus: API_STATUS.IDLE,
  cities: [],
};

export const locationsSlice = createSlice({
  name: locationsStoreKey,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCountries.pending, (state) => {
        state.countriesAPIStatus = API_STATUS.LOADING;
      })
      .addCase(getCountries.fulfilled, (state, action) => {
        state.countriesAPIStatus = API_STATUS.COMPLETE;
        state.countries = action.payload;
      })
      .addCase(getCountries.rejected, (state) => {
        state.countriesAPIStatus = API_STATUS.FAILED;
      })
      .addCase(getCities.pending, (state) => {
        state.citiesAPIStatus = API_STATUS.LOADING;
      })
      .addCase(getCities.fulfilled, (state, action) => {
        state.citiesAPIStatus = API_STATUS.COMPLETE;
        state.cities = action.payload;
      })
      .addCase(getCities.rejected, (state) => {
        state.citiesAPIStatus = API_STATUS.FAILED;
      });
  },
});
