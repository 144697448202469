import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../redux';
import { isStatusIdle } from '../utils';
import { userSelectors } from '../redux/user';
import {
  getCountries,
  getCities,
  locationsSelectors,
} from 'src/redux/locations';
import { useIsStandalonePage } from './useIsStandalonePage';

export const useLPLocation = (shouldFetch?: boolean) => {
  const dispatch = useAppDispatch();
  const isStandalonePage = useIsStandalonePage();

  const userData = useAppSelector(userSelectors.getUserData)!;
  const countriesAPIData = useAppSelector(
    locationsSelectors.getCountriesAPIData,
  );
  const citiesAPIData = useAppSelector(locationsSelectors.getCitiesAPIData);

  const suggestedCountries = countriesAPIData.countries.filter(
    (country) => country.isSuggested,
  );
  const selectedCountryCode =
    countriesAPIData.countries.find(
      (country) => country.name === userData.country,
    )?.code || '';
  const officeLocations = citiesAPIData.cities.filter(
    (city) => city.hasOffice && city.countryCode === selectedCountryCode,
  );

  useEffect(() => {
    if (isStandalonePage || !shouldFetch) return;

    if (userData && isStatusIdle(countriesAPIData.countriesAPIStatus)) {
      dispatch(getCountries());
    }

    if (userData && isStatusIdle(citiesAPIData.citiesAPIStatus)) {
      dispatch(getCities());
    }
  }, [
    userData,
    isStandalonePage,
    countriesAPIData.countriesAPIStatus,
    citiesAPIData.citiesAPIStatus,
    shouldFetch,
  ]);

  return {
    countriesAPIStatus: countriesAPIData.countriesAPIStatus,
    countries: countriesAPIData.countries,
    suggestedCountries,
    citiesAPIStatus: citiesAPIData.citiesAPIStatus,
    cities: citiesAPIData.cities,
    officeLocations,
  };
};
