const userVideoInterviewBaseAPI = '/user-video-interview';

export const skipUserVideoInterviewPath = () =>
  `${userVideoInterviewBaseAPI}/skip`;

export const createUserVideoInterviewPath = () => userVideoInterviewBaseAPI;

export const viewUserVideoInterviewPath = () =>
  `${userVideoInterviewBaseAPI}/view`;

export const finishUserVideoInterviewQuestionPath = (
  userVideoInterviewId: string,
  questionNumber: number,
) =>
  `${userVideoInterviewBaseAPI}/${userVideoInterviewId}/question/${questionNumber}`;

export const finishUserVideoInterviewPath = (userVideoInterviewId: string) =>
  `${userVideoInterviewBaseAPI}/${userVideoInterviewId}/finish`;

export const restartUserVideoInterviewPath = (userVideoInterviewId: string) =>
  `${userVideoInterviewBaseAPI}/${userVideoInterviewId}/restart`;

export const videoInterviewReminderDatePath = () =>
  `${userVideoInterviewBaseAPI}/set-reminder-date`;
