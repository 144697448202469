/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef } from 'react';
import { useLocation, useNavigate, useNavigationType } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux';

import { useEmojisplosion } from '../../hooks';

import { Typography } from '@mui/material';
import {
  CustomButton,
  Flex,
  PageTransitionWrapper,
  ResponsiveBreak,
  StepPanel,
  StepPanelHeader,
} from '../../components';

import { confirmIntermediateScreen, userSelectors } from '../../redux/user';

import happyFace from '../../assets/tt-happy-face.png';
import happyFaceWebp from '../../assets/tt-happy-face.webp';

const InternalTestTaskSentStep: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const navigationType = useNavigationType();
  const location = useLocation();

  const confirmIntermediateScreenLoading = useAppSelector(
    userSelectors.getIsUserPerformingAction,
  );

  const timeRef = useRef<HTMLDivElement>(null);
  useEmojisplosion(timeRef, 'ALL_DONE');

  useEffect(() => {
    if (navigationType.toString() === 'POP') {
      navigate(1);
      return;
    }
  }, [location]);

  const handleClick = () => {
    dispatch(confirmIntermediateScreen());
  };

  return (
    <PageTransitionWrapper>
      <StepPanel
        data-testid="auth-user-confirmation"
        maxWidth="50rem"
        width="fit-content"
      >
        <StepPanelHeader
          ref={timeRef}
          srcSetPng={happyFace}
          srcSetWebp={happyFaceWebp}
          alt="Happy face"
          sx={{ padding: '0 1.5rem', whiteSpace: 'pre-line' }}
        >
          It was a pleasure to meet you,
          <ResponsiveBreak breakpoint="sm" /> now it’s time to show your hard
          skills
        </StepPanelHeader>

        <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
          Our team would like to ask you to complete a short test assignment.
        </Typography>

        <Flex width={{ xs: '100%', sm: 'auto' }}>
          <CustomButton
            onClick={handleClick}
            label="Continue"
            color="primary"
            isLoading={confirmIntermediateScreenLoading}
            fullWidth
          />
        </Flex>
      </StepPanel>
    </PageTransitionWrapper>
  );
};

export default InternalTestTaskSentStep;
