import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../redux';
import { userSelectors } from '../redux/user';
import { isStatusIdle } from '../utils';
import {
  magicLinkCandidateSelectors,
  postUserWithZohoSignUp,
} from '../redux/magicLinkCandidate';

export const useMagicLinkCandidate = () => {
  const dispatch = useAppDispatch();

  const userData = useAppSelector(userSelectors.getUserData);
  const apiStatus = useAppSelector(
    magicLinkCandidateSelectors.getMagicLinkCandidateApiStatus,
  );
  const zohoId = useAppSelector(
    magicLinkCandidateSelectors.getMagicLinkCandidateZohoId,
  );

  useEffect(() => {
    if (userData && isStatusIdle(apiStatus) && zohoId) {
      dispatch(postUserWithZohoSignUp(zohoId));
    }
  }, [userData, apiStatus, zohoId]);
};
