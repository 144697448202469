import React from 'react';
import { Box, Typography } from '@mui/material';
import { ISubNavigationStep } from '../../../../types';
import { isKeyClick } from '../../../../utils';
import { StepIcon, StepLabel } from '../../../../components';

interface IProps {
  step: ISubNavigationStep;
  handleEditStep: (step: ISubNavigationStep) => void;
}

const QuestioneerStep: React.FC<IProps> = ({ step, handleEditStep }) => {
  const { route, isActive, isHeader, isEditable } = step;
  const handleClick = () => {
    handleEditStep(step);
  };
  return (
    <Box
      onClick={handleClick}
      sx={{
        cursor: isHeader ? undefined : 'pointer',
        pointerEvents: isEditable ? undefined : 'none',
        position: 'relative',
        pl: 3.2,
        mb: isHeader ? 3 : 0,
      }}
      onKeyUp={(e: React.KeyboardEvent<any>) => {
        if (isKeyClick(e)) {
          e.stopPropagation();
          handleClick();
        }
      }}
      role={route && isEditable ? 'button' : ''}
      tabIndex={route && isEditable ? 0 : -1}
    >
      {isHeader && <StepIcon isActive={isActive} />}
      <StepLabel step={step} showCheckbox={!isHeader} />
      {isHeader && (
        <Typography variant="body2" color="secondary">
          You can answer in any order
        </Typography>
      )}
    </Box>
  );
};

export default QuestioneerStep;
