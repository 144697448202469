import React from 'react';
import { useLocation, resolvePath } from 'react-router-dom';
import { Box, Typography, Theme, Checkbox, styled } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { ISubNavigationStep } from '../../types';

interface IProps {
  step: ISubNavigationStep;
  showCheckbox?: boolean;
  showEditIcon?: boolean;
}

const UncheckIcon = styled('span', {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<{ isActive?: boolean }>(({ theme, isActive = false }) => ({
  borderRadius: '0.25rem',
  width: 22,
  height: 22,
  backgroundColor: isActive
    ? theme.palette.highlight.accent
    : theme.palette.highlight.neutral,
}));

const CheckedIcon = styled(UncheckIcon)(({ theme }) => ({
  backgroundColor: theme.palette.brand.accent,
  animation: 'selectItem 0.25s',
  '@keyframes selectItem': {
    '0%': {
      backgroundColor: theme.palette.highlight.accent,
    },
    '100%': {
      backgroundColor: theme.palette.brand.accent,
    },
  },
}));

export const StepLabel: React.FC<IProps> = ({
  step,
  showCheckbox = false,
  showEditIcon = false,
}) => {
  const location = useLocation();
  const { label, isEditable, isHeader, isChecked } = step;
  const isActiveStep = location.pathname === resolvePath(step.route).pathname;
  const getTextColor = (theme: Theme): string => {
    if (isActiveStep) {
      return theme.palette.brand.accent;
    }
    if (isHeader || isEditable) {
      return theme.palette.text.primary;
    }
    return theme.palette.text.secondary;
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      sx={{
        '&:hover': {
          textDecoration: isEditable && !isActiveStep ? 'underline' : undefined,
          textUnderlinePosition: 'from-font',
          textDecorationThickness: '0.5px',
        },
      }}
    >
      {showCheckbox && (
        <Checkbox
          name={label}
          checked={isChecked}
          icon={<UncheckIcon isActive={isActiveStep} />}
          checkedIcon={<CheckedIcon />}
          sx={{ position: 'absolute', transform: 'translate(-50%)', left: 0 }}
          disableRipple
        />
      )}
      <Typography
        variant="body1"
        sx={(theme) => ({
          color: getTextColor(theme),
        })}
      >
        {label}
      </Typography>
      {isEditable && showEditIcon && (
        <EditIcon sx={{ color: 'text.secondary', ml: 1, fontSize: '1rem' }} />
      )}
    </Box>
  );
};
