import React from 'react';
import { Box } from '@mui/material';
import { ICountry } from '../../../types';

interface IProps {
  props: React.HTMLAttributes<HTMLLIElement>;
  option: ICountry;
}
const AutocompleteCountryOption: React.FC<IProps> = ({ props, option }) => {
  return (
    <Box
      key={option.name}
      component="li"
      {...props}
      padding="1rem 1.5rem!important"
      sx={(theme) => ({
        backgroundColor: `${theme.palette.highlight.actionable}!important`,
        borderBottom: '3px solid white',
        '& > img': { mr: 2, flexShrink: 0 },
        ':hover': {
          backgroundColor: `${theme.palette.highlight.accent}!important`,
        },
        '&.Mui-focused': {
          backgroundColor: `${theme.palette.highlight.accent}!important`,
        },
      })}
    >
      <img
        loading="lazy"
        width="20"
        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
        alt={`${option.name} flag`}
      />
      {option.name}
    </Box>
  );
};

export default AutocompleteCountryOption;
