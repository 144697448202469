import React, { useMemo } from 'react';

import { PageTransitionWrapper } from '../../components';
import { TIScheduledLayout } from './layouts/TIScheduledLayout';
import { TIDoneLayout } from './layouts/TIDoneLayout';
import { TIPassedLayout } from './layouts/TIPassedLayout';

import { isPast } from 'date-fns';
import { useUserMeetings } from 'src/hooks';
import { useAppSelector } from 'src/redux';
import { userSelectors } from 'src/redux/user';

export const TechnicalInterviewPage: React.FC = () => {
  const userData = useAppSelector(userSelectors.getUserData);
  const { activeIntroCall, activeTechnicalInterview } = useUserMeetings();

  const Layout = useMemo(() => {
    if (userData?.hiredAt) {
      return TIPassedLayout;
    }

    if (
      activeTechnicalInterview &&
      isPast(new Date(activeTechnicalInterview.scheduledDate))
    )
      return TIDoneLayout;

    if (activeTechnicalInterview) {
      return TIScheduledLayout;
    }

    return null;
  }, [activeIntroCall, activeTechnicalInterview, userData?.hiredAt]);

  if (!Layout) return null;

  return (
    <PageTransitionWrapper>
      <Layout />
    </PageTransitionWrapper>
  );
};
