/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { BrandLogo } from '../../components';
import ReferFriendSuccess from './components/ReferFriendSuccess';
import ReferFriendForm from './components/ReferFriendForm';
import SignInForm from './SignIn';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  referFriendSelectors,
  setReferralAuthStatus,
} from '../../redux/referFriend';
import Cookies from 'js-cookie';
import { AUTH_STATUS } from '../../types/referFriend';
import ReferFriendRejected from './components/ReferFriendRejected';
import { logEvent } from 'src/services';

const ReferFriend: React.FC = () => {
  const dispatch = useAppDispatch();

  const authStatus = useAppSelector(referFriendSelectors.getAuthStatus);
  const wasRejected = useAppSelector(
    referFriendSelectors.getRefferalSubmitWasRejected,
  );
  const isRefferalInProcess = useAppSelector(
    referFriendSelectors.getRefferalInProcess,
  );
  const referralName = useAppSelector(
    referFriendSelectors.getRefferalSubmitName,
  );

  useEffect(() => {
    logEvent('launchpod - refer-friend-page_loaded');
  }, []);

  useEffect(() => {
    const access_token = Cookies.get('Authentication');
    access_token && dispatch(setReferralAuthStatus(AUTH_STATUS.LOGGEDIN));
  }, []);

  const [isReferralSubmitted, serIsReferralSubmitted] = useState(false);

  const onReferMoreFriendsClick = useCallback(
    () => serIsReferralSubmitted(false),
    [],
  );

  const onReferralSubmit = useCallback(() => serIsReferralSubmitted(true), []);

  const SignedInComponent = useMemo(() => {
    if (!isReferralSubmitted)
      return <ReferFriendForm onReferralSubmit={onReferralSubmit} />;

    if (wasRejected)
      return (
        <ReferFriendRejected
          isRefferalInProcess={isRefferalInProcess}
          referralName={referralName}
          onReferMoreFriendsClick={onReferMoreFriendsClick}
        />
      );

    return (
      <ReferFriendSuccess onReferMoreFriendsClick={onReferMoreFriendsClick} />
    );
  }, [isReferralSubmitted, isRefferalInProcess, wasRejected, referralName]);

  return (
    <Box
      sx={(theme) => ({
        background: isReferralSubmitted
          ? theme.palette.common.white
          : theme.palette.background.backgroundPrimary,
        minWidth: '100%',
        minHeight: '100%',
        padding: '41px 16px',
        [theme.breakpoints.down('lg')]: {
          paddingTop: '130px',
        },
      })}
    >
      <Box sx={{ position: 'absolute', top: '49px', left: '66px' }}>
        <BrandLogo width={133} />
      </Box>
      <Box display="flex" alignItems="center" flexDirection="column">
        {authStatus === AUTH_STATUS.LOGGEDIN ? (
          <>{SignedInComponent}</>
        ) : (
          <SignInForm />
        )}
      </Box>
    </Box>
  );
};

export default ReferFriend;
