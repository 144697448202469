import React, { useState, useEffect, useRef } from 'react';
import { Box, FormControlLabel, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import MyLocationIcon from '@mui/icons-material/MyLocation';

import { useAppDispatch, useAppSelector } from '../../redux';
import { updateOffice, userSelectors } from '../../redux/user';
import { getUserPrefferedName } from '../../utils';
import { APP_ROUTES } from '../../constants';
import {
  useFocusInputAfterAnimation,
  useLPLocation,
  usePersistUserAnswers,
} from '../../hooks';
import {
  GoToNextStep,
  PageTransitionWrapper,
  RadioButton,
  ResponsiveBreak,
  SelectableWrapper,
  StickyPanel,
} from '../../components';
import {
  gamifiedResponseSelectors,
  gamifiedResponseSlice,
} from '../../redux/gamifiedResponse';
import GoToPreviousStep from '../../components/goToPreviousStep/GoToPreviousStep';

import galleryImages from '../../assets/office-photo-gallery.png';
import galleryImagesIn from '../../assets/office-photo-gallery-in.png';
import remoteOptionImage from '../../assets/remote-popup-image.png';

const Office = (): React.ReactElement => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userData = useAppSelector(userSelectors.getUserData)!;
  const isLoading = useAppSelector(userSelectors.getIsUserPerformingAction);
  const { officeLocations } = useLPLocation();
  const lastGamifiedMessage = useAppSelector(
    gamifiedResponseSelectors.getGamifiedResponse,
  );

  const [userOffice, setUserOffice] = useState(userData.officeLocation);

  const [autoDetected] =
    usePersistUserAnswers<Record<string, boolean>>('cityStep');

  const inputRef = useRef<HTMLInputElement | null>(null);

  useFocusInputAfterAnimation(inputRef);

  const isSuggestedOffice = (office: string): boolean =>
    userData.city === office && autoDetected?.isAutoDetected;

  const imageSet =
    userData?.country === 'India' ? galleryImagesIn : galleryImages;

  const handleGoBack = () => {
    navigate(`/${APP_ROUTES.CITY}`);
  };

  useEffect(() => {
    if (userData.officeLocation) {
      dispatch(gamifiedResponseSlice.actions.updateMessage(null));
    } else {
      if (!lastGamifiedMessage) {
        dispatch(
          gamifiedResponseSlice.actions.updateMessage(
            `Welcome back, ${getUserPrefferedName(userData)}!`,
          ),
        );
      }
    }
  }, []);

  useEffect(() => {
    setUserOffice(userData.officeLocation);
  }, [userData.officeLocation]);

  const handleChange = (officeCity: string): void => {
    setUserOffice(officeCity);
    dispatch(gamifiedResponseSlice.actions.updateMessage('Got it!'));

    if (officeCity !== 'Remote') {
      setTimeout(async () => {
        await dispatch(updateOffice({ officeLocation: officeCity }));
      }, 500);
    }
  };

  const handleSubmit = (e: React.SyntheticEvent | null): void => {
    if (e) {
      e.preventDefault();
    }

    dispatch(gamifiedResponseSlice.actions.updateMessage('Got it!'));
    setTimeout(async () => {
      await dispatch(updateOffice({ officeLocation: userOffice! }));
    }, 500);
  };

  return (
    <PageTransitionWrapper>
      <Box
        display="flex"
        data-testid="office-layout"
        flexDirection="column"
        sx={(theme) => ({
          maxWidth: '42rem',

          [theme.breakpoints.up('xl')]: {
            maxWidth: '46rem',
          },
        })}
      >
        <Box display="flex">
          <GoToPreviousStep handleGoToPreviousStep={handleGoBack} />
          <Typography variant="h2">
            Which AgileEngine team would you prefer to be meeting with?
          </Typography>
        </Box>

        <Typography
          sx={(theme) => ({
            mb: 4,
            width: '80%',

            [theme.breakpoints.down('lg')]: {
              mb: 2,
              width: '100%',
            },
          })}
        >
          AgileEngine has hybrid teams in metro areas. We work remotely most of
          the time and meet once or twice a month for work, events, and fun team
          building activities 🥳
        </Typography>
        <Box
          display="flex"
          sx={(theme) => ({
            display: 'flex',

            [theme.breakpoints.down('lg')]: {
              flexDirection: 'column-reverse',
              alignItems: 'center',
            },
          })}
        >
          <form onSubmit={handleSubmit}>
            <Box
              display="flex"
              flexDirection="column"
              flexWrap="wrap"
              width="100%"
              sx={(theme) => ({
                mb: 3,

                [theme.breakpoints.up('lg')]: {
                  maxHeight: '26rem',
                  width: '28rem',
                },

                [theme.breakpoints.up('xl')]: {
                  maxHeight: '21rem',
                },
              })}
            >
              {officeLocations.map((office) => {
                return (
                  <Box
                    key={office.id}
                    sx={(theme) => ({
                      margin: '0 1rem 0.25rem 0',
                      width: '90%',

                      [theme.breakpoints.down('lg')]: {
                        margin: '0 0 0.5rem 0',
                        width: '100%',
                      },
                    })}
                  >
                    <SelectableWrapper
                      value={office.name}
                      selectedValue={userOffice}
                      onClick={handleChange}
                      sx={{ justifyContent: 'space-between' }}
                      testid="office-option"
                    >
                      <FormControlLabel
                        checked={office.name === userOffice}
                        value={office.name}
                        control={<RadioButton tabIndex={-1} />}
                        label={
                          <Typography sx={{ fontWeight: '500' }}>
                            {office.name} {` `}
                            <Typography component="span" color="text.secondary">
                              {office.name !== 'Vadodara' ? 'metro area' : ''}
                            </Typography>
                          </Typography>
                        }
                      />
                      {isSuggestedOffice(office.name) ? (
                        <>
                          <MyLocationIcon
                            fontSize="small"
                            sx={(theme) => ({
                              [theme.breakpoints.down('lg')]: {
                                marginLeft: 'auto',
                              },
                              marginLeft: '0.5rem',
                              color: theme.palette.text.secondary,
                            })}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </SelectableWrapper>
                  </Box>
                );
              })}
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              flexWrap="wrap"
              sx={(theme) => ({
                margin: '0 1rem 0.25rem 0',
                width: '90%',

                [theme.breakpoints.down('lg')]: {
                  margin: '0 0 0.5rem 0',
                  width: '100%',
                },
              })}
            >
              <SelectableWrapper
                value={'Remote'}
                selectedValue={userOffice}
                onClick={handleChange}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'start',
                  paddingRight: '0',
                }}
                testid="office-option-remote"
              >
                <FormControlLabel
                  checked={'Remote' === userOffice}
                  value={'Remote'}
                  control={<RadioButton tabIndex={-1} />}
                  label={
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: '500', whiteSpace: 'nowrap' }}
                    >
                      I can not attend office even once a month
                    </Typography>
                  }
                />
              </SelectableWrapper>
            </Box>
            {userOffice === 'Remote' ? (
              <Stack
                direction="row"
                borderRadius="0.25rem"
                padding="0.875rem 1rem 1rem 0"
                gap="0.5rem"
                bgcolor={(theme) => theme.palette.brand.secondary}
                position="relative"
                marginTop="2rem"
                sx={(theme) => ({
                  width: '90%',

                  [theme.breakpoints.down('lg')]: {
                    width: '100%',
                  },
                })}
              >
                <Box
                  sx={(theme) => ({
                    width: '3rem',
                    [theme.breakpoints.down('sm')]: {
                      width: '4rem',
                    },
                  })}
                >
                  <Box
                    component="img"
                    src={remoteOptionImage}
                    alt="woman holding coffee"
                    sx={(theme) => ({
                      width: '4.25rem',
                      height: '9.25rem',
                      position: 'absolute',
                      bottom: 0,
                      left: '-1.25rem',
                      [theme.breakpoints.down('sm')]: {
                        left: '-0.25rem',
                      },
                    })}
                  />
                </Box>
                <Typography
                  fontWeight="500"
                  variant="body1"
                  color="text.invertedPrimary"
                >
                  It’s required to join the team from
                  <ResponsiveBreak breakpoint="xs" />
                  time to time, while working remotely.
                  <ResponsiveBreak breakpoint="xs" />
                  Our company culture promotes
                  <ResponsiveBreak breakpoint="xs" />
                  in-person communication 🤝
                </Typography>
              </Stack>
            ) : null}
            <StickyPanel>
              <GoToNextStep
                isLoading={isLoading}
                isDisabled={!userOffice}
                textIdentifier={1}
                maxWidth={{
                  xs: '10rem',
                  sm: 'initial',
                }}
              />
            </StickyPanel>
          </form>
          <Box
            component="img"
            marginLeft="-2rem"
            src={imageSet}
            alt="office page images"
            sx={{
              display: { xs: 'none', md: 'block' },
              maxWidth: '300px',
              maxHeight: '312px',
            }}
          ></Box>
        </Box>
      </Box>
    </PageTransitionWrapper>
  );
};

export default Office;
