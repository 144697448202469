import React from 'react';
import { Link, Typography } from '@mui/material';
import { format } from 'date-fns';

import { ResponsiveBreak } from 'src/components';
import { IMeeting } from 'src/types';
import { CalendlyEventType } from 'src/constants/calendly';

interface IProps {
  meetingDetails: IMeeting;
  handleOpenCalendlyModal: (url: string, eventType: CalendlyEventType) => void;
}

const MeetingDetailsMessage: React.FC<IProps> = ({
  meetingDetails,
  handleOpenCalendlyModal,
}) => {
  return (
    <>
      <Typography>
        <b>The meeting has been scheduled</b>
        <ResponsiveBreak breakpoint="xs" />
        <b>
          for{' '}
          {format(
            new Date(meetingDetails.scheduledDate),
            "EEEE, MMM d, 'at' hh:mm a",
          )}
        </b>
        <ResponsiveBreak breakpoint="xs" />
        You will receive a calendar event
        <ResponsiveBreak breakpoint="xs" />
        that you can{' '}
        <Link
          role="button"
          sx={{ cursor: 'pointer' }}
          color="text.brand"
          onClick={() =>
            handleOpenCalendlyModal(
              meetingDetails.reschedule_url,
              CalendlyEventType.RESCHEDULED,
            )
          }
        >
          reschedule
        </Link>{' '}
        or{' '}
        <Link
          role="button"
          sx={{ cursor: 'pointer' }}
          color="text.brand"
          onClick={() =>
            handleOpenCalendlyModal(
              meetingDetails.cancel_url,
              CalendlyEventType.CANCELLED,
            )
          }
        >
          cancel
        </Link>
      </Typography>
      <Typography variant="body1">See you!</Typography>
    </>
  );
};

export default MeetingDetailsMessage;
