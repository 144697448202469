import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { userRouteStoredKey } from './userRoute.const';

export interface IUserRouteSliceState {
  route: string;
}

const initialState: IUserRouteSliceState = {
  route: '',
};

export const userRouteSlice = createSlice({
  name: userRouteStoredKey,
  initialState,
  reducers: {
    setUserRoute(state, action: PayloadAction<string>) {
      state.route = action.payload;
    },
  },
});

export const { setUserRoute } = userRouteSlice.actions;
