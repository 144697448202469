import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../constants';
import { IUserData, IUserTestTask } from '../../types';
import { userStoreKey } from './user.const';
import {
  confirmIntermediateScreen,
  fetchUser,
  selectAnotherTT,
  updateCity,
  updateCompensation,
  updateCountry,
  updateEmail,
  updateEngagement,
  updateEnglishLevel,
  updateExperience,
  updateName,
  updateNoticePeriod,
  updateOffice,
  updateProfessionalIdentity,
  updateSeniority,
  updateSkills,
  updateSubmitProfile,
} from './user.thunks';
import {
  submitQuizAnswer,
  uploadInternalTestTaskResolution,
} from 'src/redux/testTasks';
import { IUserVideoInterviewData } from 'src/types/userVideoInterviewData';

export interface IUserSliceState {
  apiStatus: API_STATUS;
  userData: IUserData | null;
  isPerformingAction: boolean;
}

const initialState: IUserSliceState = {
  apiStatus: API_STATUS.IDLE,
  userData: null,
  isPerformingAction: false,
};

export const userSlice = createSlice({
  name: userStoreKey,
  initialState,
  reducers: {
    updateUserData: (state, action: PayloadAction<IUserData>) => {
      if (state.userData && state.userData.positions) {
        state.userData = {
          ...action.payload,
          positions: state.userData.positions,
        };
      } else {
        state.userData = action.payload;
      }
    },
    updateUserVideoInterview: (
      state,
      action: PayloadAction<IUserVideoInterviewData>,
    ) => {
      if (state.userData) {
        state.userData = {
          ...state.userData,
          videoInterviews: state.userData?.videoInterviews.map((uvi) =>
            uvi.id === action.payload.id ? action.payload : uvi,
          ),
        };
      }
    },
    resetUserPositions: (state) => {
      if (state.userData?.positions) {
        state.userData.positions = [];
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state, action) => {
        if (action.meta.arg) {
          state.apiStatus = API_STATUS.LOADING;
        }
      })
      .addCase(
        fetchUser.fulfilled,
        (state, action: PayloadAction<IUserData>) => {
          state.apiStatus = API_STATUS.COMPLETE;
          state.userData = action.payload;
        },
      )
      .addCase(fetchUser.rejected, (state) => {
        state.apiStatus = API_STATUS.FAILED;
      })
      .addCase(
        submitQuizAnswer.fulfilled,
        (state, action: PayloadAction<IUserTestTask>) => {
          if (state.userData?.testTasks?.length) {
            state.userData.testTasks = state.userData?.testTasks?.map(
              (testTask) =>
                testTask.id === action.payload.id ? action.payload : testTask,
            );
          }
        },
      )
      .addCase(
        uploadInternalTestTaskResolution.fulfilled,
        (state, action: PayloadAction<IUserTestTask>) => {
          if (state.userData?.testTasks?.length) {
            state.userData.testTasks = state.userData?.testTasks?.map(
              (testTask) =>
                testTask.id === action.payload.id ? action.payload : testTask,
            );
          }
        },
      )
      .addMatcher(
        isAnyOf(
          updateEmail.pending,
          updateName.pending,
          updateCountry.pending,
          updateCity.pending,
          updateOffice.pending,
          updateEnglishLevel.pending,
          updateEngagement.pending,
          updateSkills.pending,
          updateExperience.pending,
          updateSeniority.pending,
          updateCompensation.pending,
          updateNoticePeriod.pending,
          updateProfessionalIdentity.pending,
          updateSubmitProfile.pending,
          confirmIntermediateScreen.pending,
          selectAnotherTT.pending,
        ),
        (state) => {
          state.isPerformingAction = true;
        },
      )
      .addMatcher(
        isAnyOf(
          updateEmail.fulfilled,
          updateName.fulfilled,
          updateCountry.fulfilled,
          updateCity.fulfilled,
          updateOffice.fulfilled,
          updateEnglishLevel.fulfilled,
          updateEngagement.fulfilled,
          updateSkills.fulfilled,
          updateExperience.fulfilled,
          updateSeniority.fulfilled,
          updateCompensation.fulfilled,
          updateNoticePeriod.fulfilled,
          updateProfessionalIdentity.fulfilled,
          updateSubmitProfile.fulfilled,
          confirmIntermediateScreen.fulfilled,
          selectAnotherTT.fulfilled,
        ),
        (state, action: PayloadAction<IUserData | null>) => {
          state.isPerformingAction = false;
          if (action.payload) {
            state.userData = action.payload;
          }
        },
      )
      .addMatcher(
        isAnyOf(
          updateEmail.rejected,
          updateName.rejected,
          updateCountry.rejected,
          updateCity.rejected,
          updateOffice.rejected,
          updateEnglishLevel.rejected,
          updateEngagement.rejected,
          updateSkills.rejected,
          updateExperience.rejected,
          updateSeniority.rejected,
          updateCompensation.rejected,
          updateNoticePeriod.rejected,
          updateProfessionalIdentity.rejected,
          updateSubmitProfile.rejected,
          confirmIntermediateScreen.rejected,
          selectAnotherTT.rejected,
        ),
        (state) => {
          state.isPerformingAction = false;
        },
      );
  },
});

export const { updateUserData, resetUserPositions, updateUserVideoInterview } =
  userSlice.actions;
