import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { exchangeRatesStoreKey } from './exchangeRates.const';
import { getExchangeRateApiPath } from 'src/constants/apiEndpoints/exchangeRates';
import { formatErrorMessage } from '../../utils';
import { notificationAlert } from '../notifications';
import { IExchangeRateData } from '@types';

export const fetchExchangeRates = createAsyncThunk(
  `${exchangeRatesStoreKey}/fetchExchangeRates`,
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(getExchangeRateApiPath());

      return response.data as IExchangeRateData[];
    } catch (err: any) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(err);
    }
  },
);
