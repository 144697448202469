import * as yup from 'yup';

export const validationSchema = yup.object({
  email: yup
    .string()
    .email('Enter a valid email')
    .required("Friend's email is required"),
  name: yup.string().required("Friend's name is required"),
  note: yup
    .string()
    .test(
      'is less than 250 symbols',
      (value) =>
        `Please, leave a note of up to 250 symbols. You now have ${value.value?.length}.`,
      function (value) {
        return !value || value.length < 250;
      },
    )
    .optional(),
});
