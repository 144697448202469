export const countryMessage = (props: {
  countrySelected: string;
  preferedName: string;
}): string => {
  const { preferedName, countrySelected } = props;
  switch (countrySelected) {
    case 'Poland':
      return `Wspaniale cię widzieć, ${preferedName}! 👋`;
    case 'Ukraine':
      return `Раді тебе бачити, ${preferedName}! 👋`;
    case 'Brazil':
    case 'Portugal':
      return `É ótimo ver você, ${preferedName}! 👋`;
    case 'Argentina':
    case 'Colombia':
    case 'Guatemala':
    case 'Mexico':
      return `¡${preferedName}, qué bueno verte por aquí! 👋`;
    case 'India':
    default:
      return `Great to see you here, ${preferedName}! 👋`;
  }
};
