import React from 'react';
import { Box } from '@mui/material';
import brandLogo from '../../assets/brand-logo.svg';
import brandLogoWhite from '../../assets/brand-logo-white.svg';

interface IProps extends HTMLImageElement {
  inverted: boolean;
}

export const BrandLogo: React.FC<Partial<IProps>> = ({
  inverted,
  width = 134,
}) => {
  const logo = inverted ? brandLogoWhite : brandLogo;
  return (
    <Box
      component="img"
      src={logo}
      alt="AgileEngine"
      sx={(theme) => ({
        width: `${width}px`,
        [theme.breakpoints.down('sm')]: {
          width: '100px',
        },
      })}
    />
  );
};
