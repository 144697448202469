import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from '../redux';
import { setAuthError } from '../redux/auth';
import { parseJSON, parseQueryParams } from '../utils';
import { LOCAL_STORAGE_KEYS, SIGN_UP_PROVIDERS } from '../constants';
import { LocalStorageService, logEvent } from '../services';
import { setUnsubscibeFromRemindersData } from 'src/redux/unsubscribeFromReminders';
import { setPositionData } from 'src/redux/positions';
import { setMagicLinkCandidateZohoId } from 'src/redux/magicLinkCandidate';
import { setMassCampaignData } from 'src/redux/massCampaignCandidate';
import { useIsStandalonePage } from './useIsStandalonePage';
import { setCandidateSourceData } from 'src/redux/candidateSource';

export const useCaptureQueryData = () => {
  const dispatch = useAppDispatch();
  const isStandalonePage = useIsStandalonePage();
  const location = useLocation();

  useEffect(() => {
    if (!isStandalonePage) {
      logEvent('launchpod - candidate-visited-lp');
    }
  }, [isStandalonePage]);

  useEffect(() => {
    const {
      data,
      authError,
      utm_source,
      utm_medium,
      utm_campaign,
      ['utm_technical-flow']: utm_technical_flow,
      reschedule_event,
      user_name,
      user_email,
      unsubscribeToken,
      unsubscribeType,
      unsubscribeReason,
      testTaskType,
      masscampaign_data,
      lp_link: lpLink,
    } = parseQueryParams<{
      data?: string;
      authError?: string;
      utm_source?: string;
      utm_medium?: string;
      utm_campaign?: string;
      'utm_technical-flow'?: string;
      reschedule_event?: string;
      user_name?: string;
      user_email?: string;
      unsubscribeToken?: string;
      unsubscribeType?: string;
      unsubscribeReason?: string;
      testTaskType?: string;
      info?: string;
      masscampaign_data?: string;
      lp_link?: string;
    }>(location.search);

    // Handle unsubscribe page
    if (user_email && unsubscribeToken && unsubscribeType) {
      dispatch(
        setUnsubscibeFromRemindersData({
          user_name: user_name || '',
          user_email,
          unsubscribeToken,
          unsubscribeType,
          unsubscribeReason: unsubscribeReason || '',
          testTaskType: testTaskType || '',
        }),
      );

      return;
    }

    let userSource = '';

    // Track Zoho Posting through Webform event
    if (utm_source === SIGN_UP_PROVIDERS.ZOHO_WEBFORM) {
      logEvent('launchpod - welcome-page-v1_loaded');
      logEvent('launchpod - zoho-webform-used');

      userSource += 'Zoho Webform Integration';
    }

    // Track DB team triggered candidates event
    if (utm_source === SIGN_UP_PROVIDERS.DB_Team) {
      logEvent('launchpod - welcome-page-v1_loaded');
      logEvent('launchpod - triggered-lp-process-signin');

      userSource += 'Zoho Triggered LP Process';
    }

    let positionData = undefined;

    // New URL for magic link candidates
    if (lpLink) {
      dispatch(setMagicLinkCandidateZohoId(lpLink));

      logEvent('launchpod-magic-link-used');

      userSource += ' Magic Link';
    }

    if (typeof data === 'string') {
      const decodedData = parseJSON(window.atob(data));

      // Magic link containig encoded base64 data query paramater
      // decoded value {"id":"248544000138758915","utmSource":"inbound","utmProvider":"indeed","expireddate":"24-Feb-2023"}
      if (decodedData.id) {
        dispatch(setMagicLinkCandidateZohoId(decodedData.id));
        delete decodedData.id;

        logEvent('launchpod-magic-link-used');

        userSource += ' Magic Link';
      }

      // Encoded base64 data query parameter containing job description
      // decoded value {"v":"1","title":"Business Development Manager","department":"Client Partners","specialization":"Business Development Manager","skill":"Client Partners","jobPostingUrl":"https://join.agileengine.com/job/business-development-manager-agileengine-3/","utmProvider":"AE Careers page"}
      if (
        decodedData.jobPostingUrl &&
        decodedData.utmMedium !== SIGN_UP_PROVIDERS.INDEED
      ) {
        const jobPostingURL = new URL(decodedData.jobPostingUrl);
        const { utm_source, utm_medium } = parseQueryParams(
          jobPostingURL.search,
        );
        utm_source && (decodedData.utmSource = utm_source);
        utm_medium && (decodedData.utmProvider = utm_medium);

        positionData = decodedData;

        logEvent('launchpod - apply-link-used');

        userSource += ' Join AE';
      }

      // Track Indeed integration event
      if (decodedData.utmMedium === SIGN_UP_PROVIDERS.INDEED) {
        positionData = decodedData;

        logEvent('indeed-integration-used');

        userSource += ' Indeed Integration';
      }
    }

    if (utm_source?.toLowerCase() === 'inbound' && utm_medium) {
      dispatch(
        setCandidateSourceData({
          source: utm_source,
          provider: utm_medium,
        }),
      );
    }

    if (masscampaign_data) {
      // Mass Campaign link containig encoded base64 masscampaign_data query paramater
      // decoded value { candidateId: '', campaignId: '' }
      const decodedMassCampaignData = parseJSON(window.atob(masscampaign_data));

      dispatch(setMassCampaignData(decodedMassCampaignData));

      logEvent('launchpod - masscampaign-link-used');

      userSource += ' MassCampaign Link';
    }

    const { referrer } = document;

    const calculatedSource =
      userSource || utm_medium || utm_source || utm_technical_flow || referrer;
    const localStorageSource = LocalStorageService.getState(
      LOCAL_STORAGE_KEYS.USER_SOURCE,
    );

    if (calculatedSource) {
      LocalStorageService.setState(
        LOCAL_STORAGE_KEYS.USER_SOURCE,
        calculatedSource,
      );
    }

    logEvent('launchpod - user-source', {
      Source:
        userSource ||
        utm_medium ||
        utm_source ||
        utm_technical_flow ||
        referrer ||
        localStorageSource ||
        'NO SOURCE',
      'Page Location': window.location.toString(),
    });

    logEvent('launchpod - user-utm-source', {
      utmSource: utm_source,
      utmMedium: utm_medium,
      utmCampaign: utm_campaign,
      'Page Location': window.location.toString(),
    });

    if (positionData) {
      dispatch(
        setPositionData({
          ...positionData,
          isDisplayable:
            Boolean(positionData?.title) && Boolean(positionData?.skill),
        }),
      );
    }

    if (reschedule_event) {
      LocalStorageService.setState(LOCAL_STORAGE_KEYS.RESCHEDULE_EVENT, 'true');
    }

    if (authError) {
      dispatch(setAuthError(authError));
    }
  }, []);
};
