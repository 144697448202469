import { createAsyncThunk } from '@reduxjs/toolkit';
import { userGeoLocationStoredKey } from './userGeoLocation.const';

export const fetchUserGeoLocation = createAsyncThunk(
  `${userGeoLocationStoredKey}/fetchUserGeoLocation`,
  async () => {
    try {
      const response = await fetch('https://ipapi.co/json/').then(
        (response: any) => response.json(),
      );
      return response ?? null;
    } catch (err: any) {
      throw new Error(err);
    }
  },
);
