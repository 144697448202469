import { createSlice } from '@reduxjs/toolkit';
import { API_STATUS } from '../../constants';
import { feedbackStoreKey } from './feedback.const';
import { postFeedback } from './feedback.thunks';

export interface IFeedbackSliceState {
  apiStatus: API_STATUS;
}

const initialState: IFeedbackSliceState = {
  apiStatus: API_STATUS.IDLE,
};

export const feedbackSlice = createSlice({
  name: feedbackStoreKey,
  initialState,
  reducers: {
    clearFeedbackState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(postFeedback.pending, (state) => {
        state.apiStatus = API_STATUS.LOADING;
      })
      .addCase(postFeedback.fulfilled, (state) => {
        state.apiStatus = API_STATUS.IDLE;
      })
      .addCase(postFeedback.rejected, (state) => {
        state.apiStatus = API_STATUS.FAILED;
      });
  },
});

export const { clearFeedbackState } = feedbackSlice.actions;
