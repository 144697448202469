import { CircularProgress } from '@mui/material';
import { Box } from '@mui/material';
import { BrandLogo } from '../brandLogo/BrandLogo';

interface IProps {
  showBrandLogo?: boolean;
  position?: string;
}

export const Loader: React.FC<IProps> = ({ showBrandLogo, position }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: position || 'absolute',
        width: '100%',
        height: '100%',
      }}
      data-testid="is-loading"
    >
      {showBrandLogo && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            m: 6,
          }}
        >
          <BrandLogo />
        </Box>
      )}
      <CircularProgress sx={{ color: (theme) => theme.palette.brand.accent }} />
    </Box>
  );
};
