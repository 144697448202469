import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../redux';
import { userSelectors } from '../redux/user';
import { isStatusIdle } from '../utils';

import {
  candidateSourceSelectors,
  postUserWithSourceSignUp,
} from 'src/redux/candidateSource';

export const useCandidateSource = () => {
  const dispatch = useAppDispatch();

  const userData = useAppSelector(userSelectors.getUserData);
  const apiStatus = useAppSelector(
    candidateSourceSelectors.getCandidateSourceApiStatus,
  );
  const data = useAppSelector(candidateSourceSelectors.getCandidateSourceData);

  useEffect(() => {
    if (userData && isStatusIdle(apiStatus) && data) {
      dispatch(postUserWithSourceSignUp(data));
    }
  }, [userData, apiStatus, data]);
};
