import { APP_ROUTES, TestTaskType } from '../constants';
import { ILocationState } from './useUserRedirection';
import { useLocation } from 'react-router-dom';
import { useIsMobileView } from './useIsMobileView';
import { useIsQuiz } from './useIsQuiz';
import { useUserTestTasks } from './useUserTestTasks';

export const useShouldReserveChatMessageSpace = () => {
  const location: ILocationState = useLocation();
  const { currentTestTask } = useUserTestTasks();
  const isMobileView = useIsMobileView();
  const isQuiz = useIsQuiz();

  let shouldReserveChatMessageSpace =
    !(isQuiz && isMobileView) ||
    !(currentTestTask?.testTaskType === TestTaskType.INTERNAL && isMobileView);

  if (
    [`/${APP_ROUTES.APPLICATION_SUCCESS}`].includes(location.pathname) &&
    isMobileView
  ) {
    shouldReserveChatMessageSpace = false;
  }

  if (location.pathname.includes(`/${APP_ROUTES.CANCEL_APPLICATION}`)) {
    shouldReserveChatMessageSpace = false;
  }

  return shouldReserveChatMessageSpace;
};
