import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material';

export default <Components<Theme>>{
  MuiButton: {
    defaultProps: {
      disableElevation: true,
    },
    styleOverrides: {
      root: ({ ownerState, theme }) => ({
        ...(ownerState.variant === 'contained' &&
          ownerState.color === 'primary' && {
            height: '54px',
            '&:hover': {
              backgroundColor: theme.palette.background.buttonHover,
            },
          }),
        ...(ownerState.variant === 'contained' &&
          ownerState.color === 'secondary' && {
            '&:hover': {
              backgroundColor: theme.palette.background.buttonHover,
            },
          }),
        ...(ownerState.variant === 'contained' &&
          ownerState.color === 'info' && {
            height: '59px',
            padding: '12px 21px',
            backgroundColor: theme.palette.background.brandTertiary,
            textTransform: 'initial',
            '&:hover': {
              backgroundColor: theme.palette.background.brandSecondary,
            },
          }),
        ...(ownerState.variant === 'outlined' && {
          border: '2px solid',
          '&:hover': {
            backgroundColor: theme.palette.highlight.neutral,
            border: '2px solid',
          },
        }),
      }),
    },
  },
  MuiRadio: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: '0px 10px',
        '&.Mui-checked': {
          color: theme.palette.brand.accent,
        },
      }),
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: '0px 9px',
        '&.Mui-checked': {
          color: theme.palette.brand.accent,
        },
        '&.Mui-disabled': {
          color: theme.palette.button.primary.textDisabled,
        },
        '&:hover': {
          backgroundColor: 'transparent',
        },
      }),
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      label: () => ({
        width: '100%',
      }),
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: ({ theme }) => ({
        '&.Mui-disabled': {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.background.inputDisabled,
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
          },
        },
      }),
      input: ({ theme }) => ({
        '&:not(.MuiInputBase-inputHiddenLabel)': {
          paddingTop: '24px',
          paddingRight: '16px',
          paddingBottom: '16px',
          paddingLeft: '16px',
        },
        '&.Mui-disabled': {
          textFillColor: theme.palette.text.primary,
        },
      }),
      inputMultiline: () => ({
        '&[rows]': {
          marginTop: '0.75rem',
        },
      }),
      notchedOutline: {
        borderWidth: '2px',
        transition: 'border .3s',
        top: 0,
        '& legend': {
          display: 'none',
        },
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        transform: 'translate(16px, 19px) scale(1)',
      },
      outlined: {
        '&.Mui-focused, &.MuiFormLabel-filled': {
          transform: 'translate(16px, 8px) scale(0.75)',
        },
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        textDecorationThickness: '0.8px',
        textUnderlineOffset: '12%',
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: theme.palette.highlight.accent,
        borderRadius: theme.shape.borderRadius,
      }),
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      endAdornment: {
        top: 'calc(50% - 0.875rem)',
      },
    },
  },
  MuiList: {
    styleOverrides: {
      root: () => ({
        listStyleType: '"•"',
        paddingLeft: '6px',
        '& .MuiListItem-root': {
          display: 'list-item',
          padding: 0,
          paddingLeft: '8px',
          lineHeight: '140%',
          marginBottom: '12px',
        },
      }),
    },
  },
  MuiTimePickerToolbar: {
    styleOverrides: {
      hourMinuteLabel: { alignItems: 'flex-start' },
    },
  },
};
