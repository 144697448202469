import React from 'react';
import { Link, Typography } from '@mui/material';
import { ResponsiveBreak } from '../../../../../components';

interface IProps {
  country?: string | null;
}

const NotSupportedByLocationMessage: React.FC<IProps> = ({ country }) => {
  return (
    <>
      <Typography variant="body1" data-testid="success-text">
        We don’t have offices in <b>{country ?? 'your location'}</b> yet and
        can't engage experts outside our official locations.
      </Typography>
      <Typography variant="body1">
        Unless you’re open to relocation, it may be best to put our conversation
        on pause and stay in touch. At our current growth rate, chances are high
        that we will expand to {country ?? 'your location'} pretty soon :)
      </Typography>
      <Typography variant="body1">
        If relocation is among your plans, please see more about us and our
        global presence: <ResponsiveBreak breakpoint="sm" />
        <Link
          href="https://agileengine.com/about-us/"
          target="_blank"
          color="brand.accent"
        >
          agileengine.com/about-us
        </Link>
      </Typography>
    </>
  );
};

export default NotSupportedByLocationMessage;
