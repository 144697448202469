import { useEffect, useState } from 'react';
import { ICountdown } from '../types';

export const useCountdown = (
  targetDate: Date | null,
  onFinish?: () => void,
) => {
  const [countDown, setCountDown] = useState<number>(0);

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>;
    if (targetDate) {
      const countDownDate = new Date(targetDate).getTime();
      setCountDown(countDownDate - new Date().getTime());
      interval = setInterval(() => {
        const newCountDown = countDownDate - new Date().getTime();
        if (newCountDown < 1000 && onFinish) {
          onFinish();
        }
        if (newCountDown < 0) {
          clearInterval(interval);
          return;
        }
        setCountDown(newCountDown);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [targetDate]);

  return getReturnValues(countDown);
};

const getReturnValues = (countdown: number): ICountdown => {
  // calculate time left
  const days = Math.floor(countdown / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (countdown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  );
  const minutes = Math.floor((countdown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countdown % (1000 * 60)) / 1000);
  return {
    days,
    hours,
    minutes,
    seconds,
    countdown,
  };
};
