export const CodilityRowOfImages = [
  {
    image: '🏎',
    text: 'Fast track you through the process whilst maintaining objectivity',
  },
  {
    image: '💪',
    text: 'Enable you to provide hard data on your skills',
  },
  {
    image: '⏱',
    text: 'Cut interview time and reduce bias',
  },
  {
    image: '🤝',
    text: 'Allow our process to be more consistent and fair    for all our candidates',
  },
  {
    image: '🏆',
    text: 'Get you the best projects and higher pay',
  },
];

export const InternalRowOfImages = [
  {
    image: '🔆',
    text: 'A test task gives you priority consideration for our open positions',
  },
  {
    image: '⏱',
    text: 'Speeds up the process and saves you from waiting',
  },
  {
    image: '☺️',
    text: 'Allow our process to be more consistent and fair for all our candidates',
  },
];
