import { useEffect } from 'react';
import { useAppDispatch } from '../redux';
import { fetchUser } from '../redux/user';
import { useIsStandalonePage } from './useIsStandalonePage';

export const useFetchUser = () => {
  const dispatch = useAppDispatch();
  const isStandalonePage = useIsStandalonePage();

  useEffect(() => {
    if (isStandalonePage) {
      return;
    }

    dispatch(fetchUser());
  }, [isStandalonePage]);
};
