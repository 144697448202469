import { IUserGeoLocationSliceState } from './userGeoLocation.slice';
import { userGeoLocationStoredKey } from './userGeoLocation.const';
import { API_STATUS } from '@constants';

interface IState {
  [userGeoLocationStoredKey]: IUserGeoLocationSliceState;
}

const selectUserGeoLocation = (state: IState) => {
  return state[userGeoLocationStoredKey];
};

export const getUserGeoLocationApiStatus = (state: IState): API_STATUS => {
  return selectUserGeoLocation(state).apiStatus;
};

const getUserGeoLocation = (
  state: IState,
): { country_name: string; country_code: string } | null => {
  return selectUserGeoLocation(state).geoLocation;
};

export const userGeoLocationSelector = {
  getUserGeoLocationApiStatus,
  getUserGeoLocation,
};
