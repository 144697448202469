import { useEffect } from 'react';
import max from 'lodash/max';
import { isStatusComplete, isStatusIdle } from '../utils';
import {
  getAvailableVideoInterview,
  videoInterviewSelectors,
} from '../redux/videoInterview';
import { useAppDispatch, useAppSelector } from '../redux';
import { userSelectors } from '../redux/user';
import { getWorkAreaBySpecialization } from '../utils/techSkills';
import { skillsSelectors } from '../redux/skills';
import { UserVideoInterviewStatus } from 'src/types/userVideoInterviewData';
import { WORK_AREA_TYPE } from 'src/constants';

export const useUserVideoInterview = () => {
  const dispatch = useAppDispatch();
  const skillsData = useAppSelector(skillsSelectors.getSkillsData);
  const userData = useAppSelector(userSelectors.getUserData);
  const { apiStatus: availableVIDataApiStatus, data: availableVIData } =
    useAppSelector(videoInterviewSelectors.getAvailableVideoInterviewAPIData);
  const isPerformingAction = useAppSelector(
    videoInterviewSelectors.getIsVideoInterviewPerformingAction,
  );

  const userVideoInterview = userData?.videoInterviews[0];

  const finishedQuestionsAmount =
    userVideoInterview?.videoInterview.questions.filter((question) =>
      userVideoInterview.records.some(
        (record) => record.questionNumber === question.questionNumber,
      ),
    ).length || 0;
  const allQuestionsAmount =
    userVideoInterview?.videoInterview.questions.length || 0;
  const currentQuestionNumber =
    max(userVideoInterview?.records.map((e) => e.questionNumber)) || 0;

  const isLastQuestion = allQuestionsAmount - finishedQuestionsAmount === 0;
  const isUserQuitedVideoInterview =
    !!userVideoInterview && allQuestionsAmount > finishedQuestionsAmount;
  const isUserSkippedVideoInterview =
    !!userVideoInterview &&
    userVideoInterview.status === UserVideoInterviewStatus.SKIPPED;

  useEffect(() => {
    if (
      userData?.profileSubmittedAt &&
      isStatusIdle(availableVIDataApiStatus)
    ) {
      dispatch(getAvailableVideoInterview());
    }
  }, [userData, availableVIDataApiStatus]);

  const specialization =
    userData?.specialization ??
    userVideoInterview?.videoInterview?.specialization;

  return {
    availableVideoInterview: availableVIData,
    userVideoInterview,
    allQuestionsAmount,
    finishedQuestionsAmount,
    currentQuestionNumber,
    isLastQuestion,
    isUserQuitedVideoInterview,
    isUserSkippedVideoInterview,
    isLoading: isPerformingAction,
    isLoaded: isStatusComplete(availableVIDataApiStatus),
    specializationType: [
      'Project Manager TECH',
      'Product Manager TECH',
    ].includes(specialization as string)
      ? WORK_AREA_TYPE.NON_ENGINEERING
      : getWorkAreaBySpecialization(
          userData?.specialization ??
            userVideoInterview?.videoInterview?.specialization,
          skillsData.data.specializations,
        ),
  };
};
