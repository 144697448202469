import React, { FC } from 'react';
import { IPanelProps, Panel } from '../panel/Panel';

export const StepPanel: FC<IPanelProps> = ({ children, ...rest }) => {
  return (
    <Panel
      bgcolor={{ md: 'highlight.neutral' }}
      borderRadius="3rem"
      pt={{ md: 6 }}
      py={{ md: 9 }}
      px={{ md: 3, lg: 9 }}
      maxWidth="33rem"
      mx="auto"
      display="flex"
      flexDirection="column"
      gap={3}
      alignItems={{ xs: 'flex-start', sm: 'center' }}
      textAlign={{ xs: 'left', sm: 'center' }}
      {...rest}
    >
      {children}
    </Panel>
  );
};
