import React from 'react';
import { Box, Typography } from '@mui/material';
import { BasicDialog, CustomButton, TextButton } from '../../../components';
import QuizIcon from '../../../assets/quiz-image.png';
import { useUserVideoInterview } from 'src/hooks';
import { useAppSelector } from '../../../redux';
import { userSelectors } from '../../../redux/user';

interface IProps {
  handleClose: () => void;
  handleStartRecord: (e: React.SyntheticEvent | null) => void;
  handleSkipSelection?: (e: React.SyntheticEvent | null) => void;
  handleCalendar: () => void;
  isTechQuestions?: boolean;
  isOpen: boolean;
  showSkipButton?: boolean;
}

export const VideoInterviewDialog: React.FC<IProps> = ({
  handleStartRecord,
  handleClose,
  handleSkipSelection,
  handleCalendar,
  isOpen = true,
  showSkipButton,
}) => {
  const userData = useAppSelector(userSelectors.getUserData)!;
  const userReminderDate = userData.videoInterviewReminderDate;
  const { availableVideoInterview, userVideoInterview } =
    useUserVideoInterview();

  const onCreateVideoRecordClick = () => {
    handleStartRecord(null);
  };

  return (
    <BasicDialog
      isOpen={isOpen}
      handleClose={handleClose}
      maxWidth="34rem"
      overflowVisible
    >
      <Box
        component="img"
        srcSet={`${QuizIcon} 2x`}
        sx={(theme) => ({
          position: 'absolute',
          top: '2rem',
          transform: 'translateX(-55%)',
          left: '50%',
          maxWidth: '150px',
          [theme.breakpoints.up('sm')]: { top: '-5rem', maxWidth: '200px' },
        })}
      />
      <Typography variant="h2" textAlign="center" mt={26} mb={4}>
        Keep it casual
      </Typography>
      <Typography variant="body1" textAlign="center" gutterBottom mb={3}>
        No need to wear a tie (unless you want to) <br />
        or take this too serious. Just make sure <br />
        you have{' '}
        {availableVideoInterview?.duration ||
          userVideoInterview?.videoInterview.duration}{' '}
        minutes for the recording.
      </Typography>
      <Box
        sx={{
          maxWidth: '300px',
          display: 'flex',
          justifyContent: showSkipButton ? 'space-between' : 'center',
          margin: '0 auto',
        }}
      >
        <CustomButton
          label="OK, record it"
          onClick={onCreateVideoRecordClick}
          color="primary"
        />
        {showSkipButton && (
          <CustomButton
            label="Skip it"
            onClick={handleSkipSelection}
            color="secondary"
          />
        )}
      </Box>
      <Box
        sx={{
          textAlign: 'center',
          mt: 3,
        }}
      >
        <Typography variant="body2" color="text.secondary">
          Prefer to do it later?
        </Typography>
        <TextButton onClick={handleCalendar}>
          {userReminderDate ? 'Reschedule event' : 'Add to your calendar'}
        </TextButton>
      </Box>
    </BasicDialog>
  );
};
