import { API_STATUS } from '../constants';

export const isStatusLoading = (status: API_STATUS) =>
  status === API_STATUS.LOADING;
export const isStatusComplete = (status: API_STATUS) =>
  status === API_STATUS.COMPLETE;

export const isStatusIdle = (status: API_STATUS) => status === API_STATUS.IDLE;
export const isStatusFailed = (status: API_STATUS) =>
  status === API_STATUS.FAILED;
