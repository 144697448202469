import { css, SerializedStyles } from '@emotion/react';
import palette from '../../theme/palette';
import breakpoints from '../../theme/breakpoints';

export const useChatMessageStyles = (): SerializedStyles => {
  return css`
    &.chat-message {
      border-radius: 8px;
      background-color: ${palette.highlight.accent};
      padding: 0.4rem 1rem;
      width: fit-content;
      position: relative;
      margin-bottom: 2rem;
      ::before {
        content: '';
        position: absolute;
        left: -1rem;
        top: 0.8rem;
        width: 1rem;
        height: 1rem;
        background: ${palette.highlight.accent};
        clip-path: polygon(
          31% 30%,
          42% 40%,
          100% 100%,
          0% 100%,
          0 41%,
          5% 30%,
          18% 25%
        );
        transform: rotate(-90deg);
      }

      .children-wrapper {
        p {
          margin: 0;
        }
      }

      &.isInCenter {
        margin-bottom: 0;
        padding: 0.6rem 1rem;
        ::before {
          transform: rotate(0deg);
          left: 1rem;
          top: -1rem;
        }
      }

      &.isInApplicationSuccess {
        margin-bottom: 0;
        ::before {
          transform: rotate(90deg);
          left: 100%;
        }
        @media only screen and (max-width: ${breakpoints.values.lg}px) {
          max-width: 80%;
        }
        @media only screen and (max-width: ${breakpoints.values.md}px) {
          max-width: 50%;
          ::before {
            top: 50%;
          }
        }
      }

      &.isInUnsubscribeLayout {
        margin-bottom: 0;
        padding: 0.6rem 1rem;
        ::before {
          transform: rotate(0deg);
          left: 12rem;
          top: -1rem;
        }
      }
    }
  `;
};
