// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js

export enum Countries {
  India = 'India',
  UnitedStates = 'USA',
  UnitedKingdom = 'United Kingdom',
  Australia = 'Australia',
  Canada = 'Canada',
  NewZealand = 'New Zealand',
  Mexico = 'Mexico',
  Colombia = 'Colombia',
  Brazil = 'Brazil',
  Guatemala = 'Guatemala',
  Argentina = 'Argentina',
  Romania = 'Romania',
  Slovenia = 'Slovenia',
  Spain = 'Spain',
  Slovakia = 'Slovakia',
  Bulgaria = 'Bulgaria',
}

export const COUNTRIES_WITH_AMPM: string[] = [
  Countries.UnitedStates,
  Countries.India,
  Countries.Mexico,
  Countries.Colombia,
  Countries.Brazil,
  Countries.Guatemala,
  Countries.Argentina,
];

export const TOP_CLIENTS_WELCOME_PAGE_COUNTRY_CODES = ['CO', 'MX ', 'AR', 'UA'];
