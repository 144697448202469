import React from 'react';
import { Typography } from '@mui/material';
import { ResponsiveBreak } from '../../../../../components';

const NotFitPersonMessage = () => {
  return (
    <>
      <Typography variant="body1" data-testid="success-text">
        Our Talent Executive will reach out to you{' '}
        <ResponsiveBreak breakpoint="sm" />
        to discuss next steps.
      </Typography>
    </>
  );
};

export default NotFitPersonMessage;
