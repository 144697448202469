import React, { FC, ReactElement, ReactNode } from 'react';
import { Box, BoxProps, SwipeableDrawer } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ExternalLinks from '../../features/externalLinks/ExternalLinks';
import Sidebar from '../../features/sidebar/Sidebar';
import MobileHeader from '../../features/mobileHeader/MobileHeader';
import { RESPONSIVE_PADDING } from '../../constants';
import {
  useFeatureFlags,
  useHeader,
  useIsTabletView,
  useShouldReserveChatMessageSpace,
  useSidebar,
  useTestTaskInfoPanel,
} from '../../hooks';

interface IChildrenProps {
  ttInfoPanel?: ReactElement;
  shouldReserveChatMessageSpace: boolean;
}

interface IProps extends Omit<BoxProps, 'children'> {
  test?: string;
  children: ReactNode | ((props: IChildrenProps) => ReactNode);
}
export const MainLayout: FC<IProps> = ({ children, bgcolor, ...rest }) => {
  const isTabletView = useIsTabletView();
  const ttInfoPanel = useTestTaskInfoPanel();
  const { isTTSelectionPageSimplified } = useFeatureFlags();
  const { showHeader, setShowHeader, handleToggleHeader } = useHeader();
  const { isSidebarOpen, handleToggleSidebar } = useSidebar();
  const shouldReserveChatMessageSpace = useShouldReserveChatMessageSpace();

  return (
    <Box display="flex" bgcolor={bgcolor} {...rest}>
      <SwipeableDrawer
        anchor="right"
        open={showHeader}
        onClose={() => setShowHeader(false)}
        onOpen={handleToggleHeader}
        sx={{
          '& .MuiDrawer-paper': {
            p: 6,
          },
        }}
      >
        <Box display="flex" justifyContent="flex-end" py="0.75rem">
          <CloseIcon
            sx={{
              fontSize: 'large',
              color: 'text.secondary',
              cursor: 'pointer',
            }}
            onClick={handleToggleHeader}
          />
        </Box>
        <ExternalLinks isSidebar />
      </SwipeableDrawer>

      <Sidebar
        showHeader={showHeader}
        showSidebar={isSidebarOpen}
        handleToggleSidebar={handleToggleSidebar}
        handleToggleHeader={handleToggleHeader}
        bgcolor={bgcolor}
      />

      <Box
        component="main"
        bgcolor={bgcolor ?? 'background.default'}
        minHeight="100vh"
        width="100%"
        py={{
          xs: 3,
          md: Boolean(ttInfoPanel) && !isTTSelectionPageSimplified ? 0 : 6,
        }}
        overflow="clip"
      >
        {isTabletView && (
          <MobileHeader handleToggleSidebar={handleToggleSidebar} />
        )}
        <Box
          position="relative"
          px={
            Boolean(ttInfoPanel) && !isTTSelectionPageSimplified
              ? 'initial'
              : RESPONSIVE_PADDING
          }
          minHeight="100%"
        >
          {typeof children === 'function'
            ? children({
                ttInfoPanel: isTTSelectionPageSimplified
                  ? undefined
                  : ttInfoPanel,
                shouldReserveChatMessageSpace,
              })
            : children}
        </Box>
      </Box>
    </Box>
  );
};
