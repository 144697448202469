import axios from 'axios';
import { baseApiUrl } from '../utils';

export class InterceptorsService {
  public static init(): void {
    axios.defaults.baseURL = baseApiUrl;
    InterceptorsService.addRequestInterceptor();
  }

  private static addRequestInterceptor(): void {
    axios.interceptors.request.use((config) => {
      const newConfig = {
        ...config,
        withCredentials: true,
      };
      return newConfig;
    });
  }
}
