/** @jsxImportSource @emotion/react */
import React, { useCallback, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { BrandLogo, CustomButton } from '../../components';
import LetsConnect from '../../features/letsConnect/LetsConnect';
import DeclineSuccess from './DeclineSuccess';
import DeclineReasons from './DeclineReasons';

const DeclineReferral: React.FC = () => {
  const [isDeclined, setIsDeclined] = useState(false);

  const onDeclineSubmit = useCallback(() => setIsDeclined(true), []);
  return (
    <Box
      sx={(theme) => ({
        background: theme.palette.background.backgroundPrimary,
        minWidth: '100%',
        minHeight: '100%',
        padding: '41px 16px',
        [theme.breakpoints.down('lg')]: {
          paddingTop: '130px',
        },
      })}
    >
      <Box sx={{ position: 'absolute', top: '49px', left: '66px' }}>
        <BrandLogo width={133} />
      </Box>
      <Box display="flex" alignItems="center" flexDirection="column">
        <Box
          sx={(theme) => ({
            background: theme.palette.common.white,
            padding: '48px',
            borderRadius: '48px',
            maxWidth: '32.5rem',
            position: 'relative',
          })}
        >
          <Box display="flex" flexDirection="column">
            <Typography variant="h6" fontWeight="600" mb={1}>
              Sorry to hear you’re not interested in career opportunity with
              AgileEngine
            </Typography>

            <Typography variant="body1" mb={2}>
              You will not receive any more emails about your current job
              application, but we’re still open to staying in touch.
            </Typography>

            <Box mb={4}>
              <LetsConnect withHeader={false} />
            </Box>

            {isDeclined ? (
              <DeclineSuccess />
            ) : (
              <DeclineReasons onDeclineSubmit={onDeclineSubmit} />
            )}

            <Typography variant="body2" color="secondary" mb={1} mt={8}>
              If you changed your mind, you can proceed with application for a
              job anytime
            </Typography>
            <Box>
              <CustomButton
                href="https://join.agileengine.com/open-positions/"
                label="Apply for a job"
                color="primary"
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DeclineReferral;
