import { Box, SvgIcon, Typography } from '@mui/material';
import React from 'react';

import confirmEmailImage from '../../assets/confirm-email.webp';
import confirmEmailImagePNG from '../../assets/confirm-email.png';
import { BrandLogo, ResponsiveBreak, StepPanelHeader } from '../../components';
import { TOP_CLIENTS_LIST } from '../../constants';

const SuccessfulPositionApplication: React.FC = () => {
  const renderTopClients = (
    topClients: (typeof TOP_CLIENTS_LIST)[0],
    index: number,
  ) => (
    <Box key={index} textAlign={{ xs: 'center', md: 'start' }}>
      <SvgIcon
        inheritViewBox
        component={topClients.icon}
        sx={{
          width: { xs: '80%', md: 'inherit' },
          height: { xs: '80%', md: 'inherit' },
        }}
      />
    </Box>
  );

  return (
    <Box
      sx={(theme) => ({
        display: 'grid',
        background: theme.palette.background.backgroundPrimary,
        minHeight: '100vh',
        p: 3,

        [theme.breakpoints.down('md')]: {
          gridTemplate: 'repeat(3, min-content) / repeat(2, auto)',
          justifyItems: 'center',
          gap: '1.25rem 0',
        },

        [theme.breakpoints.down('sm')]: {
          overflowY: 'auto',
          background: 'white',
          px: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyItems: 'revert',
          gap: 6,
        },
      })}
    >
      <Box
        data-testid="sign-in-brand-logo"
        sx={(theme) => ({
          gridColumn: 1,
          gridRow: 1,
          mt: '1rem',
          ml: '1.75rem',

          [theme.breakpoints.down('md')]: {
            gridColumn: 1,
            gridRow: 1,
            justifySelf: 'auto',
            mt: '0.5rem',
            ml: '5.5rem',
          },
          [theme.breakpoints.down('sm')]: {
            mt: 3.25,
            ml: 0,
          },
        })}
      >
        <BrandLogo width={133} />
      </Box>
      <Box
        data-testid="spa-layout"
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          maxWidth: '50rem',
          borderRadius: '48px',
          background: 'white',
          gridRow: '1/3',
          gridColumn: '1/-1',
          alignSelf: 'center',
          justifySelf: 'center',
          py: 2,
          mt: 12,
          boxSizing: 'border-box',

          [theme.breakpoints.down('md')]: {
            px: 0,
            gridRow: 2,
            mt: 1,
            width: '90%',
          },
          [theme.breakpoints.down('sm')]: {
            display: 'contents',
            px: 0,
          },
        })}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            pt: 6,
            textAlign: 'center',
            mb: 3,
          }}
        >
          <StepPanelHeader
            srcSetPng={confirmEmailImagePNG}
            srcSetWebp={confirmEmailImage}
            alt="Confirm email"
            imageWidth={{ xs: 57, sm: 90, xxl: 100 }}
            sx={{ mb: 3 }}
          >
            Thank you!
            <ResponsiveBreak breakpoint="xs" />
            Please check your inbox
          </StepPanelHeader>

          <Typography variant="body1" sx={{ mb: 3 }}>
            There is a confirmation email with a magic link
            <ResponsiveBreak breakpoint="sm" />
            to continue your journey
          </Typography>
          <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
            It may take a couple of minutes to process.
          </Typography>
        </Box>
      </Box>

      <Box
        sx={() => ({
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          maxWidth: '60rem',
          gridRow: '3/3',
          gridColumn: '1/-1',
          alignSelf: 'center',
          justifySelf: 'center',
          px: 0,
          py: 2,
          boxSizing: 'border-box',
        })}
      >
        <Typography
          variant="body1"
          color="text.secondary"
          textAlign="center"
          sx={{ mb: 3 }}
        >
          Unleash your potential <ResponsiveBreak breakpoint="sm" />
          by collaborating with top-tier brands
        </Typography>
        <Box
          sx={() => ({
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            gap: '2rem',
          })}
        >
          {TOP_CLIENTS_LIST.map(renderTopClients)}
        </Box>
      </Box>
    </Box>
  );
};

export default SuccessfulPositionApplication;
