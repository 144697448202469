import { Countries } from 'src/constants';
import { ICountry } from 'src/types';
import {
  NOT_AUTHORIZED_TO_WORK_IN_US_TYPE,
  REQUIRE_VISA_SPONSORSHIP,
} from 'src/constants/notAuthorizedToWorkInUs';
import * as yup from 'yup';

export const validationSchema = yup.object({
  country: yup
    .object({
      name: yup.string().required('Please select a country'),
    })
    .nullable(),
  notAuthorizedToWorkInUs: yup
    .mixed()
    .nullable()
    .when('country', {
      is: (country: ICountry | null) =>
        country?.name === Countries.UnitedStates,
      then: yup
        .mixed()
        .oneOf(
          Object.values(NOT_AUTHORIZED_TO_WORK_IN_US_TYPE),
          'Select an option',
        ),
    }),
  requireVisaSponsorship: yup
    .mixed()
    .nullable()
    .when('country', {
      is: (country: ICountry | null) =>
        country?.name === Countries.UnitedStates,
      then: yup
        .mixed()
        .oneOf(Object.values(REQUIRE_VISA_SPONSORSHIP), 'Select an option'),
    }),
  isConsentChecked: yup.boolean().when('country', {
    is: (country: ICountry | null) => country?.name === Countries.UnitedStates,
    then: yup.boolean().oneOf([true], 'Please confirm'),
  }),
});
