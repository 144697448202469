import React from 'react';
import { Box, Breakpoint } from '@mui/material';

interface IProps {
  breakpoint: Breakpoint;
}

export const ResponsiveBreak: React.FC<IProps> = ({ breakpoint }) => {
  return (
    <Box
      component="br"
      sx={(theme) => ({
        [theme.breakpoints.down(breakpoint)]: {
          display: 'none',
        },
      })}
    />
  );
};
