import { IMassCampaign } from '@types';
import { API_STATUS } from '../../constants';
import { massCampaignCandidateStoreKey } from './massCampaignCandidate.const';
import { IMassCampaignCandidateSliceState } from './massCampaignCandidate.slice';

interface IState {
  [massCampaignCandidateStoreKey]: IMassCampaignCandidateSliceState;
}

const selectMassCampaignCandidateState = (state: IState) => {
  return state[massCampaignCandidateStoreKey];
};

const getMassCampaignCandidateApiStatus = (state: IState): API_STATUS => {
  return selectMassCampaignCandidateState(state).apiStatus;
};

const getMassCampaignCandidateData = (state: IState): IMassCampaign | null => {
  const massCampaignSlice = selectMassCampaignCandidateState(state);
  if (!massCampaignSlice.campaignId || !massCampaignSlice.candidateId)
    return null;

  return {
    campaignId: massCampaignSlice.campaignId,
    candidateId: massCampaignSlice.candidateId,
  };
};

export const massCampaignCandidateSelectors = {
  getMassCampaignCandidateApiStatus,
  getMassCampaignCandidateData,
};
