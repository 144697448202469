import { useMemo } from 'react';
import { isQuizTestTask } from '../utils';
import { useAppSelector } from '../redux';
import { userSelectors } from '../redux/user';
import { useUserTestTasks } from './useUserTestTasks';
import { useKnowledgeQuiz } from './useKnowledgeQuiz';

export const useIsQuiz = (): boolean => {
  const userData = useAppSelector(userSelectors.getUserData);
  const { currentTestTask } = useUserTestTasks();
  const { hasStartedKnowledgeQuiz } = useKnowledgeQuiz();

  const isQuiz = useMemo(() => {
    if (!userData) return false;

    return (
      isQuizTestTask(currentTestTask?.testTaskType ?? null) ||
      hasStartedKnowledgeQuiz
    );
  }, [userData, hasStartedKnowledgeQuiz]);

  return isQuiz;
};
