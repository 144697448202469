import React, { FC } from 'react';
import { CurrencyType } from '../../../../types';
import { Typography } from '@mui/material';

interface IProps {
  rate: string;
  currency: CurrencyType;
}
export const ExchangeRate: FC<IProps> = ({ rate, currency }) => {
  return (
    <Typography variant="body2" color={'text.secondary'} whiteSpace={'nowrap'}>
      1 USD ≈ {rate} {currency}
    </Typography>
  );
};
