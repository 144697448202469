import { Box, SvgIcon, Theme, Typography } from '@mui/material';
import React, { FC, ReactNode } from 'react';
import VolumeOffRoundedIcon from '@mui/icons-material/VolumeOffRounded';
import VolumeUpRoundedIcon from '@mui/icons-material/VolumeUpRounded';
import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx';

interface IProps {
  subTitles: string;
  isMuted: boolean;
  handleMuteToggle: () => void;
  children: ReactNode;
  sx?: (theme: Theme) => SystemStyleObject<Theme>;
}
export const SubtitlesContainer: FC<IProps> = ({
  subTitles,
  isMuted,
  handleMuteToggle,
  children,
  sx,
}) => {
  if (!subTitles) return null;

  return (
    <Box
      data-testid="subtitles-container"
      sx={(theme) => ({
        marginTop: '1.15rem',
        zIndex: 1,
        width: '300px',
        height: '5rem',
        minHeight: '5rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        gap: '8px',

        [theme.breakpoints.down('sm')]: {
          m: 0,
          zIndex: 0,
          position: 'relative',
          right: '1rem',
        },
        [theme.breakpoints.up('xxl')]: {
          height: 'auto',
        },
        ...(sx ? sx(theme) : {}),
      })}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '100%',
          minHeight: '56px',
          background: theme.palette.highlight.accent,
          borderRadius: '8px',
          position: 'relative',
          boxShadow: '0px 4px 0px 0px rgba(170, 134, 3, 0.26);',
          '&::before': {
            content: "''",
            position: 'absolute',
            height: '34px',
            width: '34px',
            background: 'inherit',
            top: '-25%',
            left: '0px',
            clipPath: 'polygon(100% 0, 0 100%, 100% 100%)',
            zIndex: -1,
          },
        })}
      >
        <Typography
          variant="body2"
          textAlign="center"
          sx={(theme) => ({
            width: '100%',
            padding: '8px 30px 10px 12px',
            [theme.breakpoints.down('sm')]: {
              padding: '8px 30px 10px 12px',
            },
          })}
        >
          {subTitles}
        </Typography>
        <SvgIcon
          id="volume-icon"
          inheritViewBox
          component={isMuted ? VolumeOffRoundedIcon : VolumeUpRoundedIcon}
          htmlColor="black"
          fontSize="small"
          cursor="pointer"
          onClick={handleMuteToggle}
          sx={{
            opacity: '0.3',
            position: 'absolute',
            right: '6px',
            top: '50%',
            transform: 'translateY(-60%)',
            '&:hover': {
              opacity: '0.5',
            },
          }}
        />
      </Box>
      {children}
    </Box>
  );
};
