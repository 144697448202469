import { Box, Link, Typography } from '@mui/material';
import { cloclIcon } from 'src/constants/apiEndpoints';

export const ExplanationPanel = () => {
  return (
    <Box
      sx={{
        background: '#BB28BE0D',
        padding: '19px 23px',
        borderRadius: '16px',
        maxWidth: '340px',
      }}
    >
      <Typography variant="body1">
        30 – 60 minute test that cuts further interview time and reduces bias.
      </Typography>
      <Box display="flex" justifyContent="space-between" gap={5} mt={2}>
        <Typography variant="body2" color="text.secondary">
          Coding on the{' '}
          <Link
            href="https://view-su2.highspot.com/viewer/6348509beb5f7d8b14bc6b01"
            target="_blank"
            color="text.secondary"
          >
            Codility platform
          </Link>{' '}
          right in your browser.
        </Typography>
        <img src={cloclIcon} alt="ClockIcon" width="40" height="45" />
      </Box>
    </Box>
  );
};
