import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../constants';
import { authStoreKey } from './auth.const';
import { authUser, logout } from './auth.thunks';

export interface IAuthSliceState {
  apiStatus: API_STATUS;
  authError: string | null;
  authUserEmailSent: boolean;
  submitProfileEmailSent: boolean;
}

const initialState: IAuthSliceState = {
  apiStatus: API_STATUS.IDLE,
  authError: null,
  authUserEmailSent: false,
  submitProfileEmailSent: false,
};

export const authSlice = createSlice({
  name: authStoreKey,
  initialState,
  reducers: {
    setAuthError: (state, action: PayloadAction<string>) => {
      state.authError = action.payload;
    },
    clearAuthError: (state) => {
      state.authError = null;
    },
    setAuthUserEmailSent: (state, action: PayloadAction<boolean>) => {
      state.authUserEmailSent = action.payload;
    },
    setSubmitProfileEmailSent: (state, action: PayloadAction<boolean>) => {
      state.submitProfileEmailSent = action.payload;
    },
    clearAuthState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(isAnyOf(authUser.pending, logout.pending), (state) => {
        state.apiStatus = API_STATUS.LOADING;
      })
      .addMatcher(isAnyOf(authUser.fulfilled, logout.fulfilled), (state) => {
        state.apiStatus = API_STATUS.COMPLETE;
      })
      .addMatcher(isAnyOf(authUser.rejected, logout.rejected), (state) => {
        state.apiStatus = API_STATUS.FAILED;
      });
  },
});

export const {
  setAuthError,
  clearAuthError,
  setAuthUserEmailSent,
  setSubmitProfileEmailSent,
  clearAuthState,
} = authSlice.actions;
