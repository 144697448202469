import { useQuestioneerNavigationSteps } from './useQuestioneerNavigationSteps';
import { useLocation, useNavigate } from 'react-router-dom';

interface IProps {
  handleGoToPreviousStep?: () => void;
}
export const useGoToPreviousStep = (props?: IProps) => {
  const { handleGoToPreviousStep: _handleGoToPreviousStep } = props ?? {};
  const { navigationSteps } = useQuestioneerNavigationSteps();
  const location = useLocation();
  const navigate = useNavigate();

  let currentStep: null | number = null;
  navigationSteps.map((step, index) => {
    if (step.route === location.pathname.replace('/', '')) {
      currentStep = index;
    }
  });
  const hasPreviousStep = currentStep || _handleGoToPreviousStep;

  const onGoToPreviousStep = () => {
    if (!hasPreviousStep) {
      return;
    }
    if (_handleGoToPreviousStep) {
      _handleGoToPreviousStep();
      return;
    }
    const previousStepRoute = navigationSteps[currentStep! - 1].route;
    navigate('/' + previousStepRoute);
  };

  return { onGoToPreviousStep, hasPreviousStep, currentStep };
};
