/** @jsxImportSource @emotion/react */
import React, {
  ChangeEvent,
  DragEvent,
  KeyboardEvent,
  MouseEvent,
  MutableRefObject,
  useRef,
  useState,
  useEffect,
} from 'react';
import cl from 'classnames';
import { Box, Button, IconButton, ButtonBaseActions } from '@mui/material';
import { useInputFileStyles } from './FileInput.styles';
import { AttachFile, CheckCircle, Close } from '@mui/icons-material';
import {
  EMPTY_FILE_ERROR_MESSAGE,
  INVALID_FORMAT_ERROR_MESSAGE,
  LARGE_FILE_ERROR_MESSAGE,
  ACCEPTED_EXTENSIONS,
} from '../../constants';
import uniqBy from 'lodash/uniqBy';
import { useFocusInputAfterAnimation } from '../../hooks';

interface IProps {
  label: string;
  hint?: string;
  name?: string;
  value?: string[];
  loading?: boolean;
  progress: number;
  error?: Record<number, string>;
  setErrorInFile: (errors: Record<number, string>) => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  acceptedExtensions?: string[];
  multiple?: boolean;
  autoFocus?: boolean;
}

const KILO_BYTE = 1024;
const MIN_FILE_SIZE_MAP = {
  pdf: KILO_BYTE,
  docx: 6 * KILO_BYTE,
  doc: 10 * KILO_BYTE,
};
const MAX_FILE_SIZE = 20 * 1048576 - 1; // 19.(9) MB

export const FileInput: React.FC<IProps> = ({
  label,
  hint,
  name,
  value,
  loading,
  progress = 0,
  error,
  setErrorInFile,
  onChange,
  acceptedExtensions = ACCEPTED_EXTENSIONS,
  multiple,
  autoFocus,
}): React.ReactElement => {
  const styles = useInputFileStyles();
  const [filename, setFilename] = useState<string[] | null>(
    () => value?.filter((item) => item) ?? null,
  );
  const [files, setFiles] = useState<File[] | []>([]);
  const [errors, setErrors] = useState<Record<number, string>>(error ?? {});

  const [isDragAndDrop, setIsDragAndDrop] = useState(false);
  const fileInputRef = useRef() as MutableRefObject<HTMLInputElement>;
  const fileWrapperRef = useRef() as MutableRefObject<HTMLLabelElement>;
  const buttonActionRef = useRef() as MutableRefObject<ButtonBaseActions>;

  useFocusInputAfterAnimation(autoFocus ? fileWrapperRef : useRef(null));

  useEffect(() => {
    if (autoFocus) {
      buttonActionRef.current.focusVisible();
    }
  }, []);

  useEffect(() => {
    if (!files.length) return;

    files.forEach((file, index) => _handleFileInput(file, index));

    if (onChange) {
      onChange({
        target: { files, name },
      } as unknown as ChangeEvent<HTMLInputElement>);
    }
  }, [files]);

  useEffect(() => {
    setErrorInFile(errors);
  }, [errors]);

  const _handleFileInput = (file?: File, index = 0) => {
    if (!file) return;

    const { name: fileName, size } = file;

    const splittingExtension = fileName.split('.');
    const extension = '.' + splittingExtension[splittingExtension.length - 1];
    const isAllowed = acceptedExtensions.find(
      (ext) => ext === extension.toLowerCase(),
    );

    const isFileTooSmall = (extension: string, size: number): boolean => {
      const minFileSize = Number(
        MIN_FILE_SIZE_MAP[extension as keyof typeof MIN_FILE_SIZE_MAP],
      );

      return size < minFileSize;
    };

    const isMinSize = isFileTooSmall(
      splittingExtension[splittingExtension.length - 1],
      size,
    );

    if (isMinSize) {
      setErrors((prev) => ({ ...prev, [index]: EMPTY_FILE_ERROR_MESSAGE }));
    } else if (size > MAX_FILE_SIZE) {
      setErrors((prev) => ({ ...prev, [index]: LARGE_FILE_ERROR_MESSAGE }));
    } else if (!isAllowed) {
      setErrors((prev) => ({
        ...prev,
        [index]: INVALID_FORMAT_ERROR_MESSAGE(acceptedExtensions.join(', ')),
      }));
    } else {
      setErrors((prev) => ({ ...prev, [index]: '' }));
    }

    setFilename((prev) =>
      multiple ? (prev ?? [])?.concat(fileName) : [fileName],
    );
    setIsDragAndDrop(false);
  };

  const onChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const rawFiles = Array.from(e.target.files);
    setFiles((prev) =>
      multiple ? uniqBy([...prev, ...rawFiles], 'name') : rawFiles,
    );
  };

  const onDropFile = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (!e.dataTransfer.files) {
      return;
    }

    const rawFiles = Array.from(e.dataTransfer.files);
    setFiles((prev) =>
      multiple ? uniqBy([...prev, ...rawFiles], 'name') : rawFiles,
    );
  };

  const clearInputFile =
    (index = 0) =>
    (event: MouseEvent) => {
      event.stopPropagation();
      event.preventDefault();

      let rawFiles = files;

      if (rawFiles?.length) {
        rawFiles = files.filter((item, idx) => idx !== index);
        setFiles((prev) => prev.filter((item, idx) => idx !== index));
      }

      if (!rawFiles.length) {
        fileInputRef.current!.value = '';
        setFilename(null);
      }

      setErrors((prev) => ({ ...prev, [index]: '' }));

      if (onChange) {
        onChange({
          target: { files: [], name },
        } as unknown as ChangeEvent<HTMLInputElement>);
      }
    };

  const onKeyUp = (e: KeyboardEvent) => {
    e.stopPropagation();
    if (e.code === 'Enter' || e.code === 'Space') {
      fileInputRef.current?.click();
    }
  };

  return (
    <Box css={styles}>
      <Box position="relative">
        <Button
          ref={fileWrapperRef}
          action={buttonActionRef}
          className={cl('file-input', { 'drag-and-drop': isDragAndDrop })}
          variant="outlined"
          component="label"
          startIcon={<AttachFile className="file-input-icon-attach" />}
          endIcon={
            filename && progress === 0 && !multiple ? (
              <IconButton onClick={clearInputFile(0)}>
                <Close fontSize="small" />
              </IconButton>
            ) : null
          }
          onKeyUp={onKeyUp}
          onDragOver={(e: DragEvent) => {
            e.preventDefault();
            e.stopPropagation();
            setIsDragAndDrop(true);
          }}
          onDragLeave={(e: DragEvent) => {
            e.preventDefault();
            e.stopPropagation();
            setIsDragAndDrop(false);
          }}
          onDrop={onDropFile}
          sx={{ letterSpacing: 0, fontSize: '1rem' }}
        >
          <div className={'file-input-labels'}>
            {!multiple && filename?.length ? (
              <span className="file-input-labels--filename">
                {filename.toString()}
              </span>
            ) : (
              <>
                <div>{label}</div>
                {hint && <div className="file-input-labels--hint">{hint}</div>}
              </>
            )}
          </div>
          <input
            type="file"
            accept={acceptedExtensions.toString()}
            hidden
            name={name}
            onChange={onChangeFile}
            multiple={multiple}
            ref={fileInputRef}
          />
        </Button>

        {loading ? (
          <div
            className="linear-progress"
            style={{ width: `${progress ?? 0}%` }}
          >
            <div className="wrapper">
              <CheckCircle
                className={`linear-progress-check ${
                  progress >= 100 ? 'linear-progress-check--animate' : ''
                }`}
              />
            </div>
          </div>
        ) : null}
      </Box>

      {multiple && files && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
          {files?.map((file, index) => (
            <Box key={file.name}>
              <Button
                fullWidth
                variant="contained"
                component="div"
                color="info"
                startIcon={<AttachFile className="file-input-icon-attach" />}
                endIcon={
                  filename && progress === 0 ? (
                    <IconButton onClick={clearInputFile(index)}>
                      <Close fontSize="small" sx={{ color: 'white' }} />
                    </IconButton>
                  ) : null
                }
              >
                <Box sx={{ width: '100%', textAlign: 'left' }}>{file.name}</Box>
              </Button>
              {errors && (
                <Box className="file-input-error" mt={0.5}>
                  {errors[index]}
                </Box>
              )}
            </Box>
          ))}
        </Box>
      )}

      {!multiple &&
        errors &&
        Object.values(errors).map((item, index) => (
          <Box key={index} className="file-input-error" mt={0.5}>
            {item}
          </Box>
        ))}
    </Box>
  );
};

export default FileInput;
