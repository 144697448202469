import { createSlice } from '@reduxjs/toolkit';
import { referralJobOpeningsStoreKey } from './referralJobOpenings.const';
import { fetchReferralJobOpenings } from './referralJobOpenings.thunks';
import { API_STATUS } from '../../constants';
import { IJobOpeningRaw } from '@types';

export interface IReferralJobOpeningsSliceState {
  apiStatus: API_STATUS;
  data: IJobOpeningRaw[] | null;
  apiError: string;
}

const initialState: IReferralJobOpeningsSliceState = {
  apiStatus: API_STATUS.IDLE,
  data: null,
  apiError: '',
};

export const referralJobOpeningsSlice = createSlice({
  name: referralJobOpeningsStoreKey,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchReferralJobOpenings.pending, (state) => {
        state.apiStatus = API_STATUS.LOADING;
      })
      .addCase(fetchReferralJobOpenings.fulfilled, (state, action) => {
        state.apiStatus = API_STATUS.COMPLETE;
        state.data = action.payload;
      })
      .addCase(fetchReferralJobOpenings.rejected, (state, action) => {
        state.apiStatus = API_STATUS.FAILED;
        state.apiError = action.payload as string;
      });
  },
});
