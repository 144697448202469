import React from 'react';
import { Box, Typography } from '@mui/material';
import { BasicDialog, CustomButton, TextButton } from '../../../components';
import hourglassIcon from '../../../assets/hourglass-icon.png';
import { VideoInterviewRowOfImages } from './RowOfImages';
import { logEvent } from 'src/services';

interface IProps {
  isOpen: boolean;
  handleClose: () => void;
  handleFinishVideoInterview: () => void;
}

export const ExitInterviewDialog: React.FC<IProps> = ({
  isOpen,
  handleClose,
  handleFinishVideoInterview,
}) => {
  const handleContinueRecording = () => {
    handleClose();
  };

  const onStopInterviewClick = () => {
    logEvent('launchpod - video-interview-record-page_stop-interview-clicked');
    handleFinishVideoInterview();
    handleClose();
  };

  return (
    <BasicDialog
      isOpen={isOpen}
      handleClose={handleClose}
      maxWidth="38rem"
      overflowVisible
    >
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
        <img src={hourglassIcon} width="50px" alt="Exit interview" />
      </Box>
      <Typography
        variant="h3"
        textAlign="center"
        mb={3}
        sx={{ color: 'black' }}
      >
        You still have time <br />
        to finish answering
      </Typography>
      <Box
        sx={(theme) => ({
          background: theme.palette.highlight.accent,
          padding: '25px 35px',
          display: 'flex',
          flexDirection: 'column',
          mb: 3,
        })}
      >
        <Typography
          variant="body1"
          color="brand.secondary"
          fontWeight="600"
          mt={{ sm: 4, md: 3, lg: 3 }}
          mb={3}
        >
          Why it’s important?
        </Typography>
        {VideoInterviewRowOfImages.map((block, index) => {
          return (
            <Box key={index} display="flex" alignItems="flex-start" pb={2}>
              <Box
                display="flex"
                minWidth="3rem"
                justifyContent="center"
                fontSize="2rem"
              >
                {block.image}
              </Box>
              <Typography
                color="text.primary"
                variant="body1"
                px={2}
                sx={{ whiteSpace: 'pre-line' }}
              >
                {block.text}
              </Typography>
            </Box>
          );
        })}
      </Box>
      <Box
        sx={{
          textAlign: 'center',
          mb: 3,
        }}
      >
        <Typography variant="body2" color="text.secondary">
          Want to resume the recording?
        </Typography>
        <TextButton onClick={handleContinueRecording}>
          Continue recording
        </TextButton>
      </Box>
      <Box sx={{ maxWidth: '300px', margin: '0 auto' }}>
        <CustomButton
          label="Stop interview"
          fullWidth
          onClick={onStopInterviewClick}
          color="primary"
        />
      </Box>
    </BasicDialog>
  );
};
