import React from 'react';
import { Box, Link, SvgIcon } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';

import { BrandLogo } from '../../components';
import { LpTermsOfUseText } from './LpTermsOfUseText';

const TermsOfUse: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={(theme) => ({
        display: 'grid',
        gridTemplate: 'repeat(3, auto) / repeat(2, auto)',
        background: theme.palette.background.backgroundPrimary,

        [theme.breakpoints.down('md')]: {
          gridTemplate: 'repeat(3, min-content) / repeat(2, auto)',
          justifyItems: 'center',
          gap: '1.25rem 0',
        },

        [theme.breakpoints.down('sm')]: {
          overflowY: 'auto',
          background: 'white',
          px: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyItems: 'revert',
          gap: 0,
        },
      })}
    >
      <Box
        data-testid="sign-in-brand-logo"
        sx={(theme) => ({
          gridColumn: 1,
          gridRow: 1,
          mt: '2rem',
          ml: '1.75rem',

          [theme.breakpoints.down('md')]: {
            gridColumn: 1,
            gridRow: 1,
            justifySelf: 'auto',
            mt: '4.5rem',
            ml: '5.5rem',
          },
          [theme.breakpoints.down('sm')]: {
            mt: 3.25,
            ml: 0,
          },
        })}
      >
        <BrandLogo width={94} />
      </Box>
      <Box
        data-testid="tou-layout"
        sx={(theme) => ({
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          maxWidth: '59.5rem',
          borderRadius: '48px',
          background: 'white',
          gridRow: '1/3',
          gridColumn: '1/-1',
          alignSelf: 'center',
          justifySelf: 'center',
          px: 12,
          my: 12,
          [theme.breakpoints.down('md')]: {
            px: 8,
            gridRow: 2,
            mt: 1,
            width: '90%',
          },
          [theme.breakpoints.down('sm')]: {
            display: 'contents',
            px: 0,
          },
        })}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          my={6}
        >
          <Box display="flex" alignItems="center" onClick={() => navigate(-1)}>
            <SvgIcon
              inheritViewBox
              component={ArrowBackRoundedIcon}
              sx={(theme) => ({
                color: 'brand.accent',
                cursor: 'pointer',
                transition: '500ms',
                ':hover': {
                  color: 'text.primary',
                },
                mr: 2,
                [theme.breakpoints.down('md')]: {
                  ml: -1,
                  mr: 1,
                  mt: 0.25,
                },
              })}
              role="button"
            />
            <Link
              color="text.secondary"
              sx={(theme) => ({
                textDecorationThickness: '0.5px',
                textUnderlinePosition: 'from-font',
                cursor: 'pointer',

                '&:hover': {
                  color: theme.palette.text.primary,
                  textDecoration: 'none',
                },
              })}
            >
              Back
            </Link>
          </Box>
          <Link
            href="https://agileengine.com/privacy-policy"
            target="_blank"
            color="text.secondary"
            sx={(theme) => ({
              textDecorationThickness: '0.5px',
              textUnderlinePosition: 'from-font',
              cursor: 'pointer',

              '&:hover': {
                color: theme.palette.text.primary,
                textDecoration: 'none',
              },
            })}
          >
            Privacy policy
          </Link>
        </Box>
        <LpTermsOfUseText />
      </Box>
    </Box>
  );
};

export default TermsOfUse;
