import React from 'react';
import ShareFeedbackForm from './components/ShareFeedbackForm';
import BasicDialog from '../../components/basicDialog/BasicDialog';

interface IProps {
  isOpen: boolean;
  handleClose: () => void;
}

const ShareFeedbackDialog: React.FC<IProps> = ({
  isOpen = true,
  handleClose,
}) => {
  return (
    <BasicDialog
      isOpen={isOpen}
      handleClose={handleClose}
      canClickOutside={false}
    >
      <ShareFeedbackForm handleClose={handleClose} />
    </BasicDialog>
  );
};

export default ShareFeedbackDialog;
