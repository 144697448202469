import { useMemo } from 'react';
import { APP_ROUTES } from '../constants';
import { useAppSelector } from '../redux';
import { userSelectors } from '../redux/user';
import {
  useIsDisqualified,
  useIsPage,
  useIsQuestioneerStepper,
  useIsQuiz,
} from '../hooks';

export const useIsMainStepper = (): boolean => {
  const userData = useAppSelector(userSelectors.getUserData);
  const isQuestioneerStepper = useIsQuestioneerStepper();
  const isQuizStepper = useIsQuiz();
  const isEmailPage = useIsPage(APP_ROUTES.EMAIL);
  const isProfileReviewPage = useIsPage(APP_ROUTES.PROFILE_REVIEW);
  const isUnsubscribePage = useIsPage(APP_ROUTES.UNSUBSCRIBE_FROM_REMINDERS);
  const { isDisqualified, isNotSupportedByOpenPositions } = useIsDisqualified();

  const isMainStepper = useMemo(() => {
    if (isEmailPage || isProfileReviewPage) {
      return true;
    }

    if (
      !userData ||
      isQuestioneerStepper ||
      isQuizStepper ||
      (isDisqualified && !isNotSupportedByOpenPositions) ||
      isUnsubscribePage
    ) {
      return false;
    }

    return true;
  }, [
    userData,
    isQuestioneerStepper,
    isQuizStepper,
    isEmailPage,
    isProfileReviewPage,
    isUnsubscribePage,
    isDisqualified,
    isNotSupportedByOpenPositions,
  ]);

  return isMainStepper;
};
