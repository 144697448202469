import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiEndpoints } from '../../constants';
import { formatErrorMessage } from '../../utils';
import { notificationAlert } from '../notifications';
import { magicLinkCandidateStoreKey } from './magicLinkCandidate.const';

export const postUserWithZohoSignUp = createAsyncThunk(
  `${magicLinkCandidateStoreKey}/postZohoIdAndIsDirectLinkUser`,
  async (zohoId: string, thunkAPI) => {
    try {
      const response = await axios.post(
        apiEndpoints.userSignedWithZohoIdPath(),
        { zohoId },
      );

      return response.data;
    } catch (err: any) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(err);
    }
  },
);
