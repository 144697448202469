import { Box, IconButton, SvgIcon } from '@mui/material';
import FastRewindRoundedIcon from '@mui/icons-material/FastRewindRounded';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import PauseRoundedIcon from '@mui/icons-material/PauseRounded';
import ReplayRoundedIcon from '@mui/icons-material/ReplayRounded';
import React, { FC } from 'react';

interface IProps {
  isPaused: boolean;
  handleVideoRewind: () => void;
  handleVideoPlayPause: () => void;
  handleVideoReplay: () => void;
}
export const OverlayControls: FC<IProps> = ({
  isPaused,
  handleVideoRewind,
  handleVideoPlayPause,
  handleVideoReplay,
}) => {
  return (
    <Box
      className="overlay-controls"
      sx={(theme) => ({
        background: theme.palette.background.overlay,
        position: 'absolute',
        zIndex: 2,
        height: '100%',
        width: '100%',
        clipPath: 'circle()',
        color: 'white',
        opacity: 0,
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        padding: '0 0.5rem',
        '&:hover': {
          opacity: 1,
          transition: 'opacity 0.5s',
        },
      })}
    >
      <IconButton id="rewind" onClick={handleVideoRewind}>
        <SvgIcon
          inheritViewBox
          component={FastRewindRoundedIcon}
          sx={{ color: 'white' }}
        />
      </IconButton>

      <IconButton id="pause" onClick={handleVideoPlayPause}>
        <SvgIcon
          inheritViewBox
          component={isPaused ? PlayArrowRoundedIcon : PauseRoundedIcon}
          sx={{ color: 'white' }}
        />
      </IconButton>

      <IconButton id="replay" onClick={handleVideoReplay}>
        <SvgIcon
          inheritViewBox
          component={ReplayRoundedIcon}
          sx={{ color: 'white' }}
        />
      </IconButton>
    </Box>
  );
};
