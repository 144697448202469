export const secondarySkillsMessage = (props: {
  secondarySkillsSelected: string[];
}): string | null => {
  const { secondarySkillsSelected } = props;
  const aeLovedTechs = 'Java' || 'Wordpress';
  const lastTechSelected =
    secondarySkillsSelected[secondarySkillsSelected.length - 1];
  const isLovedTech = lastTechSelected === aeLovedTechs;
  if (isLovedTech) {
    return `We love ${lastTechSelected} too!`;
  } else if (secondarySkillsSelected.length > 8) {
    return "Wow, that's a lot!";
  } else if (secondarySkillsSelected.length > 4) {
    return "Wow, you're quite a polyglot!";
  } else if (secondarySkillsSelected.length >= 1) {
    return 'Cool, please tell us more about you';
  } else {
    return 'Alright!';
  }
};
