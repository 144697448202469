import {
  MutableRefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import debounce from 'lodash/debounce';
import { ANIMATION_DURATION } from '../constants';
import { useIsNarrowView } from './useIsNarrowView';

interface IElementDimensions {
  top: number;
  height: number;
}

export interface IStickyItemProps {
  elRef: MutableRefObject<HTMLDivElement | null>;
  isBottom?: boolean | false;
  isHiddenOnLandscape?: boolean | true;
  delayCalculation?: number | 300;
}

export const useStickyItem = ({
  elRef,
  isBottom = false,
  isHiddenOnLandscape = true,
  delayCalculation = 300,
}: IStickyItemProps) => {
  const delay = ANIMATION_DURATION * 1000 + delayCalculation;
  const [isSticky, setIsSticky] = useState(false);
  const [elRec, setElRec] = useState<IElementDimensions | null>(null);
  const isNarrowView = useIsNarrowView();

  const shouldRemoveSticky = useMemo(
    () => isHiddenOnLandscape && isNarrowView,
    [isHiddenOnLandscape, isNarrowView],
  );

  useEffect(() => {
    if (!shouldRemoveSticky) return;
    setIsSticky(false);
  }, [shouldRemoveSticky]);

  useEffect(() => {
    if (!elRec) return;
    handleScroll(elRec);
  }, [elRec]);

  useLayoutEffect(() => {
    if (elRef.current === null) return;

    const el = elRef.current;
    const timeoutId = setTimeout(() => {
      const { top, height } = el.getBoundingClientRect();
      setElRec({ top, height });
    }, delay);
    return () => {
      clearTimeout(timeoutId);
      setElRec(null);
    };
  }, [elRef]);

  useEffect(() => {
    if (!elRec) return;

    const debouncedHandleScroll = debounce(handleScroll.bind(null, elRec), 100);

    window.addEventListener('scroll', debouncedHandleScroll);
    window.addEventListener('resize', debouncedHandleScroll);
    return () => {
      window.removeEventListener('scroll', debouncedHandleScroll);
      window.removeEventListener('resize', debouncedHandleScroll);
    };
  }, [elRec, shouldRemoveSticky]);

  const handleScroll = useCallback(
    (elRec: IElementDimensions) => {
      if (shouldRemoveSticky) return;

      const { top, height } = elRec;

      if (isBottom) {
        const scrollTop = window.scrollY + window.innerHeight;
        if (scrollTop <= top + height + 20) {
          setIsSticky(true);
        } else {
          setIsSticky(false);
        }
        return;
      }

      const scrollTop = window.scrollY;
      if (scrollTop >= top + 20) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    },
    [shouldRemoveSticky],
  );

  return {
    isSticky: shouldRemoveSticky ? false : isSticky,
  };
};
