import React, { useEffect } from 'react';
import { useIsDisqualified } from '../../../../hooks';
import { useAppSelector } from '../../../../redux';

import { Flex, ResponsiveBreak, StepPanel } from '../../../../components';
import LetsConnect from '../../../letsConnect/LetsConnect';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';

import NotFitPersonMessage from './messages/NotFitPersonMessage';
import NotSupportedProgrammingLanguageMessage from './messages/NotSupportedProgrammingLanguageMessage';
import NotSupportedEnglishLevelMessage from './messages/NotSupportedEnglishLevelMessage';
import NotSupportedLegalRestrictionsMessage from './messages/NotSupportedLegalRestrictionsMessage';
import NotSupportedByLocationMessage from './messages/NotSupportedByLocationMessage';
import NotSupportedByCompensationMessage from './messages/NotSupportedByCompensationMessage';
import NotSupportedByOpenPositions from './messages/NotSupportedByOpenPositions';
import NotAuthorizedToWorkInUsMessage from './messages/NotAuthorizedToWorkInUsMessage';

import { userSelectors } from '../../../../redux/user';

import startImage from '../../../../assets/passing-star.png';
import NotSupportedFailedInterviewMessage from './messages/NotSupportedFailedInterviewMessage';
import { logEvent } from 'src/services';

export const DisqualifiedLayout = () => {
  const userData = useAppSelector(userSelectors.getUserData);

  const {
    isNotFitPerson,
    isNotSupportedProgramingLanguage,
    isNotSupportedByEnglishLevel,
    isNotSupportedByLegalRestrictions,
    isNotAuthorizedToWorkInUs,
    isRequireVisaSponsorship,
    isNotSupportedByLocation,
    isNotSupportedByCompensation,
    isNotSupportedByOpenPositions,
    isNonHireable,
    isRejectedOffer,
    isHasNotStartedWorking,
    isNotFitBySoftSkills,
    isFailedInterview,
    isOtherDisqualifyReason,
    isNotSupportedByOpenPositionsSeniority,
    isNotSupportedByOpenPositionLocation,
    isNotSupportedByOpenPositionsCoreStack,
    isVIFailed,
  } = useIsDisqualified();

  useEffect(() => {
    logEvent('launchpod-user-disqualified-cant-continue-booking');
  }, []);

  return (
    <StepPanel
      {...(isNotSupportedByOpenPositions
        ? { maxWidth: '45rem' }
        : {
            alignItems: 'flex-start',
            textAlign: 'left',
          })}
    >
      {!isNotSupportedByOpenPositions && (
        <Flex justifyContent="space-between">
          <Typography variant="h2" mb={0} data-testid="success-title">
            Thank you <ResponsiveBreak breakpoint="xs" />
            for your application!
          </Typography>

          <Box
            component="img"
            src={startImage}
            alt="Star"
            width={{ xs: 57, sm: 67, xxl: 100 }}
            height={{ xs: 57, sm: 67, xxl: 100 }}
          />
        </Flex>
      )}

      {isVIFailed && (
        <Typography variant="body1">
          We’ve reviewed your video interview answers.
        </Typography>
      )}

      {(isRejectedOffer ||
        isHasNotStartedWorking ||
        isNotFitBySoftSkills ||
        isNotFitPerson ||
        isNonHireable ||
        isOtherDisqualifyReason ||
        isRequireVisaSponsorship ||
        isVIFailed) && <NotFitPersonMessage />}

      {isNotSupportedProgramingLanguage && (
        <NotSupportedProgrammingLanguageMessage
          primarySkills={userData?.primarySkills}
        />
      )}

      {isNotSupportedByEnglishLevel && <NotSupportedEnglishLevelMessage />}

      {isNotSupportedByLegalRestrictions &&
        !isNotAuthorizedToWorkInUs &&
        !isRequireVisaSponsorship && <NotSupportedLegalRestrictionsMessage />}

      {isNotAuthorizedToWorkInUs && <NotAuthorizedToWorkInUsMessage />}

      {isNotSupportedByLocation && (
        <NotSupportedByLocationMessage country={userData?.country} />
      )}

      {isNotSupportedByCompensation && <NotSupportedByCompensationMessage />}

      {isNotSupportedByOpenPositions && (
        <NotSupportedByOpenPositions
          isNotSupportedByOpenPositionsSeniority={
            isNotSupportedByOpenPositionsSeniority
          }
          isNotSupportedByOpenPositionLocation={
            isNotSupportedByOpenPositionLocation
          }
          isNotSupportedByOpenPositionsCoreStack={
            isNotSupportedByOpenPositionsCoreStack
          }
        />
      )}

      {isFailedInterview && <NotSupportedFailedInterviewMessage />}

      {!isNotSupportedByOpenPositions && (
        <LetsConnect justifyContent="flex-start" textAlign="left" mt={3} />
      )}
    </StepPanel>
  );
};
