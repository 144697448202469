import { ResponsiveBreak, StepPanel, StepPanelHeader } from '../../components';
import starImage from '../../assets/passing-star.png';
import { Typography } from '@mui/material';
import LetsConnect from '../../features/letsConnect/LetsConnect';
import React, { useRef } from 'react';
import { useEmojisplosion } from '../../hooks';

export const InternalTestTaskDone = () => {
  const starRef = useRef<HTMLDivElement>(null);
  useEmojisplosion(starRef, 'TT_PASSED');

  return (
    <StepPanel maxWidth="34rem">
      <StepPanelHeader ref={starRef} srcSetPng={starImage} alt="Star">
        Thanks, we’re reviewing <ResponsiveBreak breakpoint="md" />
        your answers
      </StepPanelHeader>
      <Typography variant="body1">
        We will email you with the results and next steps.
        <br />
        Typically reviews take 1-2 business days.
      </Typography>
      <LetsConnect justifyContent={{ xs: 'flex-start', sm: 'center' }} mt={3} />
    </StepPanel>
  );
};
