import React, { ReactElement } from 'react';

import { CustomButton } from '../../../components';
import { logEvent } from 'src/services';

interface IProps {
  isDisabled: boolean;
  isLoading: boolean;
  handlePassTTClick: () => void;
}

const PassTestTaskButton = ({
  isDisabled,
  isLoading,
  handlePassTTClick,
}: IProps): ReactElement => {
  return (
    <CustomButton
      color="primary"
      label="Start now"
      testid="proceed-to-test-button"
      onClick={() => {
        logEvent('launchpod-continue-to-tt-confirmation-popup');
        handlePassTTClick();
      }}
      isDisabled={isDisabled}
      isLoading={isLoading}
      maxWidth="16rem"
    />
  );
};

export default PassTestTaskButton;
