import React, { FC, ReactNode } from 'react';
import { Box } from '@mui/material';
import { slideIn } from '../../../constants';

interface IProps {
  children: ReactNode;
  'data-testid'?: string;
}

export const AdditionalInfoPanelWrapper: FC<IProps> = ({
  children,
  ...rest
}) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        py: 4,
        px: 3,
        [theme.breakpoints.down('md')]: {
          mt: 4,
          maxWidth: '22rem',
        },
        [theme.breakpoints.up('md')]: {
          pt: 6,
          px: 4,
          maxWidth: '22rem',
          animation: `${slideIn} 0.25s linear`,
          animationFillMode: 'both',
          animationDelay: '0.4s',
        },
        [theme.breakpoints.up('lg')]: {
          pt: 8,
        },
        [theme.breakpoints.up('xl')]: {
          py: 8,
          paddingLeft: 8,
          paddingRight: 5,
          maxWidth: '25rem',
        },
      })}
      {...rest}
    >
      {children}
    </Box>
  );
};
