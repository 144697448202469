import { ReactComponent as AsanaLogo } from '../assets/asana-brand-logo.svg';
import { ReactComponent as DialpadLogo } from '../assets/dialpad-brand-logo.svg';
import { ReactComponent as ShutterstockLogo } from '../assets/shutterstock-brand-logo.svg';
import { ReactComponent as GrouponLogo } from '../assets/groupon-brand-logo.svg';
import { ReactComponent as VMwareLogo } from '../assets/vmware-brand-logo.svg';

export const TOP_CLIENTS_LIST = [
  {
    icon: AsanaLogo,
    alt: 'Asana',
  },
  {
    icon: DialpadLogo,
    alt: 'Dialpad',
  },
  {
    icon: ShutterstockLogo,
    alt: 'Shutterstock',
  },
  {
    icon: GrouponLogo,
    alt: 'Groupon',
  },
  {
    icon: VMwareLogo,
    alt: 'VMware',
  },
];
