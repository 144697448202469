import { SvgIcon } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { isKeyClick } from '../../utils';
import { useGoToPreviousStep } from '../../hooks/useGoToPreviousStep';

interface IProps {
  handleGoToPreviousStep?: () => void;
  sx?: SystemStyleObject;
}

export const GoToPreviousStep: React.FC<IProps> = ({
  handleGoToPreviousStep,
  sx = {},
}) => {
  const { onGoToPreviousStep, hasPreviousStep } = useGoToPreviousStep({
    handleGoToPreviousStep,
  });

  return (
    <SvgIcon
      inheritViewBox
      component={ArrowBackRoundedIcon}
      sx={(theme) => ({
        color: hasPreviousStep ? 'brand.accent' : 'text.secondary',
        ml: -6,
        mr: 3,
        mt: 1,
        cursor: hasPreviousStep ? 'pointer' : 'initial',
        transition: '500ms',
        ':hover': {
          color: hasPreviousStep ? 'text.primary' : 'text.secondary',
        },
        [theme.breakpoints.between('md', 'lg')]: {
          ml: -4,
          mr: 1,
        },
        [theme.breakpoints.down('md')]: {
          ml: -1,
          mr: 1,
          mt: 0.25,
        },
        ...sx,
      })}
      onClick={onGoToPreviousStep}
      onKeyUp={(e: React.KeyboardEvent<any>) => {
        if (isKeyClick(e)) {
          onGoToPreviousStep();
        }
      }}
      role="button"
      tabIndex={0}
    />
  );
};

export default GoToPreviousStep;
