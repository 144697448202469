import React from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import MicNoneIcon from '@mui/icons-material/MicNone';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';

interface IProps {
  videoInputDevices: Array<{ label: string; deviceId: string }>;
  handleSelectVideoInput: (event: SelectChangeEvent) => void;
  currentVideoInput: string;
  audioInputDevices: Array<{ label: string; deviceId: string }>;
  handleSelectAudioInput: (event: SelectChangeEvent) => void;
  currentAudioInput: string;
  deviceIsBlocked: boolean;
}

const RecordInputsButtons: React.FC<IProps> = ({
  videoInputDevices,
  handleSelectVideoInput,
  currentVideoInput,
  audioInputDevices,
  handleSelectAudioInput,
  currentAudioInput,
  deviceIsBlocked,
}) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        justifyContent: 'space-between',
        width: '511px',
        marginTop: '20px',
        ['@media (max-width: 1200px)']: {
          width: '100%',
        },
        [theme.breakpoints.down('md')]: {
          display: 'none',
        },
      })}
    >
      <FormControl
        sx={{ width: '246px' }}
        disabled={deviceIsBlocked || !!!currentAudioInput}
      >
        {!!!currentAudioInput && (
          <InputLabel
            sx={{
              marginLeft: '30px',
            }}
          >
            Access required
          </InputLabel>
        )}
        <Select
          sx={{
            '.MuiSelect-select': {
              paddingTop: '16px !important',
              paddingLeft: '5px !important',
            },
          }}
          value={currentAudioInput}
          onChange={handleSelectAudioInput}
          startAdornment={
            <InputAdornment position="start">
              <MicNoneIcon />
            </InputAdornment>
          }
        >
          {audioInputDevices.map(({ label, deviceId }) => (
            <MenuItem key={deviceId} value={deviceId}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl
        sx={{ width: '246px' }}
        disabled={deviceIsBlocked || !!!currentVideoInput}
      >
        {!!!currentVideoInput && (
          <InputLabel
            sx={{
              marginLeft: '30px',
            }}
          >
            Access required
          </InputLabel>
        )}
        <Select
          sx={{
            '.MuiSelect-select': {
              paddingTop: '16px !important',
              paddingLeft: '5px !important',
            },
          }}
          value={currentVideoInput}
          label="Access required"
          onChange={handleSelectVideoInput}
          startAdornment={
            <InputAdornment position="start">
              <VideocamOutlinedIcon />
            </InputAdornment>
          }
        >
          {videoInputDevices.map(({ label, deviceId }) => (
            <MenuItem key={deviceId} value={deviceId}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default RecordInputsButtons;
