import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { APP_ROUTES } from '../constants';
import { useAppSelector } from '../redux';
import { userSelectors } from '../redux/user';
import { isStatusComplete, isStatusFailed } from '../utils';

interface IProps {
  children: JSX.Element;
}

const AccessibleRoutes = [APP_ROUTES.HOME, `/${APP_ROUTES.EMAIL}`];

const RouteGuard: React.FC<IProps> = ({ children }) => {
  const location = useLocation();
  const userData = useAppSelector(userSelectors.getUserData);
  const userApiStatus = useAppSelector(userSelectors.getUserApiStatus);

  if (
    !userData &&
    !AccessibleRoutes.includes(location.pathname) &&
    (isStatusComplete(userApiStatus) || isStatusFailed(userApiStatus))
  ) {
    return <Navigate to={APP_ROUTES.HOME} state={{ from: location }} replace />;
  }

  return isStatusComplete(userApiStatus) ||
    AccessibleRoutes.includes(location.pathname)
    ? children
    : null;
};

export default RouteGuard;
