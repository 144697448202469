import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import CssBaseline from '@mui/material/CssBaseline';
import { SnackbarProvider } from 'notistack';
import { setupStore } from './redux';
import { muiTheme } from './theme';
import App from './App';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { CookieConsentSnackbar } from './components';

Sentry.init({
  dsn: 'https://169fd3db0e9c4348b18e30feefd2228f@o1193549.ingest.sentry.io/6567309',
  environment: process.env.REACT_APP_ENV,
  integrations: [new BrowserTracing()],
  enabled: !/(local|test)/gi.test(process.env.REACT_APP_ENV || 'local'),
  tracesSampler: () =>
    !/(local|test)/.test(process.env.REACT_APP_ENV || 'local') ? 1 : 0,
});
const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  // <React.StrictMode>
  <Provider store={setupStore()}>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={muiTheme}>
        <CssBaseline />
        <SnackbarProvider
          autoHideDuration={null}
          maxSnack={1}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          hideIconVariant
        >
          <BrowserRouter>
            <App />
            <CookieConsentSnackbar />
          </BrowserRouter>
        </SnackbarProvider>
      </ThemeProvider>
    </LocalizationProvider>
  </Provider>,
  // </React.StrictMode>,
);
