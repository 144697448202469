import React from 'react';
import {
  Typography,
  Link,
  ListItem,
  Box,
  List,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
/**
 * Generated from https://docs.google.com/document/d/1uOk1-tHvZTOrfWF_VEZmKqOvsMFqfP4l9FmnKO7kabg
 * Download as .html & adapted for React
 */
export const LpTermsOfUseText = (): JSX.Element => {
  return (
    <Box
      component={'section'}
      pb={12}
      sx={{
        fontFamily:
          '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", sans-serif',
        fontWeight: 400,
        letterSpacing: 0,
        fontSize: '18px',
      }}
    >
      <Typography variant="h1" mb={2}>
        Terms of use
      </Typography>
      <p>
        AgileEngine websites, LaunchPod
        <br />
        Version 1.0 February 1, 2023
      </p>
      <List
        sx={{
          listStyle: 'upper-roman',
          my: 6,
        }}
      >
        <ListItem>
          <Link href="#preamble">Preamble</Link>
        </ListItem>
        <ListItem>
          <Link href="#definitions">Definitions</Link>
        </ListItem>
        <ListItem>
          <Link href="#rights">
            Rights and obligations of the website users
          </Link>
        </ListItem>
        <ListItem>
          <Link href="#communication">Communication with AgileEngine</Link>
        </ListItem>
        <ListItem>
          <Link href="#messages">
            Messages and newsletters from AgileEngine
          </Link>
        </ListItem>
        <ListItem>
          <Link href="#rights">Intellectual property rights</Link>
        </ListItem>
        <ListItem>
          <Link href="#data">Personal data</Link>
        </ListItem>
        <ListItem>
          <Link href="#cookies">Cookies policy</Link>
        </ListItem>
        <ListItem>
          <Link href="#responsibility">Responsibility</Link>
        </ListItem>
        <ListItem>
          <Link href="#misc">Miscellaneous</Link>
        </ListItem>
      </List>
      <Typography variant="h2" id="preamble">
        I. Preamble
      </Typography>
      <p>
        Terms of Use (hereinafter – ToU) provides information on the rules
        applicable to use of the Websites published by AgileEngine companies the
        rules applicable to processing of your personal data.
      </p>
      <p>
        ToU are not applicable to relationships between AgileEngine and the
        Users related to provision and order of AgileEngine positions and/or
        Services (the respective information is provided on AgileEngine
        Websites).
      </p>
      <p>
        ToU shall be followed both by AgileEngine and the Users. AgileEngine
        retains discretion to amend ToU in part or in full. ToU, its new version
        and the respective amendments are effective commencing publication
        on the Websites.
      </p>
      <p>
        By accessing the Websites the Users agree to comply with ToU presented
        in the version, which is effective at the moment of such access. Please
        comply with ToU, otherwise please terminate immediately the use of the
        Websites of AgileEngine.
      </p>
      <p>
        The rightsholder of all rights related to the Websites and the
        respective Content is AgileEngine.
      </p>
      <p>
        The Websites do not contain a comment posting option. To communicate
        with AgileEngine the Users may use the communicating options available
        on the AgileEngine Websites for this purpose.
      </p>
      <p>
        Access to AgileEngine Websites and the respective Services is provided
        exclusively for the Users over the age of 16 years-old.
      </p>
      <Typography variant="h2" id="definitions" mt={6}>
        II. Definitions
      </Typography>
      <p>
        <strong>AgileEngine</strong> means all companies related to AgileEngine
        LLC.
      </p>
      <p>
        <strong>Content</strong>
         means all static and dynamic elements, information, texts, images,
        audio and video files, databases, trademarks and logos, technical and
        graphic solutions inserted to the Websites.
      </p>
      <p>
        <strong>Cookies</strong>
        <span>
           mean text files sent from the Websites to the Users’ electronic
          devices and saved with web-browsers. Such files do not identify the
          Users, but fix information on their actions related to use of the
          Websites. Such information is collected for improvement of operation
          of the Websites and customization of the respective settings:
          monitoring preferences of the Users, “auto-fill” option, sharing
          information in social media, customization of Services to the Users’
          preferences, collection of general statistic information on use of the
          Websites.
        </span>
      </p>
      <p>
        <strong>Personal data</strong> means the personal data collected via the
        Websites, i.e. the information that directly or indirectly identifies
        the Users.
      </p>
      <p>
        <strong>Positions</strong>
        <span>
          &nbsp;means all opportunities with vacancies and different types of
          the engagement according to the requirements for the project.
        </span>
      </p>
      <p>
        <strong>Service(s)</strong>
        <span>
          &nbsp;means any service and related functions which are provided by
          AgileEngine in favor of the Users complimentary/for some fees
          available for order on the Websites (if applicable).
        </span>
      </p>
      <p>
        <strong>Terms of Use</strong>
        <span>&nbsp;means the rules governing use of the Websites.</span>
      </p>
      <p>
        <strong>User</strong>
        <span>
          &nbsp;means any natural person or legal entity gaining access,
          browsing or visiting the Websites with or without order of the
          Services.
        </span>
      </p>
      <p>
        <strong>Use of AgileEngine Websites</strong>
        <span>
          &nbsp;means any web-browsing, downloading or any other use of the
          Content of AgileEngine Websites, use of the Services provided by
          AgileEngine.
        </span>
      </p>
      <p>
        <strong>Websites</strong>
        <span>
          &nbsp;mean the websites published by AgileEngine (inclusively{' '}
          <Link href="https://launchpod.agileengine.com">
            launchpod.agileengine.com
          </Link>
        </span>
        ).
      </p>
      <Typography variant="h2" id="rights" mt={6}>
        III. Rights and obligations of the users of the websites
      </Typography>
      <p>The Websites can be used exclusively for the following purposes:</p>
      <List sx={{ mb: 3, mt: -1.5, maxWidth: '40ch' }}>
        <ListItem>
          to browse-up with the presented Content on Services of vacancies of
          AgileEngine;
        </ListItem>
        <ListItem>to order the Services of AgileEngine;</ListItem>
        <ListItem>to manage specifics on the Services of AgileEngine;</ListItem>
        <ListItem>
          to submit necessary information as a candidate on the position;
        </ListItem>
        <ListItem>
          to communicate AgileEngine with requests, comments, complaints and
          suggestions sent by messages via feedback form on the Websites, and
          further response from AgileEngine;
        </ListItem>
        <ListItem>
          for other purposes provided via the Websites of AgileEngine.
        </ListItem>
      </List>
      <p>
        It is illegitimate to use the Websites for the other purposes than
        mentioned above.
      </p>
      <p>The Users are obliged to the following:</p>
      <List sx={{ mb: 3, mt: -1.5, maxWidth: '40ch' }}>
        <ListItem>
          to follow ToU and the legislative provisions of the applicable law;
        </ListItem>
        <ListItem>
          to use the Websites exclusively for the purposes mentioned hereto;
        </ListItem>
        <ListItem>
          not to use in any way the Content of the Websites for commercial or
          non-commercial purposes, not to decompile the Websites, not to resort
          to reverse-engineering, to disassociate or attempts to interfere with
          the source code of the Websites without prior permission of
          AgileEngine;
        </ListItem>
        <ListItem>
          not to use the Websites for sending any messages of violent, abusive,
          disparaging nature or those that in any other way infringing the
          honor, dignity and business reputation of AgileEngine or third
          parties, as well as transmitting unsolicited commercial messages
          (“spam”);
        </ListItem>
        <ListItem>
          not to use software to interfere or attempt to interfere in operation
          of the Websites.
        </ListItem>
      </List>
      <Typography variant="h2" id="communication" mt={6}>
        IV. Communication with AgileEngine
      </Typography>
      <p>The Users may contact AgileEngine:</p>
      <List sx={{ mb: 3, mt: -1.5, maxWidth: '40ch' }}>
        <ListItem>
          via sending message (request or complaint) through the feedback form
          on the Websites;
        </ListItem>
        <ListItem>
          via sending emails on relevant emails on the Websites.
        </ListItem>
      </List>
      <p>
        To contact AgileEngine and get feedback the Users provide AgileEngine
        with the following scope of Personal data: name, surname, email address,
        telephone number, city and state of residence. Detailed information on
        collection and processing of Personal data as well as terms of transfer
        of Personal data to third parties is provided in the relevant section of
        the ToU and additionally on the main Website:
        <Link href="https://agileengine.com/privacy-policy/">
          agileengine.com/privacy-policy
        </Link>
      </p>
      <p>
        It is prohibited to file the following information via the Websites:
      </p>
      <List sx={{ mb: 3, mt: -1.5, maxWidth: '40ch' }}>
        <ListItem>
          any messages of a violent, abusive, disrespectful nature or any other
          messages, violating of the right to the honor, dignity and business
          reputation of AgileEngine or third parties;
        </ListItem>
        <ListItem>unsolicited commercial messages (“spam”).</ListItem>
      </List>
      <p>
        In case of sending such messages by the Users AgileEngine is entitled
        not to respond to such messages, as well as to deprive the Users to send
        such messages to use the feedback option further.
      </p>
      <p>
        Communication between the Users and AgileEngine is processed with
        technical fixing tools. The records of message exchange conducted
        between the Users and AgileEngine, are stored by AgileEngine to monitor
        the quality of services as well as to make subsequent changes
        to operations and Services of AgileEngine. The Users own any messages,
        information and materials sent via feedback forms on the Websites.
      </p>
      <Typography variant="h2" id="messages" mt={6}>
        V. Messages and newsletters from AgileEngine
      </Typography>
      <p>
        <strong>Messages from AgileEngine</strong>
      </p>
      <p>The Users may receive the following messages from AgileEngine:</p>
      <List sx={{ mb: 3, mt: -1.5, maxWidth: '40ch' }}>
        <ListItem>
          responding to the requests or complaints sent via feedback forms on
          {' '} the Websites;
        </ListItem>
        <ListItem>
          processing the Users’ position submissions and/or Services of{' '}
          AgileEngine, managing such submissions, providing replies Services of
          {' '} AgileEngine.
        </ListItem>
      </List>
      <p>
        AgileEngine sends SMS, emails, messages in messengers, messages in the
        feedback forms.
      </p>
      <p>
        The Users can manage the option of messages sending with AgileEngine
        Websites settings (depending on the functionality and the internal
        Websites settings).
      </p>
      <p>
        AgileEngine also provides newsletters to the Users of the Websites with
        information on positions and/or Services of AgileEngine.
      </p>
      <p>
        <strong>Newsletters</strong>
      </p>
      <p>
        Newsletters are sent exclusively to the contacts included to database of
        {' '} the Users’ Personal data previously provided to AgileEngine on
        {' '} the basis of consent on the Websites:
      </p>
      <List sx={{ mb: 3, mt: -1.5, maxWidth: '40ch' }}>
        <ListItem>via newsletter subscription;</ListItem>
        <ListItem>via submission on positions of AgileEngine.</ListItem>
      </List>
      <p>
        AgileEngine sends newsletters via SMS, emails, messengers, and messages
        in User account chat.
      </p>
      <p>
        Newsletters content is formed by AgileEngine in accordance with the
        Users’ preferences and based on their profile data.
      </p>
      <p>
        There is an option to unsubscribe from AgileEngine newsletters
        by following the link inserted to the message with such newsletter.
      </p>
      <Typography variant="h2" id="rights" mt={6}>
        VI. Intellectual property rights
      </Typography>
      <p>
        AgileEngine is an exclusive rightsholder of intellectual property rights
        and the related rights to the Content of the Websites (or the rights
        to use the Content were duly provided to AgileEngine by any third
        parties).
      </p>
      <p>
        It is prohibited to use the Content for commercial or non-commercial
        purposes fully or partially in any way (meaning reproduction,
        representation, adaptation, modification, publication, transmission,
        misrepresentation, integration to another web-site). If such use of the
        Content is necessary, AgileEngine should be requested via the feedback
        forms on the Websites.
      </p>
      <p>
        In case intellectual property rights to the Content of the Websites are
        violated by any third party, AgileEngine will take all necessary
        remedies including court action.
      </p>
      <Typography variant="h2" id="data" mt={6}>
        VII. Personal data
      </Typography>
      <p>
        AgileEngine collects and processes the Users’ Personal data for the
        purposes of due access to the Websites as well as provision AgileEngine
        positions and/or Services to the Users.
      </p>
      <p>
        Depending on the circumstances of data collection and processing,
        different companies affiliated to AgileEngine are controllers and
        processors of the Users’ Personal data. To receive information on  
        AgileEngine company acting as data controller or processor please send
        a request to AgileEngine data protection personnel at
        policy@agileengine.com (the response may be provided exclusively in
        respect of the requesting individual).
      </p>
      <p>
        AgileEngine does not collect or process Personal data of minors under 16
        years old.
      </p>
      <p>
        AgileEngine does not sell, rent, release, disclose, disseminate, make
        available, transfer, or otherwise communicate orally, in writing, or
        by electronic or other means, a Users’ Personal information to another
        business or a third party for monetary or other valuable consideration.
      </p>
      <p>
        AgileEngine does not collect or process the following categories of
        Personal data: racial or ethnic origin, political opinions, religious
        or philosophical beliefs, membership in political parties and trade
        unions, convictions, as well as health related data, sex life or sex
        orientation data, biometric or genetic data of Users (“sensitive
        personal data”).
      </p>
      <p>
        AgileEngine ensures to take all appropriate measures on Personal data
        protection considering provisions of applicable law on personal data
        protection (including GDPR, CCPA provisions, if applicable) and the
        specifics of AgileEngine business.
      </p>
      <p>
        <strong>Contacts</strong>
      </p>
      <p>
        For any questions on Personal data protection please send request or
        complaint to AgileEngine data protection personnel at
        <Link href="mailto:policy@agileengine.com">
          policy@agileengine.com
        </Link>{' '}
        or in the applicable form on the Websites.
      </p>
      <p>
        <strong>
          Categories of Personal data collected and processed by AgileEngine,
          purposes and legal grounds of Personal data collecting and processing
        </strong>
      </p>
      <p>
        The scope, method, purpose and legal ground for collecting and
        processing of Personal Data by AgileEngine may vary depending on the way
        of use of AgileEngine Websites by the Users as well as on the scope
        of Services requested by the Users.
      </p>
      <p>
        AgileEngine collects and processes the following categories of Personal
        data of the Users:
      </p>
      <List sx={{ mb: 3, mt: -1.5, maxWidth: '40ch' }}>
        <ListItem>
          name, surname, email address, mobile telephone number, city and state
          of residence, resume data;
        </ListItem>
        <ListItem>account data for the Websites (logins, passwords);</ListItem>
        <ListItem>“cookies”, technical data for AgileEngine Websites.</ListItem>
      </List>
      <p>
        AgileEngine also collects anonymized data, which is not Personal data
        (AgileEngine cannot identify any Users with such data). Anonymized data
        is collected and processed in consolidated form and is used for the
        purposes of marketing researches.
      </p>
      <p>
        There are the following legal grounds for collecting and processing of
        Personal data by AgileEngine via use of the Websites by the Users:
      </p>
      <List sx={{ mb: 3, mt: -1.5, maxWidth: '40ch' }}>
        <ListItem>
          consent to collection and processing of Personal data;
        </ListItem>
        <ListItem>fulfillment of AgileEngine contractual obligations;</ListItem>
        <ListItem>
          compliance with legal requirements applicable to AgileEngine;
        </ListItem>
        <ListItem>AgileEngine legal interest.</ListItem>
      </List>
      <p>
        AgileEngine collects and processes Personal data of the Users&rsquo; of
        the Websites for the following purposes:
      </p>
      <List sx={{ mb: 3, mt: -1.5, maxWidth: '40ch' }}>
        <ListItem>
          creating the Users&#39; accounts on AgileEngine Websites;
        </ListItem>
        <ListItem>
          providing access for the Users to AgileEngine Websites;
        </ListItem>
        <ListItem>
          submitting on positions, ordering AgileEngine positions and/or
          Services, managing such orders, providing AgileEngine positions and/or
          Services;
        </ListItem>
        <ListItem>
          tracking the preferences of the Users to provide customized
          information about AgileEngine positions and/or Services;
        </ListItem>
        <ListItem>
          providing responses to search and information requests of users about
          AgileEngine positions and/or Services;
        </ListItem>
        <ListItem>
          managing subscriptions to newsletter on AgileEngine positions and/or
          Services;
        </ListItem>
        <ListItem>use of feedback forms on AgileEngine Websites;</ListItem>
        <ListItem>
          providing information exchange from the Websites via e-mail or social
          networks;
        </ListItem>
        <ListItem>
          conducting marketing researches and analysis of trends for improvement
          of AgileEngine positions and/or Services;
        </ListItem>
        <ListItem>improving operations of AgileEngine Websites;</ListItem>
        <ListItem>
          providing information on AgileEngine positions and/or Services
          (newsletters, information on promotions and other marketing events).
        </ListItem>
      </List>
      <Table
        sx={{
          mb: 6,
          '& td': {
            verticalAlign: 'top',
            fontFamily: 'inherit',
            fontWeight: '400',
            fontSize: '14px',
          },
        }}
      >
        <TableBody>
          <TableRow>
            <TableCell colSpan={3}>
              <strong>
                Websites enabling order of AgileEngine positions/Services
              </strong>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <strong>Categories of Personal Data</strong>
            </TableCell>
            <TableCell>
              <strong>Purposes</strong>
            </TableCell>
            <TableCell>
              <strong>Legal grounds</strong>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              Name, surname, email address, mobile telephone number, city and
              state of residence
            </TableCell>
            <TableCell>
              <List sx={{ p: 0 }}>
                <ListItem>
                  creating the Users' accounts on AgileEngine Websites;
                </ListItem>
                <ListItem>
                  providing access for the Users to AgileEngine Websites;
                </ListItem>
                <ListItem>
                  ordering AgileEngine Services, managing such orders, providing
                  AgileEngine Services;
                </ListItem>
                <ListItem>submitting on positions of AgileEngine;</ListItem>
                <ListItem>
                  tracking the preferences of the Users to provide customized
                  information about AgileEngine positions and/or Services;
                </ListItem>
                <ListItem>
                  use of feedback forms on AgileEngine Websites
                </ListItem>
              </List>
            </TableCell>
            <TableCell>
              Consent to collection and processing of Personal data, fulfillment
              of contractual obligations of AgileEngine
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Account data</TableCell>
            <TableCell>
              <List sx={{ p: 0 }}>
                <ListItem>
                  providing access for the Users to AgileEngine Websites
                </ListItem>
              </List>
            </TableCell>
            <TableCell>
              Consent to collection and processing of Personal data, fulfillment
              of contractual obligations of AgileEngine
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>“cookies”, technical data</TableCell>
            <TableCell>
              <List sx={{ p: 0 }}>
                <ListItem>
                  tracking the preferences of the Users to provide customized
                  information about AgileEngine positions and/or Services;
                </ListItem>
                <ListItem>
                  providing responses to search and information requests
                  of users about AgileEngine positions and/or Services;
                </ListItem>
                <ListItem>
                  improving operations of AgileEngine Websites
                </ListItem>
              </List>
            </TableCell>
            <TableCell>
              Consent to collection and processing of Personal data
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={3}>
              <strong>Websites for informational and marketing purposes</strong>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              Name, surname, email address, mobile telephone number, city and
              country of living
            </TableCell>
            <TableCell>
              <List sx={{ p: 0 }}>
                <ListItem>
                  use of feedback forms on AgileEngine Websites;
                </ListItem>
                <ListItem>
                  providing information on AgileEngine positions and/or Services
                  (newsletters, information on promotions and other marketing
                  events)
                </ListItem>
              </List>
            </TableCell>
            <TableCell>
              Consent to collection and processing of Personal data
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>“cookies”, technical data</TableCell>
            <TableCell>
              <List sx={{ p: 0 }}>
                <ListItem>
                  providing information exchange from AgileEngine Websites via
                  email or social networking;
                </ListItem>
                <ListItem>
                  tracking the preferences of the Users to provide customized
                  information about AgileEngine positions and/or Services;
                </ListItem>
                <ListItem>
                  providing responses to search and information requests of
                  users about AgileEngine positions and/or Services
                </ListItem>
              </List>
            </TableCell>
            <TableCell>
              Consent to collection and processing of Personal Data
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <p>
        It is necessary to collect and process the Users’ Personal data for use
        for use of AgileEngine Websites as well as for provision of AgileEngine
        positions and/or Services.
      </p>
      <p>
        There is a log-in option for the Websites via social networks managed
        by third parties (depending on the functionality and internal settings
        of the Websites). By making such an entry, the Users hereby agree
        to provide AgileEngine with the shared access to Personal data to the
        scope requested under the settings on Personal data in the respective
        social network.
      </p>
      <p>
        AgileEngine processes each category of Personal data for the period
        necessary to fulfill each of the purposes hereto. After that, such
        Personal data would be deleted. In any case, AgileEngine is entitled
        to store personal data for a period necessary for fulfillment of its
        obligations in respect of the Users for providing AgileEngine positions
        and/or Services as well as to meet the obligations required by the
        applicable law.
      </p>
      <p>
        <strong>Users&rsquo; rights related to Personal data</strong>
      </p>
      <List sx={{ mb: 3, mt: -1.5 }}>
        <ListItem>right of access to Personal data;</ListItem>
        <ListItem>right to rectification of Personal data;</ListItem>
        <ListItem>
          right of access to methods of processing of Personal data;
        </ListItem>
        <ListItem>right to be informed on Personal data breaches;</ListItem>
        <ListItem>
          right on limited transferring of Personal data to third parties;
        </ListItem>
        <ListItem>
          right to revoke the consent to collection and processing of Personal
          data;
        </ListItem>
        <ListItem>
          right to restriction on collection and processing of Personal data;
        </ListItem>
        <ListItem>right to erasure of Personal data.</ListItem>
      </List>
      <p>
        Please submit a request or complaint on Personal data to the AgileEngine
        data protection personnel at: policy@agileengine.com or contact the
        competent data protection authorities.
      </p>
      <p>
        <strong>Transfer of Personal data to third parties</strong>
      </p>
      <p>
        AgileEngine transfers Personal data to third parties (including
        cross-border transfer of Personal data) exclusively in the scope
        necessary for the purposes of collection and processing of Personal data
        for which such Personal data was initially collected by AgileEngine.
      </p>
      <p>
        AgileEngine transfers Personal data exclusively in case of necessity,
        in a minimum scope, for a minimum period of time, which is necessary
        under such transfer.
      </p>
      <p>
        Due to fulfillment of the Positions and/or provision of Services,
        ensuring access to the Websites as well as improving of it and access
        AgileEngine may transfer Personal data to:
      </p>
      <List sx={{ mb: 3, mt: -1.5, maxWidth: '40ch' }}>
        <ListItem>affiliated companies of AgileEngine;</ListItem>
        <ListItem>partners of AgileEngine;</ListItem>
        <ListItem>Customers of AgileEngine;</ListItem>
        <ListItem>(sub)contractors of AgileEngine;</ListItem>
        <ListItem>
          third parties, in particular to state authorities (in case of
          compliance with the legislative provisions);
        </ListItem>
        <ListItem>
          third parties in case of any legal procedures related to
          reorganization of AgileEngine.
        </ListItem>
      </List>
      <p>
        AgileEngine should take all necessary legal, organizational and
        technical measures to ensure protection of Personal data.
      </p>
      <Typography variant="h2" id="cookies" mt={6}>
        VIII. Cookies policy
      </Typography>
      <p>
        Use of the Websites requires use of “cookies” installed on the Users'
        electronic devices.
      </p>
      <p>AgileEngine uses the following categories of “cookies”:</p>
      <List sx={{ mb: 3, mt: -1.5, maxWidth: '40ch' }}>
        <ListItem>
          to provide users with a consistent access to functionality of the
          Websites;
        </ListItem>
        <ListItem>to improve and facilitate use of the Websites;</ListItem>
        <ListItem>
          to represent correctly advertising messages of AgileEngine on the
          Websites, third party websites, social networks;
        </ListItem>
        <ListItem>
          to provide access of the Users to exchange of information from the
          Websites via social networks;
        </ListItem>
        <ListItem>
          to monitor the metrics of the audience of the Websites (traffic
          analysis, usage trends etc.).
        </ListItem>
      </List>
      <p>
        Prior to using “cookies” on the Websites, AgileEngine requests the
        Users’ consent to such use by sending the respective notification
        (in a banner form).
      </p>
      <p>
        There are the following options provided for the Users in respect
        of “cookies” settings:
      </p>
      <List sx={{ mb: 3, mt: -1.5, maxWidth: '40ch' }}>
        <ListItem>allow automatic storage and use of “cookies”;</ListItem>
        <ListItem>warn about “cookies” use;</ListItem>
        <ListItem>customize “cookies” use;</ListItem>
        <ListItem>
          block “cookies” use (in such a case there may be some restrictions
          on operation of the Websites, e.g. no option of customized settings
          etc.).
        </ListItem>
      </List>
      <p>
        When using the Websites, it is possible to use functional options of the
        other resources, in particular:
      </p>
      <List sx={{ mb: 3, mt: -1.5, maxWidth: '40ch' }}>
        <ListItem>
          social networks (there is an option of direct content sharing from the
          Websites) (depending on functionality and internal settings of the
          Websites). There are separate “cookies” policies of such companies;
        </ListItem>
        <ListItem>
          Google Analytics for collecting statistical information of general
          character on use of the Websites (such information is used by
          AgileEngine exclusively for improving the Content and provision of the
          Services ordered via the Websites). Operation of Google Analytics is
          governed by the respective policy of Google;
        </ListItem>
        <ListItem>
          other resources, which safety was checked by AgileEngine.
        </ListItem>
      </List>
      <p>
        You may also see advertisements of our Websites on third-party websites,
        such as social media. These ads may be tailored to you by cookies (which
        are tracking your web activity and allow us to show ads to users who
        have visited our Website). You can see ads on social media because
        we involved a social network to show ads to our customers or users that
        match the demographic profile of our customers or users. In some cases,
        this may involve the transfer of your email address, social network and
        phone number. If you no longer wish to see special ads, you may change
        your cookie and privacy settings in your browser and on third-party
        websites.
      </p>
      <p>
        You can see our ads on AgileEngine Websites and third-party applications
        and websites, as well as on the various devices you use. We can use this
        process to improve the effectiveness of marketing efforts for the user.
      </p>
      <p>
        Please note that in case the function of “cookies” use is blocked,
        it may result in improper operation of the Websites. There is an option
        to block the function of “cookies” use in part or in full (certain types
        of “cookies”) by modifying the settings of the web-browsers on devices
        used for access to the Websites.
      </p>
      <Typography variant="h2" id="responsibility" mt={6}>
        IX. Responsibility
      </Typography>
      <p>
        Breach of any ToU provisions will lead to bringing the Users to{' '}
        responsibility established under the ToU and the law applicable to{' '}
        regulation of such ToU.
      </p>
      <p>
        There are hyperlinks to third-party web resources inserted to the
        Websites. AgileEngine is not responsible for the content posted on such
        web resources, as well as for the collection and processing of Personal
        Data of Users, which may occur as a result of the use of such resources.
      </p>
      <p>
        AgileEngine takes every effort to provide the Users with secure,
        uninterrupted and correct access to the Websites. At the same time
        AgileEngine is not responsible for any interruptions in the work of the
        Websites, the incompatibility of such Websites with all types of the
        Users’ devices, and the inability of the Users to access to the Websites
        due to the absence of the Internet connection. AgileEngine retains
        discretion to restrict the Users’ access to the Websites in full
        or in part.
      </p>
      <p>
        AgileEngine is not liable for any damages arising for the Users from use
        of the Websites. Also, AgileEngine is not liable before any third
        parties for any Users’ actions committed with use of the Websites.
      </p>
      <Typography variant="h2" id="misc" mt={6}>
        X. Miscellaneous
      </Typography>
      <p>
        ToU is effective as of the moment of its publishing on the Websites.
      </p>
      <p>
        Any disputes arisen between AgileEngine and the Users related to use
        of the Websites are settled with negotiations held between the parties
        (such settlement should be initiated by the Users in question with
        communicating AgileEngine with the respective request) as{' '}
        well as in accordance with the applicable law.
      </p>
      <p>
        For any questions on use of the Websites please contact AgileEngine
        support at feedback form on the Websites. For the issues related to{' '}
        collection and processing of Personal data please contact AgileEngine
        data protection personnel at 
        <Link href="mailto:policy@agileengine.com">policy@agileengine.com</Link>
        .
      </p>
    </Box>
  );
};
