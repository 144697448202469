import { useMemo } from 'react';
import { useAppSelector } from 'src/redux';

import { userGeoLocationSelector } from 'src/redux/userGeoLocation';

import { useIsExpiredMagicLink } from '../../../hooks';

import { TOP_CLIENTS_WELCOME_PAGE_COUNTRY_CODES } from 'src/constants';

export const useSignInHooks = () => {
  const isExpiredMagicLink = useIsExpiredMagicLink();
  const userGeoLocation = useAppSelector(
    userGeoLocationSelector.getUserGeoLocation,
  )!;

  const topClientsWelcomePageCountries = useMemo(
    () =>
      TOP_CLIENTS_WELCOME_PAGE_COUNTRY_CODES.find(
        (countryCode) => countryCode === userGeoLocation?.country_code,
      ),
    [userGeoLocation],
  );

  // Welcome page variations.
  const showWelcomePageTopClients = topClientsWelcomePageCountries;
  const showWelcomeCountrySpecificTitle = !topClientsWelcomePageCountries;

  return {
    isExpiredMagicLink,
    showWelcomePageTopClients,
    showWelcomeCountrySpecificTitle,
  };
};
