import { css, SerializedStyles } from '@emotion/react';
import { useTheme } from '@mui/material';

const PB_BORDER_RADIUS = '0.5rem';
const PB_TRANSITION_LONG = 'all 600ms cubic-bezier(0.3, 0.7, 0.4, 1)';

export const useProgressButtonStyles = (
  color: 'primary' | 'secondary',
  fullWidth: boolean,
  maxWidth?: string,
): SerializedStyles => {
  const theme = useTheme();

  return css`
    position: relative;
    cursor: pointer;
    border: none;
    background: transparent;
    padding: 0;
    text-decoration: none;
    outline-offset: 4px;
    width: ${fullWidth ? '100%' : 'initial'};
    max-width: ${maxWidth ? maxWidth : 'initial'};

    .ae-progress-button_text {
      padding: 1rem 2rem;
      border-radius: ${PB_BORDER_RADIUS};
      background: ${theme.palette.button[color].front};
      will-change: transform;
      transition: ${PB_TRANSITION_LONG};
      color: ${theme.palette.button[color].text};
      font-size: 1rem;
      font-weight: 600;
      font-family: ${theme.typography.fontFamily};
    }

    .linear-progress {
      position: absolute;
      top: 0;
      height: 100%;
      border-radius: ${PB_BORDER_RADIUS};
      background-color: ${theme.palette.brand.secondary};
      width: 0%;
      z-index: 1;
      transition: width 0.5s ease-in-out;
    }

    &::after {
      content: attr(data-label);
      position: absolute;
      z-index: 2;
      width: 100%;
      height: 100%;
      text-align: center;
      top: 0;
      left: 0;
      padding-top: inherit;
      color: ${theme.palette.button[color].text};
      font-size: 1rem;
      font-weight: 600;
      font-family: ${theme.typography.fontFamily};
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `;
};
