import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiEndpoints } from '../../constants';
import { authStoreKey } from './auth.const';
import { setAuthUserEmailSent } from './auth.slice';
import { fetchUser } from '../user';
import { LocalStorageService } from '../../services';
import { IEmailBody } from '../../types/email';
import { ICandidateSource, IMassCampaign, IPositionData } from '@types';

export const authUser = createAsyncThunk(
  `${authStoreKey}/authUser`,
  async (
    body: IEmailBody & {
      zohoId: string | null;
      position: IPositionData | null;
      massCampaignData: IMassCampaign | null;
      candidateSource: ICandidateSource | null;
    },
    thunkAPI,
  ) => {
    const response = await axios.post(apiEndpoints.authUser(), body, {
      withCredentials: false,
    });
    if (response.data.userExists) {
      thunkAPI.dispatch(setAuthUserEmailSent(true));
    } else {
      await thunkAPI.dispatch(fetchUser());
    }
    return response.data;
  },
);

export const logout = createAsyncThunk(
  `${authStoreKey}/logout`,
  async (redirectTo?: string) => {
    try {
      const response = await axios
        .get(apiEndpoints.logout(), {
          withCredentials: true,
        })
        .then((r) => {
          LocalStorageService.clearState();
          window.location.href = redirectTo ?? '/';
          return r;
        });

      return response.data;
    } catch (err: any) {
      throw new Error(err);
    }
  },
);
