/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux';

import { Box, Typography } from '@mui/material';
import { CustomButton, PageTransitionWrapper } from '../../components';

import {
  resolveUserDuplicationSelectors,
  selectMainUser,
} from '../../redux/resolveUserDuplication';
import { userSelectors } from '../../redux/user';

import confirmEmailImage from '../../assets/confirm-email.webp';
import confirmEmailImagePNG from '../../assets/confirm-email.png';
import {
  isProceedWith,
  PROCEED_WITH_RESPONSE,
  ProceedWithResponse,
} from '../../constants/proceedWithResponse';

const ResolveUserDuplication: React.FC = () => {
  const dispatch = useAppDispatch();

  const { token } = useParams();
  const [proceedWith, setProceedWith] = useState<ProceedWithResponse | null>(
    null,
  );
  const userData = useAppSelector(userSelectors.getUserData);
  const isLoading = useAppSelector(
    resolveUserDuplicationSelectors.getIsLoading,
  );

  const handleClick = (
    useLatestResponse: boolean,
    proceed: ProceedWithResponse,
  ) => {
    setProceedWith(proceed);
    dispatch(selectMainUser({ token, useLatestResponse }));
  };

  return (
    <PageTransitionWrapper>
      <Box data-testid="auth-user-confirmation">
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
          <picture>
            <source srcSet={confirmEmailImage} type="image/webp" />
            <source srcSet={confirmEmailImagePNG} type="image/png" />
            <img
              src={confirmEmailImage}
              alt="Confirm email"
              width="140px"
              height="116px"
            />
          </picture>
        </Box>
        <Typography
          variant="h1"
          textAlign="center"
          sx={{ maxWidth: '50ch', m: '1rem auto' }}
        >
          We've got 2 responses from you
        </Typography>
        <Typography
          variant="body1"
          textAlign="center"
          mb={4}
          sx={{ maxWidth: '50ch', marginLeft: 'auto', marginRight: 'auto' }}
        >
          We found one more response from your {userData?.email} which you
          provided previously. Do you want to proceed with current response or
          previous one?
        </Typography>
        <Box display="flex" justifyContent="center" mb={3}>
          <CustomButton
            isLoading={isProceedWith.current(proceedWith) && isLoading}
            onClick={() => handleClick(true, PROCEED_WITH_RESPONSE.CURRENT)}
            label="Proceed with current response"
            testid="proceed-with-current-response"
          />
        </Box>
        <Box display="flex" justifyContent="center" mb={4}>
          <CustomButton
            isLoading={isProceedWith.previous(proceedWith) && isLoading}
            onClick={() => handleClick(false, PROCEED_WITH_RESPONSE.PREVIOUS)}
            label="Proceed with previous response"
            testid="proceed-with-previous-response"
            color="secondary"
          />
        </Box>
      </Box>
    </PageTransitionWrapper>
  );
};

export default ResolveUserDuplication;
