import { useState } from 'react';
import { BasicDialog, CustomButton } from '../../../../components';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Stack,
  SvgIcon,
  Typography,
} from '@mui/material';
import ClockIcon from '../../../../assets/clock-icon.png';
import { ReactComponent as CodilityIcon } from '../../../../assets/codility-icon.svg';
import { logEvent } from 'src/services';

interface IProps {
  showTTDialog: boolean;
  setShowTTDialog: React.Dispatch<React.SetStateAction<boolean>>;
  handleCreateTestTask: (simulated: boolean) => void;
  createTTIsLoading: boolean;
}

export const CodilityTTDialog: React.FC<IProps> = ({
  showTTDialog,
  setShowTTDialog,
  handleCreateTestTask,
  createTTIsLoading,
}) => {
  const [simulated, setSimulated] = useState(false);

  const onCreateTTClick = () => {
    handleCreateTestTask(simulated);
  };

  return (
    <BasicDialog
      isOpen={showTTDialog}
      handleClose={() => {
        logEvent('launchpod-continue-to-tt-confirmation-popup-closed');
        setShowTTDialog(false);
      }}
    >
      <Box display="flex" justifyContent="center">
        <img src={ClockIcon} alt="time-limited interview indicator" />
      </Box>
      <Typography variant="h2" textAlign="center" paddingTop={4}>
        Ready?
      </Typography>
      <Typography variant="body1" textAlign="center" marginBottom={4}>
        The test is going to take{' '}
        <Box
          component="br"
          sx={(theme) => ({
            [theme.breakpoints.down('md')]: {
              display: 'none',
            },
          })}
        />
        <b>30 – 60 minutes or less if you do it faster.</b>
        <Box component="br" />
        <Box
          component="br"
          sx={(theme) => ({
            [theme.breakpoints.down('md')]: {
              display: 'none',
            },
          })}
        />
        You have one attempt in 6 months.
      </Typography>
      <Stack spacing={2} display="flex" direction="column" alignItems="center">
        {/* Checkbox to simulate test task behavior without calling the codility to save money
        Should be available only in dev envs */}
        {process.env.REACT_APP_ENV !== 'production' && (
          <FormControlLabel
            control={
              <Checkbox
                onChange={() => setSimulated(!simulated)}
                checked={simulated}
              />
            }
            label={
              <Typography variant="body1" sx={{ whiteSpace: 'nowrap' }}>
                Bypass Codility
              </Typography>
            }
            sx={{ mr: 0 }}
          />
        )}
        <CustomButton
          color="primary"
          label="Proceed to the test"
          startIcon={<SvgIcon inheritViewBox component={CodilityIcon} />}
          onClick={onCreateTTClick}
          isDisabled={false}
          isLoading={createTTIsLoading}
          fullWidth
        />
      </Stack>
    </BasicDialog>
  );
};
