import { createSlice } from '@reduxjs/toolkit';
import { gamifiedResponseStoreKey } from './gamifiedResponse.const';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface IGamifiedResponseSliceState {
  lastMessage: string | null;
}

const initialState: IGamifiedResponseSliceState = {
  lastMessage: null,
};

export const gamifiedResponseSlice = createSlice({
  name: gamifiedResponseStoreKey,
  initialState,
  reducers: {
    updateMessage(state, action: PayloadAction<string | null>) {
      state.lastMessage = action.payload;
    },
  },
});

export const { updateMessage } = gamifiedResponseSlice.actions;
