import { useMemo } from 'react';
import { APP_ROUTES } from '../constants';
import { useAppSelector } from '../redux';
import { userSelectors } from '../redux/user';
import { useIsPage } from './useIsPage';

export const useIsQuestioneerStepper = (): boolean => {
  const userData = useAppSelector(userSelectors.getUserData);
  const isEmailPage = useIsPage(APP_ROUTES.EMAIL);
  const isProfileReviewPage = useIsPage(APP_ROUTES.PROFILE_REVIEW);
  const isLongTimeNoSeePage = useIsPage(APP_ROUTES.LONG_TIME_NO_SEE);

  const isQuestioneerStepper = useMemo(() => {
    if (!userData) return false;

    return (
      !userData.profileSubmittedAt &&
      !isProfileReviewPage &&
      !isEmailPage &&
      !isLongTimeNoSeePage
    );
  }, [userData, isEmailPage, isProfileReviewPage, isLongTimeNoSeePage]);

  return isQuestioneerStepper;
};
