import React from 'react';
import { Typography } from '@mui/material';

const NotSupportedFailedInterviewMessage: React.FC = () => {
  return (
    <>
      <Typography variant="body1" data-testid="success-text">
        Thank you for your interest in AgileEngine and passing all the steps of
        the journey. Unfortunately, our current projects don't appear to match
        your profile. We will reach out to you once we have something exciting,
        so please stay tuned!
      </Typography>
      <Typography variant="body1">Have a great day!</Typography>
    </>
  );
};

export default NotSupportedFailedInterviewMessage;
