export const NOTICE_PERIOD_LIST = [
  {
    value: 1,
    label: "I'm available now",
  },
  {
    value: 2,
    label: '1 week after offer',
  },
  {
    value: 3,
    label: '2-4 weeks',
  },
  {
    value: 4,
    label: '5-8 weeks',
  },
  {
    value: 5,
    label: '9+ weeks',
  },
];

export const NOTICE_PERIOD_MAP: Record<number, string> = {
  1: 'Available now',
  2: '1 week after offer',
  3: '2-4 weeks',
  4: '5-8 weeks',
  5: '9+ weeks',
};
