export const primarySkillsMessage = (props: {
  skills: string[] | null;
}): string | null => {
  const { skills } = props;
  const aeLovedTechs = 'Java';
  if (!skills) {
    return null;
  }
  const lastTechSelected = skills[skills.length - 1];
  const isLovedTech = lastTechSelected === aeLovedTechs;
  if (isLovedTech) {
    return `We love ${lastTechSelected} too!`;
  } else if (skills.length === 3) {
    return `${skills[0]}, ${skills[1]}, and ${skills[2]} — what a combo!`;
  } else if (skills.length > 3 || skills.length === 0) {
    return 'Please select up to 3';
  } else {
    return 'Got it! 👌';
  }
};
