import React from 'react';
import {
  AutocompleteRenderInputParams,
  styled,
  TextField,
  Tooltip,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { ICountry } from '../../../types';

interface IProps {
  params: AutocompleteRenderInputParams;
  inputRef: React.MutableRefObject<HTMLInputElement | null>;
  countrySelected: ICountry | null;
  locationIsDetected: boolean;
  isSelected?: boolean | false;
}

const SelectableTextField = styled(TextField, {
  shouldForwardProp: (prop) => !['isSelected'].includes(String(prop)),
})<{ isSelected: boolean }>(({ theme, isSelected }) => ({
  alignItems: 'center',
  borderRadius: theme.shape.borderRadius,
  background: isSelected
    ? theme.palette.highlight.accent
    : theme.palette.highlight.white,
  '.MuiOutlinedInput-root': {
    paddingRight: '6px !important',
  },
}));

const AutocompleteTextField: React.FC<IProps> = ({
  params,
  inputRef,
  countrySelected,
  locationIsDetected,
  isSelected = false,
}) => {
  return (
    <SelectableTextField
      {...params}
      isSelected={isSelected}
      inputRef={inputRef}
      placeholder="Other"
      autoComplete="off"
      InputProps={{
        ...params.InputProps,
        startAdornment: (
          <SearchIcon
            fontSize="small"
            sx={(theme) => ({
              marginLeft: '1rem',
              color: theme.palette.text.secondary,
            })}
          />
        ),
        endAdornment: (
          <>
            {countrySelected?.name && isSelected && locationIsDetected && (
              <Tooltip title="Based on your location" placement="top">
                <MyLocationIcon
                  fontSize="small"
                  sx={(theme) => ({
                    marginLeft: '0.5rem',
                    color: theme.palette.text.secondary,
                  })}
                />
              </Tooltip>
            )}
            {params.InputProps.endAdornment}
          </>
        ),
        sx: { fontWeight: '600' },
      }}
    />
  );
};

export default AutocompleteTextField;
