import React, {
  KeyboardEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Autocomplete,
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../redux';
import { updateCountry, userSelectors } from '../../redux/user';
import { userGeoLocationSelector } from '../../redux/userGeoLocation';
import { ICountry } from '../../types';
import { getUserPrefferedName } from '../../utils';
import { APP_ROUTES, BASE_CURRENCY, Countries } from '../../constants';
import { useLPLocation } from '../../hooks';
import {
  GoToNextStep,
  PageTransitionWrapper,
  RadioButton,
  ResponsiveBreak,
  SelectableWrapper,
  StickyPanel,
} from '../../components';
import {
  gamifiedResponseSelectors,
  gamifiedResponseSlice,
} from '../../redux/gamifiedResponse';
import { countryMessage } from './components/countryMessage';
import AutocompleteCountryOption from './components/AutocompleteCountryOption';
import AutocompleteTextField from './components/AutocompleteTextField';
import GoToPreviousStep from '../../components/goToPreviousStep/GoToPreviousStep';
import {
  NOT_AUTHORIZED_TO_WORK_IN_US_TYPE,
  REQUIRE_VISA_SPONSORSHIP,
} from '../../constants/notAuthorizedToWorkInUs';
import { validationSchema } from './components/validationSchema';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { logEvent } from 'src/services';
import { useFormik } from 'formik';

const STEP_MAX_WIDTH = '33.25rem';
const CONTAINER_MAX_WIDTH = '42rem';

const notAuthorizedToWorkInUsOptions = [
  { type: NOT_AUTHORIZED_TO_WORK_IN_US_TYPE.YES, label: 'Yes' },
  { type: NOT_AUTHORIZED_TO_WORK_IN_US_TYPE.NO, label: 'No' },
];

const requireVisaSponsorshipOptions = [
  { type: REQUIRE_VISA_SPONSORSHIP.YES, label: 'Yes' },
  { type: REQUIRE_VISA_SPONSORSHIP.NO, label: 'No' },
];

type CountryFormValues = {
  country: ICountry | null;
  notAuthorizedToWorkInUs: NOT_AUTHORIZED_TO_WORK_IN_US_TYPE | null;
  requireVisaSponsorship: REQUIRE_VISA_SPONSORSHIP | null;
  isConsentChecked: boolean;
};

const Country = (): React.ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userData = useAppSelector(userSelectors.getUserData)!;
  const userGeoLocation = useAppSelector(
    userGeoLocationSelector.getUserGeoLocation,
  )!;
  const lastGamifiedMessage = useAppSelector(
    gamifiedResponseSelectors.getGamifiedResponse,
  );
  const isLoading = useAppSelector(userSelectors.getIsUserPerformingAction);
  const { countries, suggestedCountries } = useLPLocation();

  const [detectedLocation, setDetectedLocation] = useState<ICountry | null>(
    null,
  );
  const [showLocationWarning, setShowLocationWarning] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const formik = useFormik<CountryFormValues>({
    initialValues: {
      country: null,
      notAuthorizedToWorkInUs: null,
      requireVisaSponsorship: null,
      isConsentChecked: false,
    },
    validationSchema,
    onSubmit: () => handleSubmit(),
  });

  useEffect(() => {
    if (userData.country) {
      dispatch(gamifiedResponseSlice.actions.updateMessage(null));
    } else {
      if (!lastGamifiedMessage) {
        dispatch(
          gamifiedResponseSlice.actions.updateMessage(
            `Welcome back, ${getUserPrefferedName(userData)}!`,
          ),
        );
      }
    }

    const payload: CountryFormValues = formik.initialValues;

    if (userData.country) {
      let countryObject = null;
      countryObject = countries.find(
        (countryInfo: ICountry) => countryInfo.name === userData.country,
      );
      payload.country = countryObject ?? null;
    } else {
      const countryInfo = countries.find(
        (country: ICountry) =>
          country.code.toLowerCase() ===
          userGeoLocation?.country_code?.toLowerCase(),
      );

      if (countryInfo) {
        payload.country = {
          currency: countryInfo?.currency ?? BASE_CURRENCY,
          code: countryInfo?.code ?? '',
          name: countryInfo?.name ?? '',
          phone: countryInfo?.phone ?? '',
        };
        dispatch(
          gamifiedResponseSlice.actions.updateMessage(
            countryMessage({
              countrySelected: userGeoLocation.country_name,
              preferedName: getUserPrefferedName(userData),
            }),
          ),
        );
      }
      setDetectedLocation(payload.country);
    }

    if (userData.notAuthorizedToWorkInUs !== null) {
      payload.notAuthorizedToWorkInUs = Number(
        !userData.notAuthorizedToWorkInUs,
      );
    }

    if (userData.requireVisaSponsorship !== null) {
      payload.requireVisaSponsorship = Number(userData.requireVisaSponsorship);
    }

    formik.setValues(payload);

    logEvent('launchpod-country-page-loaded');
  }, [userGeoLocation]);

  const handleChangeOption = (value: NOT_AUTHORIZED_TO_WORK_IN_US_TYPE) => {
    formik.setFieldValue('notAuthorizedToWorkInUs', value);
    formik.setFieldTouched('requireVisaSponsorship', false);
  };

  const handleChangeVisaSponsorship = (value: REQUIRE_VISA_SPONSORSHIP) => {
    formik.setFieldValue('requireVisaSponsorship', value);
  };

  const handleChangeIsConsent = () => {
    formik.setFieldValue('isConsentChecked', !formik.values.isConsentChecked);
  };

  const getNotAuthorizedToWorkInUs = (country: string) => {
    return country === Countries.UnitedStates
      ? formik.values.notAuthorizedToWorkInUs ===
          NOT_AUTHORIZED_TO_WORK_IN_US_TYPE.NO
      : null;
  };

  const getRequireVisaSponsorship = (country: string) => {
    return country === Countries.UnitedStates
      ? formik.values.requireVisaSponsorship === REQUIRE_VISA_SPONSORSHIP.YES
      : null;
  };

  const handleUpdateCountry = (country: string): void => {
    setTimeout(async () => {
      const countryCode =
        countries.find((entry) => entry.name === country)?.code || 'US';
      if (
        country === Countries.UnitedStates &&
        (formik.values.notAuthorizedToWorkInUs === null ||
          formik.values.requireVisaSponsorship === null ||
          formik.values.isConsentChecked === false)
      )
        return;

      await dispatch(
        updateCountry({
          country,
          notAuthorizedToWorkInUs: getNotAuthorizedToWorkInUs(country),
          requireVisaSponsorship: getRequireVisaSponsorship(country),
        }),
      );

      if (
        userData.country &&
        countries.find((country) => country.code === countryCode)?.isSuggested
      ) {
        navigate(`/${APP_ROUTES.CITY}`);
      }
    }, 500);
  };

  const handleSubmit = (): void => {
    logEvent('launchpod-country-continue-click', {
      'Country Selected': formik.values.country?.name,
    });
    dispatch(
      gamifiedResponseSlice.actions.updateMessage(
        countryMessage({
          countrySelected: formik.values.country!.name,
          preferedName: getUserPrefferedName(userData),
        }),
      ),
    );

    handleUpdateCountry(formik.values.country!.name);
  };

  const handleAutocompleteChange = (
    _event: any,
    newValue: ICountry | null,
  ): void => {
    logEvent('launchpod-country-selected-from-input', {
      'Country Selected': newValue?.name,
    });
    if (newValue && newValue !== formik.values.country) {
      dispatch(
        gamifiedResponseSlice.actions.updateMessage(
          countryMessage({
            countrySelected: newValue.name,
            preferedName: getUserPrefferedName(userData),
          }),
        ),
      );

      if (detectedLocation?.code === newValue?.code) {
        handleUpdateCountry(newValue.name);
      } else {
        setShowLocationWarning(true);
      }
    }
    formik.setFieldValue('country', newValue);
  };

  const handleChange = (countryLabel: string): void => {
    const countryObject = countries.find(
      (countryInfo: ICountry) => countryInfo.name === countryLabel,
    );

    logEvent('launchpod-country-selected-from-suggestion', {
      'Country Selected': countryLabel,
    });

    formik.setFieldValue('country', countryObject ?? null);
    dispatch(
      gamifiedResponseSlice.actions.updateMessage(
        countryMessage({
          countrySelected: countryLabel,
          preferedName: getUserPrefferedName(userData),
        }),
      ),
    );
    if (detectedLocation?.code === countryObject?.code) {
      handleUpdateCountry(countryLabel);
    } else {
      setShowLocationWarning(true);
    }
  };

  const isDetectedCountry = (
    countryCode: ICountry | undefined | null,
  ): boolean => detectedLocation?.code === countryCode?.code;

  const autocompleteValue = useMemo(() => {
    const fromTheList = suggestedCountries.some(
      (country: ICountry) => country.code === formik.values.country?.code,
    );
    return fromTheList ? null : formik.values.country;
  }, [formik.values.country]);

  return (
    <PageTransitionWrapper>
      <Box display="flex" data-testid="country-layout">
        <GoToPreviousStep />
        <Typography variant="h2">
          What's your country
          <br />
          of residence?
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Box maxWidth={CONTAINER_MAX_WIDTH}>
          <Grid
            container
            sx={{
              flexFlow: 'column',
              maxHeight: { lg: '20rem' },
              flexWrap: 'wrap',
            }}
            gap="0.25rem"
          >
            {suggestedCountries.map((countryObject) => {
              return (
                <Grid item key={countryObject.name} xs={12}>
                  <SelectableWrapper
                    value={countryObject.name}
                    selectedValue={formik.values.country?.name}
                    onClick={handleChange}
                    sx={{
                      justifyContent: 'space-between',
                      padding: '0.875rem 1.5rem 1rem',
                    }}
                    testid={`select-${countryObject.name.replace(/ /g, '-')}`}
                  >
                    <Box display="flex" alignItems="center">
                      <img
                        loading="lazy"
                        width="20"
                        height="15"
                        src={`https://flagcdn.com/w20/${countryObject.code.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${countryObject.code.toLowerCase()}.png 2x`}
                        alt={`${countryObject.name} flag`}
                      />
                      <Typography
                        variant="body1"
                        sx={{ marginLeft: '0.5rem', fontWeight: '500' }}
                      >
                        {countryObject.name}
                      </Typography>
                    </Box>
                    {isDetectedCountry(countryObject) ? (
                      <Tooltip title="Based on your location" placement="top">
                        <MyLocationIcon
                          fontSize="small"
                          sx={(theme) => ({
                            [theme.breakpoints.down('lg')]: {
                              marginLeft: 'auto',
                            },
                            marginLeft: '0.5rem',
                            color: theme.palette.text.secondary,
                          })}
                        />
                      </Tooltip>
                    ) : (
                      <></>
                    )}
                  </SelectableWrapper>
                </Grid>
              );
            })}
            <Grid item xs={12}>
              <Autocomplete
                fullWidth
                forcePopupIcon={false}
                value={autocompleteValue}
                options={countries}
                getOptionLabel={(option) => option.name as string}
                renderOption={(props, option) => (
                  <AutocompleteCountryOption
                    key={option.code}
                    props={props}
                    option={option}
                  />
                )}
                PaperComponent={({ children }) => (
                  <Paper
                    sx={(theme) => ({
                      background: theme.palette.highlight.actionable,
                    })}
                  >
                    {children}
                  </Paper>
                )}
                clearIcon={null}
                componentsProps={{
                  clearIndicator: { disabled: true },
                  popper: { style: { width: '20rem' } },
                }}
                renderInput={(params) => (
                  <AutocompleteTextField
                    params={params}
                    inputRef={inputRef}
                    countrySelected={formik.values.country}
                    locationIsDetected={isDetectedCountry(
                      formik.values.country,
                    )}
                    isSelected={
                      Boolean(formik.values.country) &&
                      autocompleteValue?.code === formik.values.country?.code &&
                      isDetectedCountry(formik.values.country)
                    }
                  />
                )}
                onChange={handleAutocompleteChange}
                isOptionEqualToValue={(option, value) =>
                  option.name === value?.name
                }
                onKeyUp={(e: KeyboardEvent) => e.stopPropagation()}
              />
            </Grid>
          </Grid>
        </Box>
        {showLocationWarning && (
          <Box
            sx={(theme) => ({
              backgroundColor: theme.palette.highlight.actionable,
              border: `10px solid #0000000D`,
              borderRadius: '10px',
              marginTop: '2rem',
              boxSizing: 'border-box',
              padding: '1.25rem',
              maxWidth: CONTAINER_MAX_WIDTH,
            })}
          >
            <Typography variant="body1" fontWeight="500">
              You must be physically located in the position’s location.
              <ResponsiveBreak breakpoint="xs" />
              No relocation is offered.
            </Typography>
          </Box>
        )}
        {formik.values.country?.name === Countries.UnitedStates ? (
          <Box maxWidth={STEP_MAX_WIDTH}>
            <Typography variant="body1" mt="2rem" gutterBottom>
              Are you legally authorized to work in the US?
            </Typography>
            <Grid container columnSpacing={1}>
              {notAuthorizedToWorkInUsOptions.map((option) => {
                return (
                  <Grid
                    item
                    xs={6}
                    key={option.type}
                    data-testid={`option-${option.type}`}
                  >
                    <SelectableWrapper
                      value={option.type}
                      selectedValue={formik.values.notAuthorizedToWorkInUs}
                      onClick={handleChangeOption}
                    >
                      <FormControlLabel
                        checked={
                          option.type === formik.values.notAuthorizedToWorkInUs
                        }
                        value={option.type}
                        control={<RadioButton tabIndex={-1} />}
                        label={option.label}
                      />
                    </SelectableWrapper>
                  </Grid>
                );
              })}
            </Grid>
            {formik.touched.notAuthorizedToWorkInUs &&
              formik.errors.notAuthorizedToWorkInUs && (
                <FormHelperText
                  sx={(theme) => ({
                    color: theme.palette.error.main,
                  })}
                >
                  {formik.errors.notAuthorizedToWorkInUs}
                </FormHelperText>
              )}
            {formik.values.notAuthorizedToWorkInUs !== null && (
              <>
                <Typography variant="body1" mt="2rem" gutterBottom>
                  Do you now, or will you in the future, require sponsorship for
                  employment visa status (e.g., H-1B visa, etc.) to work legally
                  for our company AgileEngine, LLC in the USA?
                </Typography>
                <Grid container columnSpacing={1}>
                  {requireVisaSponsorshipOptions.map((option) => {
                    return (
                      <Grid
                        item
                        xs={6}
                        key={option.type}
                        data-testid={`option-${option.type}`}
                      >
                        <SelectableWrapper
                          value={option.type}
                          selectedValue={formik.values.requireVisaSponsorship}
                          onClick={handleChangeVisaSponsorship}
                        >
                          <FormControlLabel
                            checked={
                              option.type ===
                              formik.values.requireVisaSponsorship
                            }
                            value={option.type}
                            control={<RadioButton tabIndex={-1} />}
                            label={option.label}
                          />
                        </SelectableWrapper>
                      </Grid>
                    );
                  })}
                </Grid>
                {formik.touched.requireVisaSponsorship &&
                  formik.errors.requireVisaSponsorship && (
                    <FormHelperText
                      sx={(theme) => ({
                        color: theme.palette.error.main,
                      })}
                    >
                      {formik.errors.requireVisaSponsorship}
                    </FormHelperText>
                  )}
              </>
            )}
          </Box>
        ) : null}

        {formik.values.requireVisaSponsorship !== null && (
          <>
            <Box
              sx={(theme) => ({
                backgroundColor: theme.palette.highlight.actionable,
                border: `10px solid #0000000D`,
                borderRadius: '10px',
                marginTop: '30px',
                maxWidth: { lg: '41rem' },
              })}
            >
              <Box padding={'30px'}>
                <Typography variant="body1" fontWeight="500">
                  Applicants must be authorized to work for ANY employer in the
                  US
                </Typography>
                <Typography
                  variant="body1"
                  fontWeight="500"
                  color="text.secondary"
                >
                  i.e. Green card holders, TN visa holders, GC EAD, H4 EAD, U4U
                  with EAD.
                </Typography>
                <Typography
                  variant="body1"
                  fontWeight="500"
                  mt="2rem"
                  sx={{ whiteSpace: 'pre-line' }}
                >
                  We are unable to sponsor or take over sponsorship{'\n'}
                  of an employment Visa at this time.
                </Typography>
              </Box>

              <Divider
                variant="fullWidth"
                sx={{
                  border: 'none',
                  height: '1px',
                  background:
                    'repeating-linear-gradient(to right, gray 0, gray 4px, transparent 4px, transparent 8px)',
                }}
              />

              <Box padding={'30px'}>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  Please confirm
                </Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleChangeIsConsent}
                      name={'isConsentChecked'}
                      checked={formik.values.isConsentChecked}
                    />
                  }
                  label={
                    <Box>
                      <Stack justifyContent="space-between" gap="0.25rem">
                        <Typography variant="body1" fontWeight="500">
                          I hereby declare that the information provided is true
                          and correct.
                        </Typography>
                      </Stack>
                    </Box>
                  }
                  sx={{ alignItems: 'start' }}
                />

                <Typography
                  variant="body1"
                  fontWeight="500"
                  color="text.secondary"
                  mt={1}
                  sx={{ whiteSpace: 'pre-line' }}
                >
                  I also understand that any willful dishonesty may lead to
                  refusal{'\n'}
                  of this application or immediate termination of employment.
                </Typography>
              </Box>
            </Box>
          </>
        )}
      </form>
      <StickyPanel delayCalculation={100}>
        <GoToNextStep
          isDisabled={!formik.isValid}
          isLoading={isLoading}
          textIdentifier={1}
          handleSubmitStep={() => formik.handleSubmit()}
          maxWidth={{ xs: '10rem', sm: 'initial' }}
        />
      </StickyPanel>
    </PageTransitionWrapper>
  );
};

export default Country;
