import { useState } from 'react';
import { keyBy } from 'lodash';

import { Box, Stack, Typography } from '@mui/material';
import ReactPlayer from 'react-player';

import { videoInterviewFileUrl } from 'src/constants/apiEndpoints/awsS3';

import { IViewUserVideoInterviewData } from 'src/types/userVideoInterviewData';

type VideoInterviewQuestionsProps = {
  userVideoInterviewData: IViewUserVideoInterviewData;
};

export const VideoInterviewQuestions = ({
  userVideoInterviewData,
}: VideoInterviewQuestionsProps) => {
  const [selectedQuestion, setSelectedQuestion] = useState<number | null>(1);

  const questions = userVideoInterviewData.videoInterview?.questions ?? [];

  const recordByQuestionNumber = keyBy(
    userVideoInterviewData.records ?? [],
    'questionNumber',
  );

  const selectedRecordUrl = selectedQuestion
    ? recordByQuestionNumber[selectedQuestion]?.fileName
    : recordByQuestionNumber[1]?.fileName;

  return (
    <Stack direction={{ xs: 'column', md: 'row' }}>
      <Stack direction="row" gap={3} justifyContent="center">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            background: 'black',
            minWidth: '530px',
            borderRadius: '4px',
          }}
        >
          {selectedRecordUrl ? (
            <ReactPlayer
              controls
              playing
              width="100%"
              height="auto"
              url={
                selectedRecordUrl
                  ? videoInterviewFileUrl(selectedRecordUrl)
                  : undefined
              }
              style={{
                background: 'black',
                borderRadius: '4px',
              }}
            />
          ) : (
            <Typography variant="body2" color="text.invertedSecondary">
              Please select a question to play
            </Typography>
          )}
        </Box>
        <Stack gap={0.5} sx={{ width: '50%' }}>
          <Typography variant="h1" mb="1rem">
            {userVideoInterviewData.user?.firstName}{' '}
            {userVideoInterviewData.user?.lastName} video introduction
          </Typography>
          {questions.map((question) => {
            const hasRecord = !!recordByQuestionNumber[question.questionNumber];
            return (
              <Box
                key={question.questionNumber}
                onClick={() =>
                  hasRecord && setSelectedQuestion(question.questionNumber)
                }
                sx={(theme) => ({
                  padding: '8px 16px 16px 16px',
                  cursor: hasRecord ? 'pointer' : 'default',
                  borderRadius: '4px',
                  background:
                    selectedQuestion === question.questionNumber
                      ? theme.palette.highlight.accent
                      : hasRecord
                      ? theme.palette.highlight.actionable
                      : theme.palette.highlight.neutral,
                })}
              >
                <Typography variant="body2" color="text.secondary">
                  Question {question.questionNumber}
                </Typography>
                <Typography variant="body1">{question.title}</Typography>
              </Box>
            );
          })}
        </Stack>
      </Stack>
    </Stack>
  );
};
