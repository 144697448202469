import React from 'react';
import { Flex } from '../../../components';
import { Stack } from '@mui/material';
import TestTasksSelectableList from '../components/TestTasksSelectableList';
import PassTestTaskButton from '../components/PassTestTaskButton';
import RescheduleTestTaskButton from '../components/RescheduleTestTaskButton';
import LabeledTextButton from 'src/components/labledTextButton/LabeledTextButton';
import { TTScheduledPanel } from '../components/TTScheduledPanel';
import { CodilityDescription } from '../components/CodilityDescription';
import { useFeatureFlags } from 'src/hooks/useFeatureFlags';
import { AvailableTestTaskWithPositionsData } from '@types';

interface IOneTTLayoutProps {
  testTaskSelected: string | null;
  testTasks: AvailableTestTaskWithPositionsData[];
  isPerformingAction: boolean;
  testTaskReminderDate: string | null;
  handleTTChange: (testTask: AvailableTestTaskWithPositionsData) => void;
  handlePassTTClick: () => void;
  handleScheduleForLater: () => void;
}

const OneTTLayout: React.FC<IOneTTLayoutProps> = ({
  testTaskSelected,
  testTasks,
  isPerformingAction,
  testTaskReminderDate,
  handleTTChange,
  handlePassTTClick,
  handleScheduleForLater,
}) => {
  const { isTTSelectionPageSimplified } = useFeatureFlags();

  const onProceedTTClick = () => {
    handlePassTTClick();
  };

  return (
    <>
      <TestTasksSelectableList
        selectedTestTask={testTaskSelected}
        testTasks={testTasks}
        handleChange={handleTTChange}
      />
      <Stack spacing={3} direction="column" mt={4}>
        {testTaskReminderDate ? (
          <>
            <TTScheduledPanel
              testTaskReminderDate={testTaskReminderDate}
              handleScheduleForLater={handleScheduleForLater}
            />
            <Flex
              gap={4}
              flexWrap="wrap"
              alignItems="flex-start"
              flexDirection="column"
            >
              <LabeledTextButton
                label="In case you’re still up to it now"
                buttonText="Proceed to the test"
                onClick={onProceedTTClick}
              />
              {!isTTSelectionPageSimplified && <CodilityDescription />}
            </Flex>
          </>
        ) : (
          <Flex flexDirection="column" gap={4} alignItems="start">
            <Flex gap={2}>
              <PassTestTaskButton
                isDisabled={!testTaskSelected}
                isLoading={isPerformingAction}
                handlePassTTClick={handlePassTTClick}
              />
              <RescheduleTestTaskButton
                isDisabled={isPerformingAction}
                testTaskReminderDate={testTaskReminderDate}
                handleScheduleForLater={handleScheduleForLater}
              />
            </Flex>
            {!isTTSelectionPageSimplified && <CodilityDescription />}
          </Flex>
        )}
      </Stack>
    </>
  );
};

export default OneTTLayout;
