import React from 'react';
import { Box, FormControlLabel, Stack, Typography } from '@mui/material';
import {
  ContinueMessage,
  RadioButton,
  SelectableWrapper,
} from '../../../components';
import {
  CONTRACTOR_ENGAGEMENT_DATA,
  EMPLOYEE_ENGAGEMENT_DATA,
  ENGAGEMENT_TABLE_HEADERS,
  ENGAGEMENT_TYPE,
  COMMON_ENGAGEMENT_DATA,
} from '../../../constants';
import TableRow from './TableRow';

interface IProps {
  engagementSelected: string | null;
  handleChange: (engagement: string) => void;
}

const EngagementTable: React.FC<IProps> = ({
  engagementSelected,
  handleChange,
}) => {
  return (
    <Box width="52rem" margin="2rem 0">
      <TableRow
        firstBlock={<ContinueMessage textIdentifier={1} />}
        secondBlock={
          <SelectableWrapper
            value={ENGAGEMENT_TYPE.CONTRACTOR}
            selectedValue={engagementSelected}
            onClick={handleChange}
            display="block"
            testid="engagement-option"
          >
            <FormControlLabel
              value={ENGAGEMENT_TYPE.CONTRACTOR}
              control={<RadioButton tabIndex={-1} />}
              label={
                <Typography variant="body1" fontWeight="600">
                  {CONTRACTOR_ENGAGEMENT_DATA.name}
                </Typography>
              }
            />
            <Typography variant="body2" marginLeft="1.8rem">
              {CONTRACTOR_ENGAGEMENT_DATA.info}
            </Typography>
          </SelectableWrapper>
        }
        thirdBlock={
          <SelectableWrapper
            value={ENGAGEMENT_TYPE.EMPLOYEE}
            selectedValue={engagementSelected}
            onClick={handleChange}
            display="block"
          >
            <FormControlLabel
              value={ENGAGEMENT_TYPE.EMPLOYEE}
              control={<RadioButton tabIndex={-1} />}
              label={
                <Typography variant="body1" fontWeight="600">
                  {EMPLOYEE_ENGAGEMENT_DATA.name}
                </Typography>
              }
            />
            <Typography variant="body2" marginLeft="1.8rem">
              {EMPLOYEE_ENGAGEMENT_DATA.info}
            </Typography>
          </SelectableWrapper>
        }
      />
      <TableRow
        hasBorder
        firstBlock={
          <Typography variant="body2" color="brand.accent">
            {ENGAGEMENT_TABLE_HEADERS.cooperationType}
          </Typography>
        }
        secondBlock={
          <Typography variant="body1">
            {CONTRACTOR_ENGAGEMENT_DATA.cooperationType}
          </Typography>
        }
        thirdBlock={
          <Typography variant="body1">
            {EMPLOYEE_ENGAGEMENT_DATA.cooperationType}
          </Typography>
        }
      />
      <TableRow
        hasBorder
        firstBlock={
          <Typography variant="body2" color="brand.accent">
            {ENGAGEMENT_TABLE_HEADERS.payment}
          </Typography>
        }
        secondBlock={
          <Typography variant="body1">
            {CONTRACTOR_ENGAGEMENT_DATA.payment}
          </Typography>
        }
        thirdBlock={
          <Typography variant="body1">
            {EMPLOYEE_ENGAGEMENT_DATA.payment}
          </Typography>
        }
      />
      <TableRow
        hasBorder
        firstBlock={
          <Typography variant="body2" color="brand.accent">
            {ENGAGEMENT_TABLE_HEADERS.taxes}
          </Typography>
        }
        secondBlock={
          <Typography variant="body1">
            {CONTRACTOR_ENGAGEMENT_DATA.taxes}
          </Typography>
        }
        thirdBlock={
          <Typography variant="body1">
            {EMPLOYEE_ENGAGEMENT_DATA.taxes}
          </Typography>
        }
      />
      <TableRow
        hasBorder
        firstBlock={
          <Typography variant="body2" color="brand.accent">
            {ENGAGEMENT_TABLE_HEADERS.socialSecurity}
          </Typography>
        }
        secondBlock={
          <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap' }}>
            {CONTRACTOR_ENGAGEMENT_DATA.socialSecurity}
          </Typography>
        }
        thirdBlock={
          <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap' }}>
            {EMPLOYEE_ENGAGEMENT_DATA.socialSecurity}
          </Typography>
        }
      />
      <TableRow
        hasBorder
        firstBlock={
          <Typography variant="body2" color="brand.accent">
            {ENGAGEMENT_TABLE_HEADERS.daysOff}
          </Typography>
        }
        secondBlock={
          <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap' }}>
            {CONTRACTOR_ENGAGEMENT_DATA.daysOff}
          </Typography>
        }
        thirdBlock={
          <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap' }}>
            {EMPLOYEE_ENGAGEMENT_DATA.daysOff}
          </Typography>
        }
      />
      <TableRow
        hasBorder
        firstBlock={
          <Typography variant="body2" color="brand.accent">
            {ENGAGEMENT_TABLE_HEADERS.vacationBonus}
          </Typography>
        }
        secondBlock={
          <Typography variant="body1">
            {CONTRACTOR_ENGAGEMENT_DATA.vacationBonus}
          </Typography>
        }
        thirdBlock={
          <Typography variant="body1">
            {EMPLOYEE_ENGAGEMENT_DATA.vacationBonus}
          </Typography>
        }
      />
      <TableRow
        hasBorder
        firstBlock={
          <Typography variant="body2" color="brand.accent">
            {ENGAGEMENT_TABLE_HEADERS.christmasBonus}
          </Typography>
        }
        secondBlock={
          <Typography variant="body1">
            {CONTRACTOR_ENGAGEMENT_DATA.christmasBonus}
          </Typography>
        }
        thirdBlock={
          <Typography variant="body1">
            {EMPLOYEE_ENGAGEMENT_DATA.christmasBonus}
          </Typography>
        }
      />
      <TableRow
        hasBorder
        firstBlock={
          <Typography variant="body2" color="brand.accent">
            {ENGAGEMENT_TABLE_HEADERS.healthInsurance}
          </Typography>
        }
        secondBlock={
          <Typography variant="body1">
            {CONTRACTOR_ENGAGEMENT_DATA.healthInsurance}
          </Typography>
        }
        thirdBlock={
          <Typography variant="body1">
            {EMPLOYEE_ENGAGEMENT_DATA.healthInsurance}
          </Typography>
        }
      />
      <Stack
        direction="row"
        spacing={1}
        sx={{ boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.1)' }}
      >
        <Box width="5.2rem" padding="1rem 0">
          <Typography variant="body2" color="brand.accent">
            {ENGAGEMENT_TABLE_HEADERS.anualBudget}
          </Typography>
        </Box>
        <Box width="45rem" padding="1rem 1.5rem 1rem 3.5rem">
          <Typography variant="body1" overflow="visible">
            {COMMON_ENGAGEMENT_DATA.anualBudget}
          </Typography>
        </Box>
      </Stack>
      <TableRow
        hasBorder
        firstBlock={
          <Typography variant="body2" color="brand.accent">
            {ENGAGEMENT_TABLE_HEADERS.severance}
          </Typography>
        }
        secondBlock={
          <Typography variant="body1" overflow="visible">
            {CONTRACTOR_ENGAGEMENT_DATA.severance}
          </Typography>
        }
        thirdBlock={
          <Typography variant="body1" overflow="visible">
            {EMPLOYEE_ENGAGEMENT_DATA.severance}
          </Typography>
        }
      />
    </Box>
  );
};

export default EngagementTable;
