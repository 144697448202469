import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { API_STATUS } from '../../constants';
import { candidateSourceStoreKey } from './candidateSource.const';
import { postUserWithSourceSignUp } from './candidateSource.thunks';
import { ICandidateSource } from '@types';

export interface ICandidateSourceSliceState {
  apiStatus: API_STATUS;
  data: ICandidateSource | null;
}

const initialState: ICandidateSourceSliceState = {
  apiStatus: API_STATUS.IDLE,
  data: null,
};

export const candidateSourceSlice = createSlice({
  name: candidateSourceStoreKey,
  initialState,
  reducers: {
    setCandidateSourceData: (
      state,
      action: PayloadAction<ICandidateSource>,
    ) => {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postUserWithSourceSignUp.pending, (state) => {
        state.apiStatus = API_STATUS.LOADING;
      })
      .addCase(postUserWithSourceSignUp.fulfilled, (state) => {
        state.apiStatus = API_STATUS.COMPLETE;
      })
      .addCase(postUserWithSourceSignUp.rejected, (state) => {
        state.apiStatus = API_STATUS.FAILED;
      });
  },
});

export const { setCandidateSourceData } = candidateSourceSlice.actions;
