import { Box, Typography } from '@mui/material';
import React from 'react';
import { BasicDialog, CustomButton } from '../../../components';
import quizImageSrc from '../../../assets/quiz-image.png';
import { plural } from '../../../utils';

interface IProps {
  showQuizConfirmationModal: boolean;
  handleCloseConfirmationModal: () => void;
  handleFinishQuiz: () => void;
  isLoading: boolean;
  renderCountdown: () => string;
  unansweredQuestionsCount: number;
}

const QuizConfirmationModal: React.FC<IProps> = ({
  showQuizConfirmationModal,
  handleCloseConfirmationModal,
  handleFinishQuiz,
  isLoading,
  renderCountdown,
  unansweredQuestionsCount,
}) => {
  return (
    <BasicDialog
      isOpen={showQuizConfirmationModal}
      handleClose={handleCloseConfirmationModal}
      overflowVisible
    >
      <Box
        component="img"
        srcSet={`${quizImageSrc} 2x`}
        sx={(theme) => ({
          position: 'absolute',
          top: '2rem',
          transform: 'translateX(-55%)',
          left: '50%',
          maxWidth: '150px',
          [theme.breakpoints.up('sm')]: { top: '-5rem', maxWidth: '200px' },
        })}
      />
      <Box
        sx={(theme) => ({
          mt: 26,
          [theme.breakpoints.up('sm')]: { mt: 22 },
        })}
      >
        {!!unansweredQuestionsCount ? (
          <>
            <Typography
              variant="h3"
              color="primary"
              textAlign="center"
              mb={3}
              maxWidth={{ sm: 'initial', md: '20ch' }}
            >
              {unansweredQuestionsCount} question
              {plural(unansweredQuestionsCount)}
              <br />
              {unansweredQuestionsCount === 1 ? `hasn't` : `haven't`}{' '}
              been answered
            </Typography>
            <Typography variant="body1" textAlign="center" gutterBottom mb={3}>
              Do you want to finish anyway?
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="h2" textAlign="center" mb={3}>
              Easy right?
            </Typography>
            <Typography variant="body1" textAlign="center" gutterBottom mb={3}>
              You have answered all the questions in time. Wanna double-check or
              you done?
            </Typography>
          </>
        )}
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box mb={3}>
          <CustomButton
            label={
              !!unansweredQuestionsCount
                ? 'Confirm finishing'
                : `That’s it, I’ve finished it`
            }
            onClick={handleFinishQuiz}
            isLoading={isLoading}
          />
        </Box>
        <Typography variant="body2" color="secondary">
          You still have{' '}
          <Typography
            variant="body2"
            component="span"
            sx={{ fontVariantNumeric: 'tabular-nums', letterSpacing: 0 }}
          >
            {renderCountdown()}
          </Typography>{' '}
          time left
        </Typography>
        <Typography
          onClick={handleCloseConfirmationModal}
          variant="body1"
          color="brand.accent"
          fontWeight="bold"
          sx={{
            opacity: '0.8',
            ':hover': {
              opacity: '1',
              cursor: 'pointer',
            },
          }}
        >
          Back to quiz
        </Typography>
      </Box>
    </BasicDialog>
  );
};

export default QuizConfirmationModal;
