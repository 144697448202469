import React, { ChangeEvent } from 'react';
import NumberFormat, {
  NumberFormatProps,
  NumberFormatValues,
} from 'react-number-format';

export const NumberFormatCustom = React.forwardRef(
  (
    props: Omit<NumberFormatProps, 'defaultValue'> & {
      maxValue?: number;
      'data-testid'?: string;
    },
    ref: React.Ref<HTMLInputElement>,
  ) => {
    const { onChange, maxValue, ...other } = props;

    const validateMaxValue = (values: NumberFormatValues) => {
      return !maxValue || +values.value <= maxValue;
    };

    return (
      <NumberFormat
        getInputRef={ref}
        onValueChange={(values) => {
          onChange &&
            onChange({
              target: {
                value: values.value,
              },
            } as ChangeEvent<HTMLInputElement>);
        }}
        thousandSeparator
        isNumericString
        isAllowed={validateMaxValue}
        {...other}
      />
    );
  },
);
