import React, { useMemo } from 'react';
import { add } from 'date-fns';
import {
  useCountdown,
  useIsDesktopView,
  useIsMobileView,
  useIsTabletView,
} from '../../../hooks';
import { Box, Typography } from '@mui/material';
import { CustomButton } from '../../../components';
import { plural, numberToMinuteSecondString } from '../../../utils';
import { useAppDispatch } from '../../../redux';
import { setIsSidebarOpen } from '../../../redux/sidebar';
import { LocalStorageService } from '../../../services';
import { LOCAL_STORAGE_KEYS } from '../../../constants';
import QuizConfirmationModal from './QuizConfirmationModal';

interface IProps {
  duration: number;
  quizStartDateTime: string;
  handleFinishQuiz: () => void;
  showFinishQuizConfirmationModal: boolean;
  setShowFinishQuizConfirmationModal: (state: boolean) => void;
  isLoading: boolean;
  unansweredQuestionsCount: number;
}

const QuizTimer: React.FC<IProps> = ({
  duration,
  quizStartDateTime,
  handleFinishQuiz,
  showFinishQuizConfirmationModal,
  setShowFinishQuizConfirmationModal,
  isLoading,
  unansweredQuestionsCount,
}) => {
  const dispatch = useAppDispatch();
  const isMobileView = useIsMobileView();
  const isTabletView = useIsTabletView();
  const isDesktopView = useIsDesktopView();
  const countdownDateTime = useMemo(() => {
    return add(new Date(quizStartDateTime), {
      minutes: duration,
    });
  }, [duration, quizStartDateTime]);

  const handleCountdown = () => {
    LocalStorageService.setState(
      LOCAL_STORAGE_KEYS.QUIZ_TEST_TASK_FINISHED_DUE_TO_TIMEOUT,
      true,
    );
    handleFinishQuiz();
  };

  const handleConfirmation = () => {
    setShowFinishQuizConfirmationModal(true);
  };

  const handleSibebarOpen = () => {
    dispatch(setIsSidebarOpen(true));
  };

  const { hours, minutes, seconds } = useCountdown(
    countdownDateTime,
    handleCountdown,
  );

  const renderCountdown = () => {
    return `${hours ? `${hours}:` : ''}${numberToMinuteSecondString(
      minutes,
    )}:${numberToMinuteSecondString(seconds)}`;
  };

  const handleCloseConfirmationModal = () => {
    setShowFinishQuizConfirmationModal(false);
  };

  return (
    <>
      <Box
        sx={(theme) => ({
          background: theme.palette.highlight.neutral,
          borderRadius: isMobileView ? '0px' : '8px 8px 0px 0px',
          position: isMobileView ? 'initial' : 'fixed',
          bottom: 0,
          transform: isMobileView ? 'translateX(-1.5rem)' : 'translateX(-3rem)',
          width: isMobileView ? 'calc(100% + 3rem)' : '100%',
          maxWidth: { lg: '40rem' },
          display: 'flex',
          flexDirection: 'row',
          px: { xs: 3, md: 6 },
          py: { xs: 2, sm: 4 },
          mb: isMobileView ? 3 : 0,
          zIndex: 1,
        })}
      >
        <Box
          display="flex"
          flexDirection="column"
          flexGrow={{ xs: 1, sm: 0, lg: 1 }}
          maxWidth={{ xs: 'initial', sm: '16rem', lg: 'initial' }}
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
            gap={0}
          >
            <Box>
              <Typography
                variant="body1"
                color="primary"
                role={isTabletView ? 'botton' : ''}
                sx={{
                  cursor: isTabletView ? 'pointer' : 'initial',
                  textDecoration: isTabletView ? 'underline' : 'initial',
                  maxWidth: '7rem',
                  lineHeight: '1.35',
                }}
                onClick={isTabletView ? handleSibebarOpen : undefined}
                tabIndex={isTabletView ? 0 : undefined}
              >
                {unansweredQuestionsCount
                  ? `${unansweredQuestionsCount} question${plural(
                      unansweredQuestionsCount,
                    )} remaining`
                  : 'All questions done!'}
              </Typography>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="flex-end">
              {!isTabletView && (
                <Typography variant="body2" color="secondary">
                  Time left
                </Typography>
              )}
              <Typography
                variant="body1"
                component="span"
                color={minutes >= 1 ? '#000' : '#FF4C4C'}
                sx={(theme) => ({
                  textAlign: 'right',
                  fontSize: 24,
                  fontWeight: 600,
                  fontVariantNumeric: 'tabular-nums',
                  letterSpacing: 0,
                  marginTop: '-6px',
                  marginRight: '-1px',
                  [theme.breakpoints.down('md')]: {
                    fontSize: 18,
                    marginTop: 0,
                  },
                })}
              >
                {renderCountdown()}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box ml={3}>
          <CustomButton
            label={isDesktopView ? 'Finish' : 'Finish now'}
            onClick={handleConfirmation}
          />
        </Box>
      </Box>
      <QuizConfirmationModal
        showQuizConfirmationModal={showFinishQuizConfirmationModal}
        handleCloseConfirmationModal={handleCloseConfirmationModal}
        handleFinishQuiz={handleFinishQuiz}
        isLoading={isLoading}
        renderCountdown={renderCountdown}
        unansweredQuestionsCount={unansweredQuestionsCount}
      />
    </>
  );
};

export default QuizTimer;
