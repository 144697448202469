import React, { useCallback, useEffect, useRef } from 'react';
import { Stack, useTheme, useMediaQuery, Typography } from '@mui/material';
import { CustomButton } from '../customButton/CustomButton';
import ContinueMessage from '../continueMessage/ContinueMessage';
import { StackProps } from '@mui/material/Stack/Stack';

interface IProps extends StackProps {
  handleSubmitStep?: () => void;
  isLoading: boolean;
  isDisabled?: boolean;
  textIdentifier?: number;
  isSubmitStep?: boolean;
  label?: string;
  validationError?: string;
  continueBtnRef?: React.MutableRefObject<HTMLButtonElement | null>;
}

export const GoToNextStep: React.FC<IProps> = ({
  handleSubmitStep,
  isLoading,
  isDisabled,
  textIdentifier,
  isSubmitStep,
  validationError,
  continueBtnRef,
  label,
  ...rest
}) => {
  const theme = useTheme();
  const fullWidth = useMediaQuery(theme.breakpoints.down('sm'));
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const handleEnterKey = useCallback(
    (e: KeyboardEvent) => {
      if (e.code === 'Enter' && !isDisabled && !isLoading) {
        e.stopImmediatePropagation();
        buttonRef.current?.click();
      }
    },
    [isDisabled, isLoading],
  );

  useEffect(() => {
    window.addEventListener('keyup', handleEnterKey);
    return () => {
      window.removeEventListener('keyup', handleEnterKey);
    };
  }, [handleEnterKey]);

  useEffect(() => {
    if (buttonRef.current && continueBtnRef) {
      continueBtnRef.current = buttonRef.current;
    }
  }, [buttonRef]);

  return (
    <Stack
      direction={fullWidth ? 'column' : 'row'}
      marginTop="2rem"
      marginBottom={2}
      spacing={2}
      alignItems="center"
      {...rest}
    >
      <CustomButton
        ref={buttonRef}
        fullWidth={fullWidth}
        isLoading={isLoading}
        isDisabled={isDisabled}
        onClick={handleSubmitStep ? handleSubmitStep : undefined}
        label={label ? label : isSubmitStep ? 'Submit application' : 'Continue'}
        testid="continue-button"
      />
      {validationError ? (
        <Typography variant="body2" color="brand.accent">
          {validationError}
        </Typography>
      ) : !fullWidth && textIdentifier !== undefined ? (
        <ContinueMessage textIdentifier={textIdentifier} />
      ) : null}
    </Stack>
  );
};

export default GoToNextStep;
