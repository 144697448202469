import React from 'react';
import { Button, Link } from '@mui/material';

interface IProps extends React.ComponentProps<'button'> {
  icon: React.ReactElement;
  href?: string | undefined;
  label: string;
}

export const SocialLinkButton: React.FC<IProps> = ({ href, icon, label }) => {
  return (
    <Link href={href} aria-label={label} target="_blank">
      <Button
        size="large"
        startIcon={icon}
        variant="contained"
        tabIndex={-1}
        sx={(theme) => ({
          backgroundColor: theme.palette.background.socialLink,
          height: '4rem',
          width: '4rem',
          minWidth: '4rem',
          '.MuiButton-startIcon': {
            mx: 0,
            '&>*:nth-of-type(1)': {
              fontSize: '1.5rem',
            },
          },
          '&:hover': {
            backgroundColor: theme.palette.background.buttonHoverWhite,
            borderColor: theme.palette.common.white,
          },
        })}
      />
    </Link>
  );
};
