import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../redux';
import { userSelectors } from '../redux/user';
import { isStatusIdle } from '../utils';
import { applyPosition, positionsSelectors } from 'src/redux/positions';

export const usePositionData = (withApply = false) => {
  const dispatch = useAppDispatch();

  const userData = useAppSelector(userSelectors.getUserData);
  const apiStatus = useAppSelector(positionsSelectors.getPositionsApiStatus);
  const positionData = useAppSelector(positionsSelectors.getPositionData);

  const userDisplayablePositions =
    userData?.positions?.filter((x) => x.isDisplayable) ?? [];
  const positionDataUser =
    userDisplayablePositions.length > 0
      ? userDisplayablePositions[userDisplayablePositions.length - 1]
      : null;

  useEffect(() => {
    if (
      positionData?.isDisplayable &&
      userData &&
      !positionDataUser &&
      isStatusIdle(apiStatus) &&
      withApply
    ) {
      dispatch(applyPosition(positionData));
    }
  }, [positionData, userData, positionDataUser, apiStatus]);

  return positionDataUser || positionData;
};
