import React from 'react';
import { Box, Typography } from '@mui/material';
import TTPointTimeWebp from '../../../assets/tt-point-time.webp';
import TTPointTime from '../../../assets/tt-point-time.png';
import { ContentWrapper } from './ContentWrapper';

export const ExpiredInvitationLink: React.FC = () => {
  return (
    <ContentWrapper
      data-testid="invitation-link-expired-layout"
      sx={(theme) => ({
        [theme.breakpoints.down('md')]: {
          px: 4,
          gridRow: 2,
          mt: 1,
          width: '90%',
        },
        [theme.breakpoints.down('sm')]: {
          px: 0,
        },
      })}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Box display="flex" justifyContent="center" minHeight="8.5rem">
          <picture>
            <source srcSet={TTPointTimeWebp} type="image/webp" />
            <source srcSet={TTPointTime} type="image/png" />
            <Box
              component={'img'}
              src={TTPointTime}
              alt="Quiz time"
              maxWidth="11rem"
            />
          </picture>
        </Box>
        <Typography
          variant="h1"
          fontWeight="bold"
          textAlign="center"
          gutterBottom
          sx={(theme) => ({
            lineHeight: '130%',
            [theme.breakpoints.down('md')]: {
              fontWeight: '700',
              fontSize: '1.75rem',
              letterSpacing: '-0.015em',
            },
            [theme.breakpoints.down('sm')]: {
              fontSize: '1.25rem',
            },
          })}
        >
          Looks like your invitation link has expired
        </Typography>
        <Typography
          variant="body1"
          textAlign="center"
          gutterBottom
          sx={{
            lineHeight: '150%',
            letterSpacing: '-0.02em',
          }}
        >
          Please reach out to the person who gave it to you or ping us in chat
          here.
        </Typography>
      </Box>
    </ContentWrapper>
  );
};
