import React, { useMemo, useRef } from 'react';
import {
  useEmojisplosion,
  useUserTestTasks,
  useUserVideoInterview,
} from '../../../../../hooks';
import { format } from 'date-fns';

import { Box, Typography } from '@mui/material';
import {
  CustomButton,
  Flex,
  Panel,
  ResponsiveBreak,
  StepPanelHeader,
} from '../../../../../components';

import {
  APP_ROUTES,
  LOCAL_STORAGE_KEYS,
  TestTaskType,
} from '../../../../../constants';

import { LocalStorageService, logEvent } from '../../../../../services';

import { ReactComponent as CalendlyIcon } from '../../../../../assets/calendly-icon.svg';
import startImage from '../../../../../assets/passing-star.png';
import { useNavigate } from 'react-router-dom';
import ReachOutToUs from '../ReachOutToUs';

interface IProps {
  calendlyLink: string | null;
  isCalendlyLoading: boolean;
  setShowCalendlyModal: React.Dispatch<React.SetStateAction<boolean>>;
  isPreviousTestTask?: boolean | false;
}

const TestTaskPassedMessage: React.FC<IProps> = ({
  calendlyLink,
  isCalendlyLoading,
  setShowCalendlyModal,
  isPreviousTestTask = false,
}) => {
  const navigate = useNavigate();
  const { availableVideoInterview } = useUserVideoInterview();

  const isShowCalendlyLink = typeof calendlyLink === 'string';

  const { passedTestTasks } = useUserTestTasks();
  const latestPassedTestTask = passedTestTasks?.[0];

  const isQuizTestTaskFinishedDueToTimeout = LocalStorageService.getState<
    boolean | undefined
  >(LOCAL_STORAGE_KEYS.QUIZ_TEST_TASK_FINISHED_DUE_TO_TIMEOUT);

  const starRef = useRef<HTMLDivElement>(null);
  useEmojisplosion(starRef, 'TT_PASSED');

  const passedScore = useMemo(() => {
    if (!latestPassedTestTask) return '';

    const score =
      latestPassedTestTask.testTaskType === TestTaskType.CODILITY ||
      latestPassedTestTask.testTaskType === TestTaskType.QUIZ
        ? latestPassedTestTask.score
        : null;

    if (isPreviousTestTask) {
      const date = format(
        new Date(latestPassedTestTask.resultDate!),
        'MMM d, y',
      );
      return (
        <Typography>
          You passed it on {date}
          {score ? ` with a ${score}% score.` : '.'}
        </Typography>
      );
    }
    if (score) {
      return (
        <Typography fontWeight="600" data-testid="score-info">
          {score}% is a good score!
        </Typography>
      );
    }

    return '';
  }, [isPreviousTestTask, latestPassedTestTask]);

  const testTypeText = useMemo(() => {
    if (latestPassedTestTask?.testTaskType === TestTaskType.CODILITY)
      return 'coding test';
    if (latestPassedTestTask?.testTaskType === TestTaskType.QUIZ) return 'quiz';

    return 'test task';
  }, [latestPassedTestTask]);

  const handleClick = () => {
    navigate(`/${APP_ROUTES.VIDEO_INTERVIEW_SELECTION}`);
  };

  return (
    <>
      <StepPanelHeader
        ref={starRef}
        srcSetPng={startImage}
        alt="Star"
        data-testid="success-title"
      >
        {!isPreviousTestTask ? (
          <>
            Congratulations! <ResponsiveBreak breakpoint="sm" />
            You've passed!
          </>
        ) : (
          <>
            We’ve found your <ResponsiveBreak breakpoint="sm" />
            previous {testTypeText} score!
          </>
        )}
      </StepPanelHeader>

      {passedScore}

      <Typography variant="body1" data-testid="success-text">
        {isShowCalendlyLink && !availableVideoInterview && (
          <>
            Our Talent Executive would be happy{' '}
            <ResponsiveBreak breakpoint="sm" />
            to have an intro call with you.
          </>
        )}
        {isShowCalendlyLink && !!availableVideoInterview && (
          <>
            You are our top priority candidate and our Talent Executive will
            reach out to you <ResponsiveBreak breakpoint="md" />
            to discuss the next steps.
          </>
        )}
        {!isShowCalendlyLink && (
          <>
            Our Talent Executive will reach out{' '}
            <ResponsiveBreak breakpoint="md" />
            to you to discuss the next steps.
          </>
        )}
      </Typography>
      {isQuizTestTaskFinishedDueToTimeout && (
        <Panel>
          <Typography variant="body1">
            <strong>Note:</strong> the test was automatically
            <br />
            finished when time limit was reached.
          </Typography>
        </Panel>
      )}
      {isShowCalendlyLink && availableVideoInterview && (
        <Box
          bgcolor={'highlight.actionable'}
          sx={(theme) => ({
            p: '2.5rem',
            pb: '3rem',
            borderRadius: '1rem',
            width: '500px',
            [theme.breakpoints.down('lg')]: {
              width: '100%',
            },
          })}
        >
          <Typography mb={'1.25rem'}>
            Record a quick self-introduction <ResponsiveBreak breakpoint="md" />
            to book a call with our Talent Executive to get{' '}
            <ResponsiveBreak breakpoint="md" /> to know more about our open
            positions
          </Typography>
          <CustomButton
            color="primary"
            label="Continue"
            onClick={handleClick}
          />
        </Box>
      )}
      {isShowCalendlyLink && !availableVideoInterview && (
        <>
          <Box my={1} data-testid="calendly-button">
            <CustomButton
              color="primary"
              startIcon={
                <Flex bgcolor="white" borderRadius={2} p={0.5}>
                  <CalendlyIcon />
                </Flex>
              }
              label="Book a time slot"
              onClick={() => {
                logEvent('launchpod-book-time-slot-prescreen-call-clicked');
                setShowCalendlyModal && setShowCalendlyModal(true);
              }}
              isLoading={isCalendlyLoading}
            />
          </Box>
          <ReachOutToUs />
        </>
      )}
    </>
  );
};

export default TestTaskPassedMessage;
