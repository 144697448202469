import React, { ReactElement } from 'react';

import { Box, FormHelperText, Typography } from '@mui/material';
import { TextButton } from '../textButton/TextButton';

interface IProps {
  isDisabled?: boolean;
  label: string;
  buttonText: string;
  onClick: () => void;
  startIcon?: React.ReactElement;
  errorMessage?: boolean | string;
}

const LabeledTextButton = ({
  label,
  buttonText,
  isDisabled,
  onClick,
  startIcon,
  errorMessage,
}: IProps): ReactElement => {
  return (
    <Box>
      <Typography variant="body2" color="text.secondary" gutterBottom>
        {label}
      </Typography>
      <TextButton
        onClick={onClick}
        disabled={isDisabled}
        startIcon={startIcon}
        underlined
      >
        {buttonText}
      </TextButton>
      {errorMessage && (
        <FormHelperText
          sx={(theme) => ({
            color: theme.palette.error.main,
          })}
        >
          {errorMessage}
        </FormHelperText>
      )}
    </Box>
  );
};

export default LabeledTextButton;
