import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { locationsStoreKey } from './locations.const';
import { getCountriesPath, getCitiesPath } from '../../constants/apiEndpoints';
import { ICity, ICountry } from '@types';

export const getCountries = createAsyncThunk(
  `${locationsStoreKey}/getCountries`,
  async () => {
    const response = await axios.get<ICountry[]>(getCountriesPath());
    return response.data;
  },
);

export const getCities = createAsyncThunk(
  `${locationsStoreKey}/getCities`,
  async () => {
    const response = await axios.get<ICity[]>(getCitiesPath());
    return response.data;
  },
);
