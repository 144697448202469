import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiEndpoints } from '../../constants';
import { formatErrorMessage } from '../../utils';
import { notificationAlert } from '../notifications';
import { candidateSourceStoreKey } from './candidateSource.const';
import { ICandidateSource } from '@types';

export const postUserWithSourceSignUp = createAsyncThunk(
  `${candidateSourceStoreKey}/postUserWithSourceSignUp`,
  async (data: ICandidateSource, thunkAPI) => {
    try {
      const response = await axios.post(
        apiEndpoints.userSignedWithSourcePath(),
        data,
      );

      return response.data;
    } catch (err: any) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(err);
    }
  },
);
