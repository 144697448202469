import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiEndpoints } from '../../constants';
import { formatErrorMessage } from '../../utils';
import { IPositionData } from '../../types';
import { fetchUser, resetUserPositions } from '../user';
import { notificationAlert } from '../notifications';
import { positionsStoreKey } from './positions.const';

export const applyPosition = createAsyncThunk(
  `${positionsStoreKey}/applyPosition`,
  async (body: IPositionData, thunkAPI) => {
    try {
      const response = await axios.post(apiEndpoints.applyPosition(), body);

      await thunkAPI.dispatch(fetchUser());

      return response.data;
    } catch (err: any) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(err);
    }
  },
);

export const resetPosition = createAsyncThunk(
  `${positionsStoreKey}/resetPosition`,
  async (_, thunkAPI) => {
    try {
      const response = await axios.post(apiEndpoints.resetPositionAPI());
      thunkAPI.dispatch(resetUserPositions());
      return response.data;
    } catch (err: any) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(err);
    }
  },
);
