/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import {
  FileInput,
  GoToNextStep,
  PageTransitionWrapper,
  ResponsiveBreak,
  ScheduleDialog,
} from '../../components';

import { useAppDispatch } from '../../redux';
import { uploadInternalTestTaskResolution } from '../../redux/testTasks';
import {
  LOCAL_STORAGE_KEYS,
  RESOLUTION_FILE_ACCEPTED_EXTENSIONS,
  TestTaskType,
} from '../../constants';
import { gamifiedResponseSlice } from '../../redux/gamifiedResponse';
import { LocalStorageService, logEvent } from '../../services';
import { AttachmentButton, CollapseSection } from '../../components';
import { toUpperFirstWithSpace } from '../../utils';
import { internalTestTaskFileUrl } from '../../constants/apiEndpoints';
import { InternalTestTaskDone } from './InternalTestTaskDone';
import {
  useInternalTestTaskSelectionStyles,
  useBaseContentStyles,
} from './styles';
import { useUserTestTasks } from '../../hooks';

const InternalTestTaskSelection = () => {
  const styles = useInternalTestTaskSelectionStyles();
  const baseContentStyles = useBaseContentStyles();
  const dispatch = useAppDispatch();
  const { currentTestTask, doneTestTask, isPerformingAction } =
    useUserTestTasks();

  const [testTaskResolutionFiles, setTestTaskResolutionFiles] = useState<
    File[] | string[] | null
  >(null);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [showScheduleDialog, setShowScheduleDialog] = useState(false);
  const [errorInResultFile, setErrorInResultFile] = useState<
    Record<number, string> | undefined
  >();
  const [isProcessingTtResolution, setIsProcessingTtResolution] =
    useState(false);

  useEffect(() => {
    logEvent('launchpod-non-dev-tt-selection-page-loaded');

    if (LocalStorageService.getState(LOCAL_STORAGE_KEYS.RESCHEDULE_EVENT)) {
      setShowScheduleDialog(true);
      LocalStorageService.clearState(LOCAL_STORAGE_KEYS.RESCHEDULE_EVENT);
    }

    if (doneTestTask) {
      setIsProcessingTtResolution(true);
      dispatch(gamifiedResponseSlice.actions.updateMessage(null));
    }
  }, [doneTestTask]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    logEvent('launchpod-non-dev-tt-upload-resolution');

    const { files } = event.target;
    setTestTaskResolutionFiles(files?.length ? Array.from(files) : null);
  };

  const onUploadProgress = (e: ProgressEvent) => {
    const { loaded, total } = e;
    const progress = Math.round((100 * loaded) / total);
    setUploadProgress(progress);
  };

  const handleAddToCalendar = () => {
    logEvent('launchpod-non-dev-tt-schedule-for-later-open-modal');

    setShowScheduleDialog(true);
  };

  const handleCloseScheduleForLater = () => {
    logEvent('launchpod-non-dev-tt-schedule-for-later-close-modal');

    setShowScheduleDialog(false);
  };

  const handleSubmit = (e: React.SyntheticEvent | null): void => {
    if (e) {
      e.preventDefault();
    }

    logEvent('launchpod-non-dev-tt-continue-click');

    if (!testTaskResolutionFiles) return;

    dispatch(
      uploadInternalTestTaskResolution({
        userTestTaskId: currentTestTask!.id,
        resolutionFiles: testTaskResolutionFiles!,
        onUploadProgress,
      }),
    );
  };

  const downloadAttachment = useCallback(
    async (filename: string) => {
      if (currentTestTask) {
        const url = internalTestTaskFileUrl(filename);
        window.open(url, '_blank', 'noreferrer');
      }
    },
    [currentTestTask],
  );

  const isDisabledNextStep = useMemo(() => {
    return (
      !testTaskResolutionFiles ||
      Object.values(errorInResultFile ?? {}).some((error) => Boolean(error))
    );
  }, [testTaskResolutionFiles, errorInResultFile]);

  if (!currentTestTask && !doneTestTask) return null;

  return (
    <PageTransitionWrapper>
      {isProcessingTtResolution && <InternalTestTaskDone />}
      {!isProcessingTtResolution && currentTestTask && (
        <Box css={styles}>
          <Box
            data-testid="non-dev-test-task-selection-layout"
            css={baseContentStyles}
          >
            <Box className="test-task-block">
              <Box className="objective-wrapper">
                <Typography variant="caption" color="text.secondary">
                  TEST TASK
                </Typography>
                <Typography variant="h3" color="text.primary">
                  {currentTestTask.internalTestTask!.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {currentTestTask.internalTestTask!.specialization}
                </Typography>
              </Box>
              <Box className="step-wrapper">
                <Box className="step-circle">1</Box>
                <Typography variant="body1">
                  Find the task instructions below.
                </Typography>
              </Box>
              {currentTestTask.internalTestTask!.description && (
                <div
                  className="objective-wrapper content"
                  dangerouslySetInnerHTML={{
                    __html: currentTestTask.internalTestTask!.description,
                  }}
                />
              )}
              {currentTestTask.internalTestTask!.instruction && (
                <CollapseSection
                  textLength={
                    currentTestTask.internalTestTask!.instruction.length
                  }
                >
                  <div
                    className="objective-wrapper content"
                    dangerouslySetInnerHTML={{
                      __html: currentTestTask.internalTestTask!.instruction,
                    }}
                  />
                </CollapseSection>
              )}
              {currentTestTask.internalTestTask!.attachments?.length ? (
                <Stack direction="column" spacing={2}>
                  <Box className="step-wrapper">
                    <Box className="step-circle">2</Box>
                    <Typography variant="body1">Check attachments</Typography>
                  </Box>
                  <Stack direction="column" spacing={1}>
                    {currentTestTask.internalTestTask!.attachments?.map(
                      (attachment, index) => (
                        <AttachmentButton
                          key={index}
                          variant="secondary"
                          onClick={downloadAttachment.bind(null, attachment)}
                        >
                          {toUpperFirstWithSpace(attachment)}
                        </AttachmentButton>
                      ),
                    )}
                  </Stack>
                </Stack>
              ) : null}
              <Stack direction="column" spacing={2}>
                <Box className="step-wrapper">
                  <Box className="step-circle">
                    {currentTestTask.internalTestTask!.attachments?.length
                      ? '3'
                      : '2'}
                  </Box>
                  <Typography variant="body1">
                    Once finished, share with us a file{' '}
                    <ResponsiveBreak breakpoint="md" />
                    or multiple files with your results.
                  </Typography>
                </Box>
                <FileInput
                  label="Attach your files"
                  hint="DOC, XLS, PDF, JPG, etc."
                  name="originalBoTtResultName"
                  onChange={handleChange}
                  value={testTaskResolutionFiles?.map((file) =>
                    file instanceof File ? file.name : file,
                  )}
                  loading={true}
                  progress={uploadProgress}
                  setErrorInFile={setErrorInResultFile}
                  error={errorInResultFile}
                  acceptedExtensions={RESOLUTION_FILE_ACCEPTED_EXTENSIONS}
                  multiple
                />
              </Stack>
            </Box>
            <Box display="flex" flexDirection="column">
              <GoToNextStep
                isLoading={isPerformingAction}
                isDisabled={isDisabledNextStep}
                textIdentifier={4}
                handleSubmitStep={() => handleSubmit(null)}
              />
              <Box className="add-to-calendar-wrapper">
                <Typography variant="body2" color="text.secondary">
                  Would you prefer to do it later?
                </Typography>
                <Typography
                  variant="body1"
                  fontSize="bold"
                  color="brand.accent"
                  sx={{ cursor: 'pointer' }}
                  onClick={handleAddToCalendar}
                >
                  Add to your calendar
                </Typography>
                {showScheduleDialog && (
                  <ScheduleDialog
                    isOpen={showScheduleDialog}
                    handleClose={handleCloseScheduleForLater}
                    handleScheduleSuccess={handleCloseScheduleForLater}
                    testTaskType={TestTaskType.INTERNAL}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </PageTransitionWrapper>
  );
};

export default InternalTestTaskSelection;
