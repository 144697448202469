import { API_STATUS } from '../../constants';
import { IAuthSliceState } from './auth.slice';
import { authStoreKey } from './auth.const';

interface IState {
  [authStoreKey]: IAuthSliceState;
}

const selectAuthState = (state: IState) => {
  return state[authStoreKey];
};

const getAuthApiStatus = (state: IState): API_STATUS => {
  return selectAuthState(state).apiStatus;
};

const getAuthError = (state: IState): string | null => {
  return selectAuthState(state).authError;
};

const getIsAuthUserEmailSent = (state: IState): boolean => {
  return selectAuthState(state).authUserEmailSent;
};

const getIsSubmitProfileEmailSent = (state: IState): boolean => {
  return selectAuthState(state).submitProfileEmailSent;
};

export const authSelectors = {
  getAuthApiStatus,
  getAuthError,
  getIsAuthUserEmailSent,
  getIsSubmitProfileEmailSent,
};
