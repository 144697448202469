import { getCompensationAdornment } from './getCompensationAdornment';
import { IUserData } from '../types/user';

export const formatCompensation = (userData: IUserData) => {
  const compensationTypeAdornment = getCompensationAdornment(
    userData.compensationType,
  );
  if (!userData.compensationExpectations) return 'N/A';
  const parts = userData.compensationExpectations?.toString().split('.');
  const numberPart = parts![0];
  const decimalPart = parts ? parts[1] : null;
  const thousands = /\B(?=(\d{3})+(?!\d))/g;
  return (
    numberPart.replace(thousands, ',') +
    (decimalPart ? '.' + decimalPart : '') +
    compensationTypeAdornment
  );
};
