import React from 'react';
import { Box, styled } from '@mui/material';
import { SxProps } from '@mui/system';
import { isKeyClick } from '../../utils';

export interface ISelectableWrapperProps {
  children: JSX.Element | JSX.Element[];
  value: string | number | boolean;
  selectedValue?: string | string[] | number | boolean | null | undefined;
  isSelected?: boolean;
  onClick: (
    value: any,
    e?: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>,
  ) => void;
  display?: 'flex' | 'block';
  sx?: SxProps;
  isDisabled?: boolean;
  hidden?: boolean;
  testid?: string;
}

const SelectableWrapperBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<{ isSelected: boolean; disabled: boolean; hidden?: boolean }>(
  ({ theme, isSelected, disabled }) => ({
    cursor: disabled ? 'auto' : 'pointer',
    alignItems: 'center',
    borderRadius: theme.shape.borderRadius,
    background: disabled
      ? theme.palette.highlight.neutral
      : isSelected
      ? theme.palette.highlight.accent
      : theme.palette.highlight.actionable,
    padding: '0.875rem 1.5rem 1rem 1.5rem',
    ':hover': {
      background: disabled
        ? theme.palette.highlight.neutral
        : isSelected
        ? theme.palette.highlight.accent
        : theme.palette.highlight.hover,
    },
    '&[disabled] .MuiCheckbox-root': {
      color: theme.palette.button.primary.textDisabled,
    },
    '&[hidden]': {
      display: 'none',
    },
  }),
);

export const SelectableWrapper: React.FC<ISelectableWrapperProps> = ({
  children,
  onClick,
  value,
  selectedValue,
  isSelected,
  display = 'flex',
  sx,
  isDisabled,
  testid,
  hidden,
}) => {
  const _isSelected =
    isSelected ||
    ([] as Array<string | number | boolean | null | undefined>)
      .concat(selectedValue)
      .includes(value);
  return (
    <SelectableWrapperBox
      isSelected={_isSelected}
      onClick={(e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        if (isDisabled) {
          return;
        }
        onClick(value, e);
      }}
      display={display}
      sx={sx}
      disabled={isDisabled ?? false}
      data-testid={testid}
      onKeyUp={(e: React.KeyboardEvent<HTMLDivElement>) => {
        if (isKeyClick(e)) {
          e.stopPropagation();
          if (isDisabled) {
            return;
          }
          onClick(value, e);
        }
      }}
      role="button"
      tabIndex={0}
      hidden={hidden}
    >
      {children}
    </SelectableWrapperBox>
  );
};
