import { useState } from 'react';
import { useCountdown } from '../../../hooks';
import { useAppDispatch } from '../../../redux';

import { CircularProgress, Typography } from '@mui/material';
import { Flex } from '../../../components';
import { notificationAlert } from '../../../redux/notifications';
import { logEvent } from 'src/services';

interface IEditOrResendEmailBlockProps {
  sendEmailAction: (email: string) => Promise<void>;
  emailChangeAction: any;
  userEmail: string;
  isLoading?: boolean;
}
export const EditOrResendEmailBlock = ({
  sendEmailAction,
  emailChangeAction,
  userEmail,
  isLoading,
}: IEditOrResendEmailBlockProps) => {
  const dispatch = useAppDispatch();
  const [timerEndDate, setTimerEndDate] = useState<Date | null>(null);

  const countdown = useCountdown(timerEndDate, () => setTimerEndDate(null));

  const handleResendEmail = async () => {
    await sendEmailAction(userEmail);
    setTimerEndDate(new Date(new Date().getTime() + 31000));
    dispatch(
      notificationAlert(
        `New email with the link has been sent. Please check inbox and spam just in case.`,
      ),
    );

    logEvent('launchpod-email-confirmation-resend-email');
  };

  return (
    <>
      <Flex
        flexDirection="column"
        alignItems={{ xs: 'flex-start', sm: 'center' }}
        gap={1}
        mt={5}
      >
        <Flex width="auto" gap={1}>
          <Typography variant="body2" color="text.secondary">
            Incorrect email?
          </Typography>
          <Typography
            onClick={() => {
              emailChangeAction();
              logEvent('launchpod-email-confirmation-change-email');
            }}
            variant="body2"
            color="brand.accent"
            fontWeight="bold"
            sx={{
              opacity: '0.8',
              ':hover': {
                opacity: '1',
                cursor: 'pointer',
              },
            }}
            data-testid="edit-email-button"
          >
            Change email →
          </Typography>
        </Flex>

        <Flex width="auto" gap={1}>
          <Typography variant="body2" color="text.secondary">
            Not receiving?
          </Typography>
          {isLoading && (
            <CircularProgress
              size={16}
              sx={{ color: (theme) => theme.palette.brand.accent }}
            />
          )}
          {Boolean(timerEndDate) && !isLoading && (
            <Typography variant="body2" color="text.secondary">
              <b>
                {countdown.minutes} :{' '}
                {countdown.seconds < 10
                  ? `0${countdown.seconds}`
                  : countdown.seconds}
                {'s until retry'}
              </b>
            </Typography>
          )}
          {!Boolean(timerEndDate) && !isLoading && (
            <Typography
              onClick={handleResendEmail}
              variant="body2"
              color="brand.accent"
              fontWeight="bold"
              sx={{
                opacity: '0.8',
                ':hover': {
                  opacity: '1',
                  cursor: 'pointer',
                },
              }}
              data-testid="resend-email-button"
            >
              Resend email →
            </Typography>
          )}
        </Flex>
      </Flex>
    </>
  );
};
