import React, { FC, ReactNode, useRef } from 'react';
import { BoxProps } from '@mui/material';
import { Panel } from './Panel';
import { IStickyItemProps, useStickyItem } from '../../hooks';

interface IChildrenProps {
  isSticky: boolean;
}

interface IProps extends Omit<BoxProps, 'children'> {
  children?: ReactNode | ((props: IChildrenProps) => ReactNode);
  bgcolorOpposite?: string;
}

export const StickyPanel: FC<IProps & Omit<IStickyItemProps, 'elRef'>> = ({
  children,
  bgcolor = 'highlight.white',
  bgcolorOpposite = 'transparent',
  isBottom = true,
  isHiddenOnLandscape,
  delayCalculation,
  ...rest
}) => {
  const stickyRef = useRef<HTMLDivElement>(null);
  const { isSticky } = useStickyItem({
    elRef: stickyRef,
    isBottom,
    isHiddenOnLandscape,
    delayCalculation,
  });

  return (
    <Panel
      ref={stickyRef}
      position={isSticky ? 'sticky' : 'initial'}
      bottom="env(safe-area-inset-bottom)"
      bgcolor={isSticky ? bgcolor : bgcolorOpposite}
      borderRadius={{ sm: '0.5rem 0.5rem 0 0' }}
      ml={{ md: -6 }}
      mx={{ xs: -3 }}
      px={{ xs: 3, md: 6 }}
      zIndex={1}
      {...rest}
    >
      {typeof children === 'function' ? children({ isSticky }) : children}
      <div style={{ position: 'fixed' }} />
    </Panel>
  );
};
