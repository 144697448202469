import React, { FC, KeyboardEvent, MouseEvent } from 'react';
import { Box, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { useIsTabletView } from '../../../hooks';
import { isKeyClick } from '../../../utils';

interface IProps {
  showHeader: boolean;
  handleToggleHeader: () => void;
  handleToggleSidebar: (event: KeyboardEvent | MouseEvent) => void;
}

const SidebarHamburgerIcon: FC<IProps> = ({
  showHeader,
  handleToggleHeader,
  handleToggleSidebar,
}) => {
  const isTabletView = useIsTabletView();
  return (
    <>
      {isTabletView ? (
        <Box>
          <CloseIcon
            fontSize="large"
            sx={{
              color: 'text.secondary',
              cursor: 'pointer',
            }}
            onClick={handleToggleSidebar}
          />
        </Box>
      ) : (
        <Box>
          {showHeader ? (
            <Button
              onClick={handleToggleHeader}
              onKeyUp={(e: KeyboardEvent<any>) => {
                if (isKeyClick(e)) {
                  handleToggleHeader();
                }
              }}
              aria-label="Close Menu"
              sx={{
                minWidth: 'initial',
                margin: '0',
              }}
            >
              <CloseIcon
                fontSize="large"
                sx={{
                  color: 'text.secondary',
                  cursor: 'pointer',
                }}
              />
            </Button>
          ) : (
            <Button
              onClick={handleToggleHeader}
              onKeyUp={(e: KeyboardEvent<any>) => {
                if (isKeyClick(e)) {
                  handleToggleHeader();
                }
              }}
              aria-label="Open Menu"
              sx={{
                minWidth: 'initial',
                margin: '0',
              }}
            >
              <MenuIcon
                fontSize="large"
                sx={{
                  color: 'text.secondary',
                  cursor: 'pointer',
                }}
              />
            </Button>
          )}
        </Box>
      )}
    </>
  );
};

export default SidebarHamburgerIcon;
