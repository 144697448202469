import React, { FC, KeyboardEvent, MouseEvent } from 'react';
import { Box, Stack } from '@mui/material';
import { useIsIndependentVIPage } from '../../../hooks';
import { BrandLogo } from '../../../components';
import PositionLink from '../../../features/positionLink/PositionLink';
import Navigation from '../../../features/navigation/Navigation';
import { Color } from '../../../types/color';
import SidebarHamburgerIcon from './SidebarHamburgerIcon';

interface IProps {
  showHeader: boolean;
  handleToggleHeader: () => void;
  handleToggleSidebar: (event: KeyboardEvent | MouseEvent) => void;
}

const SidebarContent: FC<IProps> = ({
  showHeader,
  handleToggleHeader,
  handleToggleSidebar,
}) => {
  const isIndependentVIPage = useIsIndependentVIPage();
  return (
    <Box>
      <Stack direction="row" gap={10} alignItems="center" mt={6} mb={6}>
        <BrandLogo />
        {isIndependentVIPage ? (
          <></>
        ) : (
          <SidebarHamburgerIcon
            showHeader={showHeader}
            handleToggleHeader={handleToggleHeader}
            handleToggleSidebar={handleToggleSidebar}
          />
        )}
      </Stack>
      <PositionLink isInSideBar color={Color.PRIMARY} />
      <Navigation handleToggleSidebar={handleToggleSidebar} />
    </Box>
  );
};

export default SidebarContent;
