import React from 'react';
import { CustomButton } from '../../../components';
import { Box, SvgIcon, Typography } from '@mui/material';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';

interface IProps {
  handleStartInterview: () => void;
}

const StartRecordingButton: React.FC<IProps> = ({ handleStartInterview }) => {
  return (
    <>
      <Box
        sx={(theme) => ({
          width: 511,
          position: 'relative',
          ['@media (max-width: 1200px)']: {
            width: '100%',
          },
          [theme.breakpoints.down('md')]: {
            width: 400,
          },
          ['@media (max-width: 400px)']: {
            width: '100%',
          },
        })}
      >
        <Box
          sx={{
            position: 'absolute',
            zIndex: 1,
            color: 'white',
            top: '46%',
            transform: 'translateY(-50%)',
            left: '26px',
          }}
        >
          <Typography variant="body2">Ready?</Typography>
        </Box>
        <CustomButton
          label="Start recording"
          startIcon={<SvgIcon inheritViewBox component={PlayCircleIcon} />}
          fullWidth
          onClick={handleStartInterview}
          color="primary"
        />
      </Box>
    </>
  );
};

export default StartRecordingButton;
