import React, { useEffect, useState } from 'react';
import { Box, RadioGroup, Typography } from '@mui/material';
import { PageTransitionWrapper } from '../../components';
import { useIsMobileView, useIsDesktopView } from '../../hooks';
import { getUserPrefferedName } from '../../utils';
import { useAppDispatch, useAppSelector } from '../../redux';
import {
  gamifiedResponseSelectors,
  gamifiedResponseSlice,
} from '../../redux/gamifiedResponse';
import { updateEngagement, userSelectors } from '../../redux/user';
import EngagementTable from './components/EngagementTable';
import MobileEngagementTable from './components/MobileEngagementTable';
import GoToPreviousStep from '../../components/goToPreviousStep/GoToPreviousStep';
import { logEvent } from 'src/services';

const Engagement = (): React.ReactElement => {
  const dispatch = useAppDispatch();
  const userData = useAppSelector(userSelectors.getUserData)!;
  const lastGamifiedMessage = useAppSelector(
    gamifiedResponseSelectors.getGamifiedResponse,
  );
  const [engagementSelected, setEngagementSelected] = useState<string | null>(
    userData?.employmentType ?? null,
  );
  const isMobileView = useIsMobileView();
  const isDesktopView = useIsDesktopView();

  useEffect(() => {
    if (userData.employmentType) {
      dispatch(gamifiedResponseSlice.actions.updateMessage(null));
    } else {
      if (!lastGamifiedMessage) {
        dispatch(
          gamifiedResponseSlice.actions.updateMessage(
            `Welcome back, ${getUserPrefferedName(userData)}!`,
          ),
        );
      }
    }

    logEvent('launchpod-engagement-page-loaded');
  }, []);

  const handleChange = (engagement: string) => {
    logEvent('launchpod-engagement-selected', {
      'Engagement Type': engagement,
    });

    setEngagementSelected(engagement);
    dispatch(gamifiedResponseSlice.actions.updateMessage('Got it! 👌'));
    setTimeout(() => {
      dispatch(updateEngagement(engagement));
    }, 500);
  };

  return (
    <PageTransitionWrapper>
      <Box display="flex" data-testid="engagement-layout">
        <GoToPreviousStep />
        <Typography variant="h2">
          What cooperation model
          <br />
          works best for you?
        </Typography>
      </Box>
      <Typography variant="body1">
        We need to ensure there are no legal or contract blockers to move on.
      </Typography>
      <RadioGroup value={engagementSelected ?? ''}>
        {isDesktopView ? (
          <Box mx={isMobileView ? 'auto' : '2rem'}>
            <MobileEngagementTable
              engagementSelected={engagementSelected}
              handleChange={handleChange}
            />
          </Box>
        ) : (
          <EngagementTable
            engagementSelected={engagementSelected}
            handleChange={handleChange}
          />
        )}
      </RadioGroup>
    </PageTransitionWrapper>
  );
};

export default Engagement;
