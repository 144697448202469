import React, { useState } from 'react';
import { Card, CardHeader, IconButton, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Color } from '../../types';
import { usePositionData } from '../../hooks';
import PositionMenu from './components/PositionMenu';

interface IProps {
  color?: Color;
  isInSideBar?: boolean;
}

export const PositionLink: React.FC<IProps> = ({
  color = Color.INVERTED,
  isInSideBar,
}) => {
  const positionData = usePositionData();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isOpen = Boolean(anchorEl);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {positionData !== null && positionData.isDisplayable !== false ? (
        <Card
          elevation={0}
          sx={(theme) => ({
            width: '100%',
            mb: isInSideBar ? 4 : 'initial',
            backgroundColor:
              color === Color.INVERTED
                ? theme.palette.background.positionLink
                : theme.palette.highlight.accent,
          })}
        >
          <CardHeader
            disableTypography
            action={
              <IconButton
                id="position-menu-button"
                aria-controls="position-menu"
                aria-haspopup="true"
                aria-expanded={isOpen ? 'true' : undefined}
                sx={{
                  color:
                    color === Color.INVERTED ? 'common.white' : 'common.black',
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                }}
                onClick={handleOpenMenu}
              >
                <MoreVertIcon />
              </IconButton>
            }
            title={
              <>
                <Typography
                  variant="body2"
                  color={
                    color === Color.INVERTED
                      ? 'text.invertedSecondary'
                      : 'text.secondary'
                  }
                  fontWeight="600"
                  fontSize="0.5rem"
                  textTransform="uppercase"
                >
                  Apply for a job
                </Typography>
                <Typography
                  variant="body1"
                  color={
                    color === Color.INVERTED
                      ? 'text.invertedPrimary'
                      : 'text.primary'
                  }
                  fontWeight="600"
                  lineHeight="130%"
                >
                  {positionData.title}
                </Typography>
              </>
            }
            subheader={
              <Typography
                variant="body2"
                color={
                  color === Color.INVERTED
                    ? 'text.invertedPrimary'
                    : 'text.primary'
                }
                fontWeight="500"
                mt={0.25}
              >
                {positionData.skill}
              </Typography>
            }
            sx={{
              py: 1.5,
              pr: 1,
              pl: 2.5,
              '& .MuiCardHeader-action': {
                alignSelf: 'center',
              },
            }}
          />
          <PositionMenu
            anchorEl={anchorEl}
            isOpen={isOpen}
            handleCloseMenu={handleCloseMenu}
            link={positionData.jobPostingUrl}
          />
        </Card>
      ) : null}
    </>
  );
};

export default PositionLink;
