import React, { useCallback } from 'react';
import { useAppDispatch } from 'src/redux';
import { useNavigate } from 'react-router-dom';

import { Menu, MenuItem, Typography } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import { resetPosition } from 'src/redux/positions';
import { APP_ROUTES } from 'src/constants';

interface IProps {
  anchorEl: HTMLButtonElement | null;
  handleCloseMenu: () => void;
  isOpen: boolean;
  link?: string;
}

const PositionMenu: React.FC<IProps> = ({
  anchorEl,
  handleCloseMenu,
  isOpen,
  link,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onResetClick = useCallback(() => dispatch(resetPosition()), []);
  const onCancelClick = useCallback(
    () => navigate(`/${APP_ROUTES.CANCEL_APPLICATION}`),
    [],
  );

  return (
    <Menu
      id="position-menu"
      aria-labelledby="position-menu-button"
      anchorEl={anchorEl}
      open={isOpen}
      onClose={handleCloseMenu}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <MenuItem
        component="a"
        href={link}
        target="_blank"
        onClick={handleCloseMenu}
      >
        <Typography variant="body1" fontWeight={500} mr={4}>
          View job description
        </Typography>
        <OpenInNewIcon sx={{ color: 'text.secondary' }} />
      </MenuItem>
      <MenuItem onClick={onResetClick}>
        <Typography variant="body1" fontWeight={500} mr={4}>
          Reset job position
        </Typography>
      </MenuItem>
      <MenuItem onClick={onCancelClick}>
        <Typography variant="body1" fontWeight={500} mr={4}>
          Cancel job application
        </Typography>
      </MenuItem>
    </Menu>
  );
};

export default PositionMenu;
