import { COMPENSATION_TYPE } from '../constants';

export const getCompensationAdornment = (compensationType: string | null) => {
  switch (compensationType) {
    case COMPENSATION_TYPE.YEARLY:
      return '/y';
    case COMPENSATION_TYPE.MONTHLY:
      return '/m';
    case COMPENSATION_TYPE.HOURLY:
      return '/h';
    default:
      return '';
  }
};
