import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { unsubscribeFromRemindersStoreKey } from './unsubscribeFromReminders.const';
import { postUnsubscribeFromReminders } from './unsubscribeFromReminders.thunks';
import { API_STATUS } from 'src/constants';
import { IUnsubscribeFromRemindersRequest } from 'src/types';

export interface IUnsubscribeFromRemindersSliceState {
  apiStatus: API_STATUS;
  data: IUnsubscribeFromRemindersRequest | null;
}

const initialState: IUnsubscribeFromRemindersSliceState = {
  apiStatus: API_STATUS.IDLE,
  data: null,
};

export const unsubscribeFromRemindersSlice = createSlice({
  name: unsubscribeFromRemindersStoreKey,
  initialState,
  reducers: {
    setUnsubscibeFromRemindersData: (
      state,
      action: PayloadAction<IUnsubscribeFromRemindersRequest>,
    ) => {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postUnsubscribeFromReminders.pending, (state) => {
        state.apiStatus = API_STATUS.LOADING;
      })
      .addCase(postUnsubscribeFromReminders.fulfilled, (state) => {
        state.apiStatus = API_STATUS.COMPLETE;
      })
      .addCase(postUnsubscribeFromReminders.rejected, (state) => {
        state.apiStatus = API_STATUS.FAILED;
      });
  },
});

export const { setUnsubscibeFromRemindersData } =
  unsubscribeFromRemindersSlice.actions;
