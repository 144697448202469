import { API_STATUS } from '../../constants';
import { IFeedbackSliceState } from './feedback.slice';
import { feedbackStoreKey } from './feedback.const';

interface IState {
  [feedbackStoreKey]: IFeedbackSliceState;
}

const selectFeedbackState = (state: IState) => {
  return state[feedbackStoreKey];
};

const getFeedbackApiStatus = (state: IState): API_STATUS => {
  return selectFeedbackState(state).apiStatus;
};

export const feedbackSelectors = {
  getFeedbackApiStatus,
};
