import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack } from '@mui/material';
import { ISubNavigationStep } from '../../../../types';
import QuestioneerStep from './QuizStep';
import { useDebounce } from '../../../../hooks';

interface IProps {
  steps: ISubNavigationStep[];
}

const QuizStepper: React.FC<IProps> = ({ steps }) => {
  const navigate = useNavigate();

  const handleEditStep = (step: ISubNavigationStep) => {
    if (step.route) {
      navigate(step.route);
    }
  };

  const debouncedHandleEditStep = useDebounce((step: ISubNavigationStep) => {
    handleEditStep(step);
  }, 500);

  return (
    <Stack direction="row" ml={4} mr={2}>
      <Stack rowGap={1.2} mb={6}>
        {steps.map((step, index) => (
          <QuestioneerStep
            key={index}
            step={step}
            handleEditStep={debouncedHandleEditStep}
          />
        ))}
      </Stack>
    </Stack>
  );
};

export default React.memo(QuizStepper);
