import React, { FC } from 'react';
import { Typography } from '@mui/material';
import { Panel, TextButton } from '../../../components';

interface IProps {
  testTaskReminderDate: string | null;
  handleScheduleForLater: () => void;
}
export const TTScheduledPanel: FC<IProps> = ({
  testTaskReminderDate,
  handleScheduleForLater,
}) => {
  return (
    <Panel maxWidth="25rem">
      <Typography variant="body1" fontWeight="600">
        The test has been scheduled for {testTaskReminderDate}
      </Typography>
      <Typography variant="body1">
        You will receive a calendar event that you can{' '}
        <TextButton onClick={handleScheduleForLater} underlined>
          reschedule or cancel
        </TextButton>
      </Typography>
    </Panel>
  );
};
