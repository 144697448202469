import { Box, Stack } from '@mui/material';
import React from 'react';

interface IProps {
  firstBlock?: React.ReactElement;
  secondBlock: React.ReactElement;
  hasBorder?: boolean;
  top?: string | number;
}

const MobileTableRow: React.FC<IProps> = ({
  firstBlock,
  secondBlock,
  hasBorder,
  top = 0,
}) => {
  return (
    <Stack
      direction="row"
      spacing={1}
      justifyContent="space-between"
      sx={(theme) => ({
        boxShadow: hasBorder ? 'inset 0px -1px 0px rgba(0, 0, 0, 0.1)' : 'none',
        position: hasBorder ? 'relative' : 'sticky',
        top: hasBorder ? 'auto' : top,
        backgroundColor: theme.palette.highlight.white,
        zIndex: hasBorder ? 0 : 1,
        mx: hasBorder
          ? 0
          : {
              xs: -3,
              sm: -6,
            },
        transition: 'all 0.2s',
      })}
    >
      {firstBlock && <Box py={2}>{firstBlock}</Box>}
      <Box py={hasBorder ? 2 : 0}>{secondBlock}</Box>
    </Stack>
  );
};

export default MobileTableRow;
