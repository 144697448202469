import React from 'react';
import { CustomButton, TextButton } from '../../../components';
import { Box, Stack, Typography } from '@mui/material';
import { formatReminderDate } from '../../../utils';
import { useAppDispatch, useAppSelector } from '../../../redux';
import { userSelectors } from '../../../redux/user';
import { setVideoInterviewReminderDate } from '../../../redux/videoInterview';

interface IProps {
  deviceIsReady: boolean;
  setShowScheduleDialog: (showScheduleDialog: boolean) => void;
  showRescheduleMessage: boolean;
  handleStartRecordSuccess: (e: React.SyntheticEvent | null) => void;
  setShowRescheduleMessage: (showRescheduleMessage: boolean) => void;
  questionNumber?: number;
}

const SelectionButtons: React.FC<IProps> = ({
  deviceIsReady,
  setShowScheduleDialog,
  showRescheduleMessage,
  setShowRescheduleMessage,
  handleStartRecordSuccess,
  questionNumber = 1,
}) => {
  const dispatch = useAppDispatch();
  const userData = useAppSelector(userSelectors.getUserData)!;
  const userReminderDate = userData?.videoInterviewReminderDate;

  const resetVIReminderDate = async () => {
    setShowScheduleDialog(false);
    await dispatch(
      setVideoInterviewReminderDate({ videoInterviewReminderDate: null }),
    ).unwrap();
    setShowRescheduleMessage(false);
  };

  return (
    <>
      {userReminderDate && showRescheduleMessage ? (
        <Box sx={{ maxWidth: '38rem' }}>
          <Box
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              background: theme.palette.highlight.accent,
              p: 3,
              mb: 3,
              mt: 3,
              alignItems: 'center',
            })}
          >
            <Typography variant="body1" fontWeight="bold">
              The video interview has been scheduled for{' '}
              {formatReminderDate(userReminderDate, userData.country!)}
            </Typography>
            <Typography variant="body1">
              You will receive a calendar event that you can{' '}
              <TextButton
                onClick={() => setShowScheduleDialog(true)}
                underlined
              >
                reschedule or cancel
              </TextButton>
            </Typography>
          </Box>
          <Stack direction="column" alignItems="center">
            <Typography variant="body2" color="secondary">
              In case you're still up to it now
            </Typography>
            <TextButton onClick={resetVIReminderDate}>Start now</TextButton>
          </Stack>
        </Box>
      ) : (
        <Box
          sx={(theme) => ({
            marginTop: '50px',
            display: 'flex',
            [theme.breakpoints.down('xl')]: {
              flexDirection: 'column',
              maxWidth: '511px',
              textAlign: 'center',
              marginTop: '20px',
            },
            ['@media (max-width: 1200px)']: {
              maxWidth: '100%',
            },
            [theme.breakpoints.down('md')]: {
              maxWidth: '350px',
              margin: '0 auto',
            },
          })}
        >
          <Box>
            <CustomButton
              label={`Proceed to question #${questionNumber}`}
              fullWidth
              isDisabled={!deviceIsReady}
              onClick={handleStartRecordSuccess}
              color="primary"
            />
          </Box>
          <Box
            sx={(theme) => ({
              ml: '24px',
              [theme.breakpoints.down('xl')]: {
                mt: '20px',
              },
            })}
          >
            <Typography variant="body2" color="text.secondary">
              Prefer to do it later?
            </Typography>
            <TextButton onClick={() => setShowScheduleDialog(true)}>
              {userReminderDate ? 'Reschedule event' : 'Add to your calendar'}
            </TextButton>
          </Box>
        </Box>
      )}
    </>
  );
};

export default SelectionButtons;
