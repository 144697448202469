import React from 'react';
import { Box, Typography } from '@mui/material';
import { BasicDialog, CustomButton } from '../../../components';
import VideoNotWorking from '../../../assets/video-not-working.png';

interface IProps {
  isOpen: boolean;
  handleClose: () => void;
}

const VideoInterviewDialog: React.FC<IProps> = ({ isOpen, handleClose }) => {
  return (
    <BasicDialog isOpen={isOpen} handleClose={handleClose} overflowVisible>
      <Box display="flex" justifyContent="center">
        <img src={VideoNotWorking} alt="video doesn't work" />
      </Box>
      <Typography variant="h2" textAlign="center" paddingTop={3}>
        Your current device doesn’t <br /> allow to record video
      </Typography>
      <Typography variant="body1" textAlign="center" my={4}>
        We’ve emailed you a link to proceed to the
        <br />
        video interview from your laptop or desktop computer.
      </Typography>
      <CustomButton
        label="Close"
        fullWidth
        onClick={handleClose}
        color="secondary"
      />
    </BasicDialog>
  );
};

export default VideoInterviewDialog;
