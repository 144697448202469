import { format } from 'date-fns';

import { COUNTRIES_WITH_AMPM } from '../constants';

export const formatDateTimeByCountry = (
  date: Date | string,
  formatStr: string,
  country: string,
) => {
  date = typeof date === 'string' ? new Date(date) : date;
  formatStr = COUNTRIES_WITH_AMPM.includes(country)
    ? `${formatStr} a`
    : formatStr;

  return format(date, formatStr);
};

export const formatReminderDate = (
  date: Date | string | null,
  country: string,
) => {
  if (!date) return null;

  date = typeof date === 'string' ? new Date(date) : date;
  const isAMPM = COUNTRIES_WITH_AMPM.includes(country);

  return `${format(date, 'EEEE, MMM d,')} at ${format(
    date,
    isAMPM ? 'hh:mm a' : 'HH:mm',
  )}`;
};
