import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { meetingStoreKey } from './meeting.const';
import { activeUserMeetingsAPI } from '../../constants/apiEndpoints';
import { formatErrorMessage } from '../../utils';
import { notificationAlert } from '../notifications';
import { IMeeting } from '@types';

export const getActiveUserMeetings = createAsyncThunk(
  `${meetingStoreKey}/getActiveUserMeetings`,
  async (_, thunkAPI) => {
    try {
      const response = await axios.get<IMeeting[]>(activeUserMeetingsAPI());
      return response.data;
    } catch (err: any) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(err);
    }
  },
);
