export interface ICalendlyData {
  id: string;
  link: string;
  country: string;
}

export enum CalendlyStatuses {
  ACTIVE = 'active',
  CANCELED = 'canceled',
}
