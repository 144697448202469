export const englishLevelMessage = (props: {
  englishLevelSelected: string;
}): string => {
  const { englishLevelSelected } = props;
  switch (englishLevelSelected) {
    case 'Pre-Intermediate':
      return 'Got it! Thanks!';
    case 'Intermediate':
      return 'Cool!';
    case 'Upper-Intermediate':
      return 'Wonderful!';
    case 'Advanced':
      return 'Outstanding!';
    default:
      return 'Got it! Thanks!';
  }
};
