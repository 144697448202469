import { CalendlyStatuses } from './calendly';

export enum MeetingType {
  INTRO_CALL = 1,
  TI_CALL = 2,
}

export interface IMeeting {
  id: string;
  userId: string;
  status: CalendlyStatuses;
  event: string;
  uri: string;
  rescheduled: boolean;
  reschedule_url: string;
  canceled: boolean;
  cancel_url: string;
  rawData: any;
  type: MeetingType;
  invitee: string;
  scheduledDate: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
}
