import { useMemo } from 'react';
import { ISubNavigationStep, IUserTestTask } from '../types';
import { APP_ROUTES, KnowledgeQuizStatus } from '../constants';
import { useUserTestTasks } from './useUserTestTasks';
import { useKnowledgeQuiz } from './useKnowledgeQuiz';

export const useQuizNavigationSteps = () => {
  const { currentTestTask } = useUserTestTasks() as {
    currentTestTask: IUserTestTask | null;
  };
  const { knowledgeQuiz } = useKnowledgeQuiz();

  const quizNavigationSteps = useMemo(() => {
    const steps: ISubNavigationStep[] = [];

    if (currentTestTask) {
      const quizTestTask = currentTestTask?.quizTestTask?.quiz ?? {};
      const quizAnswers = currentTestTask?.quizAnswers ?? {};

      steps.push({
        label: 'Pass a short test',
        description: 'You can answear in any order',
        route: '',
        isHeader: true,
        isActive: true,
      });

      Object.keys(quizTestTask).forEach((questionId: string) => {
        steps.push({
          label: `Question ${questionId}`,
          route: `${APP_ROUTES.QUIZ_TEST_TASK}/${questionId}`,
          isChecked: Boolean(quizAnswers?.[questionId]?.length),
          isEditable: true,
        });
      });
    } else if (knowledgeQuiz?.status === KnowledgeQuizStatus.Started) {
      steps.push({
        label: 'Take a 5 min knowledge quiz',
        description: 'You can answear in any order',
        route: '',
        isHeader: true,
        isActive: true,
      });

      Object.keys(knowledgeQuiz.questions).forEach((questionId: string) => {
        steps.push({
          label: `Question ${questionId}`,
          route: `${APP_ROUTES.KNOWLEDGE_QUIZ}/${questionId}`,
          isChecked: Boolean(knowledgeQuiz.answers?.[questionId]?.length),
          isEditable: true,
        });
      });
    }

    return steps;
  }, [currentTestTask, knowledgeQuiz]);

  return { quizNavigationSteps };
};
