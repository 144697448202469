import React, { FC, KeyboardEvent, MouseEvent } from 'react';
import { Box, Theme, Typography, SvgIcon } from '@mui/material';
import { INavigationStep } from '../../../../types';
import { isKeyClick } from '../../../../utils';
import { StepIcon } from '../../../../components';
import { useIsPage } from '../../../../hooks';

interface IProps {
  step: INavigationStep;
  handleStepClick: (
    event: KeyboardEvent | MouseEvent,
    step: INavigationStep,
  ) => void;
}

const MainStep: FC<IProps> = ({ step, handleStepClick }) => {
  const isStepActive = useIsPage(step.route ?? '');
  const {
    label,
    isActive,
    isCompleted,
    isSkipped,
    isNavigatable,
    numeration,
    route,
    icon,
  } = step;

  const showPointer = isNavigatable ?? (isActive && !isCompleted);

  const handleClick = (event: KeyboardEvent | MouseEvent) => {
    handleStepClick(event, step);
  };

  const getTextColor = (theme: Theme): string => {
    if (isStepActive && showPointer) {
      return theme.palette.text.purple;
    }
    if (showPointer) {
      return theme.palette.text.brand;
    }
    return theme.palette.text.primary;
  };

  return (
    <Box
      key={numeration}
      display="flex"
      alignItems="center"
      position="relative"
      pl={3.2}
    >
      <StepIcon
        index={numeration}
        isActive={isActive}
        isCompleted={isCompleted}
        isSkipped={isSkipped}
      />
      <Box
        onClick={handleClick}
        sx={(theme) => ({
          color: getTextColor(theme),
          cursor: showPointer ? 'pointer' : undefined,
          textDecoration: showPointer ? 'underline' : undefined,
          textDecorationThickness: '0.75px',
          textUnderlineOffset: '10%',
          pointerEvents: showPointer ? undefined : 'none',
        })}
        onKeyUp={(e: KeyboardEvent<any>) => {
          if (isKeyClick(e)) {
            e.stopPropagation();
            handleClick(e);
          }
        }}
        role={route && showPointer ? 'button' : ''}
        tabIndex={route && showPointer ? 0 : -1}
      >
        <Typography variant="body1">{label}</Typography>
      </Box>
      {icon && (
        <Box display="flex" alignItems="center" ml="10px">
          <SvgIcon
            inheritViewBox
            component={icon}
            sx={() => ({
              width: '15px',
            })}
          />
        </Box>
      )}
    </Box>
  );
};

export default MainStep;
