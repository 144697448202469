import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux';
import { useScrollToTop } from '../../../hooks';

import { Box, FormControlLabel, Grid, Typography } from '@mui/material';
import {
  ContinueMessage,
  GoBackOnSkills,
  SelectableWrapper,
} from '../../../components';
import { gamifiedResponseSlice } from '../../../redux/gamifiedResponse';
import { skillsSelectors } from '../../../redux/skills';
import {
  WORK_AREA_TYPE,
  WORK_AREA_TYPE_BY_SPECIALIZATION_TYPE,
} from '../../../constants';
import { useTechSkillsContext } from '../TechSkills';
import { ISpecializationData } from '@types';
import { logEvent } from 'src/services';

const getSpecializationBasedOnHighlevel = (
  specialization: string | null,
  specializationList: ISpecializationData[],
) => {
  if (!specialization) return [];

  return specializationList.filter((subObject) => {
    return subObject.parentSpecialization === specialization;
  });
};

const getGridHeight = (
  specializationLength: number,
  columnsCount: number,
): number => {
  const itemHeight = 64;
  const rowGap = 24;
  return (
    (specializationLength / columnsCount) * itemHeight + rowGap * columnsCount
  );
};

const Specialization: React.FC = () => {
  useScrollToTop();
  const dispatch = useAppDispatch();

  const {
    goBackToWorkArea,
    handleSpecializationChange,
    handleHighlevelSpecializationChange,
  } = useTechSkillsContext();
  const userTechStack = useAppSelector(skillsSelectors.getTechStack);
  const skillsData = useAppSelector(skillsSelectors.getSkillsData);

  const [showSubSpecializations, setShowSubSpecializations] = useState(false);

  useEffect(() => {
    if (userTechStack?.specialization) {
      dispatch(gamifiedResponseSlice.actions.updateMessage(null));
    }

    logEvent('launchpod-specialization-page-loaded');
  }, []);

  const handlePossibleSubSpecialization = (specialization: string) => {
    const isHighlevelSpecializationSelected = getSpecializationBasedOnHighlevel(
      specialization,
      skillsData.data.specializations,
    ).length;
    if (isHighlevelSpecializationSelected) {
      handleHighlevelSpecializationChange(specialization);
      setShowSubSpecializations(true);
    } else {
      handleSpecializationChange(specialization);
    }
  };

  const subSpecializationsBySelectedHighlevel = useMemo(() => {
    return getSpecializationBasedOnHighlevel(
      userTechStack?.highlevelSpecialization || null,
      skillsData.data.specializations,
    );
  }, [userTechStack?.highlevelSpecialization, skillsData.data.specializations]);

  const goBackToHighlevelSpecialization = () => {
    handleHighlevelSpecializationChange(userTechStack?.highlevelSpecialization);
    setShowSubSpecializations(false);
  };

  const isEngineeringWorkArea =
    userTechStack?.workArea === WORK_AREA_TYPE.ENGINEERING;

  const specializationsList = skillsData.data.specializations.filter(
    (specializationObj) =>
      WORK_AREA_TYPE_BY_SPECIALIZATION_TYPE[specializationObj.type] ===
        userTechStack?.workArea && !specializationObj.parentSpecialization,
  );

  return (
    <>
      <GoBackOnSkills
        workArea={userTechStack?.workArea || ''}
        goBackToWorkArea={goBackToWorkArea}
        subSpecialization={userTechStack?.highlevelSpecialization}
        goBackToSubSpecialization={
          showSubSpecializations ? goBackToHighlevelSpecialization : undefined
        }
      />
      <Typography variant="h2">What's your specialization?</Typography>
      <Box mb={2} data-testid="specialization-layout">
        {showSubSpecializations ? (
          <Grid
            container
            rowSpacing={0.5}
            columnSpacing={2}
            data-testid="subspecialization-layout"
          >
            <Grid item container rowSpacing={1} lg={6}>
              {subSpecializationsBySelectedHighlevel.map((specialization) => (
                <Grid item key={specialization.value} xs={12}>
                  <SelectableWrapper
                    value={specialization.value}
                    selectedValue={userTechStack?.specialization}
                    onClick={(specialization) => {
                      logEvent('launchpod-subspecialization-selected');
                      handleSpecializationChange(specialization);
                    }}
                    testid="subspecialization-option"
                  >
                    <FormControlLabel
                      checked={
                        specialization.value === userTechStack?.specialization
                      }
                      value={specialization.value}
                      control={<div />}
                      label={
                        <Typography variant="body1">
                          {specialization.title}
                        </Typography>
                      }
                    />
                  </SelectableWrapper>
                </Grid>
              ))}
            </Grid>
          </Grid>
        ) : (
          <Box
            display="grid"
            gap="8px"
            sx={(theme) => ({
              [theme.breakpoints.up('lg')]: {
                gridTemplateRows: 'repeat(auto-fill, 56px)',
                gridTemplateColumns: 'repeat(auto-fit, minmax(0, 1fr))',
                gridAutoFlow: 'column',
                columnGap: '24px',
                height: `${getGridHeight(specializationsList.length, 2)}px`,
              },
              [theme.breakpoints.up('xl')]: {
                height: `${getGridHeight(
                  specializationsList.length,
                  isEngineeringWorkArea ? 2 : 3,
                )}px`,
                maxWidth: isEngineeringWorkArea ? '80%' : '100%',
              },
              [theme.breakpoints.up('xxl')]: {
                height: `${getGridHeight(specializationsList.length, 2)}px`,
                maxWidth: isEngineeringWorkArea ? '80%' : '100%',
              },
            })}
          >
            {specializationsList.map((specialization) => (
              <SelectableWrapper
                key={specialization.value}
                value={specialization.value}
                selectedValue={
                  userTechStack?.highlevelSpecialization ||
                  userTechStack?.specialization
                }
                onClick={(specialization) => {
                  logEvent('launchpod-subspecialization-selected');

                  handlePossibleSubSpecialization(specialization);
                }}
                testid="specialization-option"
                sx={{
                  minHeight: '56px',
                  padding: '0 1.5rem',
                }}
              >
                <FormControlLabel
                  checked={
                    specialization.value ===
                    (userTechStack?.highlevelSpecialization ||
                      userTechStack?.specialization)
                  }
                  value={specialization.value}
                  control={<div />}
                  label={
                    <Typography variant="body1" sx={{ lineHeight: 1.2 }}>
                      {specialization.title}
                    </Typography>
                  }
                />
              </SelectableWrapper>
            ))}
          </Box>
        )}
      </Box>
      <Box>
        <ContinueMessage textIdentifier={1} />
      </Box>
    </>
  );
};

export default Specialization;
