import React from 'react';
import { TextButton } from '../../../components';
import { Popover, Typography } from '@mui/material';
import { AvailableTestTaskWithPositionsData } from '@types';

interface IProps {
  anchorEl: Element | ((element: Element) => Element) | null | undefined;
  requiredForPositionTT: AvailableTestTaskWithPositionsData | undefined;
  testTaskSelected: AvailableTestTaskWithPositionsData | null;
  handleClose: () => void;
}

const TTRequiredPositionPopover: React.FC<IProps> = ({
  anchorEl,
  requiredForPositionTT,
  testTaskSelected,
  handleClose,
}) => {
  return (
    <Popover
      id={'required-tt-popover'}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      PaperProps={{
        sx: (theme) => ({
          background: theme.palette.background.popoverBlue,
          maxWidth: '28rem',
          padding: '2rem',
          borderRadius: '0.5rem',
        }),
      }}
    >
      <Typography
        variant="body1"
        color="text.invertedPrimary"
        fontWeight={600}
        marginBottom={'1rem'}
      >
        You can take a test in {testTaskSelected?.name} but you wouldn't be
        eligible for the position you applied for because it required{' '}
        {requiredForPositionTT?.technicalFlow}.
      </Typography>
      <Typography
        variant="body1"
        color="text.invertedPrimary"
        marginBottom={'1rem'}
      >
        If you do a {testTaskSelected?.name} test we will suggest only{' '}
        {testTaskSelected?.technicalFlow} positions in the future, if you are
        available. You can also do tests in multiple languages to qualify for
        more positions.
      </Typography>
      <TextButton
        sx={{ color: 'text.invertedPrimary' }}
        onClick={handleClose}
        underlined
      >
        Got it
      </TextButton>
    </Popover>
  );
};

export default TTRequiredPositionPopover;
