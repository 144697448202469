import { API_STATUS } from '../../constants';
import { IFeatureFlags } from '../../types';
import { featureFlagsStoreKey } from './featureFlags.const';
import { IFeatureFlagsSliceState } from './featureFlags.slice';

interface IState {
  [featureFlagsStoreKey]: IFeatureFlagsSliceState;
}

export const selectFeatureFlagsState = (state: IState) => {
  return state[featureFlagsStoreKey];
};

export const getFeatureFlagsApiStatus = (state: IState): API_STATUS => {
  return selectFeatureFlagsState(state).apiStatus;
};

export const getFeatureFlags = (state: IState): IFeatureFlags => {
  return selectFeatureFlagsState(state).featureFlags;
};
