import { Countries } from './countriesList';
import { CurrencyType } from '../types';

export const BASE_CURRENCY: CurrencyType = 'USD';

export const MAX_YEARLY_COMPENSATION = 1200000;
export const MAX_MONTHLY_COMPENSATION = 100000;
export const MAX_HOURLY_COMPENSATION = 10000;

export enum COMPENSATION_TYPE {
  HOURLY = 'hourly',
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
}

export const COUNTRIES_WITH_YEARLY_COMPENSATION = [Countries.UnitedStates];

export const COMPENSATION_OPTIONS = [
  { type: COMPENSATION_TYPE.HOURLY, label: 'Hourly' },
  { type: COMPENSATION_TYPE.MONTHLY, label: 'Monthly' },
  { type: COMPENSATION_TYPE.YEARLY, label: 'Yearly' },
];

export const getRatioForCountry = (country: string | null): number => {
  switch (country) {
    case 'Argentina':
    case 'Brazil':
    case 'Colombia':
    case 'Guatemala':
    case 'Mexico':
    case 'USA':
      return 155.33;
    case 'USA':
      return 153.33;
    case 'India':
      return 149.33;
    case 'Bulgaria':
    case 'Romania':
    case 'Slovakia':
    case 'Slovenia':
    case 'Spain':
      return 152;
    case 'Portugal':
    case 'Poland':
      return 164.67;
    case 'Ukraine':
      return 166;
    default:
    case undefined:
      return 155.33;
  }
};
