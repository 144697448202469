import React from 'react';
import { Box, Typography } from '@mui/material';

import { ReactComponent as CalendlyIcon } from 'src/assets/calendly-icon.svg';
import { CustomButton, Flex, ResponsiveBreak } from 'src/components';
import { CalendlyEventType } from 'src/constants/calendly';
import { logEvent } from 'src/services';

interface IProps {
  calendlyLink: string | null;
  isCalendlyLoading: boolean;
  handleOpenCalendlyModal: (url: string, eventType: CalendlyEventType) => void;
}

const MeetingCancelledMessage: React.FC<IProps> = ({
  calendlyLink,
  isCalendlyLoading,
  handleOpenCalendlyModal,
}) => {
  const isShowCalendlyLink = typeof calendlyLink === 'string';

  return (
    <>
      <Typography>
        <b>The meeting has been canceled</b>
        <ResponsiveBreak breakpoint="xs" />
        You can schedule meeting once again
      </Typography>
      {isShowCalendlyLink && (
        <Box mt="1.5rem" data-testid="calendly-button">
          <CustomButton
            color="primary"
            startIcon={
              <Flex bgcolor="white" borderRadius={2} p={0.5}>
                <CalendlyIcon />
              </Flex>
            }
            label="Book a time slot"
            onClick={() => {
              logEvent('launchpod-book-time-slot-prescreen-call-clicked');
              handleOpenCalendlyModal(calendlyLink, CalendlyEventType.CREATED);
            }}
            isLoading={isCalendlyLoading}
          />
        </Box>
      )}
    </>
  );
};

export default MeetingCancelledMessage;
