export const ACCEPTED_EXTENSIONS: string[] = [
  '.txt',
  '.doc',
  '.docx',
  '.pdf',
  '.pages',
  '.odt',
];

export const RESOLUTION_FILE_ACCEPTED_EXTENSIONS = [
  ...ACCEPTED_EXTENSIONS,
  '.jpg',
  '.jpeg',
  '.png',
  '.csv',
];
