import { useEffect, useState } from 'react';
import { useAppSelector } from '../redux';
import { skillsSelectors } from '../redux/skills';
import { testTasksSelectors } from '../redux/testTasks';
import { userSelectors } from '../redux/user';
import { isStatusLoading } from '../utils';
import { featureFlagsSelectors } from '../redux/featureFlags';
import { userGeoLocationSelector } from '../redux/userGeoLocation';
import { videoInterviewSelectors } from '../redux/videoInterview';
import { knowledgeQuizSelectors } from '../redux/knowledgeQuiz';
import { meetingSelectors } from '../redux/meeting';
import { useLPLocation } from './useLPLocation';

export const useAppIsLoading = () => {
  const skillsData = useAppSelector(skillsSelectors.getSkillsData);
  const userApiStatus = useAppSelector(userSelectors.getUserApiStatus);
  const knowledgeQuizAPIStatus = useAppSelector(
    knowledgeQuizSelectors.getKnowledgeQuizAPIStatus,
  );
  const { apiStatus: testTaskAPIStatus } = useAppSelector(
    testTasksSelectors.getAvailableTestTasksApiData,
  );
  const featureFlagsApiStatus = useAppSelector(
    featureFlagsSelectors.getFeatureFlagsApiStatus,
  );
  const userGeoLocationApiStatus = useAppSelector(
    userGeoLocationSelector.getUserGeoLocationApiStatus,
  );
  const { apiStatus: availableVIDataAPIStatus } = useAppSelector(
    videoInterviewSelectors.getAvailableVideoInterviewAPIData,
  );
  const { countriesAPIStatus, citiesAPIStatus } = useLPLocation();
  const activeUserMeetingsApiStatus = useAppSelector(
    meetingSelectors.getMeetingsAPIStatus,
  );
  const { apiStatus: availableTTDataAPIStatus } = useAppSelector(
    testTasksSelectors.getAvailableTestTasksApiData,
  );

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(
      isStatusLoading(userApiStatus) ||
        isStatusLoading(skillsData.apiStatus) ||
        isStatusLoading(testTaskAPIStatus) ||
        isStatusLoading(countriesAPIStatus) ||
        isStatusLoading(citiesAPIStatus) ||
        isStatusLoading(featureFlagsApiStatus) ||
        isStatusLoading(availableVIDataAPIStatus) ||
        isStatusLoading(knowledgeQuizAPIStatus) ||
        isStatusLoading(userGeoLocationApiStatus) ||
        isStatusLoading(activeUserMeetingsApiStatus) ||
        isStatusLoading(availableTTDataAPIStatus),
    );
  }, [
    userApiStatus,
    skillsData.apiStatus,
    testTaskAPIStatus,
    countriesAPIStatus,
    citiesAPIStatus,
    featureFlagsApiStatus,
    userGeoLocationApiStatus,
    availableVIDataAPIStatus,
    knowledgeQuizAPIStatus,
    availableTTDataAPIStatus,
  ]);

  return isLoading;
};
