import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { API_STATUS } from '../../constants';
import { magicLinkCandidateStoreKey } from './magicLinkCandidate.const';
import { postUserWithZohoSignUp } from './magicLinkCandidate.thunks';

export interface IMagicLinkCandidateSliceState {
  apiStatus: API_STATUS;
  zohoId: string | null;
}

const initialState: IMagicLinkCandidateSliceState = {
  apiStatus: API_STATUS.IDLE,
  zohoId: null,
};

export const magicLinkCandidateSlice = createSlice({
  name: magicLinkCandidateStoreKey,
  initialState,
  reducers: {
    setMagicLinkCandidateZohoId: (state, action: PayloadAction<string>) => {
      state.zohoId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postUserWithZohoSignUp.pending, (state) => {
        state.apiStatus = API_STATUS.LOADING;
      })
      .addCase(postUserWithZohoSignUp.fulfilled, (state) => {
        state.apiStatus = API_STATUS.COMPLETE;
      })
      .addCase(postUserWithZohoSignUp.rejected, (state) => {
        state.apiStatus = API_STATUS.FAILED;
      });
  },
});

export const { setMagicLinkCandidateZohoId } = magicLinkCandidateSlice.actions;
