import { css, SerializedStyles } from '@emotion/react';
import { useTheme } from '@mui/material';

const CB_BORDER_RADIUS = '0.5rem';
const CB_TRANSITION_LONG = 'all 600ms cubic-bezier(0.3, 0.7, 0.4, 1)';
const CB_TRANSITION_MEDIUM = 'all 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5)';
const CB_TRANSITION_SHORT = 'transform 34ms';

export const useCustomButtonStyles = (
  color: 'primary' | 'secondary',
  fullWidth: boolean,
  isSquare: boolean,
  maxWidth?: string,
): SerializedStyles => {
  const theme = useTheme();

  return css`
    position: relative;
    border: none;
    background: transparent;
    padding: 0;
    cursor: pointer;
    text-decoration: none;
    outline-offset: 4px;
    transition: filter 250ms;
    width: ${fullWidth ? '100%' : 'initial'};
    max-width: ${maxWidth ? maxWidth : 'initial'};

    .ae-custom-button_front {
      display: flex;
      justify-content: center;
      position: relative;
      padding: ${isSquare ? '1rem' : '1rem 2rem'};
      border-radius: ${CB_BORDER_RADIUS};
      background: ${theme.palette.button[color].front};
      will-change: transform;
      transform: translateY(-4px);
      transition: ${CB_TRANSITION_LONG};
    }

    .ae-custom-button_text {
      font-size: 1rem;
      font-weight: 600;
      font-family: ${theme.typography.fontFamily};
      color: ${theme.palette.button[color].text};
      display: flex;
      flex-direction: row;
      justify-content: ${fullWidth ? 'center' : 'initial'};
      align-items: center;
    }

    &.is-loading .ae-custom-button_text {
      visibility: hidden;
    }

    &.is-custom-loading .ae-custom-button_text {
      visibility: visible;
    }

    .ae-custom-button_edge {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: ${CB_BORDER_RADIUS};
      background: ${theme.palette.button[color].edge};
    }

    .ae-custom-button_shadow {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: ${CB_BORDER_RADIUS};
      background: ${theme.palette.button[color].shadow};
      will-change: transform;
      transform: translateY(4px);
      transition: ${CB_TRANSITION_LONG};
    }

    .ae-custom-button_start-icon {
      display: flex;
      padding-right: ${isSquare ? 'initial' : '0.5rem'};
    }

    .ae-custom-button_end-icon {
      display: flex;
      padding-left: ${isSquare ? 'initial' : '0.5rem'};
    }

    .ae-custom-button_loader {
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
    }

    &:hover:not(.is-loading) .ae-custom-button_front {
      background-color: ${theme.palette.button[color].frontHover};
      transform: translateY(-8px);
      transition: ${CB_TRANSITION_MEDIUM};
    }

    &:hover:not(.is-loading) .ae-custom-button_edge {
      background-color: ${theme.palette.button[color].edgeHover};
    }

    &:hover:not(.is-loading) .ae-custom-button_shadow {
      background-color: ${theme.palette.button[color].shadowHover};
      transform: translateY(6px);
      transition: ${CB_TRANSITION_MEDIUM};
    }

    &:focus:not(.is-loading) .ae-custom-button_front {
      background-color: ${theme.palette.button[color].frontFocus};
      transform: translateY(-8px);
      transition: ${CB_TRANSITION_MEDIUM};
    }

    &:focus:not(.is-loading) .ae-custom-button_edge {
      background-color: ${theme.palette.button[color].edgeFocus};
    }

    &:focus:not(.is-loading) .ae-custom-button_shadow {
      background-color: ${theme.palette.button[color].shadowFocus};
      transform: translateY(6px);
      transition: ${CB_TRANSITION_MEDIUM};
    }

    &:focus-visible {
      outline: none;
    }

    &:active:not(.is-loading) .ae-custom-button_front,
    &.is-loading .ae-custom-button_front {
      background-color: ${theme.palette.button[color].frontActive};
      transform: translateY(-2px);
      transition: ${CB_TRANSITION_SHORT};
    }

    &:active:not(.is-loading) .ae-custom-button_edge,
    &.is-loading .ae-custom-button_edge {
      background-color: ${theme.palette.button[color].edgeActive};
    }

    &:active:not(.is-loading) .ae-custom-button_shadow,
    &.is-loading .ae-custom-button_shadow {
      background-color: ${theme.palette.button[color].shadowActive};
      transform: translateY(2px);
      transition: ${CB_TRANSITION_SHORT};
    }

    &:disabled,
    &.is-loading {
      pointer-events: none;
    }

    &:disabled .ae-custom-button_front {
      background-color: ${theme.palette.button[color].frontDisabled};
      transform: translateY(-2px);
      transition: ${CB_TRANSITION_SHORT};
    }

    &:disabled .ae-custom-button_text {
      color: ${theme.palette.button[color].textDisabled};
    }

    &:disabled .ae-custom-button_edge {
      background-color: ${theme.palette.button[color].edgeDisabled};
    }

    &:disabled .ae-custom-button_shadow {
      transform: translateY(0px);
      transition: ${CB_TRANSITION_SHORT};
    }
  `;
};
