import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../redux';
import { userSelectors } from '../redux/user';
import { isStatusIdle } from '../utils';
import {
  massCampaignCandidateSelectors,
  postUserWithMassCampaignSignUp,
} from '../redux/massCampaignCandidate';

export const useMassCampaignCandidate = () => {
  const dispatch = useAppDispatch();

  const userData = useAppSelector(userSelectors.getUserData);
  const apiStatus = useAppSelector(
    massCampaignCandidateSelectors.getMassCampaignCandidateApiStatus,
  );
  const massCampaignData = useAppSelector(
    massCampaignCandidateSelectors.getMassCampaignCandidateData,
  );

  useEffect(() => {
    if (userData && isStatusIdle(apiStatus) && massCampaignData) {
      dispatch(postUserWithMassCampaignSignUp(massCampaignData));
    }
  }, [userData, apiStatus, massCampaignData]);
};
