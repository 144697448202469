import React, { useRef } from 'react';

import { CustomButton, Flex, StepPanel, StepPanelHeader } from 'src/components';

import { useEmojisplosion } from 'src/hooks';

import starImage from 'src/assets/passing-star.png';

const ReferFriendSuccess = ({
  onReferMoreFriendsClick,
}: {
  onReferMoreFriendsClick: () => void;
}) => {
  const starRef = useRef<HTMLDivElement>(null);
  useEmojisplosion(starRef, 'DEFAULT');

  return (
    <StepPanel maxWidth="34rem">
      <StepPanelHeader ref={starRef} srcSetPng={starImage} alt="Star">
        Thanks for your referral
      </StepPanelHeader>
      <Flex width={{ xs: '100%', sm: 'auto' }}>
        <CustomButton
          onClick={onReferMoreFriendsClick}
          label="Refer more friends"
          color="primary"
          fullWidth
        />
      </Flex>
    </StepPanel>
  );
};

export default ReferFriendSuccess;
