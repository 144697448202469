import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { notificationsSelector } from '../redux/notifications';
import { clearNotificationAlert } from '../redux/notifications';

let displayed: string[] = [];

export const useNotifier = () => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const notifications = useAppSelector(notificationsSelector.getNotifications);

  const storeDisplayed = (id: string) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id: string) => {
    displayed = [...displayed.filter((key) => id !== key)];
  };

  const handleClose = (notificationKey: string | number) => {
    dispatch(clearNotificationAlert(String(notificationKey)));
    removeDisplayed(String(notificationKey));
  };

  useEffect(() => {
    notifications.forEach(
      ({ key, message, options = {}, dismissed = false }) => {
        if (dismissed) {
          // dismiss snackbar using notistack
          closeSnackbar(key);
          return;
        }

        // do nothing if snackbar is already displayed
        if (displayed.includes(key)) return;

        // display snackbar using notistack
        enqueueSnackbar(message, {
          key,
          ...options,
          action: (notificationKey: string | number) => (
            <IconButton onClick={() => handleClose(notificationKey)}>
              <CloseIcon fontSize="small" sx={{ color: 'common.white' }} />
            </IconButton>
          ),
          onClose: (event, reason, myKey) => {
            if (options.onClose) {
              options.onClose(event, reason, myKey);
            }
          },
          onExited: (_, notificationKey: string | number) => {
            handleClose(notificationKey);
          },
        });

        // keep track of snackbars that we've displayed
        storeDisplayed(key);
      },
    );
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);
};
