import React from 'react';
import { Typography } from '@mui/material';
import { ResponsiveBreak } from '../../../../../components';

interface IProps {
  primarySkills?: string[] | null;
}

const NotSupportedProgrammingLanguageMessage: React.FC<IProps> = ({
  primarySkills,
}) => {
  const renderProgrammingLanguages = () => {
    if (!primarySkills) return 'your technologies';

    if (primarySkills?.length === 1) {
      return <b>{primarySkills[0]}</b>;
    }

    if (primarySkills?.length > 1) {
      return primarySkills.map((skill, index) => (
        <b>
          {skill}
          {index !== primarySkills?.length - 1 ? ', ' : ''}
        </b>
      ));
    }
  };

  return (
    <>
      <Typography variant="body1" data-testid="success-text">
        We are thrilled to have you here, but{' '}
        <ResponsiveBreak breakpoint="sm" />
        we currently have no open projects involving{' '}
        <ResponsiveBreak breakpoint="sm" />
        {renderProgrammingLanguages()}. However, we launch 2–3 new{' '}
        <ResponsiveBreak breakpoint="sm" />
        projects every month, and we would be happy{' '}
        <ResponsiveBreak breakpoint="sm" />
        to offer you something exciting in the future.
      </Typography>
      <Typography variant="body1">
        Let’s stay in touch and reconnect <ResponsiveBreak breakpoint="sm" />
        when the time is right.
      </Typography>
    </>
  );
};

export default NotSupportedProgrammingLanguageMessage;
