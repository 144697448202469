import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { unsubscribeFromRemindersStoreKey } from './unsubscribeFromReminders.const';
import { formatErrorMessage } from '../../utils';
import { notificationAlert } from '../notifications';
import { unsubscribeTTRemindersPath } from '../../constants/apiEndpoints';
import { IUnsubscribeFromRemindersRequest } from '@types';

export const postUnsubscribeFromReminders = createAsyncThunk(
  `${unsubscribeFromRemindersStoreKey}/postUnsubscribe`,
  async (body: IUnsubscribeFromRemindersRequest, thunkAPI) => {
    try {
      const response = await axios.post(unsubscribeTTRemindersPath(), body);
      return response.data;
    } catch (err: any) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(err);
    }
  },
);
