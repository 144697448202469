import React, { useEffect, useState } from 'react';
import {
  Box,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import {
  ContinueMessage,
  PageTransitionWrapper,
  RadioButton,
  SelectableWrapper,
} from '../../components';
import { SENIORITY_OPTIONS } from '../../constants';
import { getUserPrefferedName } from '../../utils';
import { useAppDispatch, useAppSelector } from '../../redux';
import { updateSeniority, userSelectors } from '../../redux/user';
import {
  gamifiedResponseSelectors,
  gamifiedResponseSlice,
} from '../../redux/gamifiedResponse';
import GoToPreviousStep from '../../components/goToPreviousStep/GoToPreviousStep';
import { seniorityMessage } from './components/seniorityMessage';
import { logEvent } from 'src/services';

const STEP_MAX_WIDTH = '14rem';

const Seniority: React.FC = () => {
  const dispatch = useAppDispatch();
  const userData = useAppSelector(userSelectors.getUserData)!;
  const lastGamifiedMessage = useAppSelector(
    gamifiedResponseSelectors.getGamifiedResponse,
  );
  const [seniorityChecked, setSeniorityChecked] = useState<string | null>(
    userData.seniority ?? null,
  );

  useEffect(() => {
    if (userData.seniority) {
      dispatch(gamifiedResponseSlice.actions.updateMessage(null));
    } else {
      if (!lastGamifiedMessage) {
        dispatch(
          gamifiedResponseSlice.actions.updateMessage(
            `Welcome back, ${getUserPrefferedName(userData)}!`,
          ),
        );
      }
    }

    logEvent('launchpod-seniority-page-loaded');
  }, []);

  const handleChange = (value: string) => {
    logEvent('launchpod-seniority-selected');

    setSeniorityChecked(value);
    dispatch(
      gamifiedResponseSlice.actions.updateMessage(seniorityMessage(value)),
    );
    setTimeout(() => {
      dispatch(updateSeniority(value));
    }, 500);
  };

  return (
    <PageTransitionWrapper>
      <Box display="flex" data-testid="seniority-layout">
        <GoToPreviousStep />
        <Typography variant="h2">How do you rate your seniority?</Typography>
      </Box>
      <Box mb={2}>
        <FormControl sx={{ width: '100%' }}>
          <RadioGroup value={seniorityChecked}>
            <Stack spacing={0.5}>
              {SENIORITY_OPTIONS.map((seniorityObject) => {
                return (
                  <Box
                    key={seniorityObject.value}
                    sx={(theme) => ({
                      width: STEP_MAX_WIDTH,
                      [theme.breakpoints.down('md')]: {
                        width: '100%',
                      },
                    })}
                  >
                    <SelectableWrapper
                      key={seniorityObject.value}
                      value={seniorityObject.value}
                      selectedValue={seniorityChecked}
                      onClick={() => handleChange(seniorityObject.value)}
                      display="block"
                      testid="seniority-option"
                    >
                      <FormControlLabel
                        value={seniorityObject.value}
                        control={<RadioButton tabIndex={-1} />}
                        label={
                          <Typography variant="body1">
                            {seniorityObject.label}
                          </Typography>
                        }
                      />
                    </SelectableWrapper>
                  </Box>
                );
              })}
            </Stack>
          </RadioGroup>
        </FormControl>
      </Box>
      <ContinueMessage textIdentifier={1} />
    </PageTransitionWrapper>
  );
};

export default Seniority;
