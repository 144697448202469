import { useCallback, useEffect, useRef } from 'react';
import { DebounceSettings } from 'lodash';
import debounce from 'lodash/debounce';

export const useDebounce = (
  callback: (...args: any[]) => void,
  delay: number,
  options: DebounceSettings = { leading: false, trailing: true },
) => {
  const inputsRef = useRef(callback);
  useEffect(() => {
    inputsRef.current = callback;
  });
  return useCallback(
    debounce((...args) => inputsRef.current(...args), delay, options),
    [delay],
  );
};
