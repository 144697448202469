/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';

import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { motion } from 'framer-motion';
import { TextButton } from '../textButton/TextButton';

import { useCookieConsentSnackbarStyles } from './CookieConsentSnackbar.style';
import {
  ANIMATION_DELAY,
  ANIMATION_DURATION,
  APP_ROUTES,
} from '../../constants';

export const CookieConsentSnackbar: React.FC = () => {
  const style = useCookieConsentSnackbarStyles();

  const [isShown, setIsShown] = useState(!window.Cookiebot?.hasResponse);

  const onCookiebotOnLoad = () => {
    setIsShown(!window.Cookiebot.hasResponse);
  };

  useEffect(() => {
    window.addEventListener('CookiebotOnLoad', onCookiebotOnLoad);

    return () => {
      window.removeEventListener('CookiebotOnLoad', onCookiebotOnLoad);
    };
  }, []);

  const onMoreDetailsClick = () => {
    window.open(`/${APP_ROUTES.TERMS_OF_USE}#cookies`, '_blank');
  };
  const onOpenDialogClick = () => window.Cookiebot.show();
  const onDenyClick = () =>
    window.Cookiebot.submitCustomConsent(false, false, false, false);
  const onAllowClick = () =>
    window.Cookiebot.submitCustomConsent(true, true, true, false);

  if (!isShown) return null;

  return (
    <motion.div
      css={style}
      initial={{ y: '100%' }}
      animate={{ y: 0 }}
      transition={{
        duration: ANIMATION_DURATION - ANIMATION_DELAY,
        delay: ANIMATION_DELAY,
      }}
    >
      <div className="container">
        <Typography variant="body1">
          This website uses cookies.{' '}
          <Box
            component="br"
            sx={(theme) => ({
              [theme.breakpoints.up('lg')]: {
                display: 'none',
              },
            })}
          />
          <TextButton
            onClick={onMoreDetailsClick}
            variant="secondary"
            underlined
            sx={({ palette }) => ({
              color: palette.button.primary.text,
              fontWeight: 500,
              opacity: 0.7,
            })}
          >
            More details →
          </TextButton>
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          gap={{ xs: 3, lg: 5 }}
          mt={{ xs: 1, lg: 0 }}
        >
          <TextButton
            onClick={onOpenDialogClick}
            sx={({ palette }) => ({ color: palette.button.primary.text })}
          >
            Customize...
          </TextButton>
          <TextButton
            onClick={onDenyClick}
            sx={({ palette }) => ({ color: palette.button.primary.text })}
          >
            Deny
          </TextButton>
          <TextButton onClick={onAllowClick}>Allow all</TextButton>
        </Box>
      </div>
    </motion.div>
  );
};
