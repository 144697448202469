import React from 'react';
import {
  useAppIsLoading,
  useCaptureQueryData,
  useFetchUser,
  useUserTestTasks,
  useInitInterceptors,
  useNotifier,
  useScrollToTop,
  useUserRedirection,
  useLPLocation,
  useFetchSkillsData,
  useFetchFeatureFlags,
  useFetchUserGeoLocation,
  useMagicLinkCandidate,
  useMassCampaignCandidate,
  useKnowledgeQuiz,
  useAnalytics,
  useUserMeetings,
  useCandidateSource,
  usePositionData,
} from './hooks';
import { Loader, PageWrapper } from './components';
import Routes from './routes/Routes';

const App: React.FC = () => {
  useInitInterceptors();
  useCaptureQueryData();
  useFetchUser();
  useFetchFeatureFlags(true);
  useUserRedirection();
  useNotifier();
  useScrollToTop();
  useLPLocation(true);
  useFetchSkillsData(true);
  useFetchUserGeoLocation(true);
  useUserTestTasks(true);
  useMagicLinkCandidate();
  useMassCampaignCandidate();
  useCandidateSource();
  useKnowledgeQuiz(true);
  useAnalytics();
  useUserMeetings(true);
  usePositionData(true);

  const isAppLoading = useAppIsLoading();

  if (isAppLoading) {
    return (
      <PageWrapper>
        <Loader showBrandLogo />
      </PageWrapper>
    );
  }

  return <Routes />;
};

export default App;
