import React from 'react';
import BasicDialog from '../../components/basicDialog/BasicDialog';
import AccountDataForm from './components/AccountDataForm';

interface IProps {
  isOpen: boolean;
  handleClose: () => void;
}

const AccountDataDialog: React.FC<IProps> = ({
  isOpen = true,
  handleClose,
}) => {
  return (
    <BasicDialog maxWidth="36rem" isOpen={isOpen} handleClose={handleClose}>
      <AccountDataForm handleClose={handleClose} />
    </BasicDialog>
  );
};

export default AccountDataDialog;
