import React from 'react';
import { motion } from 'framer-motion';
import { styled } from '@mui/system';
import { Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckIcon from '@mui/icons-material/Check';

const IconWrapper = styled('div')({
  position: 'absolute',
  width: '2rem',
  height: '2rem',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  transform: 'translateX(calc(-50% - 0.5px))',
  top: '-0.25rem',
  left: 0,
});

const IconContainer = styled(motion.div)({
  width: '1.5rem',
  height: '1.5rem',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '0.5rem',
  boxShadow: '0 0 0 0.1rem #ffffff',
});

interface IProps {
  index?: number;
  isActive?: boolean;
  isCompleted?: boolean;
  isSkipped?: boolean;
  isPending?: boolean;
}

export const StepIcon: React.FC<IProps> = ({
  index,
  isActive,
  isCompleted,
  isSkipped,
  isPending,
}) => {
  if (isCompleted) {
    return (
      <IconWrapper>
        <IconContainer
          sx={(theme) => ({
            backgroundColor: theme.palette.success.main,
          })}
        >
          <CheckIcon
            sx={{
              fontSize: '1rem',
              color: (theme) => theme.palette.common.white,
            }}
          />
        </IconContainer>
      </IconWrapper>
    );
  }

  if (isActive) {
    return (
      <IconWrapper>
        <IconContainer
          sx={(theme) => ({
            backgroundColor: theme.palette.background.brandSecondary,
          })}
        >
          <ArrowForwardIcon
            sx={{
              fontSize: '1rem',
              color: (theme) => theme.palette.common.white,
            }}
          />
        </IconContainer>
      </IconWrapper>
    );
  }

  if (isSkipped) {
    return (
      <IconWrapper>
        <IconContainer
          sx={(theme) => ({
            backgroundColor: theme.palette.background.stepsCircle,
          })}
        >
          <CloseIcon
            sx={{
              fontSize: '1rem',
              color: (theme) => theme.palette.common.white,
            }}
          />
        </IconContainer>
      </IconWrapper>
    );
  }

  if (isPending) {
    return (
      <IconWrapper>
        <IconContainer>
          <AccessTimeIcon
            sx={{
              fontSize: '2rem',
              color: (theme) => theme.palette.background.stepsCircle,
            }}
          />
        </IconContainer>
      </IconWrapper>
    );
  }

  return (
    <IconWrapper>
      <IconContainer
        sx={(theme) => ({
          backgroundColor: theme.palette.background.stepsCircle,
        })}
      >
        <Typography
          sx={{
            fontWeight: 'bold',
            fontFamily: 'Arial, sans‑serif',
            fontSize: '0.8rem',
            color: (theme) => theme.palette.common.white,
          }}
        >
          {index}
        </Typography>
      </IconContainer>
    </IconWrapper>
  );
};
