import React, { forwardRef } from 'react';
import { Box, BoxProps, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

export interface IPanelProps extends BoxProps {
  sx?: SxProps<Theme>;
}

export const Panel = forwardRef<unknown, IPanelProps>(
  ({ children, sx, bgcolor = 'highlight.accent', ...rest }, ref) => {
    return (
      <Box
        ref={ref}
        bgcolor={bgcolor}
        borderRadius={'0.25rem'}
        py={2}
        px={3}
        sx={sx}
        {...rest}
      >
        {children}
      </Box>
    );
  },
);
