import { Stack, Typography, alpha } from '@mui/material';
import { guaranteeUserIcon } from 'src/constants/apiEndpoints';

export const GuaranteeDescription = () => {
  return (
    <Stack
      direction="row"
      padding="1rem 0.875rem"
      bgcolor={(theme) => alpha(theme.palette.background.brandSecondary, 0.05)}
      borderRadius="1rem"
      marginLeft="-1rem"
      sx={(theme) => ({
        gap: 2,
        [theme.breakpoints.down('md')]: {
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
        },
      })}
    >
      <img
        src={guaranteeUserIcon}
        alt="GuaranteeUserIcon"
        width="177px"
        height="107px"
      />
      <Typography variant="body1" margin="auto">
        This short video gives you 100% chance of being shown to your direct
        Hiring Manager prior to the other candidates.
      </Typography>
    </Stack>
  );
};
