import { createSlice } from '@reduxjs/toolkit';
import { resolveUserDuplicationStoreKey } from './resolveUserDuplication.const';
import { selectMainUser } from './resolveUserDuplication.thunks';

export interface IResolveUserDuplicationSliceState {
  isLoading: boolean;
}

const initialState: IResolveUserDuplicationSliceState = {
  isLoading: false,
};

export const resolveUserDuplicationSlice = createSlice({
  name: resolveUserDuplicationStoreKey,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(selectMainUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(selectMainUser.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(selectMainUser.rejected, (state) => {
        state.isLoading = false;
      });
  },
});
