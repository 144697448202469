import React, { useMemo, useState } from 'react';
import { add, set, formatISO } from 'date-fns';
import { Box, Grid, TextField, Typography } from '@mui/material';
import { BasicDialog, CustomButton } from '../';
import clockIcon from '../../assets/clock-icon.png';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { useAppDispatch, useAppSelector } from '../../redux';

import { userSelectors } from '../../redux/user';
import { COUNTRIES_WITH_AMPM, TestTaskType } from '../../constants';
import { LoadingEventType } from '../../types/loadingEventType';
import { DateValidationError } from '@mui/x-date-pickers/internals';
import { TimeValidationError } from '@mui/x-date-pickers/internals/hooks/validation/useTimeValidation';
import { useDebounce } from '../../hooks';
import {
  setVideoInterviewReminderDate,
  videoInterviewSelectors,
} from '../../redux/videoInterview';
import {
  setTestTaskReminderDate,
  testTasksSelectors,
} from '../../redux/testTasks';

interface IProps {
  handleClose: () => void;
  handleScheduleSuccess: () => void;
  isOpen: boolean;
  testTaskType?: TestTaskType;
  isVideoInterview?: boolean;
}

export const ScheduleDialog: React.FC<IProps> = ({
  handleClose,
  handleScheduleSuccess,
  isOpen = true,
  testTaskType = TestTaskType.CODILITY,
  isVideoInterview = false,
}) => {
  const dispatch = useAppDispatch();
  const [loadingType, setLoadingType] = useState<LoadingEventType | null>(null);
  const userData = useAppSelector(userSelectors.getUserData)!;
  const [dateError, setDateError] = useState<DateValidationError | null>(null);
  const [timeError, setTimeError] = useState<TimeValidationError | null>(null);
  const setDebouncedTime = useDebounce((newValue) => setTime(newValue), 500);

  const currentReminderDate = isVideoInterview
    ? userData.videoInterviewReminderDate
    : userData.testTaskReminderDate;

  const isLoading = isVideoInterview
    ? useAppSelector(
        videoInterviewSelectors.getIsVideoInterviewPerformingAction,
      )
    : useAppSelector(testTasksSelectors.getIsPerformingTestTaskAction);

  const [date, setDate] = useState<Date | null>(
    currentReminderDate
      ? new Date(currentReminderDate)
      : add(new Date(), { days: 1 }),
  );

  const [time, setTime] = useState<Date | null>(
    currentReminderDate
      ? new Date(currentReminderDate)
      : set(date!, { hours: 9, minutes: 0, seconds: 0 }),
  );

  const handleSubmitTestTaskDate = async () => {
    const _date = formatISO(date!, { representation: 'date' });
    const _time = formatISO(time!, { representation: 'time' });
    const testTaskReminderDate = new Date(`${_date}T${_time}`).toISOString();
    if (testTaskReminderDate != userData.testTaskReminderDate) {
      setLoadingType('RESCHEDULE');
      await dispatch(
        setTestTaskReminderDate({ testTaskReminderDate, testTaskType }),
      ).unwrap();
      setLoadingType(null);
    }
    handleScheduleSuccess();
  };

  const handleSubmitVideoInterviewDate = async () => {
    const _date = formatISO(date!, { representation: 'date' });
    const _time = formatISO(time!, { representation: 'time' });
    const videoInterviewReminderDate = new Date(
      `${_date}T${_time}`,
    ).toISOString();
    if (videoInterviewReminderDate != userData.videoInterviewReminderDate) {
      setLoadingType('RESCHEDULE');
      await dispatch(
        setVideoInterviewReminderDate({ videoInterviewReminderDate }),
      ).unwrap();
      setLoadingType(null);
    }
    handleScheduleSuccess();
  };

  const handleDate = (newValue: Date | null) => {
    setDate(newValue);

    if (!(newValue instanceof Date && !isNaN(newValue.getTime())) || !time)
      return;

    const _date = formatISO(newValue, { representation: 'date' });
    const _time = formatISO(time, { representation: 'time' });
    setTime(new Date(`${_date}T${_time}`));
  };

  const handleCancel = async () => {
    setLoadingType('CANCEL');
    if (isVideoInterview) {
      await dispatch(
        setVideoInterviewReminderDate({ videoInterviewReminderDate: null }),
      ).unwrap();
    } else {
      await dispatch(
        setTestTaskReminderDate({
          testTaskReminderDate: null,
          testTaskType: testTaskType,
        }),
      ).unwrap();
    }
    setLoadingType(null);
    handleClose();
  };

  const ampm = useMemo(
    () =>
      userData.country ? COUNTRIES_WITH_AMPM.includes(userData.country) : false,
    [userData.country],
  );

  const isDisabledSchedule = useMemo(
    () => !date || !time || Boolean(dateError) || Boolean(timeError),
    [date, time, dateError, timeError],
  );

  return (
    <BasicDialog isOpen={isOpen} handleClose={handleClose} maxWidth="34rem">
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
        <img src={clockIcon} alt="Schedule" />
      </Box>
      <Typography variant="h2" textAlign="center" mb={4}>
        {isVideoInterview
          ? `Choose what time\n works best`
          : 'Schedule for later'}
      </Typography>
      <Typography variant="body1" textAlign="center" gutterBottom mb={2}>
        We will send you a calendar event and couple reminders
        {isVideoInterview
          ? `\n to make sure you won't miss it`
          : 'for your preferable time'}
      </Typography>
      <Grid container spacing={1} mb={2}>
        <Grid item xs={12} md={7}>
          <DatePicker
            views={['day']}
            inputFormat="MMMM dd"
            value={date}
            onChange={handleDate}
            disablePast
            maxDate={add(new Date(), { days: 7 })}
            disableMaskedInput
            renderInput={(params) => (
              <TextField fullWidth hiddenLabel {...params} />
            )}
            onError={setDateError}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <TimePicker
            value={time}
            onChange={setDebouncedTime}
            renderInput={(params) => (
              <TextField fullWidth hiddenLabel {...params} />
            )}
            ampm={ampm}
            disableIgnoringDatePartForTimeValidation={true}
            minTime={new Date()}
            onError={setTimeError}
          />
        </Grid>
      </Grid>
      <Box mb={currentReminderDate ? 2 : 6}>
        <CustomButton
          label={currentReminderDate ? 'Reschedule' : 'Remind me'}
          fullWidth
          onClick={
            isVideoInterview
              ? handleSubmitVideoInterviewDate
              : handleSubmitTestTaskDate
          }
          isLoading={isLoading && loadingType === 'RESCHEDULE'}
          isDisabled={isDisabledSchedule}
        />
      </Box>
      {currentReminderDate && (
        <Box mb={6}>
          <CustomButton
            label="Cancel event"
            fullWidth
            onClick={handleCancel}
            isLoading={isLoading && loadingType === 'CANCEL'}
            color="secondary"
          />
        </Box>
      )}
    </BasicDialog>
  );
};
