import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/redux';

import { CancelApplicationLayout } from 'src/features/cancelApplication/CancelApplicationLayout';

import { cancelApplication, userSelectors } from 'src/redux/user';
import { gamifiedResponseSlice } from 'src/redux/gamifiedResponse';

import { isStatusComplete, isStatusLoading } from 'src/utils';
import { API_STATUS } from 'src/constants';

export const CancelApplication = () => {
  const dispatch = useAppDispatch();

  const profile = useAppSelector(userSelectors.getUserData);

  const [apiStatus, setAPIStatus] = useState(API_STATUS.IDLE);

  const isLoading = isStatusLoading(apiStatus);
  const isSubmitted = isStatusComplete(apiStatus);

  useEffect(() => {
    dispatch(gamifiedResponseSlice.actions.updateMessage(null));
  }, []);

  const onSubmit = async (optionSelected: string) => {
    setAPIStatus(API_STATUS.LOADING);

    const res = await dispatch(cancelApplication(optionSelected));

    setAPIStatus(
      cancelApplication.fulfilled.match(res)
        ? API_STATUS.COMPLETE
        : API_STATUS.FAILED,
    );
  };

  return (
    <CancelApplicationLayout
      userName={profile?.firstName || ''}
      isLoading={isLoading}
      isSubmitted={isSubmitted}
      onSubmit={onSubmit}
    />
  );
};
