import { configureStore, PreloadedState } from '@reduxjs/toolkit';
import { RootState } from './types';
import { rootReducer } from './store';

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    devTools: {
      trace: true,
    },
  });
};
