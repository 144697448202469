import { Typography, Stack, Link, SvgIcon } from '@mui/material';
import { CustomButton, Loader } from 'src/components';
import { ReactComponent as CodilityIcon } from '../../../assets/codility-icon.svg';

interface IProps {
  testLink: string | null;
}

const PendingResults = ({ testLink }: IProps): JSX.Element => {
  return (
    <Stack spacing={1} direction="column" mt={4}>
      <Typography
        variant="body1"
        mb={1}
        data-testid="tt-pending-result-subtext"
        sx={(theme) => ({
          [theme.breakpoints.up('lg')]: {
            whiteSpace: 'nowrap',
          },
        })}
      >
        Please finish your{' '}
        <Link role="link" href={testLink ?? '#'}>
          coding test on Codility
        </Link>{' '}
        and wait for results.
      </Typography>
      <Loader position="auto" />
      <Typography component={'span'} variant="body1" mt={4}>
        <CustomButton
          color="primary"
          label="Back to the test"
          testid="back-to-test-button"
          startIcon={<SvgIcon inheritViewBox component={CodilityIcon} />}
          onClick={() => {
            window.location.replace(testLink || '#');
          }}
          maxWidth="16rem"
          fullWidth
        />
      </Typography>
    </Stack>
  );
};

export default PendingResults;
