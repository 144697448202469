import React from 'react';
import { BoxProps } from '@mui/material';
import { AnimatedBox } from '../animatedBox/AnimatedBox';

interface IProps extends BoxProps {
  children: React.ReactNode;
}

const variants = {
  hidden: {
    opacity: 0,
    x: '75%',
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      delay: 0.1,
      duration: 0.2,
    },
  },
  exit: {
    opacity: 0,
    x: '-25%',
    transition: {
      duration: 0.2,
    },
  },
};

export const PageTransitionWrapper: React.FC<IProps> = ({
  children,
  ...rest
}) => {
  return (
    <AnimatedBox
      variants={variants}
      initial="hidden"
      animate="visible"
      exit="exit"
      {...rest}
    >
      {children}
    </AnimatedBox>
  );
};
