import { exchangeRatesStoreKey } from './exchangeRates.const';
import { IExchangeRatesSliceState } from './exchangeRates.slice';

interface IState {
  [exchangeRatesStoreKey]: IExchangeRatesSliceState;
}

const selectExchangeRateState = (state: IState) => {
  return state[exchangeRatesStoreKey];
};

const getExchangeRatesData = (state: IState) => {
  return selectExchangeRateState(state).data;
};

export const exchangeRatesSelectors = {
  getExchangeRatesData,
};
