import { Box, Typography } from '@mui/material';
import { LOCAL_STORAGE_KEYS, TestTaskType } from '../../../../../constants';
import { LocalStorageService } from '../../../../../services';
import { Panel, ResponsiveBreak } from '../../../../../components';
import React from 'react';

interface IProps {
  score: number;
  stack: string;
  type: TestTaskType | undefined;
}

export const IsLpOriginated: React.FC<IProps> = ({ score, stack, type }) => {
  const isQuizTestTaskFinishedDueToTimeout = LocalStorageService.getState<
    boolean | undefined
  >(LOCAL_STORAGE_KEYS.QUIZ_TEST_TASK_FINISHED_DUE_TO_TIMEOUT);

  if (type === TestTaskType.INTERNAL) {
    return (
      <Typography>
        We have good and not-so-good news for you. The not-so-good news is that
        your test score is is not enough for our {stack} positions.
        <Box component="br" />
        <Box component="br" />
        As for the good news, we are still keen to see you join AgileEngine, and
        you can try your hand at other positions in 1 year.
        <Box component="br" />
        <Box component="br" />
        We are looking forward to new opportunities to collaborate with you.
        <Box component="br" />
        <Box component="br" />
        Have a great day!
      </Typography>
    );
  }

  return (
    <Box>
      <Typography>
        We have good and not-so-good news for you. The not-so-good news is that
        your test score is {score || 0}%, which is not enough for our {stack}{' '}
        projects.
        <Box component="br" />
        <Box component="br" />
        {type === TestTaskType.QUIZ
          ? 'As for the good news, we are still keen to see you join AgileEngine, and you can try your hand at other positions in 6 months. '
          : 'As for the good news, we are still keen to see you join AgileEngine, and you can try your hand at other technologies. Feel free to take tests focusing on the technologies you’re more comfortable with, and we will be happy to offer you new projects.'}
        <Box component="br" />
        <ResponsiveBreak breakpoint="md" />
        We are looking forward to new opportunities to collaborate with you.
        <Box component="br" />
        <Box component="br" />
        Have a great day!
      </Typography>
      {isQuizTestTaskFinishedDueToTimeout && (
        <Panel mt={2}>
          <Typography variant="body1">
            <strong>Note:</strong> the test was automatically
            <br />
            finished when time limit was reached.
          </Typography>
        </Panel>
      )}
    </Box>
  );
};
