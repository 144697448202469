const testTaskApi = '/test-tasks';
const userTestTaskApi = '/user-test-tasks';

export const getAvailableTestTasksPath = () => `${testTaskApi}/available`;

export const createUserTestTaskAPI = () => userTestTaskApi;
export const postUserTestTaskSimulationResultsAPI = (userTestTaskId: string) =>
  `${userTestTaskApi}/${userTestTaskId}/simulation-results`;

export const getCodilityUserSessionAPI = (userTestTaskId: string) =>
  `${userTestTaskApi}/codility/${userTestTaskId}/results`;

export const submitQuizAnswerAPI = (userTestTaskId: string) =>
  `${userTestTaskApi}/quiz/${userTestTaskId}/answer`;
export const finishQuizTestTaskAPI = (userTestTaskId: string) =>
  `${userTestTaskApi}/quiz/${userTestTaskId}/finish`;

export const uploadInternalTestTaskResolutionAPI = (userTestTaskId: string) =>
  `${userTestTaskApi}/internal/${userTestTaskId}/resolution`;

export const testTaskReminderAPI = () => `${userTestTaskApi}/reminder`;
export const testTaskReminderDateAPI = () =>
  `${userTestTaskApi}/set-reminder-date`;
