import React from 'react';
import { Stack, Typography } from '@mui/material';
import EmailForm from './EmailForm';
import { ResponsiveBreak } from '../../../../components';
import { EmailIntroWrapper } from './EmailIntroWrapper';
import { useAppSelector } from 'src/redux';
import { userGeoLocationSelector } from 'src/redux/userGeoLocation';

const AVAILABLE_COUNTRY_CODES = [
  'ar',
  'br',
  'co',
  'gt',
  'pl',
  'in',
  'mx',
  'ua',
  'us',
  'pt',
  'eu',
  'pe',
  'cr',
  've',
];

export const Country: React.FC = () => {
  const userGeoLocation = useAppSelector(
    userGeoLocationSelector.getUserGeoLocation,
  )!;

  const userCountryCode = userGeoLocation?.country_code?.toLowerCase();

  const countryCode = AVAILABLE_COUNTRY_CODES.includes(userCountryCode)
    ? userCountryCode
    : null;

  return (
    <EmailIntroWrapper
      data-testid="sign-in-layout-email-intro-country-specific-title"
      sx={() => ({
        maxWidth: '41.25rem',
      })}
    >
      <Stack
        maxWidth="35rem"
        paddingY={{ xs: 0, md: '2rem' }}
        alignItems={'center'}
      >
        <Stack
          width="100%"
          justifyContent="space-between"
          alignItems={{ xs: 'flex-start', sm: 'center' }}
          direction={{ xs: 'row-reverse', sm: 'column' }}
        >
          {countryCode && (
            <img
              loading="lazy"
              src={`https://flagcdn.com/72x54/${countryCode}.png`}
              srcSet={`https://flagcdn.com/144x108/${countryCode}.png 2x,
              https://flagcdn.com/216x162/${countryCode}.png 3x`}
              alt={`${countryCode} flag`}
              style={{
                width: '3.125rem',
                height: 'inherit',
                paddingBottom: '1rem',
              }}
            />
          )}
          <Typography
            variant="h2"
            fontWeight={{ xs: 700, sm: 600 }}
            textAlign={{ xs: 'start', sm: 'center' }}
            sx={{
              fontSize: { xs: '1.25rem', sm: '1.875rem' },
              marginBottom: { xs: '2rem', sm: '1.5rem' },
            }}
          >
            Join our like-minded <ResponsiveBreak breakpoint="xs" />
            team members in {userGeoLocation?.country_name}
          </Typography>
        </Stack>
        <EmailForm
          alignItems={{ xs: 'flex-start', sm: 'center' }}
          width="100%"
          justifyContent="center"
        />
      </Stack>
    </EmailIntroWrapper>
  );
};
