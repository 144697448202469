import { useCallback, useEffect, useState, useRef } from 'react';
import { useDebounce } from './useDebounce';

type SetValue<T> = (value: T) => void;

export function usePersistUserAnswers<T>(key: string): [T, SetValue<T>] {
  const USER_ANSWERS_KEY = 'user-answers';
  // handler to load previously saved user answers
  const getAnswers = useCallback((): Record<string, T> => {
    if (typeof window === 'undefined') {
      return {};
    }

    try {
      const answers = window.localStorage.getItem(USER_ANSWERS_KEY);
      return answers ? JSON.parse(answers) : {};
    } catch (error) {
      console.warn(`Error reading saved user answers “${key}”:`, error);
      return {};
    }
  }, [key]);

  const [userAnswers, setUserAnswers] = useState<Record<string, T>>(getAnswers);
  const currentAnswer = useRef<T | null>(null);

  // handler to persist updated user answers
  const saveAnswers = () => {
    try {
      if (typeof window !== 'undefined' && currentAnswer.current) {
        window.localStorage.setItem(
          USER_ANSWERS_KEY,
          JSON.stringify({ ...userAnswers, [key]: currentAnswer.current }),
        );
        setUserAnswers({ ...userAnswers, [key]: currentAnswer.current });
      }
    } catch (error) {
      console.warn(`Error saving user answers: ${error}`);
      return userAnswers;
    }
  };

  const debouncedSave = useDebounce(() => {
    if (currentAnswer.current) {
      saveAnswers();
    }
  }, 300);

  // handler to save incoming user input to existing answers obj.
  const setStepAnswer: SetValue<T> = useCallback((value) => {
    try {
      currentAnswer.current = value;
      debouncedSave();
    } catch (error) {
      console.warn(`Error setting user answer: ${error}`);
    }
  }, []);

  // save user answers on reloads, tab close/change, window close, etc
  useEffect(() => {
    const triggerAnswerSave = () => {
      if (document.visibilityState === 'hidden') {
        saveAnswers();
      }
    };
    document.addEventListener('visibilitychange', triggerAnswerSave);
    return () => {
      document.removeEventListener('visibilitychange', triggerAnswerSave);
    };
  }, []);

  return [userAnswers[key], setStepAnswer];
}
