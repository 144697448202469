import {
  CustomButton,
  Flex,
  ScheduleDialog,
  StepPanel,
  StepPanelHeader,
  TextButton,
} from '../../../components';
import { Typography, Box } from '@mui/material';

import handshake from '../../../assets/handshake.png';
import { useAppDispatch, useAppSelector } from '../../../redux';
import { useUserVideoInterview } from '../../../hooks';
import { restartVideoInterview } from '../../../redux/videoInterview';
import { userSelectors } from '../../../redux/user';
import { useState } from 'react';
import { formatReminderDate } from '../../../utils';

export default function ResumeInterviewLayout({
  isLoading,
  starRef,
}: {
  isLoading: boolean;
  starRef: React.RefObject<HTMLDivElement>;
  confirmIntermediateScreenLoading: boolean;
}) {
  const dispatch = useAppDispatch();

  const { allQuestionsAmount, finishedQuestionsAmount, userVideoInterview } =
    useUserVideoInterview();
  const userData = useAppSelector(userSelectors.getUserData)!;
  const userReminderDate = userData.videoInterviewReminderDate;
  const [showRescheduleMessage, setShowRescheduleMessage] = useState(
    Boolean(userReminderDate),
  );
  const [showScheduleDialog, setShowScheduleDialog] = useState(false);

  const handleRestartVideoInterview = () => {
    if (userVideoInterview) {
      return dispatch(restartVideoInterview(userVideoInterview.id));
    }
  };

  const handleScheduleSuccess = () => {
    setShowRescheduleMessage(true);
    setShowScheduleDialog(false);
  };
  return (
    <StepPanel data-testid="video-interview-finished-step" maxWidth="45rem">
      <StepPanelHeader
        srcSetPng={handshake}
        ref={starRef}
        alt="Welcome back to our small video interview"
      >
        Welcome back to our small video interview
      </StepPanelHeader>
      <Typography variant="body1">
        Looks like you’ve answered {finishedQuestionsAmount} out of{' '}
        {allQuestionsAmount} questions. <br /> We'd love to hear more from you!
      </Typography>
      <Flex width={{ xs: '100%', sm: 'auto' }}>
        <CustomButton
          onClick={handleRestartVideoInterview}
          label={`Jump to question ${finishedQuestionsAmount + 1}`}
          color="primary"
          isLoading={isLoading}
          fullWidth
        />
      </Flex>
      {userReminderDate && showRescheduleMessage && (
        <Box sx={{ maxWidth: '40rem' }}>
          <Box
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              background: theme.palette.highlight.accent,
              p: 3,
              alignItems: 'center',
            })}
          >
            <Typography variant="body1" fontWeight="bold">
              The video interview has been scheduled <br /> for{' '}
              {formatReminderDate(userReminderDate, userData.country!)}
            </Typography>
            <Typography variant="body1">
              You will receive a calendar event that you can{' '}
              <TextButton
                underlined
                onClick={() => setShowScheduleDialog(true)}
              >
                reschedule or cancel
              </TextButton>
            </Typography>
          </Box>
        </Box>
      )}
      {showScheduleDialog && (
        <ScheduleDialog
          isOpen={showScheduleDialog}
          handleClose={() => setShowScheduleDialog(false)}
          handleScheduleSuccess={handleScheduleSuccess}
          isVideoInterview
        />
      )}
    </StepPanel>
  );
}
