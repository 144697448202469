import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { apiEndpoints } from '../../constants';
import { ICreteReferralBody, IDeclineReferralBody } from '../../types';
import { formatErrorMessage } from '../../utils';
import { notificationAlert } from '../notifications';
import { referFriendStoreKey } from './referFriend.const';
import { setReferralAuthStatus } from './referFriend.slice';
import { AUTH_STATUS } from '../../types/referFriend';

export const submitReferral = createAsyncThunk(
  `${referFriendStoreKey}/submitReferral`,
  async (body: ICreteReferralBody, thunkAPI) => {
    try {
      const response = await axios.post(
        apiEndpoints.submitReferralPath(),
        body,
      );
      return response.data;
    } catch (err: any) {
      if (err.response.status === 403) {
        thunkAPI.dispatch(setReferralAuthStatus(AUTH_STATUS.ANONYMOUS));
      }
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(err);
    }
  },
);

export const declineReferral = createAsyncThunk(
  `${referFriendStoreKey}/declineReferral`,
  async (body: IDeclineReferralBody, thunkAPI) => {
    try {
      const response = await axios.post(
        apiEndpoints.declineReferralPath(),
        body,
      );
      return response.data;
    } catch (err: any) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(err);
    }
  },
);

export const login = createAsyncThunk(
  `${referFriendStoreKey}/login`,
  async (body?: Record<string, string>) => {
    try {
      const response = await axios.post(apiEndpoints.loginReferralPath(), body);
      return response.data;
    } catch (err: any) {
      throw new Error(err);
    }
  },
);
