import { css, SerializedStyles } from '@emotion/react';
import { useTheme } from '@mui/material/styles';

export const useBaseContentStyles = (): SerializedStyles => {
  const { palette } = useTheme();
  return css`
    .content {
      .brand-color {
        color: ${palette.brand.accent};
      }

      .brand-bg {
        background-color: ${palette.brand.accent};
      }

      h2 {
        margin: 0 0 1.5rem;
      }

      p {
        margin: 1.5rem 0;
      }

      & > *:last-child {
        margin-bottom: 0;
      }
    }
  `;
};

export const useInternalTestTaskSelectionStyles = (): SerializedStyles => {
  const { palette } = useTheme();
  return css`
    .test-task-block {
      border-radius: 4px;
      border: 3px solid ${palette.highlight.neutral};
      padding: 3rem 2rem 3rem;
      max-width: 50rem;
      display: flex;
      flex-direction: column;
      gap: 2.5rem;

      .objective-wrapper {
        padding: 0 1.3rem;
      }

      .step-wrapper {
        padding: 0 1.3rem;
        display: flex;
        align-items: baseline;

        .step-circle {
          border-radius: 0.5rem;
          background-color: ${palette.background?.stepsCircle};
          color: ${palette.text?.invertedPrimary};
          width: 2rem;
          height: 2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 0.7rem;

          @media only screen and (max-height: 820px) {
            width: 1.5rem;
            height: 1.5rem;

            .MuiTypography-root {
              font-size: 0.85rem;
            }
          }
          & + * {
            flex: 1;
          }
        }
      }

      a {
        text-decoration: none;
      }

      .tt-file-select-button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 2px solid ${palette.highlight.fileSelection};
        border-radius: 4px;
        width: 100%;
        padding: 1rem 1.5rem;
        cursor: pointer;
        margin-bottom: 1.5rem;
      }

      .chat-message-wrapper {
        margin-top: 2rem;
      }
    }
  `;
};
