import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { skillsStoreKey } from './skills.const';
import { getSkills } from './skills.thunks';
import { API_STATUS } from '../../constants';
import { ITechSkillsData, ITechStack } from '@types';

export interface ISkillsSliceState {
  apiStatus: API_STATUS;
  data: ITechSkillsData;
  apiError: string;
  techStack: ITechStack;
}

const initialState: ISkillsSliceState = {
  apiStatus: API_STATUS.IDLE,
  data: {
    specializations: [],
    skills: [],
  },
  apiError: '',
  techStack: {
    workArea: null,
    workAreaSet: false,
    highlevelSpecialization: null,
    specialization: null,
    specializationSet: false,
    primarySkills: null,
    notOurCoreStack: false,
    primarySkillsSet: false,
    secondarySkills: null,
    positionSkills: null,
    skillset: null,
  },
};

export const skillsSlice = createSlice({
  name: skillsStoreKey,
  initialState,
  reducers: {
    updateUserTechStack: (state, action: PayloadAction<ITechStack>) => {
      state.techStack = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSkills.pending, (state) => {
        state.apiStatus = API_STATUS.LOADING;
      })
      .addCase(getSkills.fulfilled, (state, action) => {
        state.apiStatus = API_STATUS.COMPLETE;
        state.data = action.payload;
      })
      .addCase(getSkills.rejected, (state, action) => {
        state.apiStatus = API_STATUS.FAILED;
        state.apiError = action.payload as string;
      });
  },
});

export const { updateUserTechStack } = skillsSlice.actions;
