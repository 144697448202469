import React from 'react';
import { CustomButton } from '../../../components';
import { Box, SvgIcon } from '@mui/material';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';

const LoadingRecordingButton = () => {
  return (
    <>
      <Box
        sx={(theme) => ({
          width: 511,
          position: 'relative',
          ['@media (max-width: 1200px)']: {
            width: '100%',
          },
          [theme.breakpoints.down('md')]: {
            width: 400,
          },
          ['@media (max-width: 400px)']: {
            width: '100%',
          },
        })}
      >
        <CustomButton
          label="Starting recording"
          startIcon={<SvgIcon inheritViewBox component={TimerOutlinedIcon} />}
          fullWidth
          color="primary"
          isCustomLoading={true}
        />
      </Box>
    </>
  );
};

export default LoadingRecordingButton;
