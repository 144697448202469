import { Box, Typography } from '@mui/material';
import axios from 'axios';

import RubberIcon from '../../../assets/rubber-icon.svg';
import { CustomButton } from '../../../components';
import { apiEndpoints } from '../../../constants';
import { useAppDispatch } from '../../../redux';
import { notificationAlert } from '../../../redux/notifications';
import { LocalStorageService } from '../../../services';
import { formatErrorMessage } from '../../../utils';

interface IProps {
  handleClose: () => void;
}

const DeleteAccountForm: React.FC<IProps> = ({ handleClose }) => {
  const dispatch = useAppDispatch();

  const handleDelete = async () => {
    axios
      .delete(apiEndpoints.deleteUser())
      .then(() => {
        LocalStorageService.clearState();
        window.location.href = '/';
      })
      .catch((e) => {
        dispatch(
          notificationAlert(
            formatErrorMessage(undefined, 'Error while deleting user account'),
            { variant: 'error' },
          ),
        );
        throw new Error(e);
      });

    handleClose();
  };

  return (
    <Box display="flex" gap={4} alignItems="center" flexDirection="column">
      <img src={RubberIcon} alt="Rubber" />
      <Typography variant="h2" sx={{ mb: 2, px: 1.5, textAlign: 'center' }}>
        Confirm deleting your data
      </Typography>

      <Typography textAlign="center">
        This action cannot be undone. Are you sure you want to delete your
        account?
      </Typography>

      <CustomButton
        color="primary"
        onClick={handleDelete}
        label="Delete account"
        type="button"
      />
    </Box>
  );
};

export default DeleteAccountForm;
