import { ISidebarSliceState } from './sidebar.slice';
import { sidebarStoreKey } from './sidebar.const';

interface IState {
  [sidebarStoreKey]: ISidebarSliceState;
}

const selectSidebarState = (state: IState) => {
  return state[sidebarStoreKey];
};

const getIsSidebarOpen = (state: IState): boolean => {
  return selectSidebarState(state).isSidebarOpen;
};

export const sidebarSelectors = {
  getIsSidebarOpen,
};
