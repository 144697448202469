import { RefObject, useEffect } from 'react';
import { ANIMATION_DURATION } from '../constants';

export const useFocusInputAfterAnimation = (
  inputRef: RefObject<HTMLElement | null>,
  preventScroll = false,
) => {
  const delay = ANIMATION_DURATION * 1000 + 100;
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      inputRef.current?.focus({ preventScroll });
    }, delay);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);
};
