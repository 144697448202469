import React from 'react';

import { PageTransitionWrapper } from '../../components';
import { ICPassedLayout } from './layouts/ICPassedLayout';

export const IntroCallPage: React.FC = () => {
  return (
    <PageTransitionWrapper>
      <ICPassedLayout />
    </PageTransitionWrapper>
  );
};
