import { BasicDialog, ResponsiveBreak } from '../../../../components';
import { Box, Stack, Typography } from '@mui/material';
import { CustomButton, TextButton } from '../../../../components';
import TTPointTime from '../../../../assets/tt-point-time.png';
import TTPointTimeWebp from '../../../../assets/tt-point-time.webp';

interface IProps {
  showQuizTTDialog: boolean;
  setShowQuizTTDialog: React.Dispatch<React.SetStateAction<boolean>>;
  handleScheduleForLater: () => void;
  handleCreateQuizTT: () => void;
  createQuizTTLoading: boolean;
  userReminderDate: string | null;
  duration: number;
}

const QuizTTDialog: React.FC<IProps> = ({
  showQuizTTDialog,
  setShowQuizTTDialog,
  handleCreateQuizTT,
  handleScheduleForLater,
  createQuizTTLoading,
  userReminderDate,
  duration,
}) => {
  const onCreateTTClick = () => {
    handleCreateQuizTT();
  };

  return (
    <BasicDialog
      isOpen={showQuizTTDialog}
      handleClose={() => {
        setShowQuizTTDialog(false);
      }}
    >
      <Box display="flex" justifyContent="center" minHeight="10.5rem">
        <picture>
          <source srcSet={TTPointTimeWebp} type="image/webp" />
          <source srcSet={TTPointTime} type="image/png" />
          <Box
            component="img"
            src={TTPointTime}
            alt="Quiz time"
            maxWidth="14rem"
          />
        </picture>
      </Box>
      <Typography variant="h2" textAlign="center" paddingTop={4}>
        Ready?
      </Typography>
      <Typography variant="body1" textAlign="center" marginBottom={4}>
        Once you start the test you will have{' '}
        <ResponsiveBreak breakpoint="lg" />
        {duration} minutes to complete it.
        <ResponsiveBreak breakpoint="lg" /> Are you ready to begin?
      </Typography>
      <Stack spacing={2} direction="column" alignItems="center">
        <CustomButton
          color="primary"
          label="Proceed to 1st question"
          onClick={onCreateTTClick}
          isDisabled={false}
          isLoading={createQuizTTLoading}
          fullWidth
        />
        <Stack direction="column" alignItems="center">
          <Typography variant="body2" color="secondary" textAlign="center">
            Prefer to do it later?
          </Typography>
          <TextButton onClick={handleScheduleForLater}>
            {userReminderDate ? 'Reschedule event' : 'Schedule for later'}
          </TextButton>
        </Stack>
      </Stack>
    </BasicDialog>
  );
};

export default QuizTTDialog;
