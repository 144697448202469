import React, { useEffect, useState } from 'react';
import {
  LOCAL_STORAGE_KEYS,
  TEST_TASK_STATUS,
  TestTaskType,
} from 'src/constants';
import {
  useFeatureFlags,
  useGamifiedMessage,
  useIsTabletView,
  useUserTestTasks,
} from 'src/hooks';
import { useLocation, useNavigate, useNavigationType } from 'react-router';
import {
  PageTransitionWrapper,
  ResponsiveBreak,
  ScheduleDialog,
} from '../../components';
import { Box, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../redux';
import { userSelectors } from '../../redux/user';
import { AvailableTestTaskWithPositionsData } from '../../types';
import { formatReminderDate } from '../../utils';
import { createUserTestTask } from '../../redux/testTasks';
import OneTTLayout from './layouts/OneTTLayout';
import OneTTPassedLayout from './layouts/OneTTPassedLayout';
import MultipleTTLayout from './layouts/MultipleTTLayout';
import MultipleTTWithPassedLayout from './layouts/MultipleTTWithPassedLayout';
import { CodilityTTDialog } from './components/CodilityTestTaskSelection/CodilityTTDialog';
import { CodilityTTMobileDialog } from './components/CodilityTestTaskSelection/CodilityTTMobileDialog';
import { LocalStorageService, logEvent } from '../../services';
import TTRequiredPositionPopover from './components/TTRequiredPositionPopover';
import QuizTTDialog from './components/QuizTestTaskSelection/QuizTTDialog';
import uniq from 'lodash/uniq';
import { ExplanationPanel } from './components/ExplanationPanel';

enum TEST_TASK_SELECTION_LAYOUT {
  ONE_TT = 'ONE_TT',
  ONE_TT_PASSED = 'ONE_TT_PASSED',
  MULTIPLE_TT = 'MULTIPLE_TT',
  MULTIPLE_TT_WITH_PASSED = 'MULTIPLE_TT_WITH_PASSED',
}

const getTestTaskSelectionLayout = (
  ttSelectionStatus: TEST_TASK_SELECTION_LAYOUT | null,
  {
    testTaskSelected,
    testTasks,
    isPerformingAction,
    testTaskReminderDate,
    handleTTChange,
    handlePassTTClick,
    handleScheduleForLater,
  }: {
    testTaskSelected: string | null;
    testTasks: AvailableTestTaskWithPositionsData[];
    isPerformingAction: boolean;
    testTaskReminderDate: string | null;
    handleTTChange: (testTask: AvailableTestTaskWithPositionsData) => void;
    handlePassTTClick: () => void;
    handleScheduleForLater: () => void;
  },
) => {
  let Component;
  const componentProps = {
    testTaskSelected,
    testTasks,
    isPerformingAction,
    testTaskReminderDate,
    handleTTChange,
    handlePassTTClick,
    handleScheduleForLater,
  };

  switch (ttSelectionStatus) {
    case TEST_TASK_SELECTION_LAYOUT.ONE_TT: {
      Component = OneTTLayout;
      break;
    }
    case TEST_TASK_SELECTION_LAYOUT.ONE_TT_PASSED: {
      Component = OneTTPassedLayout;
      break;
    }
    case TEST_TASK_SELECTION_LAYOUT.MULTIPLE_TT: {
      Component = MultipleTTLayout;
      break;
    }
    case TEST_TASK_SELECTION_LAYOUT.MULTIPLE_TT_WITH_PASSED: {
      Component = MultipleTTWithPassedLayout;
      break;
    }
    default: {
      Component = () => <></>;
    }
  }

  return { Component, componentProps } as any;
};

const TestTaskSelection: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const navigationType = useNavigationType();
  const location = useLocation();
  const {
    availableTestTasks,
    requiredForPositionTestTask,
    isPerformingAction,
  } = useUserTestTasks();
  const { updateGamifiedMessage } = useGamifiedMessage();
  const isTabletView = useIsTabletView();
  const { isTTSelectionPageSimplified } = useFeatureFlags();

  const userData = useAppSelector(userSelectors.getUserData)!;

  const hasPassedTestTasks = availableTestTasks.some(
    (tt) => tt.status === TEST_TASK_STATUS.PASSED,
  );

  const [ttSelectionLayout, setTTSelectionLayout] =
    useState<TEST_TASK_SELECTION_LAYOUT | null>(null);
  const [showScheduleDialog, setShowScheduleDialog] = useState(false);
  const [showCodilityTTDialog, setShowCodilityTTDialog] = useState(false);
  const [showQuizTTDialog, setShowQuizTTDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [testTaskSelected, setTestTaskSelected] =
    useState<AvailableTestTaskWithPositionsData | null>(null);

  useEffect(() => {
    if (navigationType.toString() === 'POP') {
      navigate(1);
      return;
    }
  }, [location]);

  useEffect(() => {
    if (hasPassedTestTasks) {
      updateGamifiedMessage('Wait a second... you were applying before!');

      if (availableTestTasks.length === 1) {
        setTestTaskSelected(availableTestTasks[0]);
        setTTSelectionLayout(TEST_TASK_SELECTION_LAYOUT.ONE_TT_PASSED);
      } else {
        if (requiredForPositionTestTask) {
          setTestTaskSelected(requiredForPositionTestTask);
        }
        setTTSelectionLayout(
          TEST_TASK_SELECTION_LAYOUT.MULTIPLE_TT_WITH_PASSED,
        );
      }
    } else {
      updateGamifiedMessage('Time for some action!');

      if (availableTestTasks.length === 1) {
        setTestTaskSelected(availableTestTasks[0]);
        setTTSelectionLayout(TEST_TASK_SELECTION_LAYOUT.ONE_TT);
      } else {
        if (requiredForPositionTestTask) {
          setTestTaskSelected(requiredForPositionTestTask);
        }
        setTTSelectionLayout(TEST_TASK_SELECTION_LAYOUT.MULTIPLE_TT);
      }
    }
  }, [availableTestTasks, hasPassedTestTasks, requiredForPositionTestTask]);

  // Open reschedule dialog for users coming from a reminder
  useEffect(() => {
    logEvent('launchpod-dev-tt-selection-page-loaded');

    logEvent('launchpod - user-positions-test-tasks', {
      value: uniq(
        availableTestTasks?.map((e) =>
          e.isRequiredForUserPosition
            ? 'position-required'
            : !userData.closedAt
            ? 'positions-available'
            : 'no-positions',
        ),
      )
        .sort()
        .join(', '),
    });

    logEvent(
      isTTSelectionPageSimplified
        ? 'launchpod - ab_test-tt_selection_page-simplified'
        : 'launchpod - ab_test-tt_selection_page-default',
    );

    if (LocalStorageService.getState(LOCAL_STORAGE_KEYS.RESCHEDULE_EVENT)) {
      setShowScheduleDialog(true);
      LocalStorageService.clearState(LOCAL_STORAGE_KEYS.RESCHEDULE_EVENT);
    }
  }, []);

  useEffect(() => {
    const hasCustomTestTask = availableTestTasks.some(
      (testTask) => testTask.type === TestTaskType.CUSTOM,
    );
    if (hasCustomTestTask) {
      logEvent(
        'launchpod - test-task-selection-page_custom-test-tasks-available',
      );
    }
  }, [availableTestTasks]);

  // Handle test task stack selection from the list
  const handleTTChange = (
    testTask: AvailableTestTaskWithPositionsData,
    e?: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>,
  ) => {
    if (testTask.id === testTaskSelected?.id) {
      return;
    }
    if (
      requiredForPositionTestTask &&
      !testTask.isRequiredForUserPosition &&
      e
    ) {
      setAnchorEl(e.currentTarget);
    }

    setTestTaskSelected(testTask);
    updateGamifiedMessage('We’d be happy to see you in action!');
  };

  const handlePassTTClick = () => {
    if (testTaskSelected) {
      switch (testTaskSelected.type as string) {
        case TestTaskType.CODILITY:
          setShowCodilityTTDialog(true);
          break;
        case TestTaskType.QUIZ:
          setShowQuizTTDialog(true);
          break;
        case TestTaskType.CUSTOM:
          handleCreateTestTask(false);
          break;
        default:
          break;
      }
    }
  };

  const handleCreateTestTask = (simulated?: boolean) => {
    if (!testTaskSelected) return;

    logEvent('launchpod-continue-to-dev-tt-click');

    dispatch(
      createUserTestTask({
        id: testTaskSelected.id,
        technicalFlow: testTaskSelected.technicalFlow,
        type: testTaskSelected.type,
        isRequiredForUserPosition: testTaskSelected.isRequiredForUserPosition,
        simulated,
      }),
    );

    logEvent('launchpod - user-selected-test-task', {
      value: testTaskSelected.isRequiredForUserPosition
        ? 'position-required'
        : !userData.closedAt
        ? 'positions-available'
        : 'no-positions',
    });

    setShowCodilityTTDialog(false);
    setShowQuizTTDialog(false);
  };

  // Open schedule for later dialog
  const handleScheduleForLater = () => {
    setShowScheduleDialog(true);
  };

  // Close schedule for later dialog
  const handleCloseScheduleForLater = () => {
    setShowScheduleDialog(false);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const { Component, componentProps } = getTestTaskSelectionLayout(
    ttSelectionLayout,
    {
      testTaskSelected: testTaskSelected?.id || null,
      testTasks: availableTestTasks,
      isPerformingAction,
      testTaskReminderDate: formatReminderDate(
        userData.testTaskReminderDate,
        userData.country!,
      ),
      handleTTChange,
      handlePassTTClick,
      handleScheduleForLater,
    },
  );

  return (
    <PageTransitionWrapper>
      <Box data-testid="test-task-selection-layout" />
      <Typography
        variant="h2"
        sx={(theme) => ({
          fontSize: '2rem',
          [theme.breakpoints.down('sm')]: {
            fontSize: '1.25rem',
            maxWidth: '70%',
          },
          [theme.breakpoints.down('xl')]: {
            fontSize: '1.5rem',
          },
        })}
      >
        First step is done, <ResponsiveBreak breakpoint="md" />
        now it’s time to show your skills
      </Typography>
      <Box display="flex" gap={8}>
        <Box>
          <Component {...componentProps} />
        </Box>
        {isTTSelectionPageSimplified &&
          ![
            TEST_TASK_SELECTION_LAYOUT.ONE_TT_PASSED,
            TEST_TASK_SELECTION_LAYOUT.MULTIPLE_TT_WITH_PASSED,
          ].includes(ttSelectionLayout!) && (
            <Box>
              <ExplanationPanel />
            </Box>
          )}
      </Box>
      {showCodilityTTDialog && (
        <>
          {isTabletView ? (
            <CodilityTTMobileDialog
              showTTDialog={showCodilityTTDialog}
              setShowTTDialog={setShowCodilityTTDialog}
            />
          ) : (
            <CodilityTTDialog
              showTTDialog={showCodilityTTDialog}
              setShowTTDialog={setShowCodilityTTDialog}
              handleCreateTestTask={handleCreateTestTask}
              createTTIsLoading={isPerformingAction}
            />
          )}
        </>
      )}
      {showScheduleDialog && (
        <ScheduleDialog
          isOpen={showScheduleDialog}
          handleClose={handleCloseScheduleForLater}
          handleScheduleSuccess={handleCloseScheduleForLater}
          testTaskType={testTaskSelected?.type ?? availableTestTasks[0].type}
        />
      )}
      {showQuizTTDialog && (
        <QuizTTDialog
          handleScheduleForLater={handleScheduleForLater}
          showQuizTTDialog={showQuizTTDialog}
          setShowQuizTTDialog={setShowQuizTTDialog}
          handleCreateQuizTT={handleCreateTestTask}
          createQuizTTLoading={isPerformingAction}
          userReminderDate={userData.testTaskReminderDate}
          duration={testTaskSelected?.additionalInfo?.duration || 20}
        />
      )}
      <TTRequiredPositionPopover
        anchorEl={anchorEl}
        requiredForPositionTT={requiredForPositionTestTask}
        testTaskSelected={testTaskSelected}
        handleClose={handlePopoverClose}
      />
    </PageTransitionWrapper>
  );
};

export default TestTaskSelection;
