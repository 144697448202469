/** @jsxImportSource @emotion/react */
import React, { useCallback } from 'react';
import { Button, Stack } from '@mui/material';
import { APP_ROUTES, EXTERNAL_LINKS } from '../../constants';
import { useLinkButtonStyles } from './ExternalLinks.styles';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useAppDispatch, useAppSelector } from '../../redux';
import { logout } from '../../redux/auth';
import { userSelectors } from '../../redux/user';
import { useNavigate } from 'react-router-dom';

interface IProps {
  isSidebar?: boolean;
}

const Header: React.FC<IProps> = ({ isSidebar }) => {
  const styles = useLinkButtonStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userData = useAppSelector(userSelectors.getUserData)!;

  const handleLogout = () => {
    dispatch(logout());
  };

  const onReferFriendClick = useCallback(() => {
    navigate(`/${APP_ROUTES.REFER_FRIEND}`);
  }, []);

  return (
    <Stack
      columnGap={6}
      rowGap={2}
      sx={{
        flexDirection: isSidebar ? 'column' : 'row',
        alignItems: isSidebar ? 'start' : 'end',
        flexWrap: 'wrap',
      }}
    >
      <Button
        css={styles}
        variant="text"
        href={EXTERNAL_LINKS.WHY_US}
        target="_blank"
        rel="noopener noreferrer"
      >
        Why us
      </Button>
      <Button css={styles} variant="text" onClick={onReferFriendClick}>
        Refer a friend
      </Button>
      <Button
        css={styles}
        variant="outlined"
        href={EXTERNAL_LINKS.OPEN_POSITIONS}
        target="_blank"
        rel="noopener noreferrer"
      >
        Open positions
      </Button>
      {userData && (
        <Button
          css={styles}
          variant="outlined"
          onClick={handleLogout}
          endIcon={<ExitToAppIcon />}
          component="button"
        >
          Log out
        </Button>
      )}
    </Stack>
  );
};

export default Header;
