/** @jsxImportSource @emotion/react */
import React, { ReactNode } from 'react';
import { ButtonUnstyled } from '@mui/base';
import { Stack, SvgIcon, Typography } from '@mui/material';
import { isKeyClick } from '../../utils';
import { useAttachmentButtonStyles } from './AttachmentButton.style';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { ReactComponent as GDocIcon } from '../../assets/google-docs-icon.svg';
import { ReactComponent as GSheetIcon } from '../../assets/google-sheet-icon.svg';
import { ChevronRight } from '@mui/icons-material';

const DEFAULT_EXTENSION = 'default';

interface IProps {
  onClick?: () => void;
  color?: string;
  variant?: 'primary' | 'secondary';
  children: ReactNode;
  isDisabled?: boolean;
}

export const AttachmentButton: React.FC<IProps> = ({
  onClick,
  variant = 'primary',
  isDisabled,
  children,
}) => {
  const style = useAttachmentButtonStyles(variant);

  const getIconByExtension = () => {
    let extension = DEFAULT_EXTENSION;

    if (typeof children === 'string') {
      const data = children.split('.');
      extension = data.length ? data[data.length - 1] : DEFAULT_EXTENSION;
    }

    switch (extension) {
      case 'doc':
      case 'docx':
        return GDocIcon;
      case 'xls':
      case 'xlsx':
        return GSheetIcon;
      default:
        return AttachFileIcon;
    }
  };
  return (
    <ButtonUnstyled
      css={style}
      onClick={onClick}
      onKeyUp={(e: React.KeyboardEvent<any>) => {
        if (isKeyClick(e)) {
          e.stopPropagation();
          onClick && onClick();
        }
      }}
      disabled={isDisabled}
      tabIndex={0}
    >
      <Stack direction="row" spacing={2} component="span">
        <SvgIcon inheritViewBox component={getIconByExtension()} />
        <Typography variant="body1" fontWeight="600">
          {children}
        </Typography>
      </Stack>
      <ChevronRight fontSize="medium" />
    </ButtonUnstyled>
  );
};
