import React, { KeyboardEvent, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack } from '@mui/material';
import { ISubNavigationStep } from '../../../../types';
import QuestioneerStep from './QuestioneerStep';

interface IProps {
  steps: ISubNavigationStep[];
  handleToggleSidebar?: (event: KeyboardEvent | MouseEvent) => void;
}

const QuestioneerStepper: React.FC<IProps> = ({
  steps,
  handleToggleSidebar,
}) => {
  const navigate = useNavigate();

  const handleEditStep = (
    event: KeyboardEvent | MouseEvent,
    step: ISubNavigationStep,
  ) => {
    if (step.route) {
      handleToggleSidebar && handleToggleSidebar(event);
      navigate(step.route);
    }
  };

  return (
    <Stack direction="row" ml={4} mr={2} mb={5}>
      <Stack rowGap={1}>
        {steps.map((step, index) => (
          <QuestioneerStep
            key={index}
            step={step}
            handleEditStep={handleEditStep}
          />
        ))}
      </Stack>
    </Stack>
  );
};

export default React.memo(QuestioneerStepper);
