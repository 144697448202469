export enum SeniorityOptions {
  Junior = 'Junior',
  Middle = 'Middle',
  Senior = 'Senior',
  Lead = 'Lead',
}

export const SENIORITY_OPTIONS = [
  {
    value: 'Junior',
    label: 'Junior',
  },
  {
    value: 'Middle',
    label: 'Middle',
  },
  {
    value: 'Senior',
    label: 'Senior',
  },
  {
    value: 'Lead',
    label: 'Lead',
  },
];
