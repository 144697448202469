import { useAppSelector } from 'src/redux';
import { userSelectors } from 'src/redux/user';
import { initAnalytics, registerUser } from 'src/services';
import { useEffect } from 'react';

export const useAnalytics = () => {
  const userData = useAppSelector(userSelectors.getUserData);

  useEffect(() => {
    initAnalytics();
  }, []);

  useEffect(() => {
    if (userData?.id) {
      registerUser(userData?.id);
    }
  }, [userData?.id]);
};
