import { ITestTasksSliceState } from './testTasks.slice';
import { testTasksStoreKey } from './testTasks.const';

interface IState {
  [testTasksStoreKey]: ITestTasksSliceState;
}

const selectTestTasksState = (state: IState) => {
  return state[testTasksStoreKey];
};
const getAvailableTestTasksApiData = (state: IState) => {
  return selectTestTasksState(state).availableTestTasks;
};

const getIsPerformingTestTaskAction = (state: IState) => {
  return selectTestTasksState(state).isPerformingAction;
};

export const testTasksSelectors = {
  getAvailableTestTasksApiData,
  getIsPerformingTestTaskAction,
};
