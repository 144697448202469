export const experienceMessage = (props: {
  experienceSelected: number;
}): string => {
  const { experienceSelected } = props;
  switch (experienceSelected) {
    case 0:
    case 1:
      return 'Awesome! AgileEngine is a great place for beginners';
    case 2:
    case 3:
      return `${experienceSelected} years sounds good!`;
    case 4:
    case 5:
      return `${experienceSelected} years sounds impressive!`;
    default:
      return `Sounds impressive!`;
  }
};
