import { APP_ROUTES, slideIn } from '../constants';

import { videoFileUrl } from '../constants/apiEndpoints';
import { CodilityRowOfImages } from 'src/pages/TestTaskSelection/components/RowOfImages';
import AdditionalInfoPanel from 'src/pages/TestTaskSelection/components/AdditionalInfoPanel';

export const useTestTaskInfoPanel = () => {
  const isInTTSelection =
    location.pathname === `/${APP_ROUTES.TEST_TASK_SELECTION}`;

  if (!isInTTSelection) {
    return undefined;
  }

  if (isInTTSelection) {
    return (
      <AdditionalInfoPanel
        videoUrl={videoFileUrl('intro-video-codility-v1.mp4')}
        subtitleUrl={`${process.env.PUBLIC_URL}/intro-video-codility-v1.vtt`}
        posterUrl={`${process.env.PUBLIC_URL}/intro-video-codility-poster.jpeg`}
        signature="Meet Agustín Erra, Delivery Manager"
        videoPlayerSx={(theme) => ({
          alignItems: 'flex-start',
          [theme.breakpoints.down('sm')]: {
            width: 'auto',
            animation: `${slideIn} 0.25s linear`,
            animationFillMode: 'both',
            animationDelay: '0.4s',
            position: 'absolute',
            top: '5rem',
            right: '1rem',
            alignItems: 'flex-end',
          },
        })}
        rowOfImagesTitle="This test task will"
        rowOfImages={CodilityRowOfImages}
      />
    );
  }

  return undefined;
};
