import React from 'react';
import { Typography } from '@mui/material';
import LetsConnect from '../../../letsConnect/LetsConnect';
import { TEST_TASK_STATUS } from '../../../../constants';
import { IsLpOriginated } from './messages';
import startImage from '../../../../assets/passing-star.png';
import {
  CustomButton,
  Flex,
  ResponsiveBreak,
  StepPanel,
} from '../../../../components';
import { Box } from '@mui/system';
import { useUserTestTasks } from 'src/hooks';
import { useAppDispatch } from 'src/redux';
import { selectAnotherTT } from 'src/redux/user';

const FailedTtLayout = () => {
  const dispatch = useAppDispatch();

  const { availableTestTasks, failedTestTasks } = useUserTestTasks();
  const latestFailedTestTask = failedTestTasks?.[0];

  const onTryAnotherTTClick = () => {
    dispatch(selectAnotherTT());
  };

  const hasAvailableTestTasks = availableTestTasks?.some(
    (testTask) => testTask.status === TEST_TASK_STATUS.TODO,
  );

  return (
    <StepPanel
      data-testid="failed-tt-layout"
      alignItems="flex-start"
      textAlign="left"
    >
      {hasAvailableTestTasks && (
        <Flex justifyContent="space-between" flexDirection="column">
          <Box
            component="img"
            src={startImage}
            alt="Star"
            width={57}
            height={57}
          />
          <Typography variant="h2" my={1}>
            You’ve still got a chance!
          </Typography>
          <Typography textAlign={'center'}>
            We are sorry, your test score is 
            {latestFailedTestTask?.score}, which is not enough for our{' '}
            {latestFailedTestTask?.stack} projects.
          </Typography>
          <Typography mt={2} mb={4} textAlign={'center'}>
            However, you can take another attempt on test task for other
            technology.
          </Typography>
          <CustomButton
            onClick={onTryAnotherTTClick}
            label="Try another test task"
          />
        </Flex>
      )}
      {!hasAvailableTestTasks && (
        <>
          <Flex justifyContent="space-between">
            <Typography variant="h2" mb={0}>
              Thank you <ResponsiveBreak breakpoint="xs" />
              for your application!
            </Typography>

            <Box
              component="img"
              src={startImage}
              alt="Star"
              width={{ xs: 57, sm: 67, xxl: 100 }}
              height={{ xs: 57, sm: 67, xxl: 100 }}
            />
          </Flex>

          <IsLpOriginated
            type={latestFailedTestTask?.testTaskType}
            score={latestFailedTestTask?.score || 0}
            stack={latestFailedTestTask?.stack || ''}
          />

          <LetsConnect justifyContent="flex-start" mt={2} />
        </>
      )}
    </StepPanel>
  );
};

export default FailedTtLayout;
