import React, { forwardRef } from 'react';
import { Box, BoxProps, styled } from '@mui/material';
import { LockOutlined } from '@mui/icons-material';

const IconContainer = styled(Box)({
  width: '1.5rem',
  height: '1.5rem',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
});

export const LockIcon = forwardRef<HTMLDivElement, BoxProps>((props, ref) => {
  return (
    <IconContainer ref={ref} {...props}>
      <LockOutlined />
    </IconContainer>
  );
});
