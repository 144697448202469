export default {
  values: {
    xs: 100,
    sm: 480,
    md: 821,
    lg: 1080,
    xl: 1400,
    xxl: 1920,
  },
};
