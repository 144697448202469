/** @jsxImportSource @emotion/react */
import React from 'react';
import {
  Box,
  Drawer,
  Divider,
  Stack,
  SwipeableDrawer,
  BoxProps,
} from '@mui/material';
import { useIsTabletView } from '../../hooks';
import { Color } from '../../types';
import { useSidebarStyles } from './Sidebar.styles';
import ExternalLinks from '../externalLinks/ExternalLinks';
import SidebarContent from './components/SidebarContent';
import SidebarFooter from './components/SidebarFooter';

interface IProps extends Pick<BoxProps, 'bgcolor'> {
  showSidebar: boolean;
  showHeader: boolean;
  handleToggleSidebar: (event: React.KeyboardEvent | React.MouseEvent) => void;
  handleToggleHeader: () => void;
}

const Sidebar: React.FC<IProps> = ({
  showSidebar,
  showHeader,
  handleToggleSidebar,
  handleToggleHeader,
  bgcolor = 'background.default',
}) => {
  const styles = useSidebarStyles();
  const isTabletView = useIsTabletView();
  const isIOS =
    typeof navigator !== 'undefined' &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);

  // TODO: Make the scroll bar fixed to the bottom when the nav stepper grows with data, so that current steps are shown easily
  return isTabletView ? (
    <SwipeableDrawer
      css={styles}
      anchor="right"
      open={showSidebar}
      onOpen={handleToggleSidebar}
      onClose={handleToggleSidebar}
      disableBackdropTransition={!isIOS}
      disableDiscovery={isIOS}
    >
      <Stack
        direction="column"
        justifyContent="space-between"
        sx={{ flexGrow: 1, maxHeight: '100vh' }}
      >
        <SidebarContent
          showHeader={showHeader}
          handleToggleHeader={handleToggleHeader}
          handleToggleSidebar={handleToggleSidebar}
        />
        <Box mb={6}>
          <Divider
            sx={{
              borderColor: (theme) => theme.palette.common.black,
              opacity: 0.3,
              mx: -6,
              my: 6,
            }}
          />
          <ExternalLinks isSidebar />
        </Box>
        <SidebarFooter color={Color.PRIMARY} />
      </Stack>
    </SwipeableDrawer>
  ) : (
    <Drawer
      css={styles}
      variant="permanent"
      anchor="left"
      sx={(theme) => ({
        width: '26.25rem',
        '.MuiDrawer-paper': {
          bgcolor,
          width: '26.25rem',
        },
        [theme.breakpoints.up('xxl')]: {
          width: '30rem',
          '.MuiDrawer-paper': {
            width: '30rem!important',
            pl: `6rem!important`,
          },
        },
      })}
    >
      <Stack
        sx={{
          height: '100%',
          maxHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <SidebarContent
            showHeader={showHeader}
            handleToggleHeader={handleToggleHeader}
            handleToggleSidebar={handleToggleSidebar}
          />
        </Box>
        <SidebarFooter color={Color.PRIMARY} />
      </Stack>
    </Drawer>
  );
};

export default Sidebar;
