import { referFriendStoreKey } from './referFriend.const';
import { IReferFriendSliceState } from './referFriend.slice';
import { AUTH_STATUS } from '../../types/referFriend';

interface IState {
  [referFriendStoreKey]: IReferFriendSliceState;
}

const selectReferFriendState = (state: IState) => {
  return state[referFriendStoreKey];
};

const getReferralSubmitIsLoading = (state: IState): boolean => {
  return selectReferFriendState(state).isCreating;
};

const getRefferalSubmitWasRejected = (state: IState): boolean => {
  return selectReferFriendState(state).wasRejected;
};

const getRefferalInProcess = (state: IState): boolean => {
  return selectReferFriendState(state).inProcess;
};

const getRefferalSubmitName = (state: IState): string => {
  return selectReferFriendState(state).referralName;
};

const getReferralDeclineIsLoading = (state: IState): boolean => {
  return selectReferFriendState(state).isDeclining;
};

const getAuthStatus = (state: IState): AUTH_STATUS => {
  return selectReferFriendState(state).authStatus;
};

export const referFriendSelectors = {
  getReferralSubmitIsLoading,
  getReferralDeclineIsLoading,
  getAuthStatus,
  getRefferalSubmitWasRejected,
  getRefferalInProcess,
  getRefferalSubmitName,
};
