import { BASE_CURRENCY } from '../constants';
import { CurrencyType, RatesType } from '../types';

interface IProps {
  amount: number;
  from?: CurrencyType | typeof BASE_CURRENCY;
  to: CurrencyType;
  withCeil?: boolean | true;
}

export const convertCurrencyTo =
  (rates: RatesType) =>
  ({ amount, to, from = BASE_CURRENCY, withCeil = true }: IProps) => {
    switch (from) {
      case BASE_CURRENCY: {
        const rate = rates[to] ?? 1;
        return withCeil ? Math.ceil(amount * rate) : amount * rate;
      }
      default: {
        return withCeil
          ? Math.ceil((amount / rates[from]) * rates[to])
          : (amount / rates[from]) * rates[to];
      }
    }
  };
