export const PROCEED_WITH_RESPONSE = {
  CURRENT: 'CURRENT',
  PREVIOUS: 'PREVIOUS',
} as const;

export type ProceedWithResponse = keyof typeof PROCEED_WITH_RESPONSE;

export const isProceedWith = {
  current: (type: ProceedWithResponse | null) =>
    type === PROCEED_WITH_RESPONSE.CURRENT,
  previous: (type: ProceedWithResponse | null) =>
    type === PROCEED_WITH_RESPONSE.PREVIOUS,
};
