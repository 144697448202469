import React from 'react';
import { useAppSelector } from '../../redux';

import { Box, Typography } from '@mui/material';
import { GoToPreviousStep, TextButton } from '../../components';

import { skillsSelectors } from '../../redux/skills';
import { getSpecializationTitleByValue } from '../../utils/techSkills';

export const GoBackOnSkills = (props: {
  workArea: string;
  specialization?: string | null;
  subSpecialization?: string | null;
  primarySkills?: string[] | null;
  goBackToWorkArea: () => void;
  goBackToSpecialization?: () => void;
  goBackToSubSpecialization?: () => void;
  goBackToPrimarySkills?: () => void;
  isPartiallySelected?: boolean | false;
}) => {
  const {
    workArea,
    specialization,
    subSpecialization,
    primarySkills,
    goBackToWorkArea,
    goBackToSpecialization,
    goBackToSubSpecialization,
    goBackToPrimarySkills,
    isPartiallySelected = false,
  } = props;

  const skillsData = useAppSelector(skillsSelectors.getSkillsData);

  const specializationTitle = getSpecializationTitleByValue(
    skillsData.data.specializations,
    specialization,
  );
  const subSpecializationTitle = getSpecializationTitleByValue(
    skillsData.data.specializations,
    subSpecialization,
  );

  const checkWhereToGoBack = () => {
    if (goBackToPrimarySkills) {
      goBackToPrimarySkills();
    } else if (goBackToSubSpecialization) {
      goBackToSubSpecialization();
    } else if (goBackToSpecialization) {
      goBackToSpecialization();
    } else {
      goBackToWorkArea();
    }
  };

  const getStringOfSkills = () => {
    let theString: null | string = null;
    primarySkills &&
      primarySkills.map((skill, index) => {
        if (index === primarySkills.length - 1) {
          if (primarySkills.length === 1) {
            theString = skill;
          } else {
            theString = theString + skill;
          }
        } else {
          if (theString) {
            theString = theString + skill + ', ';
          } else {
            theString = skill + ', ';
          }
        }
      });
    return theString;
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      marginBottom="2rem"
      marginTop="-0.5rem"
      flexWrap="wrap"
      pt={2}
    >
      <GoToPreviousStep
        handleGoToPreviousStep={checkWhereToGoBack}
        sx={{ mt: 0 }}
      />
      {!isPartiallySelected && (
        <>
          <TextButton
            variant="secondary"
            onClick={goBackToWorkArea}
            underlined
            sx={{ fontWeight: 500 }}
          >
            {workArea}
          </TextButton>
        </>
      )}
      {specialization && (
        <>
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{ m: '0 0.5rem' }}
            hidden={isPartiallySelected}
          >
            /
          </Typography>
          <TextButton
            variant="secondary"
            onClick={!isPartiallySelected ? goBackToSpecialization : undefined}
            disabled={isPartiallySelected}
            underlined
            sx={{ fontWeight: 500 }}
          >
            {specializationTitle || specialization}
          </TextButton>
        </>
      )}
      {goBackToSubSpecialization && (
        <>
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{ m: '0 0.5rem' }}
          >
            /
          </Typography>
          <TextButton
            variant="secondary"
            onClick={goBackToSubSpecialization}
            underlined
            sx={{ fontWeight: 500 }}
          >
            {subSpecializationTitle || subSpecialization}
          </TextButton>
        </>
      )}
      {primarySkills && (
        <>
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{ m: '0 0.5rem' }}
          >
            /
          </Typography>
          <TextButton
            variant="secondary"
            onClick={goBackToPrimarySkills}
            underlined
            sx={{ fontWeight: 500 }}
          >
            {getStringOfSkills()}
          </TextButton>
        </>
      )}
    </Box>
  );
};

export default GoBackOnSkills;
