import {
  CustomButton,
  Flex,
  StepPanel,
  StepPanelHeader,
} from '../../../components';
import { Typography } from '@mui/material';

import coffeeImage from '../../../assets/coffee.png';
import { confirmIntermediateScreen } from '../../../redux/user';
import { useAppDispatch } from '../../../redux';
import { logEvent } from 'src/services';

export default function SkipInterviewLayout({
  starRef,
  confirmIntermediateScreenLoading,
}: {
  starRef: React.RefObject<HTMLDivElement>;
  confirmIntermediateScreenLoading: boolean;
}) {
  const dispatch = useAppDispatch();

  const nextStep = () => {
    logEvent('launchdpod - video-interview-finished-page_continue-clicked');

    dispatch(confirmIntermediateScreen());
  };
  return (
    <StepPanel data-testid="video-interview-finished-step" maxWidth="45rem">
      <StepPanelHeader
        srcSetPng={coffeeImage}
        ref={starRef}
        alt="Video Interview Is Skipped!"
      >
        Async video interview skipped
      </StepPanelHeader>
      <Typography variant="body1">
        We hope to see you on a real one! <br /> Let’s continue your job
        application.
      </Typography>
      <Flex width={{ xs: '100%', sm: 'auto' }}>
        <CustomButton
          onClick={nextStep}
          label="Continue"
          color="primary"
          isLoading={confirmIntermediateScreenLoading}
          fullWidth
        />
      </Flex>
    </StepPanel>
  );
}
