import React from 'react';

import { Box, Typography } from '@mui/material';

const DeclineSuccess = () => (
  <Box
    sx={(theme) => ({
      background: theme.palette.highlight.accent,
      borderRadius: '8px',
      padding: '12px 24px 16px',
      maxWidth: '335px',
    })}
  >
    <Typography>
      <b>Thank you for sharing that!</b>
      <br /> You’ve unsubscribed from receiving job application updates.
    </Typography>
  </Box>
);

export default DeclineSuccess;
