import { API_STATUS } from '@constants';
import { referralJobOpeningsStoreKey } from './referralJobOpenings.const';
import { IReferralJobOpeningsSliceState } from './referralJobOpenings.slice';

interface IState {
  [referralJobOpeningsStoreKey]: IReferralJobOpeningsSliceState;
}

const selectReferralJobOpeningState = (state: IState) => {
  return state[referralJobOpeningsStoreKey];
};

const getReferralJobOpenings = (state: IState) => {
  return selectReferralJobOpeningState(state).data;
};

const getReferralJobOpeningsApiStatus = (state: IState): API_STATUS => {
  return selectReferralJobOpeningState(state).apiStatus;
};

export const referralJobOpeningsSelectors = {
  getReferralJobOpenings,
  getReferralJobOpeningsApiStatus,
};
