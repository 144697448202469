import { css, SerializedStyles } from '@emotion/react';

export const useShareFeedbackFormStyles = (): SerializedStyles => {
  return css`
    width: 100%;
    margin: auto;

    .feedback-text {
      border-radius: 3px;

      textarea:first-of-type {
        padding: 0;
        height: auto !important;
        overflow: auto !important;
      }
    }
  `;
};
