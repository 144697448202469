import React, { FC } from 'react';
import { CurrencyType } from '../../../../types';
import { Tooltip, Typography } from '@mui/material';

interface IProps {
  amount: string;
  rate: string;
  currency: CurrencyType;
}
export const ConvertedValue: FC<IProps> = ({ amount, rate, currency }) => {
  return (
    <Tooltip title={`1 USD ≈ ${rate} ${currency}`} placement="left">
      <Typography
        variant="body2"
        color={'text.secondary'}
        sx={{ letterSpacing: '0.02em' }}
      >
        {amount} {currency}
      </Typography>
    </Tooltip>
  );
};
