import { useCallback, useState } from 'react';

export const useHeader = () => {
  const [showHeader, setShowHeader] = useState(false);

  const handleToggleHeader = useCallback(() => {
    setShowHeader((prevState) => !prevState);
  }, []);

  return {
    showHeader,
    setShowHeader,
    handleToggleHeader,
  };
};
