import React from 'react';
import { TextButton } from '../../../components';
import { Box, SvgIcon } from '@mui/material';
import { ReactComponent as CancelPresentationIcon } from '../../../assets/cancel-presentation-icon.svg';
import StartRecordingButton from './StartRecordingButton';
import LoadingRecordingButton from './LoadingRecordingButton';
import ProceedToTheNexQuestionButton from './ProceedToTheNexQuestionButton';
import FinishInterviewButton from './FinishInterviewButton';
import StopRecordingButton from './StopRecordingButton';
import { VideoInterviewQuestionStatus } from 'src/types';
import { logEvent } from 'src/services';

interface IProps {
  questionStatus: VideoInterviewQuestionStatus;
  setQuestionStatus: (questionStatus: VideoInterviewQuestionStatus) => void;
  setShowExitInterviewDialog: (showExitInterviewDialog: boolean) => void;
  handleFinishVideoInterview: () => void;
  isLastQuestion: boolean;
  uploadProgress: number;
}

const RecordActionsButtons: React.FC<IProps> = ({
  questionStatus,
  setQuestionStatus,
  setShowExitInterviewDialog,
  isLastQuestion,
  handleFinishVideoInterview,
  uploadProgress,
}) => {
  const handleExitInterview = () => {
    setShowExitInterviewDialog(true);
  };

  const handleStartInterview = () => {
    logEvent('launchpod - video-interview-record-page_start-record-clicked');

    setQuestionStatus(VideoInterviewQuestionStatus.LOADING);
  };

  const handleStopQuestion = () => {
    setQuestionStatus(VideoInterviewQuestionStatus.STOPPING);
  };

  const handleNextQuestion = () => {
    logEvent(
      'launchpod - video-interview-record-page_proceed-next-question-clicked',
    );

    setQuestionStatus(VideoInterviewQuestionStatus.FINISHED);
  };

  const finishInterviewOnClick = (e: React.SyntheticEvent | null) => {
    if (e) {
      e.preventDefault();
    }
    logEvent(
      'launchpod - video-interview-record-page_finish-interview-clicked',
    );
    handleFinishVideoInterview();
  };

  return (
    <>
      <Box
        sx={(theme) => ({
          marginTop: '50px',
          display: 'flex',
          alignItems: 'center',
          [theme.breakpoints.down('xl')]: {
            flexDirection: 'column',
            maxWidth: '511px',
            textAlign: 'center',
            marginTop: '20px',
          },
          ['@media (max-width: 1200px)']: {
            maxWidth: '100%',
          },
        })}
      >
        {questionStatus === VideoInterviewQuestionStatus.PREPARATION && (
          <StartRecordingButton handleStartInterview={handleStartInterview} />
        )}
        {questionStatus === VideoInterviewQuestionStatus.LOADING && (
          <LoadingRecordingButton />
        )}
        {(questionStatus === VideoInterviewQuestionStatus.STARTED ||
          questionStatus === VideoInterviewQuestionStatus.STOPPING) && (
          <StopRecordingButton
            handleStopQuestion={handleStopQuestion}
            questionStatus={questionStatus}
            uploadProgress={uploadProgress}
          />
        )}
        {questionStatus === VideoInterviewQuestionStatus.STOPPED &&
          !isLastQuestion && (
            <ProceedToTheNexQuestionButton
              handleNextQuestion={handleNextQuestion}
            />
          )}
        {questionStatus === VideoInterviewQuestionStatus.STOPPED &&
          isLastQuestion && (
            <FinishInterviewButton
              handleFinishInterview={finishInterviewOnClick}
            />
          )}
        <Box
          sx={(theme) => ({
            display: 'none',
            ml: '24px',
            [theme.breakpoints.down('xl')]: {
              display: 'flex',
              mt: '20px',
              ml: 0,
            },
          })}
        >
          <TextButton
            startIcon={<SvgIcon component={CancelPresentationIcon} />}
            variant="secondary"
            onClick={handleExitInterview}
          >
            Exit interview
          </TextButton>
        </Box>
      </Box>
    </>
  );
};

export default RecordActionsButtons;
