/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import {
  GoToNextStep,
  PageTransitionWrapper,
  QuickReview,
  StickyPanel,
} from '../../components';
import AuthUserConfirmation from '../../components/AuthUserConfirmation/AuthUserConfirmation';
import { APP_ROUTES, DEFAULT_CITY_VALUE, EnglishLevels } from '../../constants';
import {
  getUserPrefferedName,
  isShowEngagementBasedOnCountry,
  isSkipCityBasedOnCountry,
  isSkipEnglishLevelBasedOnCountry,
  isSkipSeniorityBasedOnExperience,
} from '../../utils';
import { useAppDispatch, useAppSelector } from '../../redux';
import {
  updateCity,
  updateEnglishLevel,
  updateOffice,
  updateSubmitProfile,
  userSelectors,
} from '../../redux/user';

import {
  gamifiedResponseSelectors,
  gamifiedResponseSlice,
} from '../../redux/gamifiedResponse';
import { EditOrResendEmailBlock } from './components';
import { authSelectors, setSubmitProfileEmailSent } from 'src/redux/auth';
import { logEvent } from 'src/services';
import { useLPLocation } from 'src/hooks';

const ProfileReview: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userData = useAppSelector(userSelectors.getUserData)!;
  const lastGamifiedMessage = useAppSelector(
    gamifiedResponseSelectors.getGamifiedResponse,
  );
  const isLoading = useAppSelector(userSelectors.getIsUserPerformingAction);
  const isSubmitProfileEmailSent = useAppSelector(
    authSelectors.getIsSubmitProfileEmailSent,
  );
  const { suggestedCountries } = useLPLocation();

  useEffect(() => {
    if (!lastGamifiedMessage && !isSubmitProfileEmailSent) {
      dispatch(
        gamifiedResponseSlice.actions.updateMessage(
          `Welcome back, ${getUserPrefferedName(userData)}!`,
        ),
      );
    }

    logEvent('launchpod-profile-review-page-loaded');
  }, []);

  const handleChange = (navRoute: string) => {
    logEvent('launchpod-edit-step-in-review', {
      'Edit Step in Review': navRoute,
    });

    navigate(`/${navRoute}`);
  };

  const handleSubmitProfile = async () => {
    logEvent('launchpod-submit-profile-button-click');

    dispatch(gamifiedResponseSlice.actions.updateMessage(null));

    if (isSkipEnglishLevelBasedOnCountry(userData.country)) {
      dispatch(updateEnglishLevel(EnglishLevels.Advanced));
    }

    if (isSkipCityBasedOnCountry(userData.country, suggestedCountries)) {
      dispatch(
        updateCity({
          city: DEFAULT_CITY_VALUE,
          state: null,
        }),
      );
      dispatch(
        updateOffice({
          officeLocation: DEFAULT_CITY_VALUE,
        }),
      );
    }

    return new Promise((resolve, reject) => {
      setTimeout(async () => {
        try {
          const result = await dispatch(updateSubmitProfile()).unwrap();
          if (!userData.isEmailVerified) {
            dispatch(setSubmitProfileEmailSent(true));
          }
          resolve(result);
        } catch (err) {
          reject(err);
        }
      }, 500);
    });
  };

  const checkDisableSubmit = () => {
    const shouldSelectEmploymentType =
      isShowEngagementBasedOnCountry(userData?.country) &&
      !userData?.employmentType;
    const shouldSelectEnglishLevel =
      !isSkipEnglishLevelBasedOnCountry(userData?.country) &&
      !userData?.englishLevel;
    const shouldSelectCity =
      !isSkipCityBasedOnCountry(userData?.country, suggestedCountries) &&
      !userData?.city;
    const shouldSelectSeniority =
      !isSkipSeniorityBasedOnExperience(userData?.yearsOfExperience) &&
      !userData?.seniority;

    return (
      shouldSelectEmploymentType ||
      shouldSelectEnglishLevel ||
      shouldSelectCity ||
      shouldSelectSeniority
    );
  };

  const sendEmailAction = useCallback(async () => {
    await dispatch(updateSubmitProfile()).unwrap();
    return;
  }, []);

  return (
    <>
      <PageTransitionWrapper data-testid="profile-review-layout">
        {isSubmitProfileEmailSent ? (
          <>
            <AuthUserConfirmation email={userData?.email} />
            <EditOrResendEmailBlock
              userEmail={userData?.email}
              sendEmailAction={sendEmailAction}
              emailChangeAction={() => handleChange(APP_ROUTES.EMAIL)}
              isLoading={isLoading}
            />
          </>
        ) : (
          <>
            <Typography variant="h2" mb={2}>
              Quick review
            </Typography>

            <QuickReview
              user={userData}
              maxWidth={{ md: '33rem' }}
              onRedirectTo={handleChange}
            />

            <StickyPanel>
              <GoToNextStep
                isLoading={isLoading}
                isDisabled={checkDisableSubmit()}
                textIdentifier={2}
                handleSubmitStep={handleSubmitProfile}
                mt={2}
                maxWidth={{
                  xs: '10rem',
                  sm: 'initial',
                }}
              />
            </StickyPanel>
          </>
        )}
      </PageTransitionWrapper>
    </>
  );
};

export default ProfileReview;
