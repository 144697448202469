import { useEffect } from 'react';
import { TEST_TASK_STATUS } from '../constants';
import { useAppDispatch, useAppSelector } from '../redux';
import { testTasksSelectors, getAvailableTestTasks } from '../redux/testTasks';
import { userSelectors } from '../redux/user';
import { isStatusIdle } from '../utils';
import { IUserTestTask } from 'src/types';
import { useIsStandalonePage } from './useIsStandalonePage';

export const useUserTestTasks = (shouldFetch?: boolean) => {
  const dispatch = useAppDispatch();
  const isStandalonePage = useIsStandalonePage();

  const userData = useAppSelector(userSelectors.getUserData);
  const { apiStatus, data } = useAppSelector(
    testTasksSelectors.getAvailableTestTasksApiData,
  );
  const isPerformingAction = useAppSelector(
    testTasksSelectors.getIsPerformingTestTaskAction,
  );

  useEffect(() => {
    if (isStandalonePage || !shouldFetch) return;

    if (userData?.profileSubmittedAt && isStatusIdle(apiStatus)) {
      dispatch(getAvailableTestTasks());
    }
  }, [userData, apiStatus, isStandalonePage, shouldFetch]);

  const currentTestTask = (userData?.testTasks ?? []).find(
    (testTask: IUserTestTask) =>
      testTask.status.length === 1 &&
      testTask.status[0] === TEST_TASK_STATUS.SENT,
  );

  const doneTestTask = (userData?.testTasks ?? []).find(
    (testTask: IUserTestTask) =>
      testTask.status.length === 2 &&
      testTask.status.some((status) => status === TEST_TASK_STATUS.DONE),
  );

  const passedTestTasks = userData?.testTasks
    ?.filter((testTask: IUserTestTask) =>
      testTask.status.some(
        (status: TEST_TASK_STATUS) => status === TEST_TASK_STATUS.PASSED,
      ),
    )
    .sort(
      (a: any, b: any) =>
        new Date(b.resultDate).valueOf() - new Date(a.resultDate).valueOf(),
    );

  const failedTestTasks = userData?.testTasks
    ?.filter((testTask: IUserTestTask) =>
      testTask.status.some(
        (status: TEST_TASK_STATUS) => status === TEST_TASK_STATUS.FAILED,
      ),
    )
    .sort(
      (a: any, b: any) =>
        new Date(b.resultDate).valueOf() - new Date(a.resultDate).valueOf(),
    );
  const requiredForPositionTestTask = data.find(
    (tt) => tt.isRequiredForUserPosition,
  );

  return {
    isPerformingAction,
    availableTestTasks: data,
    passedTestTasks,
    failedTestTasks,
    currentTestTask,
    doneTestTask,
    requiredForPositionTestTask,
  };
};
