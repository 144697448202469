import {
  CustomButton,
  Flex,
  StepPanel,
  StepPanelHeader,
} from '../../../components';
import { Typography } from '@mui/material';

import startImage from '../../../assets/passing-star.png';
import { confirmIntermediateScreen } from '../../../redux/user';
import { useAppDispatch } from '../../../redux';
import { logEvent } from 'src/services';

export default function SuccessInterviewLayout({
  starRef,
  confirmIntermediateScreenLoading,
}: {
  starRef: React.RefObject<HTMLDivElement>;
  confirmIntermediateScreenLoading: boolean;
}) {
  const dispatch = useAppDispatch();

  const nextStep = () => {
    logEvent('launchdpod - video-interview-finished-page_continue-clicked');

    dispatch(confirmIntermediateScreen());
  };
  return (
    <StepPanel data-testid="video-interview-finished-step" maxWidth="45rem">
      <StepPanelHeader
        srcSetPng={startImage}
        ref={starRef}
        alt="Video Interview Is Finished!"
      >
        Thank you <br /> for your time and answers!
      </StepPanelHeader>
      <Typography variant="body1">
        It will take us some time to review your video. In the meantime, feel
        free to continue your job application.
      </Typography>
      <Flex width={{ xs: '100%', sm: 'auto' }}>
        <CustomButton
          onClick={nextStep}
          label="Continue"
          color="primary"
          isLoading={confirmIntermediateScreenLoading}
          fullWidth
        />
      </Flex>
    </StepPanel>
  );
}
