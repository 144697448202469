import { baseApiUrl } from 'src/utils';

const BASE_ENDPOINT =
  'https://ae-cp-static-files.s3.eu-central-1.amazonaws.com';

export const internalTestTaskFileUrl = (filename: string) =>
  `${BASE_ENDPOINT}/internal_tt_attachments/${filename}`;

export const videoFileUrl = (filename: string) =>
  `${BASE_ENDPOINT}/intro-video/${filename}`;

export const aeImageLogoUrl = `${BASE_ENDPOINT}/emails/ae-logo-bg.png`;
export const aeTeamImageUrl = `${BASE_ENDPOINT}/emails/ae-team.png`;

export const guaranteeUserIcon = `${BASE_ENDPOINT}/projects-images/guarantee-user-icon.png`;
export const cloclIcon = `${BASE_ENDPOINT}/projects-images/clock-icon.png`;

export const videoInterviewFileUrl = (filename: string) =>
  `${baseApiUrl}/admin/stream-video/video-interview/${filename}`;
