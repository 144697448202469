import React from 'react';
import { styled } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

const IconContainer = styled('div')({
  width: '1.5rem',
  height: '1.5rem',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  backgroundColor: 'black',
  marginRight: '1rem',
});

export const EditingIcon = (): React.ReactElement => {
  return (
    <IconContainer>
      <EditIcon
        htmlColor="white"
        fontSize="small"
        sx={{ padding: '0.25rem' }}
      />
    </IconContainer>
  );
};
