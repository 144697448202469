import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { ChatMessage } from '../../../components';
import React, { FC, ReactNode } from 'react';
import { useGamifiedMessage } from '../../../hooks';

interface IProps {
  shouldReserveChatMessageSpace?: boolean;
  ttInfoPanel?: ReactNode;
}

const OutLetLogic: FC<IProps> = ({
  ttInfoPanel,
  shouldReserveChatMessageSpace = true,
}) => {
  const { lastGamifiedMessage } = useGamifiedMessage();

  if (ttInfoPanel) {
    return (
      <Box
        sx={(theme) => ({
          display: 'flex',
          width: '100%',
          alignItems: 'flex-start',
          [theme.breakpoints.down(1140)]: {
            display: 'block',
          },
        })}
      >
        <Box
          py={{ md: 6 }}
          px={{
            xs: 3,
            sm: 4,
            lg: 4,
            xl: 6,
            xxl: 8,
          }}
        >
          {lastGamifiedMessage ? (
            <ChatMessage>{lastGamifiedMessage}</ChatMessage>
          ) : shouldReserveChatMessageSpace ? (
            <Box height="4.3rem" />
          ) : null}
          <Outlet />
        </Box>
        {ttInfoPanel}
      </Box>
    );
  } else {
    return (
      <>
        {lastGamifiedMessage ? (
          <ChatMessage>
            <span dangerouslySetInnerHTML={{ __html: lastGamifiedMessage }} />
          </ChatMessage>
        ) : shouldReserveChatMessageSpace ? (
          <Box height="4.3rem" />
        ) : null}
        <Outlet />
      </>
    );
  }
};

export default OutLetLogic;
