export enum ENGAGEMENT_TYPE {
  CONTRACTOR = 'CONTRACTOR',
  EMPLOYEE = 'EMPLOYEE',
}

export const COMMON_ENGAGEMENT_DATA = {
  anualBudget:
    '$150 for education • $150 for sports • $35 for team buildings • $300 one-time office budget',
};

export const CONTRACTOR_ENGAGEMENT_DATA = {
  name: 'Independent contractor',
  info: "Contractors get more autonomy and flexibility but don't receive benefits like paid time off or health insurance.",
  cooperationType: 'Contract with a US company, no end date',
  payment: 'Monthly on 15th of the month',
  taxes: 'Paid by you',
  socialSecurity: 'No',
  daysOff: 'Non-paid',
  vacationBonus: 'No',
  christmasBonus: 'No',
  healthInsurance: 'No',
  anualBudget:
    '$150 for education\n$150 for sports\n$35 for team buildings\n$300 one-time office budget',
  severance: 'No',
};

export const EMPLOYEE_ENGAGEMENT_DATA = {
  name: 'Employee',
  info: "Employees are on company payroll and receive wages and benefits in exchange for following the organization's guidelines.",
  cooperationType: 'Salary + benefits by a Mexican company',
  payment: 'Bi-weekly payroll',
  taxes: 'Paid by AgileEngine',
  socialSecurity:
    'Universal healthcare\nHousing loans INFONAVIT\nRetirement\nNurseries\nIncapacity insurance\nWork injury insurance\n',
  daysOff: '8 - 14 days of vacation\n3 sick leaves\n10 national holidays\n',
  vacationBonus: '25% vacation bonus by law',
  christmasBonus: '15 days by law',
  healthInsurance: 'Yes',
  anualBudget:
    '$150 for education\n$150 for sports\n$35 for team buildings\n$300 one-time office budget',
  severance: 'Severance of 3 months by law',
};

export const ENGAGEMENT_TABLE_HEADERS = {
  cooperationType: 'Cooperation type',
  payment: 'Payment',
  taxes: 'Taxes',
  socialSecurity: 'Social security',
  daysOff: 'Vacations, sick leaves, holidays',
  vacationBonus: 'Vacation bonus',
  christmasBonus: 'Christmas bonus',
  healthInsurance: 'Private health insurance',
  anualBudget: 'Anual budgets',
  severance: 'Severance',
};
