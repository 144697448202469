import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import {
  getUserKnowledgeQuiz,
  startUserKnowledgeQuiz,
  answerUserKnowledgeQuiz,
  finishUserKnowledgeQuiz,
} from './knowledgeQuiz.thunks';
import { IRelevantKnowledgeQuiz } from '../../types';
import { IKnowledgeQuizSliceState } from './knowledgeQuiz.types';
import { API_STATUS } from 'src/constants';
import { knowledgeQuizStoreKey } from './knowledgeQuiz.const';

const initialState: IKnowledgeQuizSliceState = {
  apiStatus: API_STATUS.IDLE,
  data: null,
  isPerformingAction: false,
};

export const knowledgeQuizSlice = createSlice({
  name: knowledgeQuizStoreKey,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserKnowledgeQuiz.pending, (state) => {
        state.apiStatus = API_STATUS.LOADING;
      })
      .addCase(
        getUserKnowledgeQuiz.fulfilled,
        (state, action: PayloadAction<IRelevantKnowledgeQuiz>) => {
          state.apiStatus = API_STATUS.COMPLETE;
          state.data = action.payload;
        },
      )
      .addCase(getUserKnowledgeQuiz.rejected, (state) => {
        state.apiStatus = API_STATUS.FAILED;
      })
      .addMatcher(
        isAnyOf(
          startUserKnowledgeQuiz.pending,
          answerUserKnowledgeQuiz.pending,
          finishUserKnowledgeQuiz.pending,
        ),
        (state) => {
          state.isPerformingAction = true;
        },
      )
      .addMatcher(
        isAnyOf(
          startUserKnowledgeQuiz.fulfilled,
          answerUserKnowledgeQuiz.fulfilled,
          finishUserKnowledgeQuiz.fulfilled,
        ),
        (state, action: PayloadAction<IRelevantKnowledgeQuiz>) => {
          state.isPerformingAction = false;
          state.data = action.payload;
        },
      )
      .addMatcher(
        isAnyOf(
          startUserKnowledgeQuiz.rejected,
          answerUserKnowledgeQuiz.rejected,
          finishUserKnowledgeQuiz.rejected,
        ),
        (state) => {
          state.isPerformingAction = false;
        },
      );
  },
});
