import { SeniorityOptions } from '../../../constants';

export const seniorityMessage = (seniority: string): string => {
  switch (seniority) {
    case SeniorityOptions.Junior:
      return 'Nice! Juniors grow fast at AgileEngine';
    case SeniorityOptions.Middle:
      return 'We’re in the <i>middle</i> of something awesome here';
    case SeniorityOptions.Senior:
      return 'Senior sounds superb!';
    case SeniorityOptions.Lead:
      return 'Great! Let’s see where this <i>leads</i> us';
    default:
      return 'Got it! Thanks!';
  }
};
