import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { testTasksStoreKey } from './testTasks.const';
import {
  createUserTestTask,
  finishQuizTestTask,
  getAvailableTestTasks,
  sendTestTaskReminder,
  setTestTaskReminderDate,
  submitQuizAnswer,
  updateSimulatedTT,
  uploadInternalTestTaskResolution,
} from './testTasks.thunks';
import { API_STATUS } from '../../constants';
import { AvailableTestTaskWithPositionsData } from 'src/types/testTasks';

export interface ITestTasksSliceState {
  availableTestTasks: {
    apiStatus: API_STATUS;
    data: AvailableTestTaskWithPositionsData[];
  };
  isPerformingAction: boolean;
}

const initialState: ITestTasksSliceState = {
  availableTestTasks: {
    apiStatus: API_STATUS.IDLE,
    data: [],
  },
  isPerformingAction: false,
};

export const testTasksSlice = createSlice({
  name: testTasksStoreKey,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAvailableTestTasks.pending, (state) => {
        state.availableTestTasks.apiStatus = API_STATUS.LOADING;
      })
      .addCase(getAvailableTestTasks.fulfilled, (state, action) => {
        state.availableTestTasks.data = action.payload;
        state.availableTestTasks.apiStatus = API_STATUS.COMPLETE;
      })
      .addCase(getAvailableTestTasks.rejected, (state) => {
        state.availableTestTasks.apiStatus = API_STATUS.FAILED;
      })

      .addMatcher(
        isAnyOf(
          createUserTestTask.pending,
          updateSimulatedTT.pending,
          submitQuizAnswer.pending,
          finishQuizTestTask.pending,
          uploadInternalTestTaskResolution.pending,
          sendTestTaskReminder.pending,
          setTestTaskReminderDate.pending,
        ),
        (state) => {
          state.isPerformingAction = true;
        },
      )
      .addMatcher(
        isAnyOf(
          createUserTestTask.fulfilled,
          updateSimulatedTT.fulfilled,
          submitQuizAnswer.fulfilled,
          finishQuizTestTask.fulfilled,
          uploadInternalTestTaskResolution.fulfilled,
          sendTestTaskReminder.fulfilled,
          setTestTaskReminderDate.fulfilled,
        ),
        (state) => {
          state.isPerformingAction = false;
        },
      )
      .addMatcher(
        isAnyOf(
          createUserTestTask.rejected,
          updateSimulatedTT.rejected,
          submitQuizAnswer.rejected,
          finishQuizTestTask.rejected,
          uploadInternalTestTaskResolution.rejected,
          sendTestTaskReminder.rejected,
          setTestTaskReminderDate.rejected,
        ),
        (state) => {
          state.isPerformingAction = false;
        },
      );
  },
});
