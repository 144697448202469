import React, { useEffect, useRef, KeyboardEvent, useMemo } from 'react';
import {
  Autocomplete,
  Box,
  createFilterOptions,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { GoToNextStep, GoBackOnSkills, StickyPanel } from '../../../components';
import { useFocusInputAfterAnimation, useScrollToTop } from '../../../hooks';
import { getUserPrefferedName } from '../../../utils';
import { useAppDispatch, useAppSelector } from '../../../redux';
import { userSelectors } from '../../../redux/user';
import {
  gamifiedResponseSelectors,
  gamifiedResponseSlice,
} from '../../../redux/gamifiedResponse';
import { secondarySkillsMessage } from './secondarySkillsMessage';
import { skillsSelectors } from '../../../redux/skills';
import {
  clearNotificationAlert,
  notificationAlert,
} from '../../../redux/notifications';
import { useTechSkillsContext } from '../TechSkills';
import { logEvent } from 'src/services';

const STEP_MAX_WIDTH = '33.25rem';
const SECONDARY_SKILL_MAX_LENGTH = 255;

const SecondarySkills: React.FC = () => {
  useScrollToTop();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(userSelectors.getIsUserPerformingAction);
  const userData = useAppSelector(userSelectors.getUserData)!;
  const skillsData = useAppSelector(skillsSelectors.getSkillsData);
  const lastGamifiedMessage = useAppSelector(
    gamifiedResponseSelectors.getGamifiedResponse,
  );
  const userTechStack = useAppSelector(skillsSelectors.getTechStack);
  const {
    handleSecondarySkillsChange,
    handleSubmitSecondarySkills,
    goBackToWorkArea,
    goBackToSpecialization,
    goBackToPrimarySkills,
  } = useTechSkillsContext();

  const inputRef = useRef<HTMLInputElement | null>(null);
  const filter = createFilterOptions<string>();

  const secondarySkills = useMemo(
    () =>
      skillsData.data.skills
        .filter((skill) => skill.type === 'secondary')
        .map((skill) => skill.name)
        .sort((a, b) => a.localeCompare(b)),
    [skillsData.data.skills],
  );

  useFocusInputAfterAnimation(inputRef);

  const uniteSecondaryAndArbitrarySkills = (): null | string[] => {
    const newArray: string[] = [];
    if (userTechStack?.secondarySkills) {
      newArray.push(...userTechStack?.secondarySkills);
    }
    if (userTechStack?.skillset) {
      newArray.push(...userTechStack?.skillset);
    }
    if (newArray.length) {
      return newArray;
    } else {
      return null;
    }
  };

  const selectedSecondarySkills = uniteSecondaryAndArbitrarySkills();

  useEffect(() => {
    if (selectedSecondarySkills) {
      dispatch(gamifiedResponseSlice.actions.updateMessage(null));
    } else {
      if (!lastGamifiedMessage) {
        dispatch(
          gamifiedResponseSlice.actions.updateMessage(
            `Welcome back, ${getUserPrefferedName(userData)}!`,
          ),
        );
      }
    }

    logEvent('launchpod-secondary-skills-page-loaded');
  }, []);

  const handleSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSubmitSecondarySkills();
  };

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: string[] = [],
  ) => {
    dispatch(clearNotificationAlert('long-skillset'));
    if (newValue.length) {
      const lastValue = newValue[newValue.length - 1];
      if (lastValue.length > SECONDARY_SKILL_MAX_LENGTH) {
        dispatch(
          notificationAlert('Value too long', {
            variant: 'error',
            key: 'long-skillset',
          }),
        );
        return;
      }

      if (lastValue.includes("Add '")) {
        newValue[newValue.length - 1] = lastValue
          .replace('Add ', '')
          .replaceAll("'", '');
      }
    }

    handleSecondarySkillsChange(newValue);
    dispatch(
      gamifiedResponseSlice.actions.updateMessage(
        secondarySkillsMessage({
          secondarySkillsSelected: newValue,
        }),
      ),
    );
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    valueSelected: string[] = [],
  ) => {
    if (event.key == ',') {
      event.preventDefault();
      event.stopPropagation();
      handleChange(event, [...valueSelected, event.target.value]);
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    event.stopPropagation();
    const prevValues = selectedSecondarySkills?.length
      ? selectedSecondarySkills
      : [];
    const newValue = event.target.value.trim();
    if (newValue) {
      handleChange(event, [...prevValues, newValue]);
    }
  };

  return (
    <>
      <GoBackOnSkills
        workArea={userTechStack?.workArea || ''}
        goBackToWorkArea={goBackToWorkArea}
        specialization={userTechStack?.specialization}
        goBackToSpecialization={goBackToSpecialization}
        primarySkills={userTechStack?.primarySkills}
        goBackToPrimarySkills={goBackToPrimarySkills}
        isPartiallySelected={Boolean(userTechStack.isPartiallySelected)}
      />
      <Typography variant="h2" data-testid="secondary-skills-layout">
        What other technologies
        <br />
        do you know?
      </Typography>
      <form onSubmit={handleSubmit}>
        <Autocomplete
          multiple
          freeSolo
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          disableClearable
          autoHighlight
          value={selectedSecondarySkills ?? []}
          onChange={handleChange}
          onBlur={handleBlur}
          options={secondarySkills}
          filterOptions={(options, params) => {
            const filtered = filter(options, params) as string[];
            if (params.inputValue !== '') {
              filtered.push(`Add '${params.inputValue}'`);
            }
            return filtered;
          }}
          filterSelectedOptions
          isOptionEqualToValue={(option, value) => option === value}
          renderOption={(props, option) => (
            <Box
              key={option}
              component="li"
              {...props}
              padding="1rem 1.5rem!important"
              sx={(theme) => ({
                backgroundColor: `${theme.palette.highlight.actionable}!important`,
                borderBottom: '3px solid white',
                '&:hover': {
                  backgroundColor: `${theme.palette.highlight.accent}!important`,
                },
                '&.Mui-focused': {
                  backgroundColor: `${theme.palette.highlight.accent}!important`,
                },
              })}
            >
              {option}
            </Box>
          )}
          renderInput={(params) => {
            params.inputProps.onKeyDown = (e) =>
              handleKeyDown(e, selectedSecondarySkills || []);
            return (
              <TextField
                {...params}
                placeholder={
                  !selectedSecondarySkills ||
                  selectedSecondarySkills?.length === 0
                    ? 'Frameworks or languages'
                    : ''
                }
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <>
                      {(!selectedSecondarySkills ||
                        selectedSecondarySkills?.length === 0) && (
                        <SearchIcon
                          sx={(theme) => ({
                            marginLeft: '1rem',
                            color: theme.palette.text.secondary,
                          })}
                        />
                      )}
                      {params.InputProps.startAdornment}
                    </>
                  ),
                }}
                inputRef={inputRef}
              />
            );
          }}
          sx={{ maxWidth: STEP_MAX_WIDTH, overflow: 'auto' }}
          onKeyUp={(e: KeyboardEvent) => e.stopPropagation()}
          disablePortal={true}
        />
        <Typography variant="body2" color="text.secondary" sx={{ mt: '1rem' }}>
          Add as many as you want, but be honest!
        </Typography>
        <StickyPanel>
          <GoToNextStep
            isLoading={isLoading}
            isDisabled={false}
            textIdentifier={0}
            maxWidth={{ xs: '10rem', sm: 'initial' }}
          />
        </StickyPanel>
      </form>
    </>
  );
};

export default SecondarySkills;
