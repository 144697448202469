import { DetectedPositionType, IPositionData } from '../types/positionData';
import { IUserData } from '../types/user';

export const isSkipSpecializationBasedOnUserPosition = (
  positionData: IPositionData | null,
): boolean => {
  if (!positionData) return false;

  return (
    positionData.isDisplayable &&
    positionData.detectedPosition === DetectedPositionType.FULL
  );
};

export const isUserPositionPartial = (
  positionData: IPositionData | null,
): boolean => {
  if (!positionData) return false;

  return (
    positionData.isDisplayable &&
    positionData.detectedPosition === DetectedPositionType.PARTIAL
  );
};

export const isSkipSkills = ({
  notOurCoreStack,
  primarySkills,
}: Pick<IUserData, 'notOurCoreStack' | 'primarySkills'>): boolean => {
  return notOurCoreStack || !primarySkills?.length;
};
