import { APP_ROUTES } from 'src/constants';
import { matchPath, useLocation } from 'react-router-dom';

export const useIsIndependentVIPage = () => {
  const location = useLocation();

  return (
    !!matchPath(
      APP_ROUTES.CANDIDATE_VIDEO_INTERVIEW_START,
      location.pathname,
    ) ||
    !!matchPath(APP_ROUTES.CANDIDATE_VIDEO_INTERVIEW_RECORD, location.pathname)
  );
};
