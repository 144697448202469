import { useTheme } from '@mui/material';
import { css, SerializedStyles } from '@emotion/react';

export const useLinkButtonStyles = (): SerializedStyles => {
  const theme = useTheme();
  return css`
    color: ${theme.palette.text.primary};
    font-weight: 600;
    border-color: ${theme.palette.common.black};
  `;
};
