export interface IPositionData {
  jobPostingUrl?: string;
  skill?: string;
  title?: string;
  utmProvider?: string;
  utmSource?: string;
  expireddate?: string;
  isDisplayable: boolean;
  detectedPosition?: DetectedPositionType;
  detectedSkills?: string[] | null;
}

export enum DetectedPositionType {
  PARTIAL = 'PARTIAL',
  FULL = 'FULL',
}
