import React, { useRef } from 'react';

import { useEmojisplosion } from '../../../hooks';
import {
  ResponsiveBreak,
  StepPanel,
  StepPanelHeader,
} from '../../../components';

import startImage from '../../../assets/passing-star.png';
import { Typography } from '@mui/material';

const FinishInterviewLayout = () => {
  const starRef = useRef<HTMLDivElement>(null);
  useEmojisplosion(starRef, 'TT_PASSED');

  return (
    <StepPanel alignItems="center" textAlign="center">
      <StepPanelHeader
        ref={starRef}
        srcSetPng={startImage}
        alt="Star"
        data-testid="success-title"
      >
        Thank you
        <ResponsiveBreak breakpoint="sm" />
        for your time and answers!
      </StepPanelHeader>
      <Typography
        variant="body1"
        mb={0}
        data-testid="not-supported-by-open-positions-text"
      >
        It will take us some time to review your video
        <ResponsiveBreak breakpoint="sm" />
        and get back to you!
      </Typography>
    </StepPanel>
  );
};

export default FinishInterviewLayout;
