import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { API_STATUS } from '../../constants';
import { massCampaignCandidateStoreKey } from './massCampaignCandidate.const';
import { postUserWithMassCampaignSignUp } from './massCampaignCandidate.thunks';
import { IMassCampaign } from '@types';

export interface IMassCampaignCandidateSliceState {
  apiStatus: API_STATUS;
  candidateId: string | null;
  campaignId: string | null;
}

const initialState: IMassCampaignCandidateSliceState = {
  apiStatus: API_STATUS.IDLE,
  candidateId: null,
  campaignId: null,
};

export const massCampaignCandidateSlice = createSlice({
  name: massCampaignCandidateStoreKey,
  initialState,
  reducers: {
    setMassCampaignData: (state, action: PayloadAction<IMassCampaign>) => {
      state.candidateId = action.payload.candidateId;
      state.campaignId = action.payload.campaignId;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postUserWithMassCampaignSignUp.pending, (state) => {
        state.apiStatus = API_STATUS.LOADING;
      })
      .addCase(postUserWithMassCampaignSignUp.fulfilled, (state) => {
        state.apiStatus = API_STATUS.COMPLETE;
      })
      .addCase(postUserWithMassCampaignSignUp.rejected, (state) => {
        state.apiStatus = API_STATUS.FAILED;
      });
  },
});

export const { setMassCampaignData } = massCampaignCandidateSlice.actions;
