import React, { useEffect } from 'react';
import { Box, FormGroup, Grid, Stack, Typography } from '@mui/material';
import { GoBackOnSkills, GoToNextStep, StickyPanel } from '../../../components';
import { useGoToPreviousStep, useScrollToTop } from '../../../hooks';
import { useAppDispatch, useAppSelector } from '../../../redux';
import { skillsSelectors } from '../../../redux/skills';
import { DEVELOPER_TYPES } from '../../../constants';
import { gamifiedResponseSlice } from '../../../redux/gamifiedResponse';
import { getPrimarySkillsBySpecialization } from '../../../utils/techSkills';
import { useTechSkillsContext } from '../TechSkills';
import { PrimarySkillCheckbox } from './PrimarySkillCheckbox';
import { NoneOfTheAboveCheckbox } from './NoneOfTheAboveCheckbox';
import { userSelectors } from 'src/redux/user';
import { logEvent } from 'src/services';

const PrimarySkills: React.FC = () => {
  useScrollToTop();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(userSelectors.getIsUserPerformingAction);
  const skillsData = useAppSelector(skillsSelectors.getSkillsData);
  const userTechStack = useAppSelector(skillsSelectors.getTechStack);
  const {
    handlePrimarySkillsChange,
    handleNotOurCoreStackToggle,
    handleSubmitPrimarySkills,
    goBackToSpecialization,
    goBackToWorkArea,
  } = useTechSkillsContext();

  const { onGoToPreviousStep } = useGoToPreviousStep();

  const primarySkills = getPrimarySkillsBySpecialization(
    userTechStack?.specialization || '',
    skillsData.data.skills,
  );

  useEffect(() => {
    if (userTechStack?.primarySkills?.length) {
      dispatch(gamifiedResponseSlice.actions.updateMessage(null));
    }

    logEvent('launchpod-primary-skills-page-loaded');
  }, []);

  const isSkillsGrid = (skills: any) => {
    return skills.length > 6;
  };

  const isContinueDisabled = () => {
    if (userTechStack?.notOurCoreStack) {
      return false;
    }
    return (
      !userTechStack?.primarySkills ||
      userTechStack?.primarySkills?.length === 0 ||
      userTechStack?.primarySkills?.length > 3
    );
  };

  const isSelectedSkill = (skillName: string) => {
    return userTechStack?.primarySkills?.includes(skillName) ?? false;
  };

  const isDisabledSkill = (skillName: string) => {
    return (
      (isSelectedSkill(skillName) &&
        userTechStack.positionSkills?.includes(skillName)) ??
      false
    );
  };

  const { isPartiallySelected } = userTechStack;

  return (
    <>
      <GoBackOnSkills
        workArea={userTechStack?.workArea || ''}
        goBackToWorkArea={
          !isPartiallySelected ? goBackToWorkArea : onGoToPreviousStep
        }
        specialization={userTechStack?.specialization}
        goBackToSpecialization={
          !isPartiallySelected ? goBackToSpecialization : undefined
        }
        isPartiallySelected={Boolean(isPartiallySelected)}
      />
      <Typography variant="h2">
        Technologies you want to work with and{' '}
        <Box
          component="br"
          sx={(theme) => ({
            [theme.breakpoints.down('xl')]: {
              display: 'none',
            },
          })}
        />
        <strong>know well enough to complete a coding test</strong>
      </Typography>
      <Typography variant="body1" marginBottom="2rem">
        We have open positions with these technologies:
      </Typography>
      <FormGroup>
        {userTechStack?.specialization === DEVELOPER_TYPES.fullstack ? (
          <>
            <Stack direction={{ xs: 'column', xl: 'row' }} spacing={4}>
              <Box maxWidth={{ md: '100%', xl: '45vw' }}>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  Backend
                </Typography>
                <Grid container rowSpacing={0.5} columnSpacing={2}>
                  {primarySkills.map((skillObject) => {
                    if (
                      skillObject.specialization === DEVELOPER_TYPES.backend
                    ) {
                      return (
                        <Grid
                          item
                          key={skillObject.name}
                          xs={12}
                          sm={6}
                          md={6}
                          lg={4}
                          xl={6}
                        >
                          <PrimarySkillCheckbox
                            value={skillObject.name}
                            onClick={handlePrimarySkillsChange}
                            isSelected={isSelectedSkill(skillObject.name)}
                            isDisabled={isDisabledSkill(skillObject.name)}
                          />
                        </Grid>
                      );
                    }
                  })}
                </Grid>
              </Box>
              <Box maxWidth={{ md: '100%', xl: '15vw' }}>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  Frontend
                </Typography>
                <Grid container rowSpacing={0.5} columnSpacing={2}>
                  {primarySkills.map((skillObject) => {
                    if (
                      skillObject.specialization === DEVELOPER_TYPES.frontend
                    ) {
                      return (
                        <Grid
                          item
                          key={skillObject.name}
                          xs={12}
                          sm={6}
                          md={6}
                          lg={4}
                          xl={12}
                        >
                          <PrimarySkillCheckbox
                            value={skillObject.name}
                            onClick={handlePrimarySkillsChange}
                            isSelected={isSelectedSkill(skillObject.name)}
                            isDisabled={isDisabledSkill(skillObject.name)}
                          />
                        </Grid>
                      );
                    }
                  })}
                </Grid>
              </Box>
            </Stack>
            <Box maxWidth={{ xl: '100%' }} mt={6} hidden={isPartiallySelected}>
              <NoneOfTheAboveCheckbox
                value={userTechStack?.notOurCoreStack}
                isSelected={userTechStack?.notOurCoreStack}
                onClick={handleNotOurCoreStackToggle}
              />
            </Box>
          </>
        ) : (
          <>
            {isSkillsGrid(primarySkills) ? (
              <>
                <Box maxWidth={{ md: '100%', xl: '45vw' }}>
                  <Grid container rowSpacing={1} columnSpacing={2}>
                    {primarySkills.map((skillObject) => {
                      if (
                        skillObject.specialization ===
                        userTechStack?.specialization
                      ) {
                        return (
                          <Grid
                            item
                            key={skillObject.name}
                            xs={12}
                            sm={6}
                            md={6}
                            lg={4}
                          >
                            <PrimarySkillCheckbox
                              value={skillObject.name}
                              onClick={handlePrimarySkillsChange}
                              isSelected={isSelectedSkill(skillObject.name)}
                              isDisabled={isDisabledSkill(skillObject.name)}
                            />
                          </Grid>
                        );
                      }
                    })}
                  </Grid>
                </Box>
                <Box
                  maxWidth={{ md: '100%', xl: '45vw' }}
                  mt={6}
                  hidden={isPartiallySelected}
                >
                  <NoneOfTheAboveCheckbox
                    value={userTechStack?.notOurCoreStack}
                    isSelected={userTechStack?.notOurCoreStack}
                    onClick={handleNotOurCoreStackToggle}
                  />
                </Box>
              </>
            ) : (
              <Stack direction="column" spacing={1} maxWidth={{ sm: '17rem' }}>
                {primarySkills.map((skillObject) => {
                  if (
                    skillObject.specialization === userTechStack?.specialization
                  ) {
                    return (
                      <PrimarySkillCheckbox
                        key={skillObject.name}
                        value={skillObject.name}
                        onClick={handlePrimarySkillsChange}
                        isSelected={isSelectedSkill(skillObject.name)}
                        isDisabled={isDisabledSkill(skillObject.name)}
                      />
                    );
                  }
                })}
                <NoneOfTheAboveCheckbox
                  value={userTechStack?.notOurCoreStack}
                  isSelected={userTechStack?.notOurCoreStack}
                  onClick={handleNotOurCoreStackToggle}
                  hidden={isPartiallySelected}
                />
              </Stack>
            )}
          </>
        )}
      </FormGroup>
      <StickyPanel delayCalculation={400}>
        <GoToNextStep
          isLoading={isLoading}
          isDisabled={isContinueDisabled()}
          textIdentifier={1}
          handleSubmitStep={handleSubmitPrimarySkills}
          validationError={
            userTechStack?.primarySkills &&
            userTechStack?.primarySkills?.length > 3
              ? 'Please select up to 3'
              : undefined
          }
          maxWidth={{ xs: '10rem', sm: 'initial' }}
        />
      </StickyPanel>
    </>
  );
};

export default PrimarySkills;
