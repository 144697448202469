import React from 'react';
import { Box, Stack, SvgIcon, Typography } from '@mui/material';
import EmailForm from './EmailForm';
import { TOP_CLIENTS_LIST } from '../../../../constants';
import { ResponsiveBreak } from '../../../../components';
import { EmailIntroWrapper } from './EmailIntroWrapper';
import DocumentMeta from 'react-document-meta';
import { useIsTabletView } from '../../../../hooks';

export const TopClients: React.FC = () => {
  const isTabletView = useIsTabletView();

  const renderTopClients = (
    topClients: (typeof TOP_CLIENTS_LIST)[0],
    index: number,
  ) => (
    <Box key={index} textAlign={{ xs: 'center', md: 'start' }}>
      <SvgIcon
        inheritViewBox
        component={topClients.icon}
        sx={{ width: 'inherit', height: 'inherit' }}
      />
    </Box>
  );

  return (
    <DocumentMeta
      {...{
        meta: {
          name: {
            robots: 'noindex',
          },
        },
      }}
    >
      <EmailIntroWrapper
        data-testid="sign-in-layout-email-intro-top-clients"
        sx={() => ({
          maxWidth: '55.75rem',
        })}
      >
        <Stack
          overflow="hidden"
          direction={{ xs: 'column', md: 'row' }}
          sx={{
            px: { xs: 0, md: '2.375rem' },
            py: { xs: 0, md: '2rem' },
            gap: { xs: '2rem', lg: '4.5rem' },
          }}
        >
          <Box>
            <Typography
              variant="h2"
              fontWeight={{ xs: 700, sm: 600 }}
              sx={{
                fontSize: { xs: '1.25rem', sm: '2rem' },
                marginBottom: { xs: '1.5rem', sm: '2rem' },
              }}
            >
              Unleash your potential <ResponsiveBreak breakpoint="xs" />
              by collaborating <ResponsiveBreak breakpoint="xs" />
              with top-tier brands
            </Typography>
            {isTabletView && (
              <Stack
                direction="row"
                overflow="scroll"
                gap="3rem"
                sx={{
                  marginTop: '0.625rem',
                  paddingBottom: '1rem',
                  marginBottom: '2rem',
                }}
              >
                {TOP_CLIENTS_LIST.map(renderTopClients)}
              </Stack>
            )}
            <EmailForm buttonTitle="Start" />
          </Box>
          {!isTabletView && (
            <Box
              sx={{
                display: 'grid',
                gap: '3rem',
                paddingX: '3.25rem',
                height: '100%',
                gridTemplate: `repeat(6, min-content) / repeat(1, 1fr)`,
              }}
            >
              {TOP_CLIENTS_LIST.map(renderTopClients)}
            </Box>
          )}
        </Stack>
      </EmailIntroWrapper>
    </DocumentMeta>
  );
};
