import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { formatErrorMessage } from '../../utils';
import { notificationAlert } from '../notifications';
import { fetchUser } from '../user';
import { knowledgeQuizStoreKey } from './knowledgeQuiz.const';
import {
  getUserKnowledgeQuizPath,
  startUserKnowledgeQuizPath,
  answerUserKnowledgeQuizPath,
  finishUserKnowledgeQuizPath,
} from 'src/constants/apiEndpoints';
import { IQuizAnswer, IRelevantKnowledgeQuiz } from '@types';

export const getUserKnowledgeQuiz = createAsyncThunk(
  `${knowledgeQuizStoreKey}/getUserKnowledgeQuiz`,
  async (_, thunkAPI) => {
    try {
      const response = await axios.get<IRelevantKnowledgeQuiz>(
        getUserKnowledgeQuizPath(),
      );

      return response.data;
    } catch (err: any) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(err);
    }
  },
);

export const startUserKnowledgeQuiz = createAsyncThunk(
  `${knowledgeQuizStoreKey}/startUserKnowledgeQuiz`,
  async (knowledgeQuizId: string, thunkAPI) => {
    try {
      const response = await axios.post<IRelevantKnowledgeQuiz>(
        startUserKnowledgeQuizPath(),
        {
          knowledgeQuizId,
        },
      );

      await thunkAPI.dispatch(fetchUser());

      return response.data;
    } catch (err: any) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(err);
    }
  },
);

export const answerUserKnowledgeQuiz = createAsyncThunk(
  `${knowledgeQuizStoreKey}/answerUserKnowledgeQuiz`,
  async (
    {
      userKnowledgeQuizId,
      answer,
    }: { userKnowledgeQuizId: string; answer: IQuizAnswer },
    thunkAPI,
  ) => {
    try {
      const response = await axios.patch<IRelevantKnowledgeQuiz>(
        answerUserKnowledgeQuizPath(userKnowledgeQuizId),
        {
          answer,
        },
      );

      return response.data;
    } catch (err: any) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(err);
    }
  },
);

export const finishUserKnowledgeQuiz = createAsyncThunk(
  `${knowledgeQuizStoreKey}/finishUserKnowledgeQuiz`,
  async (userKnowledgeQuizId: string, thunkAPI) => {
    try {
      const response = await axios.patch<IRelevantKnowledgeQuiz>(
        finishUserKnowledgeQuizPath(userKnowledgeQuizId),
      );

      await thunkAPI.dispatch(fetchUser());

      return response.data;
    } catch (err: any) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(err);
    }
  },
);
