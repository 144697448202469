import React, { FC } from 'react';
import { AnimationProps, motion } from 'framer-motion';
import { Box, BoxProps, styled } from '@mui/material';

const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({});

export const AnimatedBox: FC<BoxProps & AnimationProps> = ({
  children,
  ...rest
}) => {
  return (
    <StyledBox component={motion.div} {...rest}>
      {children}
    </StyledBox>
  );
};
