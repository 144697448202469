import { Box } from '@mui/material';
import React, { FC, ReactNode } from 'react';
import {
  AllSystemCSSProperties,
  ResponsiveStyleValue,
} from '@mui/system/styleFunctionSx';

interface IProps {
  children: ReactNode;
  maxWidth?: ResponsiveStyleValue<AllSystemCSSProperties['maxWidth']>;
}
export const VideoPlayerContainer: FC<IProps> = ({
  maxWidth = { sm: '62.5%', lg: '12.5rem', xl: '12.5rem' },
  children,
}) => {
  return (
    <Box
      data-testid="video-player-container"
      maxWidth={maxWidth}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        width: '70%',
      }}
    >
      {children}
    </Box>
  );
};
