import React, { KeyboardEvent, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack } from '@mui/material';
import { INavigationStep } from '../../../../types';
import { useGamifiedMessage } from '../../../../hooks';
import MainStep from './MainStep';

interface IProps {
  steps: INavigationStep[];
}

const MainStepper: React.FC<IProps> = ({ steps }) => {
  const navigate = useNavigate();
  const { updateGamifiedMessage } = useGamifiedMessage();

  const handleStepClick = (
    event: KeyboardEvent | MouseEvent,
    step: INavigationStep,
  ) => {
    if (step.route) {
      updateGamifiedMessage(null);
      navigate(step.route);
    }
  };

  return (
    <>
      <Stack my={3} ml={4} mr={2}>
        <Stack rowGap={3}>
          {steps.map((step, index) => (
            <MainStep
              key={index}
              step={step}
              handleStepClick={handleStepClick}
            />
          ))}
        </Stack>
      </Stack>
    </>
  );
};

export default React.memo(MainStepper);
