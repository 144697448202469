import { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Quiz } from 'src/components';

import { IQuizAnswer, IRelevantKnowledgeQuiz } from 'src/types';
import { APP_ROUTES } from 'src/constants';

export const StartedKnowledgeQuiz: React.FC<{
  knowledgeQuiz: IRelevantKnowledgeQuiz;
  isPerformingAction: boolean;
  answer: (userKnowledgeQuizId: string, answer: IQuizAnswer) => void;
  finish: (userKnowledgeQuizId: string) => void;
}> = ({ knowledgeQuiz, isPerformingAction, answer, finish }) => {
  const navigate = useNavigate();
  const queryParams = useParams<{ quizQuestionId: string }>();

  const answers = knowledgeQuiz.answers || {};

  const userKnowledgeQuizId = knowledgeQuiz.userKnowledgeQuizId;
  const questionIdx = queryParams.quizQuestionId || '1';
  const currentQuestion = knowledgeQuiz.questions[questionIdx];
  const questionsCount = Object.keys(knowledgeQuiz.questions).length;
  const currentAnswer = answers[questionIdx] || [];
  const answersCount = Object.keys(answers).length;
  const unansweredQuestionsCount = questionsCount - answersCount;
  const quizDuration = knowledgeQuiz.duration || 0;

  const onSelectAnswer = useCallback(
    (userAnswer: IQuizAnswer) => {
      if (userKnowledgeQuizId) {
        answer(userKnowledgeQuizId, userAnswer);
      }
    },
    [userKnowledgeQuizId],
  );

  const onFinishQuiz = useCallback(() => {
    if (userKnowledgeQuizId) {
      finish(userKnowledgeQuizId);
    }
  }, [userKnowledgeQuizId]);

  const onPreviousQustion = useCallback(() => {
    navigate(`/${APP_ROUTES.KNOWLEDGE_QUIZ}/${Number(questionIdx) - 1}`);
  }, [questionIdx]);

  const onNextQustion = useCallback(() => {
    navigate(`/${APP_ROUTES.KNOWLEDGE_QUIZ}/${Number(questionIdx) + 1}`);
  }, [questionIdx]);

  useEffect(() => {
    if (!questionIdx) {
      const firstUnansweredQuestionId = Object.keys(
        knowledgeQuiz.questions || {},
      ).find((questionId) => !answers[questionId]?.length);

      navigate(
        `/${APP_ROUTES.KNOWLEDGE_QUIZ}/${
          firstUnansweredQuestionId ?? questionsCount
        }`,
      );
    }
  }, [questionIdx, knowledgeQuiz.questions, answers]);

  return (
    <Quiz
      question={currentQuestion!}
      questionIdx={questionIdx}
      questionsCount={questionsCount}
      questionAnswer={currentAnswer}
      unansweredQuestionsCount={unansweredQuestionsCount}
      duration={quizDuration}
      startDate={knowledgeQuiz.startDate}
      isPerformingAction={isPerformingAction}
      onSelectAnswer={onSelectAnswer}
      onNextQuestion={onNextQustion}
      onPreviousQuestion={onPreviousQustion}
      onFinish={onFinishQuiz}
    />
  );
};
