import React, { FC } from 'react';
import { Box, Zoom } from '@mui/material';
import { CurrencyType } from '../../../../types';
import { ConvertedValue } from './ConvertedValue';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { ExchangeRate } from './ExchangeRate';

interface IProps {
  amount: string;
  rate: string;
  currency: CurrencyType;
  isShowRates?: boolean | false;
  isShowExchangeRate?: boolean | false;
  sx?: SxProps<Theme>;
}
export const CompensationRate: FC<IProps> = ({
  isShowRates = false,
  isShowExchangeRate = false,
  sx,
  amount,
  ...props
}) => {
  return (
    <Box sx={sx}>
      <Zoom
        in={isShowRates || (!isShowRates && isShowExchangeRate)}
        style={{ transformOrigin: '0 0' }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            gap: 1,
            py: 1,
          }}
        >
          {isShowExchangeRate && (
            <AutorenewIcon color="disabled" sx={{ fontSize: '1rem' }} />
          )}
          {isShowRates && <ConvertedValue amount={amount} {...props} />}
          {!isShowRates && isShowExchangeRate && <ExchangeRate {...props} />}
        </Box>
      </Zoom>
    </Box>
  );
};
