/** @jsxImportSource @emotion/react */
import React, { ReactNode, useMemo } from 'react';
import { ButtonUnstyled } from '@mui/base';
import { Stack, Typography } from '@mui/material';
import { useCollapseButtonStyles } from './CollapseButton.style';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

interface IProps {
  onClick?: () => void;
  children: ReactNode;
  isChecked?: boolean | false;
  isDisabled?: boolean;
}

export const CollapseButton: React.FC<IProps> = ({
  onClick,
  isChecked = false,
  isDisabled,
  children,
}) => {
  const style = useCollapseButtonStyles();

  const icon = useMemo(
    () =>
      isChecked ? (
        <ExpandLessIcon fontSize="medium" />
      ) : (
        <ExpandMoreIcon fontSize="medium" />
      ),
    [isChecked],
  );

  return (
    <ButtonUnstyled
      css={style}
      onClick={onClick}
      disabled={isDisabled}
      tabIndex={0}
    >
      <Stack direction="row" spacing={2} component="span">
        {icon}
        <Typography variant="body1" fontWeight="600" minWidth="4.5rem">
          {children}
        </Typography>
      </Stack>
    </ButtonUnstyled>
  );
};
