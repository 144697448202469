import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS, FEATURE_FLAG_KEYS } from '../../constants';
import { IABTestSessionPayload, IFeatureFlags } from '../../types';
import { featureFlagsStoreKey } from './featureFlags.const';
import { fetchFeatureFlags } from './featureFlags.thunks';

export interface IFeatureFlagsSliceState {
  apiStatus: API_STATUS;
  featureFlags: IFeatureFlags;
}

const initialState: IFeatureFlagsSliceState = {
  apiStatus: API_STATUS.IDLE,
  featureFlags: {},
};

export const featureFlagsSlice = createSlice({
  name: featureFlagsStoreKey,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(isAnyOf(fetchFeatureFlags.pending), (state) => {
        state.apiStatus = API_STATUS.LOADING;
      })
      .addMatcher(
        isAnyOf(fetchFeatureFlags.fulfilled),
        (state, action: PayloadAction<IABTestSessionPayload>) => {
          state.apiStatus = API_STATUS.COMPLETE;
          state.featureFlags = action.payload.featureFlags.reduce(
            (acc: IFeatureFlags, value: string) => {
              acc[value as unknown as FEATURE_FLAG_KEYS] = true;
              return acc;
            },
            {},
          );
        },
      )
      .addMatcher(isAnyOf(fetchFeatureFlags.rejected), (state) => {
        state.apiStatus = API_STATUS.FAILED;
        state.featureFlags = {};
      });
  },
});
