/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo, useRef } from 'react';
import { useAppSelector } from '../../redux';

import { PageTransitionWrapper } from '../../components';

import { userSelectors } from '../../redux/user';
import { useEmojisplosion, useUserVideoInterview } from 'src/hooks';

import { useLocation, useNavigate, useNavigationType } from 'react-router';
import SkipInterviewLayout from './layout/skipInterviewLayout';
import ResumeInterviewLayout from './layout/resumeInterviewLayout';
import QuitedInterviewLayout from './layout/quitedInterviewLayout';
import SuccessInterviewLayout from './layout/successInterviewLayout';
import { logEvent } from 'src/services';
import { UserVideoInterviewStatus } from 'src/types/userVideoInterviewData';

const VideoInterviewFinishedStep: React.FC = () => {
  const starRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const navigationType = useNavigationType();
  const location = useLocation();
  const userData = useAppSelector(userSelectors.getUserData);

  useEmojisplosion(starRef, 'VIDEO_INTERVIEW_PASSED');

  const {
    isLoading,
    userVideoInterview,
    isUserQuitedVideoInterview,
    isUserSkippedVideoInterview,
  } = useUserVideoInterview();
  const confirmIntermediateScreenLoading = useAppSelector(
    userSelectors.getIsUserPerformingAction,
  );

  useEffect(() => {
    if (navigationType.toString() === 'POP') {
      navigate(1);
      return;
    }
  }, [location]);

  useEffect(() => {
    logEvent('launchpod - video-interview-finished-page_loaded');
  }, []);

  const userInAFurtherStep =
    userVideoInterview?.status === UserVideoInterviewStatus.FINISHED &&
    !userData?.intermediateScreen;

  const userNavigatesBackVideoInterview: boolean =
    isUserQuitedVideoInterview && userInAFurtherStep;

  const FinishedInterviewLayout = useMemo(() => {
    if (isUserSkippedVideoInterview) {
      return SkipInterviewLayout;
    }

    if (userNavigatesBackVideoInterview) {
      return ResumeInterviewLayout;
    }

    if (isUserQuitedVideoInterview) {
      return QuitedInterviewLayout;
    }

    return SuccessInterviewLayout;
  }, [
    isUserSkippedVideoInterview,
    isUserQuitedVideoInterview,
    userNavigatesBackVideoInterview,
  ]);

  return (
    <PageTransitionWrapper>
      <FinishedInterviewLayout
        isLoading={isLoading}
        starRef={starRef}
        confirmIntermediateScreenLoading={confirmIntermediateScreenLoading}
      />
    </PageTransitionWrapper>
  );
};

export default VideoInterviewFinishedStep;
