import { ILocationsSliceState } from './locations.slice';
import { locationsStoreKey } from './locations.const';

interface IState {
  [locationsStoreKey]: ILocationsSliceState;
}

const selectLocationsState = (state: IState) => {
  return state[locationsStoreKey];
};

const getCountriesAPIData = (state: IState) => {
  return {
    countriesAPIStatus: selectLocationsState(state).countriesAPIStatus,
    countries: selectLocationsState(state).countries,
  };
};

const getCitiesAPIData = (state: IState) => {
  return {
    citiesAPIStatus: selectLocationsState(state).citiesAPIStatus,
    cities: selectLocationsState(state).cities,
  };
};

export const locationsSelectors = {
  getCountriesAPIData,
  getCitiesAPIData,
};
