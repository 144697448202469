import { videoInterviewDataStoreKey } from './videoInterview.const';
import { IVIDataSliceState } from './videoInterview.slice';

interface IState {
  [videoInterviewDataStoreKey]: IVIDataSliceState;
}

const selectVideoQuestionsDataState = (state: IState) => {
  return state[videoInterviewDataStoreKey];
};

const getAvailableVideoInterviewAPIData = (state: IState) => {
  return selectVideoQuestionsDataState(state).availabeVideoInterview;
};

const getUserVideoInterviewViewAPIData = (state: IState) => {
  return selectVideoQuestionsDataState(state).userVideoInterviewView;
};

const getIsVideoInterviewPerformingAction = (state: IState) => {
  return selectVideoQuestionsDataState(state).isPerformingAction;
};

export const videoInterviewSelectors = {
  getAvailableVideoInterviewAPIData,
  getUserVideoInterviewViewAPIData,
  getIsVideoInterviewPerformingAction,
};
