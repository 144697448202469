import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'src/redux';

import { Box, FormControlLabel, TextField, Typography } from '@mui/material';
import {
  CustomButton,
  Flex,
  Panel,
  ResponsiveBreak,
  SelectableWrapper,
  StepPanel,
} from '../../components';
import LetsConnect from '../letsConnect/LetsConnect';

import { unsubscribeFromRemindersOptions } from '../../constants';

import { userRouteSelector } from 'src/redux/userRoute';

export const CancelApplicationLayout: React.FC<{
  userName: string;
  preselectedOption?: string;
  isLoading: boolean;
  isSubmitted: boolean;
  onSubmit: (reason: string) => void;
}> = ({ userName, preselectedOption, isLoading, isSubmitted, onSubmit }) => {
  const navigate = useNavigate();
  const stepperRoute = useAppSelector(userRouteSelector.getUserRoute)!;

  const [optionSelected, setOptionSelected] = useState<null | string>(null);
  const [reason, setReason] = useState<null | string>(null);

  const isSomethingElseOptionSelected = optionSelected === 'somethingElse';

  useEffect(() => {
    if (preselectedOption) {
      handleSelection(preselectedOption);
    }
  }, []);

  const handleSelection = (optionSelected: string) => {
    setOptionSelected(optionSelected);
    setReason(optionSelected === 'somethingElse' ? '' : optionSelected);

    if (optionSelected !== 'somethingElse') {
      onSubmit(optionSelected);
    }
  };

  const onContinueProcessClick = useCallback(() => {
    if (stepperRoute) {
      navigate(`/${stepperRoute}`);
    } else {
      window.location.href = '/';
    }
  }, [stepperRoute]);

  const onCancelApplication = useCallback(() => {
    if (reason) {
      onSubmit(reason);
    }
  }, [reason]);

  const onKeyUp = (e: React.KeyboardEvent<any>) => {
    e.stopPropagation();
    if (e.code === 'Enter') {
      onCancelApplication();
    }
  };

  return (
    <StepPanel
      maxWidth="32.5rem"
      bgcolor={{ md: 'background.default' }}
      alignItems="flex-start"
      textAlign="left"
      py={{ md: 6 }}
      px={{ md: 6 }}
      sx={{
        transform: {
          lg: 'translateX(-15%)',
          xl: 'translateX(-30%)',
          xxl: 'translateX(-28%)',
        },
      }}
    >
      <Typography variant="h4" fontWeight="600">
        Sorry to hear you’re
        <ResponsiveBreak breakpoint={'md'} /> no longer interested, {userName}
      </Typography>

      <Typography variant="body1">
        You will not receive any more emails about your current job application,
        but we’re still open to staying in touch.
      </Typography>

      <LetsConnect withHeader={false} />

      <Box>
        {isSubmitted ? (
          <Panel maxWidth="21rem">
            {optionSelected && (
              <Typography fontWeight="600">
                Thank you for sharing that!
              </Typography>
            )}
            <Typography>
              You’ve unsubscribed from receiving job application updates.
            </Typography>
          </Panel>
        ) : (
          <>
            <Typography
              variant="body2"
              color="text.secondary"
              mb={{ xs: 4, md: 2 }}
              ml={{ md: 3 }}
            >
              Please share why you are not interested:
            </Typography>{' '}
            {Object.values(unsubscribeFromRemindersOptions).map((option) => (
              <Box
                key={option}
                m={{ xs: '0 0 0.25rem 0', lg: '0 1rem 0.25rem 0' }}
              >
                <SelectableWrapper
                  value={option}
                  onClick={() => handleSelection(option)}
                >
                  <FormControlLabel
                    value={option}
                    control={<Box width={10} />}
                    label={<Typography variant="body1">{option}</Typography>}
                  />
                </SelectableWrapper>
              </Box>
            ))}
            <Box m={{ xs: '0 0 0.5rem 0', lg: '0 1rem 0.25rem 0' }}>
              {!isSomethingElseOptionSelected ? (
                <SelectableWrapper
                  value="Something else"
                  onClick={() => handleSelection('somethingElse')}
                >
                  <FormControlLabel
                    value="Something else"
                    control={<Box width={10} />}
                    label={
                      <>
                        <Typography variant="body1">
                          Something else...
                        </Typography>

                        <Typography variant="body2" color="text.secondary">
                          Click to add your own reason, this will{' '}
                          <ResponsiveBreak breakpoint="sm" />
                          greatly help us to improve our service
                        </Typography>
                      </>
                    }
                  />
                </SelectableWrapper>
              ) : (
                <>
                  <Box
                    borderRadius={1}
                    bgcolor="highlight.accent"
                    p="0.8rem 1.3rem"
                  >
                    <TextField
                      name="otherUnsubscribeOption"
                      type="text"
                      label="Something else"
                      id="otherUnsubscribeOption"
                      variant="outlined"
                      fullWidth
                      autoFocus
                      sx={{ background: 'white' }}
                      onChange={(e) => setReason(e.target.value)}
                      onKeyUp={onKeyUp}
                      inputProps={{ maxLength: 255 }}
                    />
                  </Box>
                  <Flex width={{ xs: '100%', sm: '10rem' }} mt={2}>
                    <CustomButton
                      isLoading={isLoading}
                      isDisabled={!reason}
                      onClick={onCancelApplication}
                      label="Submit"
                      testid="submit-something-else"
                      fullWidth
                    />
                  </Flex>
                </>
              )}
            </Box>
          </>
        )}
      </Box>

      {!isSubmitted && (
        <Flex
          flexDirection="column"
          alignItems={{ xs: 'center', sm: 'flex-start' }}
          textAlign={{ xs: 'center', sm: 'left' }}
        >
          <Typography variant="body2" color="text.secondary" mt={3}>
            If you changed your mind, you can
            <ResponsiveBreak breakpoint={'xs'} />
            still continue with the job application
          </Typography>
          <Box mt={2}>
            <CustomButton
              color="primary"
              label="Continue the process"
              testid="continue-process"
              onClick={onContinueProcessClick}
            />
          </Box>
        </Flex>
      )}
    </StepPanel>
  );
};
