import React, { FC } from 'react';
import { MainLayout, PageWrapper } from '../../components';
import OutletLogic from './components/OutletLogic';

const MainPage: FC = () => {
  return (
    <PageWrapper>
      <MainLayout>{(props) => <OutletLogic {...props} />}</MainLayout>
    </PageWrapper>
  );
};

export default MainPage;
