import { keyframes } from '@mui/material';

export const ANIMATION_DURATION = 0.2;
export const ANIMATION_DELAY = 0.2;

export const slideIn = keyframes`
  from {
    transform: translateX(120%);
  }
  to {
    transform: translateX(0%);
  }
`;
