import { skillsStoreKey } from './skills.const';
import { ISkillsSliceState } from './skills.slice';

interface IState {
  [skillsStoreKey]: ISkillsSliceState;
}

const selectSkillsState = (state: IState) => {
  return state[skillsStoreKey];
};

const getSkillsData = (
  state: IState,
): Pick<ISkillsSliceState, 'apiStatus' | 'data'> => {
  return {
    apiStatus: selectSkillsState(state).apiStatus,
    data: selectSkillsState(state).data,
  };
};

const getTechStack = (state: IState) => {
  return selectSkillsState(state).techStack;
};

export const skillsSelectors = {
  getSkillsData,
  getTechStack,
};
