import { useCallback } from 'react';
import { useKnowledgeQuiz } from 'src/hooks';

import { KnowledgeQuizIntro } from './components/KnowledgeQuizIntro';
import { StartedKnowledgeQuiz } from './components/StartedKnowledgeQuiz';

export const KnowledgeQuizPage: React.FC = () => {
  const {
    knowledgeQuiz,
    isPerformingAction,
    hasKnowledgeQuizToDo,
    start,
    answer,
    finish,
  } = useKnowledgeQuiz();

  const knowledgeQuizId = knowledgeQuiz?.knowledgeQuizId || null;
  const knowledgeQuizDuration = knowledgeQuiz?.duration || 0;
  const questionsCount = knowledgeQuiz?.questions
    ? Object.keys(knowledgeQuiz?.questions).length
    : 0;

  const onStartQuiz = useCallback(() => {
    if (knowledgeQuizId) {
      start(knowledgeQuizId);
    }
  }, [knowledgeQuizId]);

  if (!knowledgeQuiz) {
    return null;
  }

  if (hasKnowledgeQuizToDo) {
    return (
      <KnowledgeQuizIntro
        questionsCount={questionsCount}
        duration={knowledgeQuizDuration}
        isPerformingAction={isPerformingAction}
        start={onStartQuiz}
      />
    );
  }

  return (
    <StartedKnowledgeQuiz
      knowledgeQuiz={knowledgeQuiz}
      isPerformingAction={isPerformingAction}
      answer={answer}
      finish={finish}
    />
  );
};
