import { ReactComponent as InstagramLogo } from '../assets/instagram-social-logo.svg';
import { ReactComponent as FacebookLogo } from '../assets/facebook-social-logo.svg';
import { ReactComponent as LinkedinLogo } from '../assets/linkedin-social-logo.svg';
import { ReactComponent as TwitterLogo } from '../assets/twitter-social-logo.svg';
import { ReactComponent as YoutubeLogo } from '../assets/youtube-social-logo.svg';
import { ReactComponent as TikTokLogo } from '../assets/tiktok-social-logo.svg';

export const SOCIAL_LINKS = [
  {
    href: `https://www.instagram.com/agileengine/`,
    icon: InstagramLogo,
    label: 'Agile Engine Instagram',
  },
  {
    href: `https://www.facebook.com/agileengine/`,
    icon: FacebookLogo,
    label: 'Agile Engine Facebook',
  },
  {
    href: `https://www.linkedin.com/company/agileengine/`,
    icon: LinkedinLogo,
    label: 'Agile Engine Linkedin',
  },
  {
    href: `https://twitter.com/agileengine`,
    icon: TwitterLogo,
    label: 'Agile Engine Twitter',
  },
  {
    href: `https://www.youtube.com/channel/UCy7jhgX7lqo13llN6NZr6Mg`,
    icon: YoutubeLogo,
    label: 'Agile Engine Youtube',
  },
  {
    href: `https://www.tiktok.com/@agileengine`,
    icon: TikTokLogo,
    label: 'Agile Engine TikTok',
  },
];
