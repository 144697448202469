import { FC, useEffect } from 'react';
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Typography,
} from '@mui/material';
import { RadioButton, SelectableWrapper } from '../../../components';
import GoToPreviousStep from '../../../components/goToPreviousStep/GoToPreviousStep';
import { WORK_AREA_TYPE } from '../../../constants';
import { useScrollToTop } from '../../../hooks';
import { useTechSkillsContext } from '../TechSkills';
import { useAppSelector } from '../../../redux';
import { skillsSelectors } from '../../../redux/skills';
import { logEvent } from 'src/services';

const WorkArea: FC = () => {
  useScrollToTop();
  useEffect(() => {
    logEvent('launchpod-work-area-page-loaded');
  }, []);

  const { handleWorkAreaChange } = useTechSkillsContext();
  const userTechStack = useAppSelector(skillsSelectors.getTechStack);

  return (
    <>
      <Box display="flex">
        <GoToPreviousStep />
        <Typography variant="h2">Where do you want to work?</Typography>
      </Box>
      <Typography variant="body1" marginBottom="2rem">
        Choose one to help us offer you the right position.
      </Typography>
      <FormControl>
        <Grid container spacing={0.5} maxWidth={{ lg: '35rem' }}>
          <Grid item xs={12} lg={8}>
            <SelectableWrapper
              value={WORK_AREA_TYPE.ENGINEERING}
              selectedValue={userTechStack?.workArea}
              onClick={handleWorkAreaChange}
              display="block"
              testid="work-area-option"
            >
              <FormControlLabel
                checked={WORK_AREA_TYPE.ENGINEERING === userTechStack?.workArea}
                value={WORK_AREA_TYPE.ENGINEERING}
                control={<RadioButton tabIndex={-1} />}
                label={
                  <Typography variant="body1" fontWeight="bold">
                    {WORK_AREA_TYPE.ENGINEERING}
                  </Typography>
                }
              />
              <Typography variant="body1" color="text.secondary" ml={4}>
                Software engineering, testing, DevOps, UI/UX design, etc.
              </Typography>
            </SelectableWrapper>
          </Grid>
          <Grid item xs={12} lg={8}>
            <SelectableWrapper
              value={WORK_AREA_TYPE.NON_ENGINEERING}
              selectedValue={userTechStack?.workArea}
              onClick={handleWorkAreaChange}
              display="block"
              testid="work-area-option"
            >
              <FormControlLabel
                checked={
                  WORK_AREA_TYPE.NON_ENGINEERING === userTechStack?.workArea
                }
                value={WORK_AREA_TYPE.NON_ENGINEERING}
                control={<RadioButton tabIndex={-1} />}
                label={
                  <Typography variant="body1" fontWeight="bold">
                    {WORK_AREA_TYPE.NON_ENGINEERING}
                  </Typography>
                }
              />
              <Typography variant="body1" color="text.secondary" ml={4}>
                Human resources, marketing, client relationships, IT support,
                etc.
              </Typography>
            </SelectableWrapper>
          </Grid>
        </Grid>
      </FormControl>
    </>
  );
};

export default WorkArea;
