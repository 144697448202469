import React, { useCallback, useEffect, useRef, useState } from 'react';
import { PopupModal } from 'react-calendly';
import { Box } from '@mui/material';

import { Loader, StepPanel, StepPanelHeader } from '../../../../components';
import LetsConnect from '../../../letsConnect/LetsConnect';
import { useEmojisplosion, useUserMeetings } from '../../../../hooks';
import MeetingDetailsDefaultMessage from './messages/MeetingDetailsDefaultMessage';
import MeetingDetailsMessage from './messages/MeetingDetailsMessage';
import MeetingCancelledMessage from './messages/MeetingCancelledMessage';

import { useAppDispatch, useAppSelector } from '../../../../redux';
import { fetchUser, userSelectors } from '../../../../redux/user';
import { calendlySelectors, getCalendlyLink } from '../../../../redux/calendly';
import { getActiveUserMeetings } from '../../../../redux/meeting';
import { isStatusLoading, pollRequest } from '../../../../utils';
import { CALENDLY_UTM_SOURCE } from 'src/constants/calendly';

import calendarImage from '../../../../assets/calendar-image.png';
import { logEvent } from 'src/services';
import { IMeeting, MeetingType } from '../../../../types';

const ACTIVE_MEETINGS_POLL_INTERVAL = 10;
const ACTIVE_MEETINGS_POLL_ATTEMPTS = 3;

export const ScheduleCallLayout = () => {
  const dispatch = useAppDispatch();
  const starRef = useRef<HTMLDivElement>(null);
  useEmojisplosion(starRef, 'CALL_BOOKED');

  const userData = useAppSelector(userSelectors.getUserData);
  const calendlyLink = useAppSelector(calendlySelectors.getCalendlyLink);
  const calendlyApiStatus = useAppSelector(calendlySelectors.getApiStatus);
  const { activeIntroCall, isLoading } = useUserMeetings();

  const [calendlyUrl, setCalendlyUrl] = useState<string | null>(null);
  const [isCallBooked, setIsCallBooked] = useState(false);

  const getUserActiveMeetings = useCallback(() => {
    return dispatch(getActiveUserMeetings()).unwrap();
  }, []);

  const pollUserActiveMeetings = () => {
    pollRequest<IMeeting[] | null>({
      fn: getUserActiveMeetings,
      validate: (value) => {
        const ic = value?.find((e) => e.type === MeetingType.INTRO_CALL);

        return ic?.id !== activeIntroCall?.id;
      },
      interval: ACTIVE_MEETINGS_POLL_INTERVAL * 1000,
      maxAttempts: ACTIVE_MEETINGS_POLL_ATTEMPTS,
    });
  };

  useEffect(() => {
    dispatch(getCalendlyLink());
  }, []);

  useEffect(() => {
    logEvent('launchpod-schedule-success-page-loaded');
  }, []);

  useEffect(() => {
    if (isCallBooked) {
      pollUserActiveMeetings();
      dispatch(fetchUser());
    }
  }, [isCallBooked]);

  const handleOpenCalendlyModal = (url: string) => {
    setCalendlyUrl(url);
    setIsCallBooked(false);
  };

  const handleCloseCalendlyModal = () => {
    setCalendlyUrl(null);
    setIsCallBooked(true);
  };

  return (
    <>
      <StepPanel data-testid="schedule-layout" maxWidth="35.5rem">
        <StepPanelHeader
          ref={starRef}
          srcSetPng={calendarImage}
          imageWidth={{ xs: 57, sm: 77, lg: 90, xxl: 100 }}
          alt="Calendar"
        >
          Can't wait <br />
          to talk to you!
        </StepPanelHeader>

        {isLoading && (
          <Box my="1.5rem">
            <Loader position="relative" />
          </Box>
        )}

        {activeIntroCall && !activeIntroCall.canceled && !isLoading && (
          <MeetingDetailsMessage
            meetingDetails={activeIntroCall}
            handleOpenCalendlyModal={handleOpenCalendlyModal}
          />
        )}
        {activeIntroCall && activeIntroCall.canceled && !isLoading && (
          <MeetingCancelledMessage
            calendlyLink={calendlyLink}
            isCalendlyLoading={isStatusLoading(calendlyApiStatus)}
            handleOpenCalendlyModal={handleOpenCalendlyModal}
          />
        )}
        {!activeIntroCall && !isLoading && <MeetingDetailsDefaultMessage />}

        <LetsConnect
          justifyContent={{ xs: 'flex-start', sm: 'center' }}
          mt={2}
        />
      </StepPanel>
      {calendlyUrl && CALENDLY_UTM_SOURCE && (
        <PopupModal
          url={calendlyUrl}
          open
          prefill={{
            email: userData?.email,
            name: `${userData?.firstName} ${userData?.lastName}`,
            firstName: userData?.firstName as string,
            lastName: userData?.lastName as string,
          }}
          onModalClose={handleCloseCalendlyModal}
          rootElement={document.getElementById('root')!}
          utm={{
            utmContent: userData?.id,
            utmSource: CALENDLY_UTM_SOURCE,
          }}
        />
      )}
    </>
  );
};
