import React, { useRef } from 'react';
import { useEmojisplosion, useUserVideoInterview } from '../../../../../hooks';

import { Box, Typography } from '@mui/material';
import {
  CustomButton,
  Flex,
  ResponsiveBreak,
  StepPanelHeader,
} from '../../../../../components';

import { APP_ROUTES } from '../../../../../constants';

import { ReactComponent as CalendlyIcon } from '../../../../../assets/calendly-icon.svg';
import startImage from '../../../../../assets/passing-star.png';
import { useNavigate } from 'react-router-dom';
import ReachOutToUs from '../ReachOutToUs';
import { logEvent } from 'src/services';

interface IProps {
  calendlyLink: string | null;
  isCalendlyLoading: boolean;
  setShowCalendlyModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const CustomTestTaskMessage: React.FC<IProps> = ({
  calendlyLink,
  isCalendlyLoading,
  setShowCalendlyModal,
}) => {
  const navigate = useNavigate();
  const { availableVideoInterview } = useUserVideoInterview();

  const isShowCalendlyLink = typeof calendlyLink === 'string';

  const starRef = useRef<HTMLDivElement>(null);
  useEmojisplosion(starRef, 'TT_PASSED');

  const handleClick = () => {
    navigate(`/${APP_ROUTES.VIDEO_INTERVIEW_SELECTION}`);
  };

  return (
    <>
      <StepPanelHeader
        ref={starRef}
        srcSetPng={startImage}
        alt="Star"
        data-testid="success-title"
      >
        Great! Let's discuss next <br />
        steps on a quick call
      </StepPanelHeader>

      <Typography variant="body1" data-testid="success-text">
        Our Talent Executive would be happy <br />
        to have an intro call with you.
      </Typography>
      {isShowCalendlyLink && !!availableVideoInterview && (
        <Box
          bgcolor={'highlight.actionable'}
          sx={(theme) => ({
            p: '2.5rem',
            pb: '3rem',
            borderRadius: '1rem',
            width: '500px',
            [theme.breakpoints.down('lg')]: {
              width: '100%',
            },
          })}
        >
          <Typography mb={'1.25rem'}>
            Record a quick self-introduction <ResponsiveBreak breakpoint="md" />
            to book a call with our Talent Executive to get{' '}
            <ResponsiveBreak breakpoint="md" /> to know more about our open
            positions
          </Typography>
          <CustomButton
            color="primary"
            label="Continue"
            onClick={handleClick}
          />
        </Box>
      )}
      {isShowCalendlyLink && !availableVideoInterview && (
        <>
          <Box my={1} data-testid="calendly-button">
            <CustomButton
              color="primary"
              startIcon={
                <Flex bgcolor="white" borderRadius={2} p={0.5}>
                  <CalendlyIcon />
                </Flex>
              }
              label="Book a time slot"
              onClick={() => {
                logEvent('launchpod-book-time-slot-prescreen-call-clicked');
                setShowCalendlyModal && setShowCalendlyModal(true);
              }}
              isLoading={isCalendlyLoading}
            />
          </Box>
          <ReachOutToUs />
        </>
      )}
    </>
  );
};

export default CustomTestTaskMessage;
