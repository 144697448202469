import React from 'react';
import { Typography } from '@mui/material';

const MessageOptions = (props: {
  textIdentifier: number;
}): React.ReactElement => {
  let message: React.ReactElement | null = null;
  switch (props.textIdentifier) {
    case 0:
      message = (
        <Typography variant="caption" color="text.secondary">
          Type and press <b>Enter</b>
        </Typography>
      );
      break;
    case 1:
      message = (
        <Typography variant="caption" color="text.secondary">
          Please select
        </Typography>
      );
      break;
    case 2:
      message = (
        <Typography variant="caption" color="text.secondary">
          Press <b>Enter</b>
        </Typography>
      );
      break;
    case 3:
      message = (
        <Typography variant="caption" color="text.secondary">
          Select and press <b>Enter</b>
        </Typography>
      );
      break;
    case 4:
      message = (
        <Typography variant="caption" color="text.secondary">
          Attach a file with your resume
        </Typography>
      );
      break;
    case 5:
      message = (
        <Typography variant="caption" color="text.secondary">
          Agree to the terms to proceed
        </Typography>
      );
      break;
  }
  return message!;
};

export default MessageOptions;
