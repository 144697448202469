export enum TestTaskType {
  CODILITY = 'CODILITY',
  QUIZ = 'QUIZ',
  INTERNAL = 'INTERNAL',
  CUSTOM = 'CUSTOM',
}

export const TestTaskTypeToText = {
  [TestTaskType.CODILITY]: 'Coding test',
  [TestTaskType.QUIZ]: 'Knowledge quiz',
  [TestTaskType.INTERNAL]: 'Internal test',
  [TestTaskType.CUSTOM]:
    'Project-specific coding test for some of our special opportunities',
};

export enum TEST_TASK_STATUS {
  SENT = 'Sent',
  DONE = 'Done',
  FAILED = 'Failed',
  PASSED = 'Passed',
  TODO = 'Todo',
}

export const TEST_TASK_STATUS_REFRESH_TIME = 15;

export const simulatedTTString = 'https://simulation-link.com/';

export const SELECT_TECHNOLOGY_ERROR_MESSAGE = 'Please select technology first';

export enum TEST_TASK_ORIGINS {
  LAUNCHPOD = 'LAUNCHPOD',
  DATAWAREHOUSE = 'DATAWAREHOUSE',
}
