import React, { FC } from 'react';
import { Checkbox, FormControlLabel, Tooltip, Typography } from '@mui/material';
import {
  Flex,
  ISelectableWrapperProps,
  SelectableWrapper,
} from '../../../components';
import { LockIcon } from '../../profileReview/components';

interface IProps extends Omit<ISelectableWrapperProps, 'children'> {
  value: string;
}
export const PrimarySkillCheckbox: FC<IProps> = ({
  value,
  onClick,
  isSelected,
  isDisabled,
  ...rest
}) => {
  return (
    <SelectableWrapper
      value={value}
      onClick={onClick}
      isSelected={isSelected}
      isDisabled={isDisabled}
      testid="primary-skill-option"
      {...rest}
    >
      <FormControlLabel
        control={
          <Checkbox
            onChange={() => onClick(value)}
            name={value}
            checked={isSelected}
          />
        }
        label={
          <Flex justifyContent="space-between">
            <Typography
              variant="body1"
              onClick={(e) => e.preventDefault()}
              sx={{ whiteSpace: 'nowrap' }}
            >
              {value}
            </Typography>
            {isDisabled && (
              <Tooltip title="Required for your position" placement="top">
                <LockIcon mr="-1rem" />
              </Tooltip>
            )}
          </Flex>
        }
        sx={{ mr: 0, width: '100%' }}
      />
    </SelectableWrapper>
  );
};
