import React, { useEffect, useRef, useState } from 'react';
import { Box, InputAdornment, TextField, Typography } from '@mui/material';
import { Loader } from '@googlemaps/js-api-loader';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../redux';
import { updateCity, userSelectors } from '../../redux/user';
import { getUserPrefferedName } from '../../utils';
import {
  APP_ROUTES,
  DEFAULT_CITY_VALUE,
  MAX_CITY_TRAVEL_TIME_HRS,
} from '../../constants';
import {
  useDebounce,
  useFocusInputAfterAnimation,
  useLPLocation,
  usePersistUserAnswers,
} from '../../hooks';
import { GoToNextStep, PageTransitionWrapper } from '../../components';
import {
  gamifiedResponseSelectors,
  gamifiedResponseSlice,
} from '../../redux/gamifiedResponse';
import GoToPreviousStep from '../../components/goToPreviousStep/GoToPreviousStep';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import SearchIcon from '@mui/icons-material/Search';
import './city.styles.scss';
import { logEvent } from 'src/services';

const googleMapLoader = new Loader({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API!,
  libraries: ['places'],
  language: 'en',
});

const City = (): React.ReactElement => {
  const dispatch = useAppDispatch();
  const userData = useAppSelector(userSelectors.getUserData)!;
  const isLoading = useAppSelector(userSelectors.getIsUserPerformingAction);
  const { countries, cities, officeLocations } = useLPLocation();
  const lastGamifiedMessage = useAppSelector(
    gamifiedResponseSelectors.getGamifiedResponse,
  );

  const navigate = useNavigate();

  const [canContinue, setCanContinue] = useState(false);
  const [userCity, setUserCity] = useState(userData.city || '');
  const [userState, setUserState] = useState(userData.state || null);
  const [originCountry, setOriginCountry] = useState(userData.country || '');
  const [userOfficeLocation, setUserOfficeLocation] = useState<string | null>(
    null,
  );

  const [autoDetected, setAutoDetected] =
    usePersistUserAnswers<Record<string, boolean>>('cityStep');

  const inputRef = useRef<HTMLInputElement | null>(null);
  const googleRef = useRef<typeof google | null>(null);

  useFocusInputAfterAnimation(inputRef);

  const getUserCountryCode = () => {
    if (!userData.country) {
      return 'US';
    }

    const countryData = countries.find(
      (country) => country.name === userData.country,
    );
    return countryData?.code || 'US';
  };

  const shouldRequestDirections = (): boolean => {
    const hasOfficeLocation = officeLocations.some(
      (location) => location.name.toLowerCase() === userCity.toLowerCase(),
    );

    if (hasOfficeLocation) {
      setUserOfficeLocation(userCity);
    }
    return !hasOfficeLocation;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserCity(event.target.value);
    setUserState(null);
    setCanContinue(false);
    setAutoDetected({ isAutoDetected: false });
  };

  const handleSubmit = (e: React.SyntheticEvent | null): void => {
    if (e) {
      e.preventDefault();
    }

    logEvent('launchpod-city-continue-click', { 'City Selected': userCity });

    dispatch(gamifiedResponseSlice.actions.updateMessage('Got it!'));
    setTimeout(async () => {
      const cityUpdatePayload = {
        city: userCity,
        state: userState,
        officeLocation: DEFAULT_CITY_VALUE,
      };

      if (userOfficeLocation && userOfficeLocation !== DEFAULT_CITY_VALUE) {
        cityUpdatePayload.officeLocation = userOfficeLocation;
      }

      if (!canContinue) return;

      await dispatch(updateCity(cityUpdatePayload));

      if (cityUpdatePayload.officeLocation !== DEFAULT_CITY_VALUE) {
        navigate(`/${APP_ROUTES.OFFICE}`);
      }
    }, 500);
  };

  const getCityDirections = async (originCity: string) => {
    const googleAPI = googleRef.current;

    const aeOfficeAddresses = officeLocations.reduce(
      (officeObj, currentLocation, currentIndex) => {
        return {
          ...officeObj,
          [currentIndex]: `${currentLocation.name}, ${currentLocation.countryCode}`,
        };
      },
      {},
    );

    if (googleAPI) {
      const directionsService = new googleAPI.maps.DirectionsService();
      try {
        for (const [index, aeOffice] of Object.entries(aeOfficeAddresses)) {
          const direction = await directionsService.route({
            origin: `${originCity}, ${originCountry}`,
            destination: aeOffice as string,
            travelMode: googleAPI.maps.TravelMode.DRIVING,
          });

          if (direction.routes[0]) {
            const durationData = direction.routes[0].legs[0].duration;
            const routeDurationHrs = durationData
              ? Math.round(durationData.value / 60 / 60)
              : +Infinity;

            if (routeDurationHrs <= MAX_CITY_TRAVEL_TIME_HRS) {
              setUserOfficeLocation(officeLocations[+index].name);
              break;
            } else {
              setUserOfficeLocation(DEFAULT_CITY_VALUE);
            }
          }
        }
      } catch (err) {
        console.error(`Error using GMaps DirectionsService: ${err}`);
        const fallbackOfficeLocation = officeLocations.find(
          (location) => location.name === originCity,
        );
        if (fallbackOfficeLocation) {
          setUserOfficeLocation(fallbackOfficeLocation.name);
        } else {
          setUserOfficeLocation(DEFAULT_CITY_VALUE);
        }
      }
    }
  };

  const loadGoogleApi = async () => {
    try {
      googleRef.current = await googleMapLoader.load();
    } catch (err) {
      console.error(`Error loading GMaps JS API: ${err}`);
      throw err;
    }
  };

  const attachGoogleAutoComplete = () => {
    const userCountryCode = getUserCountryCode();
    const googleAPI = googleRef.current;
    if (googleAPI) {
      try {
        const mapOptions = {
          types: ['(cities)'],
          componentRestrictions: { country: userCountryCode },
        };

        const autocomplete = new googleAPI.maps.places.Autocomplete(
          document.getElementById('autocomplete-city') as HTMLInputElement,
          mapOptions,
        );

        googleAPI.maps.event.addListener(autocomplete, 'place_changed', () => {
          const place = autocomplete.getPlace();

          let autoCompleteCity = userCity;
          let autoCompleteState = null;
          let autoCompleteCountry = '';

          if (place.address_components) {
            for (const addressComp of place.address_components) {
              if (addressComp.types.some((t) => t === 'locality')) {
                autoCompleteCity = addressComp.long_name;
              }
              if (addressComp.types.some((t) => t === 'country')) {
                autoCompleteCountry = addressComp.long_name;
              }
              if (
                addressComp.types.some(
                  (t) => t === 'administrative_area_level_1',
                )
              ) {
                autoCompleteState = addressComp.long_name;
              }
            }
          }

          const officeLocationCity = officeLocations.find(
            (location) => location.name === autoCompleteCity,
          );
          if (officeLocationCity) {
            setUserOfficeLocation(officeLocationCity.name);
          }
          setUserCity(autoCompleteCity);
          setUserState(autoCompleteState);
          setOriginCountry(autoCompleteCountry);
          setAutoDetected({ isAutoDetected: false });
          setCanContinue(true);
          autoCompleteCity !== '' && setCanContinue(true);
          dispatch(gamifiedResponseSlice.actions.updateMessage('Got it!'));
        });
      } catch (err) {
        console.error(err);
        throw err;
      }
    }
  };

  useEffect(() => {
    if (!userCity) {
      fetch('https://ipapi.co/json/')
        .then((response: any) => response.json())
        .then((data) => {
          if (
            data.country_name === userData.country &&
            cities.find((city) => city.name === data.city)
          ) {
            setAutoDetected({ isAutoDetected: true });
            setUserCity(data.city);
            setCanContinue(true);
            const officeLocationCity = officeLocations.find(
              (location) => location.name === data.city,
            );
            if (officeLocationCity) {
              setUserOfficeLocation(officeLocationCity?.name);
            }
            dispatch(gamifiedResponseSlice.actions.updateMessage('Got it!'));
          }
        });
    } else if (userCity === userData.city) {
      setCanContinue(true);
    }

    if (userData.city) {
      dispatch(gamifiedResponseSlice.actions.updateMessage(null));
    } else {
      if (!lastGamifiedMessage) {
        dispatch(
          gamifiedResponseSlice.actions.updateMessage(
            `Welcome back, ${getUserPrefferedName(userData)}!`,
          ),
        );
      }
    }

    loadGoogleApi().then(() => attachGoogleAutoComplete());

    logEvent('launchpod-city-page-loaded');
  }, []);

  const debouncedGetCityDirections = useDebounce((userCity: string) => {
    getCityDirections(userCity);
  }, 500);

  useEffect(() => {
    if (userCity && shouldRequestDirections()) {
      debouncedGetCityDirections(userCity);
    }
  }, [userCity]);

  return (
    <PageTransitionWrapper>
      <Box display="flex" data-testid="city-layout">
        <GoToPreviousStep />
        <Typography variant="h2">What city do you live in?</Typography>
      </Box>
      <form onSubmit={handleSubmit}>
        <Box
          display="flex"
          flexDirection="column"
          flexWrap="wrap"
          width="100%"
          sx={(theme) => ({
            [theme.breakpoints.up('lg')]: {
              maxHeight: '26rem',
              mb: 1,
              width: '28rem',
            },
            [theme.breakpoints.up('xl')]: {
              maxHeight: '21rem',
            },
          })}
        >
          <TextField
            inputRef={inputRef}
            type="text"
            variant="outlined"
            hiddenLabel
            value={userCity}
            data-testid="city-input"
            onChange={handleChange}
            InputProps={{
              id: 'autocomplete-city',
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: autoDetected?.isAutoDetected ? (
                <InputAdornment position="end">
                  <MyLocationIcon />
                </InputAdornment>
              ) : null,
              sx: { input: { ml: '-3rem', pl: '3rem' } },
            }}
            helperText={
              autoDetected?.isAutoDetected ? 'Based on your location' : ''
            }
          />
        </Box>
      </form>
      <GoToNextStep
        isLoading={isLoading}
        isDisabled={!canContinue}
        textIdentifier={3}
        handleSubmitStep={() => handleSubmit(null)}
      />
    </PageTransitionWrapper>
  );
};

export default City;
