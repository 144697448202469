import React from 'react';
import { Typography } from '@mui/material';

const NotSupportedEnglishLevelMessage: React.FC = () => {
  return (
    <>
      <Typography variant="body1" data-testid="success-text">
        Our projects involve daily and weekly meetings with native English
        speakers, including US companies and software experts. In our
        experience, <b>pre-intermediate</b> English is not enough for effective
        communication, so we can't move forward with your application right now.
      </Typography>
      <Typography variant="body1">
        We would be happy to keep in touch as your English skills grow and
        reconnect when the time is right.
      </Typography>
    </>
  );
};

export default NotSupportedEnglishLevelMessage;
