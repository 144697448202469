import React, { ReactElement } from 'react';

import { TextButton } from '../../../components';
import { Box, Typography } from '@mui/material';

interface IProps {
  isDisabled?: boolean;
  testTaskReminderDate: string | null;
  handleScheduleForLater: () => void;
}

const RescheduleTestTaskButton = ({
  isDisabled,
  testTaskReminderDate,
  handleScheduleForLater,
}: IProps): ReactElement => {
  return (
    <Box
      sx={{
        background: '#FFF2DE',
        borderRadius: '4px',
        padding: '11px 22px',
      }}
    >
      <Typography variant="body2" color="text.secondary" mb={'-5px'}>
        {testTaskReminderDate
          ? `You've scheduled it for ${testTaskReminderDate}`
          : 'Prefer to do it later?'}
      </Typography>
      <TextButton
        onClick={handleScheduleForLater}
        disabled={isDisabled}
        underlined
      >
        {testTaskReminderDate ? 'Reschedule' : 'Add to your calendar'}
      </TextButton>
    </Box>
  );
};

export default RescheduleTestTaskButton;
