import { Countries } from './countriesList';

export const SKIP_ENGLISH_LEVEL_FOR_COUNTRIES_LIST = [
  Countries.India,
  Countries.UnitedStates,
  Countries.UnitedKingdom,
  Countries.Australia,
  Countries.Canada,
  Countries.NewZealand,
];
