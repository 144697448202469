import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { resolveUserDuplicationStoreKey } from './resolveUserDuplication.const';
import { resolveUserDuplicationPath } from '../../constants/apiEndpoints';
import { formatErrorMessage } from '../../utils';
import { notificationAlert } from '../notifications';

interface IResolveUserDuplication {
  token?: string;
  useLatestResponse: boolean;
}

export const selectMainUser = createAsyncThunk(
  `${resolveUserDuplicationStoreKey}/selectMainUser`,
  async (body: IResolveUserDuplication, thunkAPI) => {
    try {
      const response = await axios.post(resolveUserDuplicationPath(), body);
      location.replace('/');
      return response.data;
    } catch (err: any) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(err);
    }
  },
);
