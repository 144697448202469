import { ICountry } from '../types';
import { DEFAULT_CITY_VALUE } from '../constants';
import { ICity } from 'src/types/location';

export const isSkipCityBasedOnCountry = (
  country: string | null | undefined,
  suggestedCountries: ICountry[],
): boolean => {
  return !suggestedCountries.find(
    (countryInfo) => countryInfo.name === country,
  );
};

export const shouldSkipOffice = (
  userCity: string | null | undefined,
  userOfficeLocation: string | null | undefined,
  officeLocations: ICity[],
): boolean => {
  if (officeLocations.some((loc) => loc.name === userCity)) {
    return false;
  }

  if (userOfficeLocation && userOfficeLocation === DEFAULT_CITY_VALUE) {
    return true;
  }

  return true;
};
