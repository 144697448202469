import { css, SerializedStyles } from '@emotion/react';
import { useTheme } from '@mui/material';

export type FileInputStyleProps = {
  progress?: number;
};

export const useInputFileStyles = (): SerializedStyles => {
  const theme = useTheme();
  return css`
    width: 100%;
    position: relative;

    .wrapper {
      width: 100%;
      height: 100%;
      position: relative;
    }

    .file-input {
      padding: 12px 21px;
      color: ${theme.palette.brand.accent};
      border-color: ${theme.palette.brand.accent};
      font-weight: bold;
      display: flex;
      justify-content: start;
      text-transform: initial;

      &-icon {
        &-attach {
          font-size: 30px !important;
        }

        &-close--fade {
          animation: fadeOut 3.5s ease-in-out;
          animation-fill-mode: both;
        }
      }

      &-labels {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;

        &--ext {
          font-weight: 100;
          color: ${theme.palette.text?.secondary};
          text-transform: none;
        }
        &--hint {
          color: ${theme.palette.button.primary.textDisabled};
          font-weight: 500;
          font-size: 13px;
          text-transform: none;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        &--filename {
          color: ${theme.palette.text?.primary};
          font-weight: 400;
          text-transform: none;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      &.drag-and-drop,
      &:hover {
        background-color: ${theme.palette.background.brandTransparent};
        border-color: ${theme.palette.brand.accent};
      }
    }

    .MuiIconButton-root {
      padding: 0;
    }

    .MuiLinearProgress-bar {
      background-color: ${theme.palette.background.brandTransparent};
    }

    .file-input-error {
      color: ${theme.palette.error.main};
      font-size: 0.8125rem;
      font-weight: 500;
      line-height: 1.66;
    }

    .linear-progress {
      position: absolute;
      top: 0;
      height: 100%;
      border-radius: 4px;
      background-color: ${theme.palette.background.brandTransparent};
      display: flex;
      align-items: center;
      justify-content: end;

      width: 0%;

      &-check {
        position: absolute;
        margin-right: 18px;
        color: ${theme.palette.brand.accent};
        top: 1rem;
        right: 1rem;
        opacity: 0;

        &--animate {
          animation: progressBarCheck 0.5s ease-in-out;
          animation-fill-mode: both;
        }
      }
    }

    @keyframes progressBarCheck {
      20% {
        opacity: 1;
      }
      100% {
        right: 0;
        opacity: 1;
      }
    }

    @keyframes fadeOut {
      90% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  `;
};
