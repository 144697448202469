import React, { useEffect, useState } from 'react';
import {
  Box,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import {
  ContinueMessage,
  PageTransitionWrapper,
  RadioButton,
  SelectableWrapper,
} from '../../components';
import { NOTICE_PERIOD_LIST } from '../../constants/noticePeriodList';
import { useAppDispatch, useAppSelector } from '../../redux';
import { updateNoticePeriod, userSelectors } from '../../redux/user';
import {
  gamifiedResponseSelectors,
  gamifiedResponseSlice,
} from '../../redux/gamifiedResponse';
import { getUserPrefferedName } from '../../utils';
import GoToPreviousStep from '../../components/goToPreviousStep/GoToPreviousStep';
import { logEvent } from 'src/services';
import isNumber from 'lodash/isNumber';

const STEP_MAX_WIDTH = '33.25rem';

export const NoticePeriod: React.FC = () => {
  const dispatch = useAppDispatch();
  const userData = useAppSelector(userSelectors.getUserData)!;
  const lastGamifiedMessage = useAppSelector(
    gamifiedResponseSelectors.getGamifiedResponse,
  );
  const [noticePeriodChecked, setNoticePeriodChecked] = useState<number | null>(
    userData.noticePeriod ?? null,
  );

  useEffect(() => {
    if (isNumber(userData.noticePeriod)) {
      dispatch(gamifiedResponseSlice.actions.updateMessage(null));
    } else {
      if (!lastGamifiedMessage) {
        dispatch(
          gamifiedResponseSlice.actions.updateMessage(
            `Welcome back, ${getUserPrefferedName(userData)}!`,
          ),
        );
      }
    }

    logEvent('launchpod-notice-period-page-loaded');
  }, []);

  const handleChange = (noticePeriodSelected: string) => {
    const noticePeriodNumber = +noticePeriodSelected;

    logEvent('launchpod-notice-period-selected');

    setNoticePeriodChecked(noticePeriodNumber);
    dispatch(
      gamifiedResponseSlice.actions.updateMessage('Hey, the stage is yours'),
    );
    setTimeout(() => {
      dispatch(updateNoticePeriod(noticePeriodNumber));
    }, 500);
  };

  return (
    <PageTransitionWrapper>
      <Box display="flex" data-testid="notice-period-layout">
        <GoToPreviousStep />
        <Typography variant="h2">
          What would be your notice{' '}
          <Box
            component="br"
            sx={(theme) => ({
              [theme.breakpoints.between('md', 'lg')]: {
                display: 'none',
              },
              [theme.breakpoints.down('sm')]: {
                display: 'none',
              },
            })}
          />
          period after getting an offer?
        </Typography>
      </Box>
      <Box maxWidth={{ sm: STEP_MAX_WIDTH }} mb={2}>
        <FormControl sx={{ display: 'flex' }}>
          <RadioGroup value={noticePeriodChecked}>
            <Stack spacing={0.5}>
              {NOTICE_PERIOD_LIST.map((period) => {
                return (
                  <SelectableWrapper
                    key={period.label}
                    value={period.value}
                    selectedValue={noticePeriodChecked}
                    onClick={handleChange}
                    testid="notice-period-option"
                  >
                    <FormControlLabel
                      value={period.value}
                      control={<RadioButton tabIndex={-1} />}
                      label={
                        <Typography variant="body1" fontWeight="500">
                          {period.label}
                        </Typography>
                      }
                    />
                  </SelectableWrapper>
                );
              })}
            </Stack>
          </RadioGroup>
        </FormControl>
      </Box>
      <ContinueMessage textIdentifier={1} />
    </PageTransitionWrapper>
  );
};

export default NoticePeriod;
