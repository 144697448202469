import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { sidebarStoreKey } from './sidebar.const';

export interface ISidebarSliceState {
  isSidebarOpen: boolean;
}

const initialState: ISidebarSliceState = {
  isSidebarOpen: false,
};

export const sidebarSlice = createSlice({
  name: sidebarStoreKey,
  initialState,
  reducers: {
    setIsSidebarOpen: (state, action: PayloadAction<boolean>) => {
      state.isSidebarOpen = action.payload;
    },
  },
});

export const { setIsSidebarOpen } = sidebarSlice.actions;
