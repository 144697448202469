import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { meetingStoreKey } from './meeting.const';
import { IMeeting } from '../../types';
import { API_STATUS } from '../../constants';
import { getActiveUserMeetings } from './meeting.thunks';

export interface IMeetingSliceState {
  apiStatus: API_STATUS;
  data: IMeeting[];
}

const initialState: IMeetingSliceState = {
  apiStatus: API_STATUS.IDLE,
  data: [],
};

export const meetingSlice = createSlice({
  name: meetingStoreKey,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getActiveUserMeetings.pending, (state) => {
        state.apiStatus = API_STATUS.LOADING;
      })
      .addCase(
        getActiveUserMeetings.fulfilled,
        (state, action: PayloadAction<IMeeting[]>) => {
          state.apiStatus = API_STATUS.COMPLETE;
          state.data = action.payload;
        },
      )
      .addCase(getActiveUserMeetings.rejected, (state) => {
        state.apiStatus = API_STATUS.FAILED;
      });
  },
});
