import { INotificationsState } from './notifications.slice';
import { INotification } from '../../types';
import { notificationsStoreKey } from './notifications.const';

interface IState {
  [notificationsStoreKey]: INotificationsState;
}

const selectNotificationsState = (state: IState) => {
  return state[notificationsStoreKey];
};

const getNotifications = (state: IState): INotification[] => {
  return selectNotificationsState(state).notifications;
};

export const notificationsSelector = {
  getNotifications,
};
