import { useAppDispatch, useAppSelector } from '../../redux';

import { CancelApplicationLayout } from 'src/features/cancelApplication/CancelApplicationLayout';
import { MainLayout, PageWrapper } from 'src/components';

import {
  postUnsubscribeFromReminders,
  unsubscribeFromRemindersSelectors,
} from '../../redux/unsubscribeFromReminders';

import { isStatusComplete, isStatusLoading } from 'src/utils';

const UnsubscribeFromReminders = () => {
  const dispatch = useAppDispatch();
  const unsubscribeApiStatus = useAppSelector(
    unsubscribeFromRemindersSelectors.getUnsubscribeFromRemindersApiStatus,
  );
  const unsubscribeData = useAppSelector(
    unsubscribeFromRemindersSelectors.getUnsubscribeFromRemindersData,
  );

  const isLoading = isStatusLoading(unsubscribeApiStatus);
  const isSubmitted = isStatusComplete(unsubscribeApiStatus);

  const onSubmit = (optionSelected: string) => {
    dispatch(
      postUnsubscribeFromReminders({
        user_email: unsubscribeData!.user_email,
        unsubscribeToken: unsubscribeData!.unsubscribeToken,
        unsubscribeType: unsubscribeData!.unsubscribeType,
        unsubscribeReason: optionSelected,
      }),
    );
  };

  return (
    <PageWrapper>
      <MainLayout
        bgcolor={{
          xs: 'background.default',
          md: 'background.backgroundPrimary',
        }}
        data-testid="unsubscribe-from-layout"
      >
        <CancelApplicationLayout
          userName={unsubscribeData?.user_name || ''}
          isLoading={isLoading}
          isSubmitted={isSubmitted}
          preselectedOption={unsubscribeData?.unsubscribeReason || ''}
          onSubmit={onSubmit}
        />
      </MainLayout>
    </PageWrapper>
  );
};

export default UnsubscribeFromReminders;
