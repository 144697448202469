import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { API_STATUS } from '../../constants';
import { positionsStoreKey } from './positions.const';
import { applyPosition, resetPosition } from './positions.thunks';
import { IPositionData } from '@types';

export interface IPositionsSliceState {
  apiStatus: API_STATUS;
  data: IPositionData | null;
}

const initialState: IPositionsSliceState = {
  apiStatus: API_STATUS.IDLE,
  data: null,
};

export const positionsSlice = createSlice({
  name: positionsStoreKey,
  initialState,
  reducers: {
    setPositionData: (state, action: PayloadAction<IPositionData>) => {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(applyPosition.pending, (state) => {
        state.apiStatus = API_STATUS.LOADING;
      })
      .addCase(
        applyPosition.fulfilled,
        (state, action: PayloadAction<IPositionData>) => {
          state.apiStatus = API_STATUS.COMPLETE;
          state.data = action.payload;
        },
      )
      .addCase(applyPosition.rejected, (state) => {
        state.apiStatus = API_STATUS.FAILED;
      })
      .addCase(resetPosition.pending, (state) => {
        state.apiStatus = API_STATUS.LOADING;
      })
      .addCase(resetPosition.fulfilled, (state) => {
        state.apiStatus = API_STATUS.COMPLETE;
        state.data = null;
      })
      .addCase(resetPosition.rejected, (state) => {
        state.apiStatus = API_STATUS.FAILED;
      });
  },
});

export const { setPositionData } = positionsSlice.actions;
