import { useRef } from 'react';
import { format } from 'date-fns';

import { ResponsiveBreak, StepPanel, StepPanelHeader } from 'src/components';
import LetsConnect from 'src/features/letsConnect/LetsConnect';
import { Typography } from '@mui/material';

import { useEmojisplosion, useUserMeetings } from 'src/hooks';

import calendarImage from '../../../assets/calendar-image.png';

export const TIScheduledLayout = () => {
  const starRef = useRef<HTMLDivElement>(null);
  useEmojisplosion(starRef, 'CALL_BOOKED');

  const { activeTechnicalInterview } = useUserMeetings();

  return (
    <StepPanel data-testid="schedule-layout" maxWidth="35.5rem">
      <StepPanelHeader
        ref={starRef}
        srcSetPng={calendarImage}
        imageWidth={{ xs: 57, sm: 77, lg: 90, xxl: 100 }}
        alt="Calendar"
      >
        Meet <br />
        your hiring manager!
      </StepPanelHeader>

      {activeTechnicalInterview && (
        <>
          <Typography>
            <b>The meeting has been scheduled</b>
            <ResponsiveBreak breakpoint="xs" />
            <b>
              for{' '}
              {format(
                new Date(activeTechnicalInterview.scheduledDate),
                'yyyy-MM-dd',
              )}
            </b>
            <ResponsiveBreak breakpoint="xs" />
            You will receive a calendar event
            <ResponsiveBreak breakpoint="xs" />
            that you can reschedule or cancel
          </Typography>
          <Typography variant="body1">See you!</Typography>
        </>
      )}
      <LetsConnect justifyContent={{ xs: 'flex-start', sm: 'center' }} mt={2} />
    </StepPanel>
  );
};
