import React from 'react';
import { Typography } from '@mui/material';
import { ResponsiveBreak } from '../../../../../components';

const NotAuthorizedToWorkInUsMessage: React.FC = () => {
  return (
    <>
      <Typography variant="body1" data-testid="success-text">
        Due to legal reasons, we can proceed only{' '}
        <ResponsiveBreak breakpoint="sm" />
        if candidate is authorized to work in United States.{' '}
      </Typography>
      <Typography variant="body1">
        If the situation changes, we would be happy{' '}
        <ResponsiveBreak breakpoint="sm" />
        to reconnect.
      </Typography>
    </>
  );
};

export default NotAuthorizedToWorkInUsMessage;
