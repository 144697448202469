export const EXPERIENCE_LIST = [
  "I'm just starting",
  '1 year or less',
  '6 years',
  '2 years',
  '7 years',
  '3 years',
  '8 years',
  '4 years',
  '9 years',
  '5 years',
  '10 years or more',
];

export const EXPERIENCE_LIST_MOBILE = [
  "I'm just starting",
  '1 year or less',
  '2 years',
  '3 years',
  '4 years',
  '5 years',
  '6 years',
  '7 years',
  '8 years',
  '9 years',
  '10 years or more',
];
