import { useMemo } from 'react';
import { useAppSelector } from '../redux';
import { userSelectors } from '../redux/user';
import { DetectedPositionType, ISubNavigationStep } from '../types';
import { APP_ROUTES } from '../constants';
import {
  isShowEngagementBasedOnCountry,
  isSkipCityBasedOnCountry,
  isSkipEnglishLevelBasedOnCountry,
  isSkipSeniorityBasedOnExperience,
  isSkipSpecializationBasedOnUserPosition,
} from '../utils';
import { usePositionData } from './usePositionData';
import { useLPLocation } from './useLPLocation';
import isNumber from 'lodash/isNumber';

export const useQuestioneerNavigationSteps = () => {
  const userData = useAppSelector(userSelectors.getUserData);
  const positionData = usePositionData();
  const { suggestedCountries } = useLPLocation();

  const navigationSteps = useMemo(() => {
    const steps: ISubNavigationStep[] = [];
    const isSkipEnglishLevel = isSkipEnglishLevelBasedOnCountry(
      userData?.country,
    );

    const isSkipCity = isSkipCityBasedOnCountry(
      userData?.country,
      suggestedCountries,
    );

    const isSkipSeniority = isSkipSeniorityBasedOnExperience(
      userData?.yearsOfExperience,
    );
    const isSkipSpecializationAndSkills =
      isSkipSpecializationBasedOnUserPosition(positionData);
    const isShowEngagement = isShowEngagementBasedOnCountry(userData?.country);

    steps.push({
      label: 'Tell us about yourself',
      route: '',
      isHeader: true,
      isActive: true,
    });

    steps.push({
      label: 'Email',
      route: APP_ROUTES.EMAIL,
      isEditable: !!userData?.email,
    });

    steps.push({
      label: 'Your name',
      route: APP_ROUTES.NAME,
      isEditable: !!userData?.firstName,
    });

    steps.push({
      label: 'Country of residence',
      route: APP_ROUTES.COUNTRY,
      isEditable: !!userData?.country,
    });

    if (!isSkipCity) {
      steps.push({
        label: 'City',
        route: APP_ROUTES.CITY,
        isEditable: !!userData?.city,
      });
    }

    if (!isSkipEnglishLevel) {
      steps.push({
        label: 'English',
        route: APP_ROUTES.ENGLISH_LEVEL,
        isEditable: !!userData?.englishLevel,
      });
    }

    if (!isSkipSpecializationAndSkills) {
      const specializationRoute =
        positionData?.detectedPosition === DetectedPositionType.PARTIAL
          ? `${APP_ROUTES.TECH_SKILLS}/${APP_ROUTES.PRIMARY_SKILLS}`
          : APP_ROUTES.TECH_SKILLS;

      steps.push({
        label: 'Specialization and skills',
        route: specializationRoute,
        isEditable: !!userData?.specialization,
      });
    }

    steps.push({
      label: 'Years of experience',
      route: APP_ROUTES.EXPERIENCE,
      isEditable: isNumber(userData?.yearsOfExperience),
    });

    if (!isSkipSeniority) {
      steps.push({
        label: 'Seniority',
        route: APP_ROUTES.SENIORITY,
        isEditable: !!userData?.seniority,
      });
    }

    if (isShowEngagement) {
      steps.push({
        label: 'Engagement',
        route: APP_ROUTES.ENGAGEMENT,
        isEditable: !!userData?.employmentType,
      });
    }

    steps.push({
      label: 'Compensation',
      route: APP_ROUTES.COMPENSATION,
      isEditable: isNumber(userData?.compensationExpectations),
    });

    steps.push({
      label: 'Notice period',
      route: APP_ROUTES.NOTICE_PERIOD,
      isEditable: isNumber(userData?.noticePeriod),
    });

    steps.push({
      label: 'Professional identity',
      route: APP_ROUTES.PROFESSIONAL_IDENTITY,
      isEditable: !!userData?.originalCvFileName,
    });

    return steps;
  }, [userData, positionData?.detectedPosition, suggestedCountries]);

  return { navigationSteps };
};
