import { IUserRouteSliceState } from './userRoute.slice';
import { userRouteStoredKey } from './userRoute.const';

interface IState {
  [userRouteStoredKey]: IUserRouteSliceState;
}

const selectUserRoute = (state: IState) => {
  return state[userRouteStoredKey];
};

const getUserRoute = (state: IState): string => {
  return selectUserRoute(state).route;
};

export const userRouteSelector = {
  getUserRoute,
};
