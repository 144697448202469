import { STANDALONE_PAGES } from 'src/constants';
import { useLocation } from 'react-router-dom';

export const useIsStandalonePage = () => {
  const location = useLocation();

  return STANDALONE_PAGES.some((appRoute) =>
    location.pathname.includes(appRoute),
  );
};
