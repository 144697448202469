import { ISkillData, ISpecializationData } from '@types';
import {
  DEVELOPER_TYPES,
  WORK_AREA_TYPE,
  WORK_AREA_TYPE_BY_SPECIALIZATION_TYPE,
} from '../constants';
import { IPositionData } from '../types/positionData';

export const getWorkAreaBySpecialization = (
  specialization: string | null | undefined,
  specializationList: ISpecializationData[],
): WORK_AREA_TYPE | null => {
  const specializationMapping = specializationList.find(
    (e) => e.value === specialization,
  );

  if (!specializationMapping) return null;

  return (
    WORK_AREA_TYPE_BY_SPECIALIZATION_TYPE[specializationMapping.type] || null
  );
};

export const getPrimarySkillsBySpecialization = (
  specialization: string,
  skillsList: ISkillData[],
) => {
  const developerSubSpecializations = [
    DEVELOPER_TYPES.backend,
    DEVELOPER_TYPES.frontend,
  ];

  if (specialization === DEVELOPER_TYPES.fullstack) {
    return skillsList.filter(
      (skillObject) =>
        developerSubSpecializations.includes(skillObject.specialization!) &&
        skillObject.type === 'primary',
    );
  }

  return skillsList.filter(
    (skillObject) =>
      skillObject.specialization === specialization &&
      skillObject.type === 'primary',
  );
};

export const getSpecializationTitleByValue = (
  specializations: ISpecializationData[],
  value?: string | null,
): string => specializations.find((e) => e.value === value)?.title || '';

export const getPositionSkills = (positions?: IPositionData[]) => {
  if (!positions || !positions.length) return null;
  const userPosition = positions[positions.length - 1];
  return userPosition?.detectedSkills ?? null;
};
