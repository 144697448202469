import { useEffect } from 'react';
import { v4 as uuidV4 } from 'uuid';
import { LOCAL_STORAGE_KEYS } from '../constants';
import { useAppDispatch, useAppSelector } from '../redux';
import { fetchFeatureFlags } from '../redux/featureFlags';
import { userSelectors } from '../redux/user';
import { LocalStorageService } from '../services';
import { useIsStandalonePage } from './useIsStandalonePage';

export const useFetchFeatureFlags = (shouldFetch?: boolean) => {
  const dispatch = useAppDispatch();
  const isStandalonePage = useIsStandalonePage();

  const userId = useAppSelector(userSelectors.getUserId);
  const localSessionId = LocalStorageService.getState<string | null>(
    LOCAL_STORAGE_KEYS.SESSION_ID,
  );
  const sessionId = localSessionId ?? uuidV4();

  useEffect(() => {
    if (isStandalonePage || !shouldFetch) return;

    dispatch(fetchFeatureFlags(sessionId));
  }, [userId, isStandalonePage, shouldFetch]);
};
