/** @jsxImportSource @emotion/react */
import React from 'react';
import { Stack, TextareaAutosize, TextField, Typography } from '@mui/material';
import { CustomButton } from '../../../components';
import { useAppDispatch, useAppSelector } from '../../../redux';
import { userSelectors } from '../../../redux/user';
import { validationSchema } from './validationSchema';
import { useFormik } from 'formik';
import { useShareFeedbackFormStyles } from './ShareFeedbackForm.styles';
import { gamifiedResponseSlice } from '../../../redux/gamifiedResponse';
import { feedbackSelectors, postFeedback } from '../../../redux/feedback';
import { isStatusIdle, isStatusLoading } from '../../../utils';

interface IProps {
  handleClose: () => void;
}

const ShareFeedbackForm: React.FC<IProps> = ({ handleClose }) => {
  const dispatch = useAppDispatch();
  const styles = useShareFeedbackFormStyles();
  const userData = useAppSelector(userSelectors.getUserData)!;
  const apiStatus = useAppSelector(feedbackSelectors.getFeedbackApiStatus);
  const isLoading = isStatusLoading(apiStatus);

  const formik = useFormik({
    initialValues: {
      email: userData.email ?? '',
      feedback: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      dispatch(gamifiedResponseSlice.actions.updateMessage(null));
      dispatch(
        postFeedback({
          feedback: values.feedback,
          isLogged: Boolean(userData.email),
          page: window.location.href,
        }),
      );
      if (isStatusIdle(apiStatus)) {
        dispatch(
          gamifiedResponseSlice.actions.updateMessage(
            `Thank you! Your feedback was submitted  📬`,
          ),
        );
      }

      if (handleClose) {
        handleClose();
      }
    },
  });

  return (
    <div css={styles}>
      <Typography variant="h1" sx={{ mb: 4 }}>
        Report issue
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={4} mb={4}>
          <TextField
            multiline
            className="feedback-text"
            name="feedback"
            placeholder="Report a bug or suggest an improvement"
            minRows={15}
            value={formik.values.feedback}
            onChange={formik.handleChange}
            error={formik.touched.feedback && Boolean(formik.errors.feedback)}
            helperText={formik.touched.feedback && formik.errors.feedback}
            InputProps={{
              inputComponent: TextareaAutosize,
              rows: 10,
            }}
          />
          <TextField
            name="email"
            placeholder="Email"
            label="Email"
            type="text"
            variant="outlined"
            disabled={Boolean(userData.email)}
            value={userData.email ?? formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <Typography
            variant="caption"
            color="text.secondary"
            sx={{ mt: '0.5rem !important' }}
          >
            Our team may email you for clarifying details.
          </Typography>
          <CustomButton
            color="secondary"
            type="submit"
            label="Send"
            isDisabled={
              !Boolean(formik.values.email) || !Boolean(formik.values.feedback)
            }
            isLoading={isLoading}
            fullWidth
          />
        </Stack>
      </form>
    </div>
  );
};

export default ShareFeedbackForm;
