import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/redux';

import { Box, Stack, Typography } from '@mui/material';

import { API_STATUS } from 'src/constants';
import { BrandLogo, PageWrapper, ResponsiveBreak } from 'src/components';
import {
  videoInterviewSelectors,
  viewUserVideoInterview,
} from 'src/redux/videoInterview';
import { VideoInterviewQuestions } from './components/VideoInterviewQuestions';
import { isStatusLoading } from 'src/utils';
import LockerIcon from 'src/assets/locker.png';

export const VideoInterviewView: React.FC = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const {
    apiStatus: viewUserVideoInterviewApiStatus,
    data: viewUserVideoInterviewData,
  } = useAppSelector(videoInterviewSelectors.getUserVideoInterviewViewAPIData);

  useEffect(() => {
    const token = searchParams.get('token');
    if (token) {
      dispatch(viewUserVideoInterview({ token }));
    }
  }, []);

  return (
    <PageWrapper isLoading={isStatusLoading(viewUserVideoInterviewApiStatus)}>
      <Stack
        sx={(theme) => ({
          background: theme.palette.background.backgroundPrimary,
          minWidth: '100%',
          minHeight: '100%',
        })}
      >
        <Box
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: 10,
            padding: '3rem 0 0 4.125rem',
          }}
        >
          <BrandLogo width={133} />
        </Box>
        <Stack
          justifyContent="center"
          alignItems="center"
          gap="0.5rem"
          flex={1}
        >
          {viewUserVideoInterviewApiStatus === API_STATUS.COMPLETE &&
          viewUserVideoInterviewData ? (
            <VideoInterviewQuestions
              userVideoInterviewData={viewUserVideoInterviewData}
            />
          ) : (
            <></>
          )}
          {viewUserVideoInterviewApiStatus === API_STATUS.FAILED && (
            <>
              <img
                src={LockerIcon}
                style={{ height: '10.75rem', width: '10.75rem' }}
              />
              <Typography variant="h1">
                You are not allowed to see this info
              </Typography>
              <Typography
                variant="body1"
                color="text.secondary"
                textAlign="center"
              >
                Looks like the secret link has expired.
                <ResponsiveBreak breakpoint="sm" />
                Please contact a person who shared it with you to generate a new
                one.
              </Typography>
            </>
          )}
        </Stack>
      </Stack>
    </PageWrapper>
  );
};
