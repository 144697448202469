import React, { FC, MouseEventHandler, ReactElement } from 'react';
import { BoxProps, IconButton, Tooltip, Typography } from '@mui/material';
import { Flex } from '../flex/Flex';
import EditIcon from '@mui/icons-material/Edit';
import { LockIcon } from '../../features/profileReview/components';

interface IProps extends Omit<BoxProps, 'children' | 'onClick'> {
  label: string;
  Component: ReactElement;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  isLocked?: boolean | false;
  lockLabel?: string;
}
export const RowLayout: FC<IProps> = ({
  label,
  Component,
  onClick,
  isLocked = false,
  lockLabel = '',
  ...rest
}) => (
  <Flex
    sx={{
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
      '&:last-child': {
        borderBottom: 'none',
      },
    }}
    {...rest}
  >
    <Flex
      alignItems={{ xs: 'flex-start', sm: 'center' }}
      flexDirection={{ xs: 'column', sm: 'row' }}
      py={1.25}
    >
      <Flex flex={1} maxWidth={{ sm: '30%' }}>
        <Typography variant="body2" color="text.brand">
          {label}
        </Typography>
      </Flex>
      <Flex
        gap={1}
        flex={2}
        maxWidth={{ sm: '70%' }}
        sx={{ overflowWrap: 'anywhere' }}
      >
        {Component}
      </Flex>
    </Flex>

    {onClick &&
      (isLocked ? (
        <Tooltip title={lockLabel} placement="top">
          <LockIcon mr={1} fontSize="small" sx={{ color: 'text.secondary' }} />
        </Tooltip>
      ) : (
        <IconButton onClick={onClick}>
          <EditIcon fontSize="small" sx={{ color: 'text.secondary' }} />
        </IconButton>
      ))}
  </Flex>
);
