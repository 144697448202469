import {
  CustomButton,
  Flex,
  ScheduleDialog,
  StepPanel,
  StepPanelHeader,
  TextButton,
} from '../../../components';
import { Typography, Box } from '@mui/material';

import coffeeImage from '../../../assets/coffee.png';
import { useAppDispatch, useAppSelector } from '../../../redux';
import { useUserVideoInterview } from '../../../hooks';
import { restartVideoInterview } from '../../../redux/videoInterview';
import { confirmIntermediateScreen, userSelectors } from '../../../redux/user';
import { useState } from 'react';
import { logEvent } from 'src/services';
import { formatReminderDate } from '../../../utils';

export default function QuitedInterviewLayout({
  isLoading,
  starRef,
  confirmIntermediateScreenLoading,
}: {
  isLoading: boolean;
  starRef: React.RefObject<HTMLDivElement>;
  confirmIntermediateScreenLoading: boolean;
}) {
  const dispatch = useAppDispatch();

  const [showScheduleDialog, setShowScheduleDialog] = useState(false);
  const { allQuestionsAmount, finishedQuestionsAmount, userVideoInterview } =
    useUserVideoInterview();
  const userData = useAppSelector(userSelectors.getUserData)!;
  const userReminderDate = userData.videoInterviewReminderDate;
  const [showRescheduleMessage, setShowRescheduleMessage] = useState(
    Boolean(userReminderDate),
  );

  const handleRestartVideoInterview = () => {
    if (userVideoInterview && !isLoading) {
      return dispatch(restartVideoInterview(userVideoInterview.id));
    }
  };

  const nextStep = () => {
    logEvent('launchdpod - video-interview-finished-page_continue-clicked');

    dispatch(confirmIntermediateScreen());
  };

  const handleScheduleSuccess = () => {
    setShowRescheduleMessage(true);
    setShowScheduleDialog(false);
  };
  return (
    <StepPanel data-testid="video-interview-finished-step" maxWidth="45rem">
      <StepPanelHeader
        srcSetPng={coffeeImage}
        ref={starRef}
        alt="Async video interview stopped"
      >
        Async video interview stopped
      </StepPanelHeader>
      <Typography variant="body1">
        Looks like you’ve answered {finishedQuestionsAmount} out of{' '}
        {allQuestionsAmount} questions. We may reach out to you to finish it
        later.
      </Typography>
      <Flex width={{ xs: '100%', sm: 'auto' }}>
        <CustomButton
          onClick={nextStep}
          label="Continue your application"
          color="primary"
          isLoading={confirmIntermediateScreenLoading}
          fullWidth
        />
      </Flex>
      <Flex justifyContent="center" columnGap="45px">
        <Box className="add-to-calendar-wrapper">
          <Typography variant="body2" color="text.secondary">
            Want to resume the recording?
          </Typography>
          <Typography
            variant="body1"
            fontSize="bold"
            color="brand.accent"
            sx={{ cursor: 'pointer' }}
            onClick={handleRestartVideoInterview}
          >
            Finish answering
          </Typography>
        </Box>

        {!userReminderDate && (
          <Box className="add-to-calendar-wrapper">
            <Typography variant="body2" color="text.secondary">
              Would you prefer to do it later?
            </Typography>
            <Typography
              variant="body1"
              fontSize="bold"
              color={isLoading ? 'text.secondary' : 'brand.accent'}
              sx={{ cursor: 'pointer' }}
              onClick={() => setShowScheduleDialog(true)}
            >
              Add to your calendar
            </Typography>
          </Box>
        )}
      </Flex>
      {userReminderDate && showRescheduleMessage && (
        <Box sx={{ maxWidth: '40rem' }}>
          <Box
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              background: theme.palette.highlight.accent,
              p: 3,
              alignItems: 'center',
            })}
          >
            <Typography variant="body1" fontWeight="bold">
              The video interview has been scheduled <br /> for{' '}
              {formatReminderDate(userReminderDate, userData.country!)}
            </Typography>
            <Typography variant="body1">
              You will receive a calendar event that you can{' '}
              <TextButton
                underlined
                onClick={() => setShowScheduleDialog(true)}
              >
                reschedule or cancel
              </TextButton>
            </Typography>
          </Box>
        </Box>
      )}
      {showScheduleDialog && (
        <ScheduleDialog
          isOpen={showScheduleDialog}
          handleClose={() => setShowScheduleDialog(false)}
          handleScheduleSuccess={handleScheduleSuccess}
          isVideoInterview
        />
      )}
    </StepPanel>
  );
}
