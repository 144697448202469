export interface ICreteReferralBody {
  name: string;
  email: string;
  note?: string;
  position?: string;
  utmSource?: string;
  utmMedium?: string;
}

export interface IDeclineReferralBody {
  reason: string;
  token: string;
}

export enum AUTH_STATUS {
  FAILED = 'failed',
  LOADING = 'loading',
  LOGGEDIN = 'loggedin',
  ANONYMOUS = 'anonymous',
}
