import React, { useRef } from 'react';

import {
  CustomButton,
  Flex,
  StepPanel,
  StepPanelHeader,
} from '../../../components';

import { useEmojisplosion } from '../../../hooks';

import brokenHeart from 'src/assets/broken-heart-icon.png';
import { Typography } from '@mui/material';

const ReferFriendRejected = ({
  onReferMoreFriendsClick,
  referralName,
  isRefferalInProcess,
}: {
  onReferMoreFriendsClick: () => void;
  referralName: string;
  isRefferalInProcess: boolean;
}) => {
  const starRef = useRef<HTMLDivElement>(null);
  useEmojisplosion(starRef, 'BAD_DONE');

  return (
    <StepPanel maxWidth="34rem">
      <StepPanelHeader ref={starRef} srcSetPng={brokenHeart} alt="Broken heart">
        We Know {referralName}
      </StepPanelHeader>
      <Typography
        variant="body1"
        color="primary"
        sx={{
          fontSize: '16px',
          marginTop: '20px',
          textAlign: 'center',
        }}
      >
        {isRefferalInProcess ? (
          <>
            It breaks our heart to tell you this contact <br />
            has an active process with us and cannot be referred at the moment.
            <br />
          </>
        ) : (
          <>
            It breaks our heart to tell you this contact <br />
            has been reffered to us just recently. <br />
          </>
        )}
      </Typography>
      <Typography
        variant="body1"
        color="primary"
        sx={{
          fontSize: '16px',
          marginTop: '10px',
          textAlign: 'center',
        }}
      >
        We're open for more suggestions.
      </Typography>
      <Flex width={{ xs: '100%', sm: 'auto' }}>
        <CustomButton
          onClick={onReferMoreFriendsClick}
          label="Refer more friends"
          color="primary"
          fullWidth
        />
      </Flex>
    </StepPanel>
  );
};

export default ReferFriendRejected;
