import { combineReducers } from '@reduxjs/toolkit';
import { authSlice, authStoreKey } from './auth';
import { locationsSlice, locationsStoreKey } from './locations';
import { feedbackSlice, feedbackStoreKey } from './feedback';
import {
  gamifiedResponseSlice,
  gamifiedResponseStoreKey,
} from './gamifiedResponse';
import { notificationsSlice, notificationsStoreKey } from './notifications';
import { skillsSlice, skillsStoreKey } from './skills';
import { userSlice, userStoreKey } from './user';
import { testTasksSlice, testTasksStoreKey } from './testTasks';
import { calendlySlice, calendlyStoreKey } from './calendly';
import {
  unsubscribeFromRemindersSlice,
  unsubscribeFromRemindersStoreKey,
} from './unsubscribeFromReminders';

import {
  resolveUserDuplicationSlice,
  resolveUserDuplicationStoreKey,
} from './resolveUserDuplication';
import { sidebarStoreKey, sidebarSlice } from './sidebar';
import { featureFlagsStoreKey, featureFlagsSlice } from './featureFlags';
import { referFriendSlice, referFriendStoreKey } from './referFriend';
import {
  userGeoLocationSlice,
  userGeoLocationStoredKey,
} from './userGeoLocation';
import { userRouteSlice, userRouteStoredKey } from './userRoute';
import {
  videoInterviewDataStoreKey,
  videoInterviewSlice,
} from './videoInterview';
import { positionsSlice, positionsStoreKey } from './positions';
import {
  magicLinkCandidateSlice,
  magicLinkCandidateStoreKey,
} from './magicLinkCandidate';
import {
  massCampaignCandidateSlice,
  massCampaignCandidateStoreKey,
} from './massCampaignCandidate';
import { meetingSlice, meetingStoreKey } from './meeting';
import { knowledgeQuizStoreKey, knowledgeQuizSlice } from './knowledgeQuiz';
import { exchangeRatesStoreKey, exchangeRatesSlice } from './exchangeRates';
import {
  referralJobOpeningsSlice,
  referralJobOpeningsStoreKey,
} from './referralJobOpenings';
import {
  candidateSourceSlice,
  candidateSourceStoreKey,
} from './candidateSource';

// Create the root reducer independently to obtain the RootState type
export const rootReducer = combineReducers({
  [authStoreKey]: authSlice.reducer,
  [locationsStoreKey]: locationsSlice.reducer,
  [feedbackStoreKey]: feedbackSlice.reducer,
  [gamifiedResponseStoreKey]: gamifiedResponseSlice.reducer,
  [notificationsStoreKey]: notificationsSlice.reducer,
  [skillsStoreKey]: skillsSlice.reducer,
  [testTasksStoreKey]: testTasksSlice.reducer,
  [videoInterviewDataStoreKey]: videoInterviewSlice.reducer,
  [userStoreKey]: userSlice.reducer,
  [calendlyStoreKey]: calendlySlice.reducer,
  [unsubscribeFromRemindersStoreKey]: unsubscribeFromRemindersSlice.reducer,
  [resolveUserDuplicationStoreKey]: resolveUserDuplicationSlice.reducer,
  [sidebarStoreKey]: sidebarSlice.reducer,
  [featureFlagsStoreKey]: featureFlagsSlice.reducer,
  [referFriendStoreKey]: referFriendSlice.reducer,
  [userGeoLocationStoredKey]: userGeoLocationSlice.reducer,
  [userRouteStoredKey]: userRouteSlice.reducer,
  [positionsStoreKey]: positionsSlice.reducer,
  [magicLinkCandidateStoreKey]: magicLinkCandidateSlice.reducer,
  [massCampaignCandidateStoreKey]: massCampaignCandidateSlice.reducer,
  [meetingStoreKey]: meetingSlice.reducer,
  [knowledgeQuizStoreKey]: knowledgeQuizSlice.reducer,
  [exchangeRatesStoreKey]: exchangeRatesSlice.reducer,
  [referralJobOpeningsStoreKey]: referralJobOpeningsSlice.reducer,
  [candidateSourceStoreKey]: candidateSourceSlice.reducer,
});
