import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiEndpoints, LOCAL_STORAGE_KEYS } from '../../constants';
import { LocalStorageService } from '../../services';
import { featureFlagsStoreKey } from './featureFlags.const';

export const fetchFeatureFlags = createAsyncThunk(
  `${featureFlagsStoreKey}/fetchFeatureFlags`,
  async (sessionId: string) => {
    try {
      const response = await axios.get(
        apiEndpoints.fetchAbTestsSession(sessionId),
      );
      if (response.data.id) {
        LocalStorageService.setState<string>(
          LOCAL_STORAGE_KEYS.SESSION_ID,
          response.data.id,
        );
      }
      return response.data;
    } catch (err: any) {
      throw new Error(err);
    }
  },
);
