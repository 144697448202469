import React from 'react';
import { Typography } from '@mui/material';
import { ResponsiveBreak } from '../../../../../components';

const NotSupportedLegalRestrictionsMessage: React.FC = () => {
  return (
    <>
      <Typography variant="body1" data-testid="success-text">
        Unfortunately, due to some changes in the requirements, we're not able
        to proceed this time.
      </Typography>
      <Typography variant="body1">
        If the situation changes, we would be happy{' '}
        <ResponsiveBreak breakpoint="sm" />
        to reconnect.
      </Typography>
    </>
  );
};

export default NotSupportedLegalRestrictionsMessage;
