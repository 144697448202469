export enum VideoInterviewQuestionStatus {
  PREPARATION = 'PREPARATION',
  LOADING = 'LOADING',
  STARTED = 'STARTED',
  STOPPING = 'STOPPING',
  STOPPED = 'STOPPED',
  FINISHED = 'FINISHED',
}
export interface IVideoInterviewQuestion {
  title: string;
  duration: number;
  preparation: number;
  questionNumber: number;
}
