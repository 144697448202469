import React from 'react';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { Stack } from '@mui/material';
import MessageOptions from './components/MessageOptions';

interface IProps {
  textIdentifier?: number;
}

export const ContinueMessage: React.FC<IProps> = ({
  textIdentifier = -1,
}): React.ReactElement => {
  return (
    <Stack spacing={0.5} direction="row" alignItems="center">
      <MessageOptions textIdentifier={textIdentifier} />
      {![1, 4, 5].includes(textIdentifier) && (
        <KeyboardReturnIcon
          sx={(theme) => ({ color: theme.palette.text.secondary })}
          fontSize="small"
        />
      )}
    </Stack>
  );
};

export default ContinueMessage;
