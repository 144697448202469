import { Radio, RadioProps, styled } from '@mui/material';

const UncheckIcon = styled('span')(() => ({
  borderRadius: '50%',
  width: 22,
  height: 22,
  boxShadow: 'inset 0 0 0 2px black',
  transition: 'boxShadow 2s linear 0s',
}));

const CheckedIcon = styled(UncheckIcon)(({ theme }) => ({
  backgroundColor: theme.palette.highlight.white,
  boxShadow: `inset 0 0 0 7px ${theme.palette.brand.accent}`,
  animation: 'selectRadio 0.25s',
  '@keyframes selectRadio': {
    '0%': {
      boxShadow: 'inset 0 0 0 2px black',
    },
    '100%': {
      boxShadow: `inset 0 0 0 7px ${theme.palette.brand.accent}`,
    },
  },
}));

export const RadioButton = (props: RadioProps) => {
  return (
    <Radio
      sx={{
        '&:hover': {
          bgcolor: 'transparent',
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<CheckedIcon />}
      icon={<UncheckIcon />}
      {...props}
    />
  );
};
