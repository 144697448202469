/** @jsxImportSource @emotion/react */
import { useNotFoundStyles } from './NotFound.styles';
import { Typography } from '@mui/material';
import { PageWrapper } from '../../components';

const NotFound: React.FC = () => {
  const styles = useNotFoundStyles();

  return (
    <PageWrapper>
      <div className="not-found-wrapper" css={styles}>
        <div className="nf-container">
          <Typography typography="h1" p={2} className="nf-status">
            404
          </Typography>
          <Typography typography="h2" p={1}>
            Page Not Found
          </Typography>
          <div className="nf-link">Go back to the previous page</div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default NotFound;
