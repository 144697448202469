import { useRef } from 'react';

import { ResponsiveBreak, StepPanel, StepPanelHeader } from 'src/components';
import LetsConnect from 'src/features/letsConnect/LetsConnect';
import { Typography } from '@mui/material';

import { useEmojisplosion } from 'src/hooks';

import calendarImage from '../../../assets/calendar-image.png';

export const ICPassedLayout = () => {
  const starRef = useRef<HTMLDivElement>(null);
  useEmojisplosion(starRef, 'CALL_BOOKED');

  return (
    <StepPanel data-testid="schedule-layout" maxWidth="35.5rem">
      <StepPanelHeader
        ref={starRef}
        srcSetPng={calendarImage}
        imageWidth={{ xs: 57, sm: 77, lg: 90, xxl: 100 }}
        alt="Calendar"
      >
        Thanks for the meeting <br />
        with our talent executive
      </StepPanelHeader>
      <Typography>
        We will email you with the next steps.{' '}
        <ResponsiveBreak breakpoint="xs" />
        Typically it takes 1–2 business days.
      </Typography>
      <Typography variant="body1">Hope to see you soon!</Typography>
      <LetsConnect justifyContent={{ xs: 'flex-start', sm: 'center' }} mt={2} />
    </StepPanel>
  );
};
