import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { referFriendStoreKey } from './referFriend.const';
import { declineReferral, submitReferral, login } from './referFriend.thunks';
import { AUTH_STATUS } from '../../types/referFriend';

export interface IReferFriendSliceState {
  isCreating: boolean;
  wasRejected: boolean;
  inProcess: boolean;
  isDeclining: boolean;
  referralName: string;
  authStatus: AUTH_STATUS;
}

const initialState: IReferFriendSliceState = {
  isCreating: false,
  wasRejected: false,
  inProcess: false,
  isDeclining: false,
  referralName: '',
  authStatus: AUTH_STATUS.LOADING,
};

export const referFriendSlice = createSlice({
  name: referFriendStoreKey,
  initialState,
  reducers: {
    setReferralAuthStatus: (state, action: PayloadAction<AUTH_STATUS>) => {
      state.authStatus = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitReferral.pending, (state) => {
        state.isCreating = true;
      })
      .addCase(submitReferral.fulfilled, (state, action) => {
        state.isCreating = false;
        state.wasRejected = action.payload.wasRejected;
        state.inProcess = action.payload.inProcess ?? false;
        state.referralName = action.payload.referralName || '';
      })
      .addCase(submitReferral.rejected, (state) => {
        state.isCreating = false;
      })
      .addCase(declineReferral.pending, (state) => {
        state.isDeclining = true;
      })
      .addCase(declineReferral.fulfilled, (state) => {
        state.isDeclining = false;
      })
      .addCase(declineReferral.rejected, (state) => {
        state.isDeclining = false;
      })
      .addCase(login.pending, (state) => {
        state.authStatus = AUTH_STATUS.LOADING;
      })
      .addCase(login.fulfilled, (state) => {
        state.authStatus = AUTH_STATUS.LOGGEDIN;
      })
      .addCase(login.rejected, (state) => {
        state.authStatus = AUTH_STATUS.FAILED;
      });
  },
});

export const { setReferralAuthStatus } = referFriendSlice.actions;
