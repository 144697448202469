import { Typography } from '@mui/material/styles/createTypography';
import palette from './palette';

export default <Typography>{
  fontFamily:
    'Montserrat, DejaVu Sans, Verdana, "Apple Color Emoji", "Noto Color Emoji", sans‑serif',

  fontWeightRegular: 500,
  fontWeightBold: 600,
  h1: {
    fontSize: '2rem',
    fontWeight: '500',
    letterSpacing: '-0.04em',
  },
  h2: {
    fontSize: '2rem',
    fontWeight: '500',
    letterSpacing: '-0.04em',
    marginBottom: '2rem',
  },
  h3: {
    fontSize: '1.5rem',
    fontWeight: '500',
    letterSpacing: '-0.03em',
    marginBottom: '0.5rem',
    color: palette.brand?.accent,
  },
  h4: {
    fontSize: '1.5rem',
    fontWeight: '500',
    letterSpacing: '-0.03em',
  },
  body1: {
    fontSize: '1rem',
    fontWeight: '500',
    letterSpacing: '-0.008em',
  },
  body2: {
    fontWeight: '500',
    fontSize: '0.8125rem',
    letterSpacing: '0.002em',
  },
  caption: {
    fontSize: '0.8125rem',
  },
  button: {
    fontWeight: '600',
    letterSpacing: '0.025em',
  },
};
